import ReportItemData, {
  ReportItemProperties
} from '@/store/packages/ReportItem/ReportItemData';
import Constructable from '@/interfaces/Constructable';
import RICommon from '@/store/packages/ReportItem/RICommon';

const createRID = ({
  backItem,
  unitItem,
  BaseRID,
  reportItemProperties,
  report_item
}: {
  report_item: ReportItemData<any, any>;
  reportItemProperties: ReportItemProperties<unknown>;
  BaseRID: Constructable<ReportItemData<unknown, unknown>>;
  unitItem?: any;
  backItem?: any;
}) => {
  const RIDInstance = unitItem ? BaseRID : RICommon
  const backRID = backItem
  // @ts-ignore
  ? new BaseRID({ ...report_item, ri_wialon_id: report_item.ri_back_wialon_id }, { ...reportItemProperties, unitItem: backItem })
    : undefined

  // @ts-ignore
  const rid = new RIDInstance(report_item, { ...reportItemProperties, unitItem, backRID });
  return rid
};

export default createRID
