import { ActionContext } from 'vuex'
import { State } from '../state'
import { IChatBotToolConfig } from '@/store/interfaces/Chat'

export default {
  async getChatToolsByChatItemId ({ dispatch }: ActionContext<State, string>, id_chat_item: number) {
    try {
      const { data }: { data: IChatBotToolConfig } = await dispatch(
        'axios',
        {
          url: `chat/item/bot/tools/get/by_chat_item/${id_chat_item}`,
          method: 'GET'
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_CHAT_TOOLS_BY_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async createUpdateChatToolConfig ({ dispatch }: ActionContext<State, string>, payload: IChatBotToolConfig) {
    try {
      const { data }: { data: IChatBotToolConfig } = await dispatch(
        'axios',
        {
          url: 'chat/item/bot/tools/create_or_update',
          method: 'POST',
          data: payload
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_UPDATE_CHAT_TOOL', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
