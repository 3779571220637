import { RouteConfig } from 'vue-router'
import store from '@/store/index'

const Cliente: RouteConfig = {
  path: '/Conductor',
  name: 'Conductor',
  component: () => import('../views/Conductor.vue'),
  async beforeEnter (to, from, next) {
    // @ts-ignore
    const isLogged: boolean = store.state.auth.is_auth
    // @ts-ignore
    if (isLogged) {
      try {
        await store.dispatch(
          'sys/setLoaderDialogState',
          {
            isLoader: true,
            dialogMessage: 'Iniciando consola'
          },
          { root: true }
        )
        // Se inicia la consola y se obtiene la informacion necesaria, ademas de iniciar los eventos del socket
        await store.dispatch('auth/isTokenExpired')

        await store.dispatch('sys/init')

        await store.dispatch('sys/requestNotificationPermission')
        await store.dispatch('sys/requestGeolocationPermission')

        await store.dispatch('sys/changeMonitTab', 'tab-Comunicaciones')

        await store.dispatch('sys/setLoaderDialogState', {
            isLoader: true,
            dialogMessage: 'Bienvenido'
          }
        )

        setTimeout(() => {
          store.dispatch('sys/setLoaderDialogState', { isLoader: false })
        }, 2000)
        next()
      } catch (error) {
        console.error(error)
        store.dispatch('sys/setLoaderDialogState', { dialogMessage: 'No se pudo iniciar session, se limpiara el navegador localmente' })
        setTimeout(async () => {
          await store.dispatch('auth/closeSession')
        }, 2500)
      }
    } else {
      next({
        replace: true,
        name: 'Login'
      })
    }
  }
}

export default Cliente
