import { MaponSDKConfig } from './interfaces/index.inferface'
import axios from 'axios'

const defaultConfig = () => {
  const config: MaponSDKConfig = {
    token: '',
    baseUrl: 'https://mapon.com/api/v1/',
    intervalTime: 10000,
    disableInterval: false
  }

  config.axios = axios.create({ baseURL: config.baseUrl })

  return config
}

export default defaultConfig()
