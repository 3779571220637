import { IScheduleTurnPeriod, IScheduleTurnPeriodType } from '@/store/interfaces/Schedule';
import BaseClass from '../BaseClass';
import { WEEKDAY_NAME, WEEKDAY } from '@/store/catalogs/WEEKDAY';
import { DateTime } from 'luxon';
// import getDayOfWeek from '@/utils/getDayOfWeek';
import Vue from 'vue';

export default class ScheduleTurnPeriod extends BaseClass<IScheduleTurnPeriod> implements IScheduleTurnPeriod {
  period_type!: IScheduleTurnPeriodType | null;
  id_schedule_turn_weekday_period!: number;
  id_schedule_turn!: number;
  id_weekday!: typeof WEEKDAY[keyof typeof WEEKDAY];
  stwp_end!: string;
  stwp_start!: string;
  stwp_createdAt!: string;

  // Valores customizados
  $stwp_startTimestamp!: number;
  $stwp_endTimestamp!: number;
  $stwp_eventTimed!: boolean;
  $stwp_offsetNowTime!: string;
  $createdTimestamp!: number;

  constructor (params: { data: IScheduleTurnPeriod, offsetNowTime?: string }) {
    super(params);
    this.$createdTimestamp = DateTime.now().toMillis();
    if (params.offsetNowTime) this.$stwp_offsetNowTime = params.offsetNowTime
  }

  public onInitInstance (): void {
    this.updateTimestamps();
  }

  public isEndInNextDay (): boolean {
    return this.stwp_end === '00:00'
  }

  public updateTimestamps ({ offsetNowTime }: { offsetNowTime?: string } = {}): void {
    const haveTimestamps = this.$stwp_startTimestamp && this.$stwp_endTimestamp
    if (offsetNowTime) this.$stwp_offsetNowTime = offsetNowTime

    if (haveTimestamps) {
      const startTime = DateTime.fromMillis(this.$stwp_startTimestamp);
      const startTimeHour = startTime.toLocaleString(DateTime.TIME_24_SIMPLE);

      const endTime = DateTime.fromMillis(this.$stwp_endTimestamp);
      const endTimeHour = endTime.toLocaleString(DateTime.TIME_24_SIMPLE);

      this.rehidratateDataPartial({
        stwp_start: startTimeHour,
        stwp_end: endTimeHour
      })
      this.updateWeekday()
    } else if (this.$stwp_offsetNowTime) {
      const startISOstring = `${this.$stwp_offsetNowTime}T${this.stwp_start}`;
      const endISOstring = `${this.$stwp_offsetNowTime}T${this.stwp_end}`;

      Vue.set(this, '$stwp_startTimestamp', DateTime.fromISO(startISOstring).toMillis());
      Vue.set(this, '$stwp_endTimestamp', DateTime.fromISO(endISOstring).toMillis());
      Vue.set(this, '$stwp_eventTimed', true);
      this.updateWeekday()
    }
  }

  public updateWeekday (): void {
    const startTime = DateTime.fromMillis(this.$stwp_startTimestamp);
    const id_weekday = startTime.weekday < 7 ? startTime.weekday + 1 : 1;

    Vue.set(this, 'id_weekday', id_weekday);
  }

  public getCalendarWeekNumber (): number {
    return this.id_weekday === 1 ? 7 : this.id_weekday - 1
  }

  public get weekdayName (): string {
    return WEEKDAY_NAME[this.id_weekday]
  }

  public get periodTypeName (): string {
    return this.period_type?.stwpt_name ?? 'DESCONOCIDO'
  }

  public get periodTypeColor (): string {
    return this.period_type?.stwpt_color ?? '#85a6ed'
  }

  public get customUniqueId (): string {
    return `${this.id_weekday}-${this.$stwp_startTimestamp}-${this.$stwp_endTimestamp}-${this.$createdTimestamp}`
  }

  public static createInstanceByTimestamps ($stwp_startTimestamp: number, rest: Partial<IScheduleTurnPeriod>) {
    const startTime = DateTime.fromMillis($stwp_startTimestamp);
    const startTimeHour = startTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    const id_weekday = startTime.weekday < 7 ? startTime.weekday + 1 : 1;

    const endTime = DateTime.fromMillis($stwp_startTimestamp).plus({ minutes: 30 });
    const endTimeHour = endTime.toLocaleString(DateTime.TIME_24_SIMPLE);

    return new ScheduleTurnPeriod({
      // @ts-ignore
      data: {
        ...rest,
        stwp_start: startTimeHour,
        stwp_end: endTimeHour,
        id_weekday,
        // @ts-ignore
        $stwp_startTimestamp,
        $stwp_endTimestamp: endTime.toMillis(),
        $stwp_eventTimed: true
      }
    })
  }
}
