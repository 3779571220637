import { ReportData, ReportItem } from '@/store/interfaces/ReportModules'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData'
import { ReportState } from './state'

export default {
  reportGroupUnitsById (state: ReportState) {
    return (id_report_group: number): ReportItemData<any, any>[] | undefined => {
      return state.reports.find(rp => rp.unitData.id_report_group === id_report_group)?.unitData.ri_items
    }
  },
  reportGroup (state: ReportState) {
    return (id_report_group: number): ReportData => {
      return state.reports[id_report_group]?.unitData
    }
  },
  getReportGroupColor (state: ReportState) {
    return (id_report_group: number): string => {
      return state.reports[id_report_group].unitData.rg_color
    }
  },
  reportItem (state: ReportState) {
    return (id_report_group: number, id_report_item: number): ReportItem => {
      return state.reports[id_report_group].unitData.ri_items[id_report_item]
    }
  },
  reportItems (state: ReportState) {
    return state.reports.map(rg => rg.unitData.ri_items).flat()
  },
  getReportLayoutIndex (state: ReportState) {
    return (i: number): number => {
      // @ts-ignore
      return state.layout.findIndex(lay => lay.i === i)
    }
  },
  reportItemsCount (state: ReportState) {
    return state.reports.map(rep => rep.unitData.ri_items.length).reduce((count, value) => {
      return count + value;
    }, 0)
  },
  isRISelected (state: ReportState) {
    return (id_report_item: number) => {
      return state.curr_id_report_item === id_report_item
    }
  },
  reportItemsByGroupId (state: ReportState) {
    return (id_group: number) => {
      return state.reports.filter(obj => obj.unitData.id_group === id_group).map(obj => obj.unitData.ri_items).flat()
    }
  },
  riWithPlataformCameras (state: ReportState) {
    const ri_with_plataform_cameras = []
    for (let i = 0; i < state.reports.length; i++) {
      const rg = state.reports[i];

      for (let j = 0; j < rg.unitData.ri_items.length; j++) {
        const ri: ReportItemData<unknown, unknown> = rg.unitData.ri_items[j];
        if (ri.ri_camera_plataform.length) ri_with_plataform_cameras.push(ri)
      }
    }

    return ri_with_plataform_cameras
  },
  totalPlataformCameras (state: ReportState) {
    return state.reports.map(rg => rg.unitData.ri_items.map(ri => ri.ri_camera_plataform)).flat(3)
  },
  riWithPlataformCamerasByRG (state: ReportState) {
    return (id_report_group: number) => {
      const ri_with_plataform_cameras = []
      for (let i = 0; i < state.reports.length; i++) {
        const rg = state.reports[i];
        if (rg.unitData.id_report_group !== id_report_group) continue

        for (let j = 0; j < rg.unitData.ri_items.length; j++) {
          const ri: ReportItemData<unknown, unknown> = rg.unitData.ri_items[j];

          for (let k = 0; k < ri.ri_camera_plataform.length; k++) {
            const rcp = ri.ri_camera_plataform[k];
            ri_with_plataform_cameras.push({ report_item: ri, camera: rcp })
          }
        }
      }

      return ri_with_plataform_cameras
    }
  }
}
