/* eslint-disable @typescript-eslint/no-explicit-any */
export default function intersection<arr1t = any, arr2t = any> (arr1: arr1t[], arr2: arr2t[], callback: (arg0: arr1t, arg1: arr2t) => boolean) {
  const elements = []

  for (let i = 0; i < arr1.length; i++) {
    const element1 = arr1[i]

    for (let j = 0; j < arr2.length; j++) {
      const element2 = arr2[j]

      if (callback(element1, element2)) {
        elements.push(element1)
        continue
      }
    }
  }

  return elements
}
