import { IScheduleTurnPeriodType, IScheduleUserDone } from '@/store/interfaces/Schedule';
import BaseClass from '../BaseClass';
import { DateTime } from 'luxon';
import store from '@/store';
import ICalendarEvent from '@/store/interfaces/vuetify/CalendarEvent';
import { IUser } from '@/store/interfaces/Manager.interface';
import formatLuxonDate from '@/utils/formatLuxonDate';
import secondsToHumanHours from '@/utils/secondsToHumanHours';

export default class ScheduleUserDone extends BaseClass<IScheduleUserDone> implements IScheduleUserDone, ICalendarEvent {
  id_schedule_user_done!: number;
  schud_start!: string;
  schud_end!: string | null;
  schud_period_payload!: IScheduleTurnPeriodType;
  id_schedule_turn_weekday_period_type!: number;
  id_user!: number;
  user!: Pick<IUser, 'id_user' | 'id_user_type' | 'us_name' | 'ut_name' | 'us_color'>;
  schud_createdAt!: string;

  eventStartTimestamp!: number;
  eventEndTimestamp!: number;
  eventTimed!: boolean;

  public onInitInstance (): void {
    this.eventTimed = true
    this.updateTimestamps();
  }

  public get secondsPeriodTime (): number {
    const start = DateTime.fromISO(this.schud_start);
    const end = this.schud_end ? DateTime.fromISO(this.schud_end) : DateTime.utc()
    const diff = end.diff(start, 'seconds').seconds
    if (diff <= 0) return 0
    return diff
  }

  public get eventId (): number {
    return this.id_schedule_user_done
  }

  public get eventColor (): string {
    return this.schud_period_payload?.stwpt_color || '#ADACAC'
  }

  public get eventName (): string {
    return this.schud_period_payload?.stwpt_name || 'DESCONOCIDO'
  }

  public get eventDate (): string {
    return DateTime.fromMillis(this.eventStartTimestamp).toFormat('yyyy-MM-dd')
  }

  public get us_name (): string {
    return this.user?.us_name
  }

  public get us_color (): string {
    return this.user?.us_color || 'disable'
  }

  public get labelTimePeriod (): string {
    // @ts-ignore
    const start = DateTime.fromISO(this.schud_start).plus({ hours: store.state.sys.timeoffset }).toFormat('HH:mm')
    // @ts-ignore
    const end = this.schud_end ? DateTime.fromISO(this.schud_end).plus({ hours: store.state.sys.timeoffset }).toFormat('HH:mm') : DateTime.now().toFormat('HH:mm')
    return `${start} - ${end}`
  }

  public get isWorking (): boolean {
    return this.schud_end === null
  }

  public get startFormatted (): string {
    // @ts-ignore
    return formatLuxonDate(DateTime.fromISO(this.schud_start).plus({ hours: store.state.sys.timeoffset }).toISO())
  }

  public get endFormatted (): string {
    // @ts-ignore
    return formatLuxonDate(this.schud_end ? DateTime.fromISO(this.schud_end).plus({ hours: store.state.sys.timeoffset }).toISO() : DateTime.now().toISO())
  }

  public get humanPeriodTime (): string {
    return secondsToHumanHours(this.secondsPeriodTime)
  }

  public getStartDatetime () {
    return DateTime.fromISO(this.schud_start, { zone: 'utc' });
  }

  public getEndDate () {
    return this.schud_end ? DateTime.fromISO(this.schud_end, { zone: 'utc' }) : DateTime.utc()
  }

  public getStartTimerLabel (): string {
    // Fecha específica
    const specificDate = this.getStartDatetime()

    // Hora actual
    // @ts-ignore
    const now = DateTime.utc();

    // Calcula la diferencia en minutos
    const diffInMinutes = specificDate.diff(now, 'minutes').minutes;

    // Convierte la diferencia en horas y minutos
    const hours = Math.floor(Math.abs(diffInMinutes) / 60);
    const minutes = Math.floor(Math.abs(diffInMinutes) % 60);

    // Formatea las horas y minutos como '00:00'
    const formattedDiff = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return formattedDiff
  }

  public updateTimestamps (): void {
    const startDatetime = this.getStartDatetime()
    const endDatetime = this.getEndDate()

    // Crea un objeto DateTime con Luxon
    // @ts-ignore
    this.eventStartTimestamp = startDatetime.toMillis();
    // @ts-ignore
    this.eventEndTimestamp = endDatetime.toMillis();
  }

  public resizeToBoundsLimits (startDateLimit: string, endDateLimit: string) {
    // @ts-ignore
    const startDateLimitDatetime = DateTime.fromFormat(startDateLimit, 'yyyy-MM-dd HH:mm:ss').toUTC()
    // @ts-ignore
    const endDateLimitDatetime = DateTime.fromFormat(endDateLimit, 'yyyy-MM-dd HH:mm:ss').toUTC()
    const currStartDatetime = this.getStartDatetime()
    const currEndDatetime = this.getEndDate()

    if (currStartDatetime < startDateLimitDatetime) {
      this.eventStartTimestamp = startDateLimitDatetime.toMillis()
      this.schud_start = startDateLimitDatetime.toISO({ suppressMilliseconds: true, includeOffset: false })
    }

    if (currEndDatetime > endDateLimitDatetime) {
      this.eventEndTimestamp = endDateLimitDatetime.toMillis()
      this.schud_end = endDateLimitDatetime.toISO({ suppressMilliseconds: true, includeOffset: false })
    }
  }
}
