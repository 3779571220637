import { State } from '../index'
import { IStepper } from '@/store/interfaces/Manager.interface'

export default {
  UNSHIFT_STEPPER (state: State, payload: IStepper) {
    state.admin.steppers.unshift(payload)
  },
  PUSH_STEPPER (state: State, payload: IStepper) {
    state.admin.steppers.push(payload)
  },
  UPDATE_STEPPER (state: State, { index, data }: { data: IStepper; index: number }) {
    Object.assign(state.admin.steppers[index], data)
  },
  DELETE_STEPPER (state: State, index: number) {
    state.admin.steppers.splice(index, 1)
  }
}
