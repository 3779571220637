import { IStep } from '@/interfaces/Steps.interface'

const state = {
  steppers: [] as any[],
  steps: [] as IStep[]
}

export type State = typeof state

export default state
