import MaponSDK from '@/submodules/maponjssdk/dist'
import AObject from '@/submodules/maponjssdk/dist/classes/actions/AObjects.action'
import { State } from './state'

export default {
  SET_SDK (state: State, sdk: MaponSDK): void {
    state.sdk = sdk
  },
  SET_OBJECTS (state: State, objects: AObject[]): void {
    state.objects = objects
  }
}
