export enum WIALON_SENSORS_TYPES {
    ABSOLUTE_FUEL_CONSUMPTION_SENSOR = 'absolute fuel consumption',
    ACCELEROMETER = 'accelerometer',
    ALARM_TRIGGER = 'alarm trigger',
    COUNTER_SENSOR = 'counter',
    CUSTOM_SENSOR = 'custom',
    CUSTOM_DIGITAL_SENSOR = 'digital',
    DRIVER_BINDING = 'driver',
    ENGINE_EFFICIENCY_SENSOR = 'engine efficiency',
    ABSOLUTE_ENGINE_HOURS = 'engine hours',
    ENGINE_IGNITION_SENSOR = 'engine operation',
    ENGINE_REVS_SENSOR = 'engine rpm',
    FUEL_LEVEL_IMPULSE_SENSOR = 'fuel level impulse sensor',
    FUEL_LEVEL_SENSOR = 'fuel level',
    IMPULSE_FUEL_CONSUMPTION_SENSOR = 'impulse fuel consumption',
    INSTANT_FUEL_CONSUMPTION_SENSOR = 'instant fuel consumption',
    MILEAGE_SENSOR = 'mileage',
    PRIVATE_MODE = 'private mode',
    RELATIVE_ENGINE_HOURS = 'relative engine hours',
    TEMPERATURE_COEFFICIENT = 'temperature coefficient',
    TEMPERATURE_SENSOR = 'temperature',
    TRAILER_BINDING = 'trailer',
    VOLTAGE_SENSOR = 'voltage',
    WEIGHT_SENSOR = 'weight sensor',
    PASSENGER_SENSOR = 'tag',
    REAL_TIME_MOTION_SENSOR = 'real-time motion sensor',
}

export enum WIALON_VOLTAGE_SENSOR_TYPE {
    BATTERY_GPS = 'battery_gps',
    BATTERY_UNIT = 'battery_unit',
}
