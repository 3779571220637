import ApiRequestConfig from "../interfaces/ApiRequestConfig.interface";
import Api from "../api/Api";
import TrackSolidSDK from "..";

export default async function makeApiRequest(
  additParams: any,
  config: ApiRequestConfig
) {
  const isDebug: boolean = additParams?.debug;
  delete additParams?.debug;
  delete additParams?.refreshTokenOnFail;
  const params = Api.getCommonParameters({
    access_token: Api.config.accessToken.result.accessToken,
    ...config,
    ...additParams,
    ...(additParams?.target && Api?.config?.target
      ? { target: Api?.config?.target }
      : {}),
  });

  const res = await Api.axios().request({
    params,
  });

  if (TrackSolidSDK?.config?.debug || isDebug) {
    console.log(res?.data);
  }

  if (res?.data?.code !== 0) {
    throw res;
    // throw Error(res?.data);
  }

  return res.data.result;
}
