import { State } from '..'

export default {
  isStepperNotificationBinded (state: State) {
    return (gs_plataform_notification_id: any): boolean => {
      const haveNotificationBinded = state.admin.steppers.some(stp => stp.groups_notifications.some(gs => gs.gs_plataform_notification_id === gs_plataform_notification_id))
      return haveNotificationBinded
    }
  }
}
