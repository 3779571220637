
import { VBtn, VChip } from 'vuetify/lib/components'

export default {
  inheritAttrs: false,
  props: {
    chip: {
      type: Boolean,
      default: false
    },
    iconProps: {
      type: Object,
      default: () => ({})
    },
    tooltipProps: {
      type: Object,
      default: () => ({})
    },
    buttonProps: {
      type: Object,
      default: () => ({
        iconName: 'mdi-account'
      })
    },
    iconName: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: null
    }
  },
  components: {
    VBtn, VChip
  }
}
