import Api from './Api';
import md5 from 'md5';
import { IRefreshAccessTokenParams, IResponce } from '../interfaces/login.interface';

export default class AccessToken extends Api {
  public static async login () {
    const params = Api.getCommonParameters({
      method: 'jimi.oauth.token.get',
      expires_in: 7200,
      user_id: Api.config.user_id,
      user_pwd_md5: md5(Api.config.user_pwd)
    });

    const res = await Api.axios().request<
      IResponce<{
        appKey: string;
        account: string;
        accessToken: string;
        expiresIn: number;
        refreshToken: string;
        time: string;
      }>
    >({
      params
    });

    if (res.data.code === 0) {
      Api.config = { ...Api.config, accessToken: res.data };
      return res.data.result;
    } else {
      throw new Error(`Error on login: ${JSON.stringify(res.data)}`);
    }
  }

  public static async refreshLoginToken (params?: IRefreshAccessTokenParams) {
    const additionalParams = Api.getCommonParameters({
      method: 'jimi.oauth.token.refresh',
      access_token: Api?.config?.accessToken?.result?.accessToken || '',
      refresh_token: Api?.config?.accessToken?.result?.refreshToken || '',
      expires_in: 7200,
      ...params
    });

    const res = await Api.axios().request<
      IResponce<{
        appKey: string;
        account: string;
        accessToken: string;
        expiresIn: number;
        refreshToken: string;
        time: string;
      }>
    >({
      params: additionalParams
    });

    if (res.data.code === 0) {
      Api.config = { ...Api.config, accessToken: res.data };
      return res.data.result;
    } else {
      throw new Error(`Error on login: ${JSON.stringify(res.data)}`);
    }
  }
}
