/* eslint-disable @typescript-eslint/no-explicit-any */
import createReportLayout from '@/components/Reports/createReportLayout'
import { Geofence } from '@/store/interfaces/Geofences'
import { ReportData, ReportDestinationData, ReportItem, ReportItemConfig, ReportItemPayload, ReportItemRecords, ReportLayout } from '@/store/interfaces/ReportModules'
import sleep from '@/utils/sleep'
import { ActionContext } from 'vuex'
import { RouteDestination } from '../geofences'
import { State, ReportState } from './state'
import xlsx, { IJsonSheet, ISettings } from 'json-as-xlsx'
import datetimeToDate from '@/utils/datetimeToDate'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData'
import ConsoleGroup from '@/store/interfaces/ConsoleGroup'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { Socket } from 'socket.io-client'
import { serializeError } from 'serialize-error'
import RIMapon from '@/store/packages/ReportItem/RIMapon'
import MaponSDK from '@/submodules/maponjssdk/dist'
import TrackSolidSDK from '@/submodules/tracksolidjavacriptsdk/dist'
import RITrackSolid from '@/store/packages/ReportItem/RITrackSolid'
import RICommon from '@/store/packages/ReportItem/RICommon'
import RIWialon from '@/store/packages/ReportItem/RIWialon'
import getReportTimeUnit from '@/utils/wialonSDK/getReportTimeUnit'
import formatLuxonDate, { formatFromISO8601, timesBetweenIso } from '@/utils/formatLuxonDate'
import { DateTime } from 'luxon'
import createRID from '@/utils/consoleUtils/createRID'

export default {
  init (context: ActionContext<State, string>) {
    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    // @ts-ignore
    const console_groups: Array<ConsoleGroup> = context.rootState.console.consoleGroups

    // Eventos de los reportes de consola
    for (let i = 0; i < console_groups.length; i++) {
      const console_group = console_groups[i]
      socket.on(`report_user_changed_${console_group.id_group}`, data => context.dispatch('socket_reportUserChanged', data))
      socket.on(`report_table_color_changed_${console_group.id_group}`, data => context.dispatch('socket_reportTableColorChanged', data))
    }

    // Eventos de las unidades de los reportes
    // @ts-ignore
    const report_layouts:ReportLayout[] = context.rootState.reports.reports
    for (let i = 0; i < report_layouts.length; i++) {
      const report_layout = report_layouts[i]
      const report_items = report_layout.unitData.ri_items

      socket.on(`report_destinations_changed_rg_${report_layout.unitData.id_report_group}`, data => context.dispatch('socket_reportDestinationsChangedRG', data))
      socket.on(`report_group_order_changed_${report_layout.unitData.id_report_group}`, data => context.dispatch('socket_reportOrderChanged', data))

      for (let j = 0; j < report_items.length; j++) {
        const report_item = report_items[j]
        socket.on(`report_changed_${report_item.id_report_item}`, data => context.dispatch('socket_reportChanged', data))
        socket.on(`report_item_message_changed_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemMessageChanged', data))
        socket.on(`report_item_properties_changed_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemPropertiesChanged', data))
        socket.on(`report_item_config_changed_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemConfigChanged', data))
        socket.on(`report_item_disabled_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemDisabled', data))
        socket.on(`report_item_camera_created_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemCameraCreated', data))
        socket.on(`report_item_camera_deleted_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemCameraDeleted', data))
      }
    }

    socket.on('report_item_added', data => context.dispatch('socket_reportItemAdded', data))
    socket.on('report_user_changed_classification', data => context.dispatch('socket_reportUserChangedClassification', data))
    socket.on('report_user_changed_by_user_groups', data => context.dispatch('socket_reportUserChangeByUserGroups', data))
    socket.on('report_ready', data => context.dispatch('socket_reportReady', data))
    socket.on('report_error', data => context.dispatch('socket_reportError', data))

    for (let i = 0; i < context.state.lastReportsSelected.length; i++) {
      const reportSelected = context.state.lastReportsSelected[i];
      if (!context.state.reports[reportSelected.report_group_index]) continue;
      if (!context.state.reports[reportSelected.report_group_index].unitData.ri_items[reportSelected.report_item_index]) continue;
      const report_item = context.state.reports[reportSelected.report_group_index].unitData.ri_items[reportSelected.report_item_index]
      context.dispatch('addReportItem', { id_report_item: report_item.id_report_item, id_report_group: report_item.id_report_group })
      report_item.initRILocationObserver()
    }
  },
  async socket_reportItemCameraCreated (context: ActionContext<State, string>, { id_report_item, ...data }: any) {
    const report_item: ReportItemData<unknown, unknown> = await context.dispatch('reportItemById', id_report_item)
    if (!report_item) return;
    if (data.id_camera_plataform) {
      report_item.ri_camera_plataform.push(data);
      if (data?.cpt_properties?.EhomeID) { // Is hikvision camera
        context.dispatch('events/susbcribeToEventMessajesByKeys', [data?.cpt_properties?.EhomeID])
      }
    }
    if (data.id_camera) report_item.ri_camera.push(data);
  },
  async socket_reportItemCameraDeleted (context: ActionContext<State, string>, { id_report_item, ...data }: any) {
    const report_item: ReportItemData<unknown, unknown> = await context.dispatch('reportItemById', parseInt(id_report_item))
    if (!report_item) return
    if (data.id_camera_plataform) {
      const index = report_item.ri_camera_plataform.findIndex(obj => obj.id_camera_plataform === data.id_camera_plataform)
      if (index === -1) return
      report_item.ri_camera_plataform.splice(index, 1)
    } else {
      const index = report_item.ri_camera.findIndex(obj => obj.id_camera === data.id_camera)
      if (index === -1) return
      report_item.ri_camera.splice(index, 1)
    }
  },
  async socket_reportItemConfigChanged (context: ActionContext<State, string>, { id_report_item, ...ri_config }: ReportItemConfig & { id_report_item: number }) {
    const report_item: ReportItemData<unknown, unknown> = await context.dispatch('reportItemById', id_report_item)
    if (!report_item) return
    report_item.setConfig(ri_config)
  },
  async socket_reportItemAdded (context: ActionContext<State, string>, { id_group, id_report_group, gp_wialon_id, ...report_item }: ReportItem & { id_group: number; id_report_group: number; gp_wialon_id: number }) {
    try {
      const index = context.state.reports.findIndex(rg => rg.unitData.id_report_group === id_report_group)
      if (index !== -1) {
        const group = context.state.reports[index]
        switch (group.unitData.id_plataform) {
          case PLATAFORM_TYPE.WIALON:{
            // @ts-ignore
            const sess = wialon.core.Session.getInstance();
            await getReportTimeUnit(sess)

            const unitItem = sess.getItem(report_item.ri_wialon_id)
            const backItem = sess.getItem(report_item.ri_back_wialon_id)
            const rid = createRID({
              // @ts-ignore
              report_item,
              reportItemProperties: {
                id_group: id_group,
                id_report_group: id_report_group,
                type: group.unitData.id_plataform,
                gp_wialon_id
              },
              // @ts-ignore
              BaseRID: RIWialon,
              unitItem,
              backItem
            })
            group.unitData.ri_items.push(rid)
            break;
          }
          case PLATAFORM_TYPE.MAPON:{
            const unitItem = await MaponSDK.actions.AUnit.getById(
              parseInt(report_item.ri_wialon_id),
              // @ts-ignore
              { include: ['ignition', 'drivers'] }
            );
            const backItem = report_item.ri_back_wialon_id
              ? await MaponSDK.actions.AUnit.getById(
                  parseInt(report_item.ri_back_wialon_id),
                  { include: ['ignition'] }
                )
              : null;
            const rid = createRID({
              // @ts-ignore
              report_item,
              reportItemProperties: {
                id_group: id_group,
                id_report_group: id_report_group,
                type: group.unitData.id_plataform,
                gp_wialon_id
              },
              // @ts-ignore
              BaseRID: RIMapon,
              unitItem,
              backItem
            })
            group.unitData.ri_items.push(rid)
            break;
          }
          case PLATAFORM_TYPE.TRACKSOLID:{
            // @ts-ignore
            const sdk: TrackSolidSDK = context.rootState.tracksolid.sdk
            if (!sdk) {
              group.unitData.ri_items.push(new RICommon(report_item, {
                id_group: id_group,
                id_report_group: id_report_group,
                type: group.unitData.id_plataform,
                gp_wialon_id,
                unitItem: null
              }))
              console.log(`Sin accesos a la unidad ${report_item.ri_unit}`);
              context.commit('sys/ADD_LOG', { title: 'UNIT_INACCESSIBLE', color: 'warning', message: `Sin accesos a datos la unidad ${report_item.ri_unit}`, payload: report_item }, { root: true })
              return
            }
            const unitItem = (await sdk.item.DeviceItem.getItemsByIds({ imeis: [report_item.ri_wialon_id] })).at(0);
            const backItem = (await sdk.item.DeviceItem.getItemsByIds({ imeis: [report_item.ri_back_wialon_id] })).at(0);
            const rid = createRID({
              // @ts-ignore
              report_item,
              reportItemProperties: {
                id_group: id_group,
                id_report_group: id_report_group,
                type: group.unitData.id_plataform,
                gp_wialon_id
              },
              // @ts-ignore
              BaseRID: RITrackSolid,
              unitItem,
              backItem
            })
            group.unitData.ri_items.push(rid)
            break;
          }
          default:{
            group.unitData.ri_items.push(new RICommon(report_item, {
              id_group: id_group,
              id_report_group: id_report_group,
              type: PLATAFORM_TYPE.CONSOLA,
              gp_wialon_id: gp_wialon_id,
              unitItem: null
            }))
            break;
          }
        }

        // @ts-ignore
        const socket: Socket = context.rootState.sys.socket
        socket.on(`report_changed_${report_item.id_report_item}`, data => context.dispatch('socket_reportChanged', data))
        socket.on(`report_item_message_changed_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemMessageChanged', data))
        socket.on(`report_item_properties_changed_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemPropertiesChanged', data))
        socket.on(`report_item_config_changed_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemConfigChanged', data))
        socket.on(`report_item_deleted_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemDisabled', data))
        socket.on(`report_item_camera_created_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemCameraCreated', data))
        socket.on(`report_item_camera_deleted_${report_item.id_report_item}`, data => context.dispatch('socket_reportItemCameraDeleted', data))
        context.state.layout[index].h = context.state.layout[index].h + 1

        context.dispatch('sys/showNotificationMessage', {
          title: `La unidad ${report_item.ri_unit} a sido añadida a ${group.unitData.rg_name}`,
          color: 'success',
          duration: -1
        }, { root: true })
        context.dispatch('sys/playSound', {
          type: 'alert'
        }, { root: true })
      }
    } catch (error) {
      console.error(error)
      context.dispatch('sys/showNotificationMessage', {
        title: `No se pudo añadir la unidad ${report_item.ri_unit}`,
        color: 'error',
        duration: -1
      }, { root: true })
      context.commit('sys/ADD_LOG', { title: 'SOCKET_REPORT_ADDED', color: 'error', message: error, payload: serializeError(error) }, { root: true })
    }
  },
  async socket_reportItemDisabled (context: ActionContext<State, string>, payload: { id_report_item: number }) {
    try {
      const data: { report_item: ReportItemData<unknown, unknown>, report_item_index: number; report_group_index:number } = await context.dispatch('reportItemPropertiesById', payload.id_report_item)
      if (data?.report_item) {
        data.report_item.stopAllUnitEvents()
        context.state.reports[data.report_group_index].unitData.ri_items.splice(data.report_item_index, 1)
        context.state.layout[data.report_group_index].h = context.state.layout[data.report_group_index].h - 1
        context.dispatch('sys/showNotificationMessage', {
          title: `La unidad ${data.report_item.ri_unit} a sido removida`,
          color: 'info',
          duration: -1
        }, { root: true })
        context.dispatch('sys/playSound', {
          type: 'alert'
        }, { root: true })
        // @ts-ignore
        const socket: Socket = context.rootState.sys.socket
        socket.off(`report_changed_${data.report_item.id_report_item}`, data => context.dispatch('socket_reportChanged', data))
        socket.off(`report_item_message_changed_${data.report_item.id_report_item}`, data => context.dispatch('socket_reportItemMessageChanged', data))
        socket.off(`report_item_properties_changed_${data.report_item.id_report_item}`, data => context.dispatch('socket_reportItemPropertiesChanged', data))
        socket.off(`report_item_config_changed_${data.report_item.id_report_item}`, data => context.dispatch('socket_reportItemConfigChanged', data))
        socket.off(`report_item_deleted_${data.report_item.id_report_item}`, data => context.dispatch('socket_reportItemDisabled', data))
      }
    } catch (error) {
      console.error(error)
      context.commit('sys/ADD_LOG', { title: 'SOCKET_REPORT_REMOVED', color: 'error', message: error, payload: serializeError(error) }, { root: true })
    }
  },
  async socket_reportDestinationsChangedRG (context: ActionContext<State, string>, payload: { id_report_group: number; report_items: number[]; DESTINATION: {geofences: any[]; route_destinations: any[] }}) {
    for (let index = 0; index < payload.report_items.length; index++) {
      const id_report_item = payload.report_items[index];
      context.dispatch('socket_reportChanged', { id_report_item, id_report_group: payload.id_report_group, last_data: { DESTINATION: payload.DESTINATION } })
    }
  },
  async socket_reportItemPropertiesChanged (context: ActionContext<State, string>, payload: any) {
    // @ts-ignore
    const report_item: ReportItemData<unknown, unknown> = await context.dispatch('reportItemById', payload.id_report_item)
    if (report_item) {
      report_item.ri_properties = payload.ri_properties
    }
  },
  reportItemById ({ state }: ActionContext<State, string>, id_report_item: number): ReportItemData<unknown, unknown> | null {
    for (let i = 0; i < state.reports.length; i++) {
      const report_group = state.reports[i]
      for (let j = 0; j < report_group.unitData.ri_items.length; j++) {
        const report_item = report_group.unitData.ri_items[j]
        if (report_item.id_report_item === id_report_item) {
          return report_item
        }
      }
    }

    return null
  },
  reportItemPropertiesById ({ state }: ActionContext<State, string>, id_report_item: number) {
    for (let i = 0; i < state.reports.length; i++) {
      const report_group = state.reports[i]
      for (let j = 0; j < report_group.unitData.ri_items.length; j++) {
        const report_item = report_group.unitData.ri_items[j]
        if (`${report_item.id_report_item}` === `${id_report_item}`) {
          return { report_item, report_group_index: i, report_item_index: j }
        }
      }
    }

    return null
  },
  getReportItemByID (context: ActionContext<State, string>, { ri_wialon_id }: { ri_wialon_id: number }): ReportItemData<unknown, unknown> | undefined {
    for (let i = 0; i < context.state.reports.length; i++) {
      const report_group = context.state.reports[i]
      for (let j = 0; j < report_group.unitData.ri_items.length; j++) {
        const report_item = report_group.unitData.ri_items[j]
        if (report_item.hasPlataformId(ri_wialon_id)) {
          return report_item
        }
      }
    }

    return undefined
  },
  sendRoutesDestinations (context: ActionContext<State, string>, payload?: {ri: ReportItemData<any, any>, rd: RouteDestination[]}): void {
    const id_report_group = payload?.ri.id_report_group ?? context.state.routesDialog.id_report_group
    const report_group_index = context.state.reports.findIndex(report => report.unitData.id_report_group === id_report_group)
    if (report_group_index !== -1) {
        const id_report_item = payload?.ri.id_report_item ?? context.state.routesDialog.id_report_item
        const report_item_index = context.state.reports[report_group_index].unitData.ri_items.findIndex(item => {
          return item.id_report_item === id_report_item
      })
      if (report_item_index !== -1) {
        const rd_destinations = payload?.rd ?? context.state.routesDialog.routes_destinations
        const route_destinations = rd_destinations.map(rd => ({ id_route_destinations: rd.id_route_destinations, rd_name: rd.rd_name }))
        const report_item = context.state.reports[report_group_index].unitData.ri_items[report_item_index]
        const DESTINATION = report_item.last_data.DESTINATION
        const reportItemPayload = {
          id_report_group,
          id_report_item,
          last_data: { DESTINATION: { ...DESTINATION, route_destinations } }
        }
        context.dispatch('reportEdit', reportItemPayload)
      }
    }
  },
  removeReportItemRouteById (context: ActionContext<State, string>, routePayload: { id_route_destinations: number, id_report_item: number, id_report_group:number}): void {
    const report_group_index = context.state.reports.findIndex(report => report.unitData.id_report_group === routePayload.id_report_group)
    if (report_group_index !== -1) {
      const report_item_index = context.state.reports[report_group_index].unitData.ri_items.findIndex(item => {
        return item.id_report_item === routePayload.id_report_item
      })
      if (report_item_index !== -1) {
        const route_destinations = context.state.reports[report_group_index].unitData.ri_items[report_item_index].last_data.DESTINATION.route_destinations.filter((rd: { id_route_destinations: number }) => rd.id_route_destinations !== routePayload.id_route_destinations)
        const DESTINATION = context.state.reports[report_group_index].unitData.ri_items[report_item_index].last_data.DESTINATION
        const reportItemPayload = {
          id_report_group: routePayload.id_report_group,
          id_report_item: routePayload.id_report_item,
          last_data: { DESTINATION: { ...DESTINATION, route_destinations } }
        }
        context.dispatch('reportEdit', reportItemPayload)
      }
    }
  },
  openRoutesDialog (context: ActionContext<State, string>, routesPayload: { id_group: number, id_wialon: number, isReportRoutesDialog: boolean, id_report_item: number }): void {
    context.commit('CHANGE_ROUTES_DIALOG', { ...routesPayload, wialon_id: routesPayload.id_wialon })
  },
  openDestinationsDialog (context: ActionContext<State, string>, destinationPayload: { wialon_id: number, id_group: number, isReportAddDestinations?: boolean }): void {
    context.commit('CHANGE_DESTINATIONS_ADD', destinationPayload)
    context.commit('CHANGE_CURRENT_COMPONENT', 'report-add-default-destinations')
  },
  toogleRouteDestination (context: ActionContext<State, string>, routeDestination: RouteDestination): void {
    const route_destination_index = context.state.routesDialog.routes_destinations.findIndex(rd => rd.id_route_destinations === routeDestination.id_route_destinations)
    context.commit('TOOGLE_ROUTE_DESTINATION', { route_destination_index, routeDestination })
  },
  setDestinationsAddGeofences (context: ActionContext<State, string>, geofences: Array<{id: number, n: string}>): void {
    context.commit('SET_DESTINATIONS_ADD_GEOFENCES', geofences)
  },
  editRouteDestination (context: ActionContext<State, string>, item: RouteDestination & { id_group: number, is_creating: boolean }): void {
    const rd_geofences = item.rd_geofences.map(geofence => ({ id: geofence.id, n: geofence.n }))
    const routeDestination = { ...item, rd_geofences: rd_geofences }
    context.commit('CHANGE_DESTINATIONS_ADD', routeDestination)
    context.commit('CHANGE_CURRENT_COMPONENT', 'report-add-default-destinations')
  },
  async deleteRoutesDestinations (context: ActionContext<State, string>, id_route_destinations: number): Promise<void> {
    await context.dispatch(
      'sys/axios',
      {
        url: 'delete_routes_destinations',
        method: 'post',
        data: {
          id_route_destinations
        }
      },
      { root: true }
    )
  },
  removeDestinationAGeofence (context: ActionContext<State, string>, id: number): void {
    const geofence_index = context.state.routesDialog.route_data.rd_geofences.findIndex(geofence => geofence.id === id)
    context.commit('REMOVE_DESTINATIONS_A_GEOFENCES', geofence_index)
  },
  addReportItem: (context: ActionContext<State, string>, reportItemData: {id_report_item: number, id_report_group:number}): void => {
    const report_group_index = context.state.reports.findIndex(rg => rg.unitData.id_report_group === reportItemData.id_report_group)
    if (report_group_index === -1) return

    const report_item_index = context.state.reports[report_group_index].unitData.ri_items.findIndex(ri => ri.id_report_item === reportItemData.id_report_item)
    if (report_item_index === -1) return

    const isReportItem = context.state.reportsSelected.findIndex(ri => ri.report_group_index === report_group_index && ri.report_item_index === report_item_index)
    if (isReportItem !== -1) {
      context.dispatch('sys/showNotificationMessage', {
        title: 'El unidad ya esta seleccionada',
        color: 'error'
      }, { root: true })
      return
    }
    const report_item = context.state.reports[report_group_index].unitData.ri_items[report_item_index]
    report_item.initMonitoringNotificationsObservers() // Activando el obsevador para saber cada cuanto reporta
    context.commit('ADD_REPORT_SELECTED', { report_group_index, report_item_index, id_report_item: reportItemData.id_report_item })
  },
  addReportItemByWialonId (context: ActionContext<State, string>, wialon_id: number): void {
    const reports = context.state.reports
    const selectedReportsIds = new Set(context.state.reportsSelected.map(rs => rs.id_report_item))
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i]
      const ri_items = report.unitData.ri_items

      for (let j = 0; j < ri_items.length; j++) {
        const unit_item = ri_items[j]
        if ((String(unit_item.ri_wialon_id) === String(wialon_id) ||
            String(unit_item.ri_back_wialon_id) === String(wialon_id)) &&
            !selectedReportsIds.has(unit_item.id_report_item)) {
          context.dispatch('addReportItem', { id_report_item: unit_item.id_report_item, id_report_group: report.unitData.id_report_group })
          return
        }
      }
    }
    context.dispatch('sys/showNotificationMessage', {
      title: 'No se encontro ninguna coincidencia',
      color: 'error'
    }, { root: true })
  },
  removeReportItem: (context: ActionContext<State, string>, report: { report_group_index: number; report_item_index: number }): void => {
    const reportsSelectedIndex = context.state.reportsSelected.findIndex(rs => rs.report_group_index === report.report_group_index && rs.report_item_index === report.report_item_index)
    if (reportsSelectedIndex !== -1) {
      const report_item = context.state.reports[report.report_group_index].unitData.ri_items[report.report_item_index]
      report_item.stopMonitoringNotificationsObservers()
      context.commit('REMOVE_REPORT_SELECTED', reportsSelectedIndex)
    }
  },
  removeAllReportItem (context: ActionContext<State, string>): void {
    const reportkeys = context.state.reportsSelected.slice()
    reportkeys.forEach((report) => {
      context.dispatch('removeReportItem', report)
    })
    context.commit('REMOVE_ALL_REPORTS')
    context.commit('CLEAN_LAST_REPORT_SELECTED')
  },
  changeReportItemDestinations (context: ActionContext<ReportState, string>, geofencesPayload: { geofences: Array<Geofence>, id_report_item: number, id_report_group: number }): void {
    // @ts-ignore
    const reports:Array<ReportLayout> = context.rootState.reports.reports
    const report_group_index = reports.findIndex(report => report.unitData.id_report_group === geofencesPayload.id_report_group)
    if (report_group_index !== -1) {
      const report_item_index = reports[report_group_index].unitData.ri_items.findIndex((r_item: { id_report_item: number }) => r_item.id_report_item === geofencesPayload.id_report_item)
      if (report_item_index !== -1) {
        const DESTINATION = context.state.reports[report_group_index].unitData.ri_items[report_item_index].last_data.DESTINATION
        // @ts-ignore
        const geofences = geofencesPayload.geofences.map(geofence => ({
          // @ts-ignore
          id: geofence.id,
          // @ts-ignore
          n: geofence.n,
          pType: geofence.pType,
          notifications: geofence?.notifications
        }))
        // console.log({
        //   DESTINATION: { ...DESTINATION, geofences } // Se sobreescribe geofences
        // })
        const reportItemPayload = {
          id_report_group: geofencesPayload.id_report_group,
          id_report_item: geofencesPayload.id_report_item,
          last_data: { DESTINATION: { ...DESTINATION, geofences } }
        }
        context.dispatch('reportEdit', reportItemPayload)
        // context.commit('SET_REPORT_GEOFENCES_ID', { geofencesId: geofences, report_item_index, report_group_index })
      }
    }
  },
  async sendGeofenceNotifications (context: ActionContext<ReportState, string>, geofencesPayload: {
    geofences: Array<{id:number, n: string, notifications: Array<{id: number, name: string}>, pType: number}>,
    id_report_group: number,
    id_report_item: number
  }
    ): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'add_notification_geofence',
          method: 'post',
          data: geofencesPayload
        },
        { root: true }
      )
    } catch (error) {
      console.log(error)
    }
},
  removeReportItemDestinationById (context: ActionContext<State, string>, reportPayload: { id: number, id_report_item: number, id_report_group: number }) :void {
    // @ts-ignore
    const reports:Array<ReportLayout> = context.rootState.reports.reports
    const report_group_index = reports.findIndex(report => report.unitData.id_report_group === reportPayload.id_report_group)
    if (report_group_index !== -1) {
      const report_item_index = reports[report_group_index].unitData.ri_items.findIndex((r_item: { id_report_item: number }) => r_item.id_report_item === reportPayload.id_report_item)
      if (report_item_index !== -1) {
        const DESTINATION = context.state.reports[report_group_index].unitData.ri_items[report_item_index].last_data.DESTINATION
        const geofences = DESTINATION.geofences.filter((geofences: { id: number }) => geofences.id !== reportPayload.id)
        const reportItemPayload = {
          id_report_group: reportPayload.id_report_group,
          id_report_item: reportPayload.id_report_item,
          last_data: { DESTINATION: { ...DESTINATION, geofences } }
        }
        context.dispatch('reportEdit', reportItemPayload)
      }
    }
  },
  initAllMonitoringObserversers (context: ActionContext<State, string>, id_report_group: number): void {
    const report = context.state.reports.find(report => report.unitData.id_report_group === id_report_group)
    if (report) {
      for (let i = 0; i < report?.unitData.ri_items.length; i++) {
        const report_item = report?.unitData.ri_items[i]
        report_item.initMonitoringNotificationsObservers()
      }
    }
  },
  stopAllMonitoringObserversers (context: ActionContext<State, string>, id_report_group: number): void {
    const report = context.state.reports.find(report => report.unitData.id_report_group === id_report_group)
    if (report) {
      for (let i = 0; i < report?.unitData.ri_items.length; i++) {
        const report_item = report?.unitData.ri_items[i]
        report_item.stopMonitoringNotificationsObservers()
      }
    }
  },
  initAllLocationObservers (context: ActionContext<State, string>, id_report_group: number): void {
    const report = context.state.reports.find(report => report.unitData.id_report_group === id_report_group)
    if (report) {
      for (let i = 0; i < report?.unitData.ri_items.length; i++) {
        const report_item = report?.unitData.ri_items[i]
        if (!(report_item.location.keyListener) && (report_item.last_data.DESTINATION.geofences.length || report_item.last_data.DESTINATION.route_destinations.length)) {
          report_item.initRILocationObserver()
        }
      }
    }
  },
  stopAllLocationObservers (context: ActionContext<State, string>, id_report_group: number): void {
    const report = context.state.reports.find(report => report.unitData.id_report_group === id_report_group)
    if (report) {
      for (let i = 0; i < report?.unitData.ri_items.length; i++) {
        const report_item = report?.unitData.ri_items[i]
        if (report_item.location.keyListener) {
          report_item.stopRILocationObserver()
        }
      }
    }
  },
  async sendReportItemMessage (context: ActionContext<State, string>, ri_data: {id_report_item: number, ri_message: string }): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'report_item_message_changed',
          method: 'post',
          data: ri_data
        },
        { root: true }
      )
    } catch (error) {
      // @ts-ignore
      console.error(error)
    }
  },
  async reportEdit (context: ActionContext<State, string>, reportData: ReportItem): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'report_edit',
          method: 'post',
          data: reportData
        },
        { root: true }
      )
    } catch (error) {
      context.dispatch('sys/addLogWithError', { title: 'REPORT_EDIT', color: 'error', message: '', error }, { root: true })
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudo realizar el cambio en el reporte de la unidad',
        type: 'error'
      }, {
        root: true
      })
      console.error(error)
    }
  },
  async getRecords (context: ActionContext<State, string>, payload: { id_report_item: number, length: number, type: string }): Promise<any> {
    try {
      const records = await context.dispatch(
        'sys/axios',
        {
          url: 'get_records',
          method: 'post',
          data: payload
        },
        { root: true }
      )

      return records
    } catch (error) {
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudieron obtener los datos',
        type: 'error'
      }, {
        root: true
      })
      console.error(error)
    }
  },
  async getExcelRecords (context: ActionContext<State, string>, payload: { id_report_item: number, length: number, type: string }): Promise<void> {
    try {
      const { data }: { data: ReportItemRecords<string>[] } = await context.dispatch('getRecords', payload)
      const report_item = context.state.reports.map(report => report.unitData.ri_items.find(item => item.id_report_item === payload.id_report_item)).filter(item => item)[0]
      if (report_item) {
        const jsonSheet: IJsonSheet[] = [
          {
            sheet: 'Movimientos',
            columns: [
              { label: 'Usuario', value: 'user' },
              { label: 'Movimientos', value: 'data' },
              { label: 'Fecha de modificacion', value: 'created_at' }
            ],
            // @ts-ignore
            content: data.map(record => ({
              user: record.user,
              ...(payload.type === 'STATUS') && { data: record.data ? record.data : '-- Borrado --' },
              // @ts-ignore
              ...(payload.type === 'DESTINATION') && { data: ([...record.data.geofences.map(geofence => geofence.n), record.data.route_destinations.map(rd => rd.rd_name)].join(', ')) || '-- Borrado --' },
              // @ts-ignore
              ...(payload.type === 'MESSAGE') && { data: record.data ? record.data : '-- Borrado --' },
              created_at: formatFromISO8601(record.created_at)
            }))
          }
        ]

        const settings: ISettings = {
          fileName: `Reporte de ${report_item.ri_unit}` // Name of the spreadsheet
        }

        xlsx(jsonSheet, settings)
      }
    } catch (error) {
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudieron obtener los datos',
        type: 'error'
      }, {
        root: true
      })
      console.error(error)
    }
  },
  async socket_reportChanged (context: ActionContext<State, string>, reportData: ReportItemPayload): Promise<void> {
    try {
      const reportGroupIndex = context.state.reports.findIndex(report => report.unitData.id_report_group === reportData.id_report_group)
      const reportItemIndex = context.state.reports[reportGroupIndex].unitData.ri_items.findIndex(reportItem => reportItem.id_report_item === reportData.id_report_item)
      const haveDestinations = reportData.last_data.DESTINATION?.geofences.length || reportData.last_data.DESTINATION?.route_destinations.length
      if (reportGroupIndex === -1 && reportItemIndex === -1) return
      context.commit('CHANGE_REPORT_ITEM_DATA', { ...reportData, reportGroupIndex, reportItemIndex })

      if (!haveDestinations) return
      const reportItem = context.state.reports[reportGroupIndex].unitData.ri_items[reportItemIndex]
      const isGroupNotificationsActive: boolean = await context.dispatch('console/isSubscribedToGroup', reportItem.id_group, { root: true })
      if (isGroupNotificationsActive) {
        reportItem.initRILocationObserver()
      }
      reportItem.onLastDataChanged()
    } catch (error) {
      console.error('Grupo no encontrado', error)
    }
  },
  async changeReportUser (context: ActionContext<State, string>, userPayload: { id_group: number, gp_wialon_id: number}): Promise<void> {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    await context.dispatch(
      'sys/axios',
      {
        url: 'report_change_user',
        method: 'post',
        data: { id_group: userPayload.id_group }
      },
      { root: true }
    )

    context.dispatch('notes/openSelectedNotes', userPayload.id_group, { root: true })
    await context.dispatch('console/suscribeNotificationGroup', userPayload.id_group, { root: true })

    const reports = context.state.reports
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i]
      if (report.unitData.id_group === userPayload.id_group) {
        context.dispatch('initAllMonitoringObserversers', report.unitData.id_report_group)
        context.dispatch('initAllLocationObservers', report.unitData.id_report_group)
      }
    }
  },
  async changeReportUserByGroup (context: ActionContext<State, string>, reportData: ReportData): Promise<void> {
    const reportsLayoutData: ReportLayout[] = context.state.reports.filter(report => report.unitData.id_clasificacion === reportData.id_clasificacion)
    const ids_groups = [...new Set(reportsLayoutData.map(report => report.unitData.id_group))]

    // Envio por socket que estoy tomando los grupos
    context.dispatch('changeReportUserByClassification', reportData.id_clasificacion)

    // Inicio los observadores de las unidades
    reportsLayoutData.forEach(report => {
      context.dispatch('initAllMonitoringObserversers', report.unitData.id_report_group)
      context.dispatch('initAllLocationObservers', report.unitData.id_report_group)
    })

    // Suscribirse a las notificaciones de los grupos
    ids_groups.forEach(id_group => {
      context.dispatch('console/suscribeNotificationGroup', id_group, { root: true })
    })
  },
  async socket_reportUserChangeByUserGroups (context: ActionContext<State, string>, { us_name, ids_clasificacions }: { us_name: string; ids_clasificacions: number[] }): Promise<void> {
    context.state.reports.forEach((report, i) => {
      if (!ids_clasificacions.includes(report.unitData.id_clasificacion)) return
      context.commit('CHANGE_REPORT_USER', { report_group_index: i, us_name })
    })
    context.dispatch('sys/showNotificationMessage', {
      title: `El usuario ${us_name} a tomado los grupos disponibles`,
      type: 'info',
      duration: 7000
    }, {
      root: true
    })
    context.dispatch('sys/playSound', { type: 'done' }, { root: true })
  },
  async socket_reportUserChangedClassification (context: ActionContext<State, string>, payload: { id_clasificacion: number; cls_name: string; us_name: string }) {
    context.state.reports.forEach((report, i) => {
      if (report.unitData.id_clasificacion !== payload.id_clasificacion) return
      context.commit('CHANGE_REPORT_USER', { report_group_index: i, us_name: payload.us_name })
    })
    context.dispatch('sys/showNotificationMessage', {
      title: `El usuario ${payload.us_name} a tomado los grupos "${payload.cls_name}"`,
      type: 'info',
      duration: 7000
    }, {
      root: true
    })
    context.dispatch('sys/playSound', { type: 'done' }, { root: true })
  },
  async changeReportUserByClassification ({ commit, dispatch }: ActionContext<State, string>, id_clasificacion: number): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'report_change_user_by_classification',
          method: 'POST',
          data: { id_clasificacion }
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'CHANGE_REPORT_USER_BY_CLASSIFICATION', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  changeReportUserByUser (context: ActionContext<State, string>) {
    // @ts-ignore
    const id_user: number = context.rootState.auth.user_data.id_user
    context.dispatch('changeReportUserByUserGroups', id_user)

    const reportsLayoutData: ReportLayout[] = context.state.reports
    // @ts-ignore
    const ids_groups = (context.rootState.console.consoleGroups as ConsoleGroup[]).map(group => group.id_group)

    // Inicio los observadores de las unidades
    reportsLayoutData.forEach(report => {
      context.dispatch('initAllMonitoringObserversers', report.unitData.id_report_group)
      context.dispatch('initAllLocationObservers', report.unitData.id_report_group)
    })

    // Suscribirse a las notificaciones de los grupos
    ids_groups.forEach(id_group => {
      context.dispatch('console/suscribeNotificationGroup', Number(id_group), { root: true })
    })
  },
  async changeReportUserByUserGroups ({ commit, dispatch }: ActionContext<State, string>, id_user: number): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'report_change_user_by_user_groups',
          method: 'POST',
          data: { id_user }
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'CHANGE_REPORT_USER_BY_USER', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async changeReportUserAllGroups (context: ActionContext<State, string>): Promise<void> {
    // @ts-ignore
    // const us_name = context.rootState.auth.user_data.us_name
    // @ts-ignore
    const groups:ConsoleGroup[] = context.rootState.console.consoleGroups

    groups.forEach(async group => {
      context.dispatch(
        'sys/axios',
        {
          url: 'report_change_user',
          method: 'post',
          data: { id_group: group.id_group, rg_name: group.gp_name }
        },
        { root: true }
      )
      // context.dispatch('notes/openSelectedNotes', userPayload.id_group, { root: true })
      context.dispatch('console/suscribeNotificationGroup', group.id_group, { root: true })

      const reports = context.state.reports
      for (let i = 0; i < reports.length; i++) {
        const report = reports[i]
        if (report.unitData.id_group === group.id_group) {
          // Activo las notificaciones de las unidades a las cuales me suscribo
          context.dispatch('initAllMonitoringObserversers', report.unitData.id_report_group)
          context.dispatch('initAllLocationObservers', report.unitData.id_report_group)
        }
      }

      await sleep(1000)
    })

    // context.dispatch('sys/sendMessageNotification', { title: 'Hola' }, { root: true })
  },
  async reportItemAdditionalPropertiesUpdate (context: ActionContext<State, string>, payload: any): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'report_item/additional_properties/update',
          method: 'post',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudieron obtener los datos',
        type: 'error'
      }, {
        root: true
      })
      context.commit('sys/ADD_LOG', { title: 'RI_PROPERTIES_CHANGED', color: 'warning', message: '', payload: serializeError(error) }, { root: true })

      throw error
    }
  },
  socket_reportUserChanged (context: ActionContext<State, string>, reportUserChange: { id_group: number, us_name: string, rg_name: string }): void {
    const reports = context.state.reports
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i]
      if (report.unitData.id_group === reportUserChange.id_group) {
        context.commit('CHANGE_REPORT_USER', { report_group_index: i, us_name: reportUserChange.us_name })
      }
    }
    context.dispatch('sys/showNotificationMessage', {
      title: `El ${reportUserChange.us_name} a tomado el grupo ${reportUserChange.rg_name}`,
      duration: 5000,
      color: 'info'
    }, {
      root: true
    })
    context.dispatch('sys/playSound', { type: 'done' }, { root: true })
  },
  socket_reportTableColorChanged (context: ActionContext<State, string>, reportColorData: { rg_color: string, id_group: number }): void {
    const reports = context.state.reports
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i]
      if (report.unitData.id_group === reportColorData.id_group) {
        context.commit('CHANGE_TABLE_COLOR', {
          report_index: i,
          rg_color: reportColorData.rg_color
        })
      }
    }
    context.dispatch('console/changeConsoleGroupColor', {
      id_group: reportColorData.id_group,
      gp_color: reportColorData.rg_color
    },
    {
      root: true
    })
  },
  async chageTableColor (context: ActionContext<State, string>, reportColorData:{ id_group: number, us_name: string }): Promise<void> {
    await context.dispatch(
      'sys/axios',
      {
        url: 'report_table_color',
        method: 'post',
        data: reportColorData
      },
      { root: true }
    )
  },
  setPrevLayout (context: ActionContext<State, string>) {
    if (context.state.lastLayout.length) {
      // @ts-ignore
      context.commit('ASSING_REPORT_LAYOUT', context.state.lastLayout)
    }
  },
  async getReportData (context: ActionContext<State, string>, payload: { reports: ReportData[] }): Promise<void> {
    try {
      let reportData = payload?.reports
      if (!reportData) {
        const { data: { data } } = await context.dispatch(
          'sys/axios',
          {
            url: 'report_table_color',
            method: 'get'
          },
          { root: true }
        )
        reportData = data
      }
      const { reportLayoutData, layout } = await createReportLayout(reportData, context)

      const areLayoutLengthEquals = context.state?.lastLayout?.length === layout.length
      const someLayoutEmpty = !context.state?.lastLayout?.length || !layout.length
      // eslint-disable-next-line multiline-ternary
      const layoutIsEquals = (someLayoutEmpty || !areLayoutLengthEquals) ? false : context.state.lastLayout.every((lay, i) => {
        try {
          if (lay.i === layout[i].i && lay.h === layout[i].h && lay.i === layout[i].i) return true;
          return false
        } catch (error) {
          // console.log('Reloading layout')
          return false
        }
      })
      if (context.rootGetters['sys/dev']) console.log('layoutIsEquals: ', layoutIsEquals)

      if (!layoutIsEquals) {
        context.commit('ASSING_LAST_REPORT_LAYOUT', [])
        context.commit('ASSING_REPORT_LAYOUT', layout)
      } else {
        context.commit('ASSING_LAST_REPORT_LAYOUT', context.state.lastLayout)
        context.commit('ASSING_REPORT_LAYOUT', context.state.lastLayout)
      }
      // Se establece la informacion del reporte
      context.commit('ASSING_REPORT_DATA', reportLayoutData)

      // Se hay unidades en el reporte de entrega, se reañaden
      if (context.state.reportsSelected.length) {
        const reportsSelected = context.state.reportsSelected
        context.state.reportsSelected = []
        setTimeout(() => {
          reportsSelected.forEach(report => {
            context.state.reportsSelected.push(report)
          })
        }, 1000)
      }
    } catch (error) {
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudieron obtener los reportes',
        color: 'error'
      }, { root: true })
      console.error(error)
    }
  },
  async getLastReports (context: ActionContext<State, string>, reportItemPayload: { id_report_item: number, type: string }): Promise<Array<any>> {
    try {
      const { data: { data } } = await context.dispatch(
        'sys/axios',
        {
          url: 'get_last_records',
          method: 'post',
          data: { type: reportItemPayload.type, id_report_item: reportItemPayload.id_report_item }
        },
        { root: true }
      )

      return data
    } catch (error) {
      context.commit('sys/ADD_LOG', { title: 'LAST_REPORTS', color: 'error', message: '', payload: serializeError(error) }, { root: true })
      console.error(error)
      return []
    }
  },
  async getGrouUserRegisters (context: ActionContext<State, string>, payload: { id_group: number, count: number }): Promise<Array<any>> {
    try {
      const { data: registers } = await context.dispatch(
        'sys/axios',
        {
          url: `/get_group_user_registers/${payload.id_group}/${payload.count}`,
          method: 'get'
        },
        { root: true }
      )

      // @ts-ignore
      const timerRegisters = registers.reduce((prev, curr, index) => {
        const prevItem = prev.at(index - 1)
        let currentValue = curr
        if (prevItem) {
          // const diff = timesBetweenIso(curr.created_at, prevItem.created_at)
          const diff = timesBetweenIso(prevItem.created_at, curr.created_at, ['hours', 'minutes', 'seconds'])
          const time = `${diff.hours} horas; ${diff.minutes} minutos; ${Math.ceil(diff.seconds)} segundos`
          currentValue = {
            ...curr,
            time,
            seconds: timesBetweenIso(prevItem.created_at, curr.created_at, ['seconds']).seconds
          }
        } else {
          const date1 = DateTime.now().toUTC()
          const date2 = DateTime.fromISO(curr.created_at.replace(' ', 'T').substring(0, 26), { zone: 'UTC' })
          const diff = date1.diff(date2, ['hours', 'minutes', 'seconds'])

          const time = `${diff.hours} horas; ${diff.minutes} minutos; ${Math.ceil(diff.seconds)} segundos`
          currentValue = {
            ...curr,
            time,
            seconds: timesBetweenIso(curr.created_at, DateTime.now().toUTC().toISO(), ['seconds']).seconds
          }
        }
        prev.push(currentValue)
        return prev
      }, [])

      return timerRegisters
    } catch (error) {
      context.commit('sys/ADD_LOG', { title: 'GROUP_REGISTERS', color: 'error', message: '', payload: serializeError(error) }, { root: true })
      console.error(error)
      return []
    }
  },
  async getExcelGroupRegisters (context: ActionContext<State, string>, payload: { id_group: number, count: number }): Promise<void> {
    try {
      const data = await context.dispatch('getGrouUserRegisters', payload)
      // @ts-ignore
      const groups: ConsoleGroup[] = context.rootState.console.consoleGroups
      const group = groups.find(g => g.id_group === payload.id_group)
      if (group) {
        const jsonSheet: IJsonSheet[] = [
          {
            sheet: 'Cambios',
            columns: [
              { label: 'Usuario', value: 'us_name' },
              { label: 'Fecha de asignacion', value: 'created_at' },
              { label: 'Tiempo de monitoreo', value: 'time' },
              { label: 'Total de segundos', value: 'seconds' }
            ],
            content: data.map((d: { created_at: string }) => ({ ...d, created_at: formatLuxonDate(d.created_at) }))
          }
        ]

        const settings: ISettings = {
          fileName: `${group.gp_name} - Cambios de usuario` // Name of the spreadsheet
        }
        xlsx(jsonSheet, settings)
      }
    } catch (error) {
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudieron obtener los datos',
        type: 'error'
      }, {
        root: true
      })
      console.error(error)
    }
  },
  async createRouteDestination (context: ActionContext<State, string>, destinationsPayload: { id_group: number, id_route_destination: number, rd_name: string, rd_geofences: Array<Geofence>, id_not?: number, id_geofence?: number }): Promise<void> {
    const rd_geofences = destinationsPayload.rd_geofences.map(geofence => ({ id: geofence.id, n: geofence.n, pType: geofence?.pType }))
    if (context.state.routesDialog.is_creating) {
      await context.dispatch(
        'sys/axios',
        {
          url: 'create_default_destination',
          method: 'post',
          data: { ...destinationsPayload, rd_geofences }
        },
        { root: true }
      )
    } else {
      await context.dispatch(
        'sys/axios',
        {
          url: 'edit_routes_destinations',
          method: 'post',
          data: destinationsPayload
        },
        { root: true }
      )
    }
  },
  async addNotification (context: ActionContext<State, string>, destinationsPayload: {
    id_group: number,
    rd_name: string,
    rd_geofences: Array<Geofence>,
    all_geofences: Array<any>
    }): Promise<void> {
    // console.log('payload', destinationsPayload)
    const rd_geofences = destinationsPayload.rd_geofences.map(geofence => ({ id: geofence.id, n: geofence.n, pType: geofence?.pType }))
    if (context.state.routesDialog.is_creating) {
      await context.dispatch(
        'sys/axios',
        {
          url: 'create_default_destination',
          method: 'post',
          data: { ...destinationsPayload, rd_geofences }
        },
        { root: true }
      )
    } else {
      await context.dispatch(
        'sys/axios',
        {
          url: 'add_notification',
          method: 'post',
          data: destinationsPayload
        },
        { root: true }
      )
    }
  },
  async sendReportItemDestiations ({ dispatch, commit }: ActionContext<State, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `report_edit/group/${payload.id_report_group}`,
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'SEND_REPORT_ITEM_DESTINATIONS', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async getUnitsGeofencesLocations ({ dispatch, commit, state, rootState }: ActionContext<State, string>, rdl_id: number): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `report/geofences/excel/${state.reportsDocuments.data.id_report_group}`,
          method: 'POST',
          // @ts-ignore
          data: { ...state.reportsDocuments.data, socket_id: rootState.sys.socket.id, rdl_id }
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'UNITS_GEOFENCES_LOCATIONS', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async socket_reportReady ({ dispatch, commit }: ActionContext<State, string>, payload: any): Promise<void> {
    const { data } = await dispatch(
      'sys/axios',
      {
        url: `/report/report_data/${payload.rd_id}`,
        method: 'GET'
      },
      { root: true }
    )
    try {
      const report_data = Object.entries(data.rdl_properties)
      const jsonSheet: IJsonSheet[] = [
        {
          sheet: 'Movimientos',
          columns: [
            { label: 'Unidad', value: 'ri_unit' },
            { label: 'Geocerca', value: 'geo_name' },
            { label: 'Entrada/Salida', value: 'onGeofence' },
            { label: 'Fecha y hora', value: 'gpsTime' },
            { label: 'Descripcion', value: 'geo_description' },
            { label: 'Velocidad', value: 'gpsSpeed' },
            { label: 'Bateria', value: 'battery' }
          ],
          // @ts-ignore
          content: (await Promise.all(report_data.map(async record => {
            const report_item = await dispatch('reportItemById', parseInt(record[0]))
            // @ts-ignore
            const ri_unit = report_item?.ri_unit ?? record[1].report_item.ri_unit
            const battery = report_item ? (report_item.batteryGPSLevel() !== -1 ? report_item.batteryGPSLevel() : (report_item.batteryUnitLevel() !== -1 ? report_item.batteryUnitLevel() : 'Nivel de bateria desconocido')) : '--------------'

            const row_name = {
              ri_unit,
              geo_name: '--------------',
              onGeofence: '--------------',
              gpsTime: '--------------',
              geo_description: '--------------',
              gpsSpeed: '--------------',
              battery
            }
            // @ts-ignore
            const rows = record[1].status.map(val => {
              return {
                ri_unit,
                geo_name: val.geofence.geo_name,
                onGeofence: val.onGeofence ? 'Entrada' : 'Salida',
                gpsTime: datetimeToDate(DateTime.fromMillis(val.timestamp).toISO()),
                geo_description: val.geofence.geo_description,
                battery: '--------------',
                gpsSpeed: val.gpsSpeed
              }
            })

            return [row_name, ...rows]
          }))).flat()
        }
      ]
      const settings: ISettings = {
        fileName: 'Reporte de unidades' // Name of the spreadsheet
      }

      xlsx(jsonSheet, settings)

      await dispatch('sys/cleanNotifications', {}, { root: true });
      await dispatch('sys/showNotificationMessage', {
        title: 'Finalizado con exito',
        color: 'success',
        duration: 10000
      }, { root: true });
    } catch (error) {
      dispatch('sys/showNotificationMessage', {
        title: 'No se pudieron obtener los datos',
        type: 'error'
      }, {
        root: true
      })
      console.error(error)
      commit('sys/ADD_LOG', { title: 'REPORT_DOCUMENT', color: 'error', message: error, payload: { error: serializeError(error), data: data.data } }, { root: true })
    } finally {
    commit('SET_REPORTS_DOCUMENTS_DATA', { isWating: false })
    }
  },
  socket_reportError ({ dispatch, commit }: ActionContext<State, string>, data: any) {
    commit('SET_REPORTS_DOCUMENTS_DATA', { isWating: false })
    commit('sys/ADD_LOG', { title: 'REPORT_DOCUMENT', color: 'error', payload: data }, { root: true })
    dispatch('sys/showNotificationMessage', {
      title: 'No se pudieron obtener los datos',
      type: 'error'
    }, {
      root: true
    })
  },
  openModalRIConfig ({ state }: ActionContext<State, string>, { id_report_group, id_report_item }:{ id_report_group: number; id_report_item: number }) {
    const report_group_index = state.reports.findIndex(obj => obj.unitData.id_report_group === id_report_group)
    const report_item_index = state.reports[report_group_index].unitData.ri_items.findIndex(obj => obj.id_report_item === id_report_item)

    state.riConfigMenu.report_group_index = report_group_index
    state.riConfigMenu.report_item_index = report_item_index
    state.riConfigMenu.modal = true
  },
  async getVideoHistory ({ dispatch, commit, rootState }: ActionContext<State, string>, imei: string): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'report/video',
          method: 'POST',
          // @ts-ignore
          data: { socket_id: rootState.sys.socket.id, imei }
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'VIDEO_REPORT_HISTORY', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async getSocketCamera ({ dispatch, commit, rootState }: ActionContext<State, string>, imei: string): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'report/socket',
          method: 'POST',
          // @ts-ignore
          data: { socket_id: rootState.sys.socket.id, imei }
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'VIDEO_REPORT_SOCKET', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async toggleRIOrder ({ dispatch, commit }: ActionContext<State, string>, data: { ids: [number, number]; id_report_group: number; }): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'report/toggle_order',
          method: 'POST',
          data
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'TOGGLE_RI_ORDER', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async getReportInformUserChangesData ({ dispatch, commit }: ActionContext<State, string>, payload: any): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'report/inform/users_changes',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'INFORM_USER_CHANGES_DATA', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async generateReportInformUserChange ({ dispatch, commit }: ActionContext<State, string>, items: { item: any; type: 'STATUS' | 'DESTINATION' | 'MESSAGE'; createdAt: string; id_report_item: number; ri_unit: string; gp_name: string; user: string; value: any }[]): Promise<void> {
    const content = await Promise.all(items.map(async obj => ({
      ...obj,
      ri_unit: (await dispatch('getReportItemByID', obj.id_report_item))?.ri_unit ?? obj.ri_unit,
      createdAt: formatFromISO8601(obj.createdAt),
      type: obj.type === 'STATUS' ? 'Estatus' : (obj.type === 'DESTINATION' ? 'Destino' : 'Mensaje'),
      data: obj.type !== 'DESTINATION' ? (obj.value || '--Borrado--') : ((obj.value as ReportDestinationData).geofences.map(obj => obj.n).concat((obj.value as ReportDestinationData).route_destinations.map(obj => obj.rd_name))).join(', ') || '--Borrado--'
    })))

    let jsonSheet : IJsonSheet[] = []
    try {
      jsonSheet = [
        {
          sheet: 'Actividad de usuarios',
          columns: [
            { label: 'Grupo', value: 'gp_name' },
            { label: 'Unidad', value: 'ri_unit' },
            { label: 'Valor', value: 'data' },
            { label: 'Usuario', value: 'user' },
            { label: 'Fecha y hora', value: 'createdAt' },
            { label: 'Tipo', value: 'type' }
          ],
          content
        }
      ]
      const settings: ISettings = {
        fileName: 'Informe de actividad de usuarios' // Name of the spreadsheet
      }

      xlsx(jsonSheet, settings)

      await dispatch('sys/cleanNotifications', {}, { root: true });
      await dispatch('sys/showNotificationMessage', {
        title: 'Finalizado con exito',
        color: 'success',
        duration: 10000
      }, { root: true });
    } catch (error) {
      dispatch('sys/showNotificationMessage', {
        title: 'No se pudieron obtener los datos',
        type: 'error'
      }, {
        root: true
      })
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GENERATE_REPORT_USER_CHANGES', color: 'error', message: error, payload: { error: serializeError(error), items, jsonSheet } }, { root: true })
    }
  },
  async socket_reportOrderChanged (context: ActionContext<State, string>, payload: { id_user: number; reports: [number, number]; id_report_group: number }) {
    if (payload.id_user === context.rootGetters['auth/userId']) return

    const report_group_index: number = context.state.reports.findIndex((obj: any) => obj.unitData.id_report_group === payload.id_report_group)
    const futureIndex = context.state.reports[report_group_index].unitData.ri_items.findIndex(obj => obj.id_report_item === payload.reports[0])
    const movingIndex = context.state.reports[report_group_index].unitData.ri_items.findIndex(obj => obj.id_report_item === payload.reports[1])
    // @ts-ignore
    const futureItem = context.state.reports[report_group_index].unitData.ri_items[futureIndex];
    // @ts-ignore
    const movingItem = context.state.reports[report_group_index].unitData.ri_items[movingIndex];

    context.commit('REPLACE_REPORT_ITEM', {
      report_group_index,
      report_item_index: futureIndex,
      report_item: movingItem
    })
    context.commit('REPLACE_REPORT_ITEM', {
      report_group_index,
      report_item_index: movingIndex,
      report_item: futureItem
    })
  },
  async changeCellItemsData (context: ActionContext<State, string>, payload: typeof context.state.riCellItems) {
    if (!payload) return

    context.commit('SET_CELL_ITEMS_DATA', payload)
    context.commit('CURR_REPORT_ITEM_FOCUSED', payload?.report_item?.id_report_item || 0)
    context.commit('SET_CELL_ITEMS_DATA', { key: context.state.riCellItems.key + 1 })
  },
  async reportGroupUpdate ({ dispatch, commit }: ActionContext<State, string>, payload: any): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'report/report_group/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'REPORT_GROUP_UPDATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  }
}
