/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import getAVLResourceDrivers from '@/utils/wialonSDK/getAVLResourceDrivers'
import { ActionContext } from 'vuex'
import Driver, { ICDriver } from '@/store/interfaces/Driver'
import getAVLResourceItem from '@/utils/wialonSDK/getAVLResourceItem'
import toogleDriverBind from '@/utils/wialonSDK/toogleDriverBind'
import { PLATAFORM_TYPE } from '../catalogs/PLATAFORM_TYPE'
import MaponSDK from '@/submodules/maponjssdk/dist'
import { serializeError } from 'serialize-error'
import { Socket } from 'socket.io-client'
import ConsoleGroup from '../interfaces/ConsoleGroup'
import Vue from 'vue'

interface DriversState {
  driversCrud: {
    modal: boolean
    id_group: number | null,
    resource_id: number | null,
    pType: PLATAFORM_TYPE
  }
  currIdGroup: number;
  resourceDrivers: Driver[],
  consoleGroupDrivers: Driver[]
  consoleGroupDriversByGroup: {
    [key: number]: Driver[]
  },
  drivers: {
    [pType: number]: {
      [x: number]: {
        [x: number]: Driver[]
      }
    }
  }
}

const state: DriversState = {
  driversCrud: {
    modal: false,
    id_group: 0,
    pType: PLATAFORM_TYPE.CONSOLA,
    resource_id: null
  },
  currIdGroup: -1,
  resourceDrivers: [],
  consoleGroupDrivers: [],
  consoleGroupDriversByGroup: {},
  drivers: {
    [PLATAFORM_TYPE.CONSOLA]: {},
    [PLATAFORM_TYPE.WIALON]: {},
    [PLATAFORM_TYPE.MAPON]: {},
    [PLATAFORM_TYPE.TRACKSOLID]: {}
  }
}

const mutations = {
  SET_CURRENT_GROUP (state: DriversState, id_group: number): void {
    state.currIdGroup = id_group
  },
  SET_MODAL_STATE (state: DriversState, payload: typeof state.driversCrud): void {
    state.driversCrud = payload
  },
  SET_RESOURCE_DRIVERS (state: DriversState, drivers: Driver[]): void {
    state.resourceDrivers = drivers
  },
  SET_CONSOLE_GROUP_DRIVERS (state: DriversState, drivers: Driver[]): void {
    state.consoleGroupDrivers = drivers
  },
  SET_DRIVERS (state: DriversState, { drivers, pType }: { drivers: { [x: number]: { [x: number]: Driver[]; }; }, pType: PLATAFORM_TYPE }): void {
    Vue.set(state.drivers, pType, drivers)
  },
  ADD_DRIVER (state: DriversState, { driver, pType }: { driver: Driver & { resource_id: number }; pType: PLATAFORM_TYPE }): void {
    if (!state.drivers[pType][driver.resource_id][driver.bu]) {
      Vue.set(state.drivers[pType][driver.resource_id], driver.bu, [])
    }
    state.drivers[pType][driver.resource_id][driver.bu].push(driver)
  },
  DELETE_DRIVER (state: DriversState, { driver, pType }: { driver: Driver & { resource_id: number, id_unit_remove: number }; pType: PLATAFORM_TYPE }): void {
    const drivers = state.drivers[pType][driver.resource_id][driver.id_unit_remove]
    if (!drivers) return
    const index = drivers.findIndex(dr => dr.id === driver.id && dr.plataform === pType)
    if (index === -1) return
    state.drivers[pType][driver.resource_id][driver.id_unit_remove].splice(index, 1)
  },
  UPDATE_DRIVER (state: DriversState, { driver, pType }: { driver: Driver & { resource_id: number }; pType: PLATAFORM_TYPE }): void {
    const index = state.drivers[pType][driver.resource_id][driver.bu].findIndex(dr => dr.id === driver.id && dr.plataform === pType)
    if (index !== -1 && driver) {
      Object.assign(state.drivers[pType][driver?.resource_id][driver?.bu][index], driver)
    }
  },
  SET_CONSOLE_GROUP_DRIVERS_BY_GROUP_ID (state: DriversState, { drivers, id_group }: { id_group: number; drivers: Driver[] }) {
    Vue.set(state.consoleGroupDriversByGroup, id_group, drivers)
  }
}

const actions = {
  init (context: ActionContext<DriversState, string>) {
    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    socket.on('driver_console_created', data => context.dispatch('socket_driverConsoleCreated', data))
    socket.on('driver_console_updated', data => context.dispatch('socket_driverConsoleUpdated', data))
    socket.on('driver_console_deleted', data => context.dispatch('socket_driverConsoleDeleted', data))
    socket.on('driver_console_assigned', data => context.dispatch('socket_driverConsoleAssinged', data))

    // Filling console drivers by group object
    // @ts-ignore
    const console_groups: ConsoleGroup[] = context.rootState.console.consoleGroups
    console_groups.forEach(async obj => {
      await context.dispatch('setConsoleGroupDriversByGroup', { id_group: obj.id_group })
    })
  },
  async socket_driverConsoleCreated (context: ActionContext<DriversState, string>, data: ICDriver) {
    // Formateando el objeto para que sea igual a la interfaz requerida Driver
    const driver = {
      id: data.id_driver,
      n: data.drv_name,
      p: data.drv_phone,
      id_group: data.id_group,
      resource_id: data.id_group,
      bu: 0,
      plataform: PLATAFORM_TYPE.CONSOLA,
      item: data
    }
    context.commit('ADD_DRIVER', { driver, pType: PLATAFORM_TYPE.CONSOLA })
    // Actualizando los console_group_drives_by_group en caso de que alla habido algun cambio
    await context.dispatch('getAllConsoleDrivers')
    await context.dispatch('setConsoleGroupDriversByGroup', { id_group: data.id_group })
  },
  async socket_driverConsoleUpdated (context: ActionContext<DriversState, string>, data: ICDriver) {
    // Formateando el objeto para que sea igual a la interfaz requerida Driver
    const driver = {
      id: data.id_driver,
      n: data.drv_name,
      p: data.drv_phone,
      id_group: data.id_group,
      resource_id: data.id_group,
      bu: data.id_report_item,
      plataform: PLATAFORM_TYPE.CONSOLA,
      item: data

    }
    context.commit('UPDATE_DRIVER', { driver, pType: PLATAFORM_TYPE.CONSOLA })
    // Actualizando los console_group_drives_by_group en caso de que alla habido algun cambio
    await context.dispatch('getAllConsoleDrivers')
    await context.dispatch('setConsoleGroupDriversByGroup', { id_group: data.id_group })
  },
  async socket_driverConsoleDeleted (context: ActionContext<DriversState, string>, data: ICDriver) {
    // Formateando el objeto para que sea igual a la interfaz requerida Driver
    const driver = {
      id: data.id_driver,
      n: data.drv_name,
      p: data.drv_phone,
      id_group: data.id_group,
      resource_id: data.id_group,
      id_unit_remove: data.id_report_item,
      plataform: PLATAFORM_TYPE.CONSOLA,
      item: data
    }
    context.commit('DELETE_DRIVER', { driver, pType: PLATAFORM_TYPE.CONSOLA })
    // Actualizando los console_group_drives_by_group en caso de que alla habido algun cambio
    await context.dispatch('getAllConsoleDrivers')
    await context.dispatch('setConsoleGroupDriversByGroup', { id_group: data.id_group })
  },
  async socket_driverConsoleAssinged (context: ActionContext<DriversState, string>, data: ICDriver & { bind: boolean; prev_id_report_item: number }) {
    // Formateando el objeto para que sea igual a la interfaz requerida Driver
    const driver = {
      id: data.id_driver,
      n: data.drv_name,
      p: data.drv_phone,
      id_group: data.id_group,
      resource_id: data.id_group,
      id_unit_remove: data.prev_id_report_item,
      bu: data.id_report_item,
      plataform: PLATAFORM_TYPE.CONSOLA,
      item: data
    }
    context.commit('DELETE_DRIVER', { driver, pType: PLATAFORM_TYPE.CONSOLA })
    context.commit('ADD_DRIVER', { driver, pType: PLATAFORM_TYPE.CONSOLA })
    // Actualizando los console_group_drives_by_group en caso de que alla habido algun cambio
    await context.dispatch('getAllConsoleDrivers')
    await context.dispatch('setConsoleGroupDriversByGroup', { id_group: data.id_group })
  },
  async setConsoleDrivers (context: ActionContext<DriversState, string>, { drivers }: { drivers: Driver & { id_group: number; id_report_item: number }[] }) {
    // @ts-ignore
    const consoleGroups: ConsoleGroup[] = context.rootState.console.consoleGroups
    const initialGroups = consoleGroups.reduce((curr, val) => {
      // @ts-ignore
      curr[0] = {}
      // @ts-ignore
      curr[val.id_group] = {}
      return curr
    }, {})

    const consoleDrivers = drivers.reduce((prev, curr) => {
      // @ts-ignore
      if (!prev[curr.id_group]) prev[curr.id_group] = {};
      // @ts-ignore
      if (!prev[curr.id_group][curr.id_report_item]) prev[curr.id_group][curr.id_report_item] = [];
      // @ts-ignore
      prev[curr.id_group][curr.id_report_item].push({ id: curr.id_driver, n: curr.drv_name, p: curr.drv_phone, id_group: curr.id_group, plataform: PLATAFORM_TYPE.CONSOLA, item: curr })

      return prev
    }, initialGroups)

    await context.commit('SET_DRIVERS', { drivers: consoleDrivers, pType: PLATAFORM_TYPE.CONSOLA })
  },
  changeCurrentDrivers (context: ActionContext<DriversState, string>, { resource_id, id_group, pType }: { resource_id: number; id_group: number; pType: PLATAFORM_TYPE }): void {
    let rDrivers: Driver[] = []
    let cDriversNoBinded: Driver[] = []
    let consoleGroupDrivers: Driver[] = []

    if (context.state.drivers[pType][resource_id]) {
      const resourceDrivers = Object.values(context.state.drivers[pType][resource_id]).reduce(function (prev, curr) {
        return prev.concat(curr)
      }, [])
      rDrivers = resourceDrivers.sort((a, b) => a.n.localeCompare(b.n))
    }

    if (context.state.drivers[PLATAFORM_TYPE.CONSOLA][id_group]) {
      consoleGroupDrivers = Object.values(context.state.drivers[PLATAFORM_TYPE.CONSOLA][id_group]).reduce(function (prev, curr) {
        return prev.concat(curr)
      }, [])
      const cgSortedDrivers = consoleGroupDrivers.sort((a, b) => a.n.localeCompare(b.n))
      cDriversNoBinded = cgSortedDrivers.filter(obj => !rDrivers.find(drv => obj.item?.drv_custom_properties?.res_driver?.id === drv.id))
    }

    if (rDrivers.length) {
      rDrivers = rDrivers.map(obj => ({ ...obj, item: consoleGroupDrivers.find(drv => drv.item?.drv_custom_properties?.res_driver?.id === obj.id)?.item }))
    }

    context.commit('SET_RESOURCE_DRIVERS', rDrivers)
    context.commit('SET_CONSOLE_GROUP_DRIVERS', cDriversNoBinded)
    context.commit('SET_CURRENT_GROUP', id_group)
  },
  async setConsoleGroupDriversByGroup (context: ActionContext<DriversState, string>, { id_group }: { id_group: number; }) {
    if (!context.state.drivers[PLATAFORM_TYPE.CONSOLA][id_group]) return []
    const drivers = Object.values(context.state.drivers[PLATAFORM_TYPE.CONSOLA][id_group]).reduce(function (prev, curr) {
      return prev.concat(curr)
    }, [])

    context.commit('SET_CONSOLE_GROUP_DRIVERS_BY_GROUP_ID', { drivers, id_group })

    return drivers
  },
  addDriver (context: ActionContext<DriversState, string>, payload: { driver: Driver & { resource_id: number }; pType: PLATAFORM_TYPE }): void {
    context.commit('ADD_DRIVER', payload)
  },
  deleteDriver (context: ActionContext<DriversState, string>, payload: { driver: Driver & { resource_id: number }; pType: PLATAFORM_TYPE }): void {
    context.commit('DELETE_DRIVER', payload)
  },
  assingToUnit (context: ActionContext<DriversState, string>, payload: { driver: Driver & { resource_id: number; id_unit_remove: number }; pType: PLATAFORM_TYPE }) {
    context.commit('DELETE_DRIVER', payload)
    context.commit('ADD_DRIVER', payload)
  },
  updateDriver (context: ActionContext<DriversState, string>, payload: { driver: Driver & { resource_id: number }; pType: PLATAFORM_TYPE }) {
    context.commit('UPDATE_DRIVER', payload)
  },
  async onDriverChanged (context: ActionContext<DriversState, string>, data: any): Promise<void> {
    const oldStateDriver: Driver = data.getOldData()
    const currentStateDriver: Driver = data.getData()
    // @ts-ignore
    const resource_id: number = data._target._id
    const unitID = currentStateDriver?.bu || oldStateDriver?.bu
    const exist = context.state.drivers[PLATAFORM_TYPE.WIALON][resource_id][unitID]
    if (!exist) {
      Vue.set(context.state.drivers[PLATAFORM_TYPE.WIALON][resource_id], unitID, [])
    }
    if (!oldStateDriver) {
      // creado
      context.commit('ADD_DRIVER', { driver: { ...currentStateDriver, resource_id, plataform: PLATAFORM_TYPE.WIALON }, pType: PLATAFORM_TYPE.WIALON })
    } else if (!currentStateDriver) {
      // eliminado
      context.commit('DELETE_DRIVER', { driver: { ...oldStateDriver, resource_id, plataform: PLATAFORM_TYPE.WIALON }, pType: PLATAFORM_TYPE.WIALON })
    } else {
      if (oldStateDriver.bu === 0 && currentStateDriver.bu !== 0) { // Se asigno a una unidad
        context.dispatch('assingToUnit', { driver: { ...currentStateDriver, resource_id, id_unit_remove: oldStateDriver.bu, plataform: PLATAFORM_TYPE.WIALON }, pType: PLATAFORM_TYPE.WIALON })
      } else if (oldStateDriver.bu !== 0 && currentStateDriver.bu === 0) { // Se removio de una unidad
        context.dispatch('assingToUnit', { driver: { ...currentStateDriver, resource_id, id_unit_remove: oldStateDriver.bu, plataform: PLATAFORM_TYPE.WIALON }, pType: PLATAFORM_TYPE.WIALON })
      } else { // Se actualizo alguna propiedad del operador
        context.commit('UPDATE_DRIVER', { driver: { ...currentStateDriver, resource_id, plataform: PLATAFORM_TYPE.WIALON }, pType: PLATAFORM_TYPE.WIALON })
      }
    }
  },
  async getDrivers (context: ActionContext<DriversState, string>): Promise<void> {
    // @ts-ignore
    const drivers = await getAVLResourceDrivers(context.rootState.console.consoleGroups.map(cGroup => cGroup.gp_wialon_id))
    context.commit('SET_DRIVERS', { drivers, pType: PLATAFORM_TYPE.WIALON })
  },
  async bindDriver (context: ActionContext<DriversState, string>, { id_report_item, isBinded, gp_wialon_id, driver, ri_wialon_id, pType }: { id_report_item: number, id_group: number, isBinded: boolean; gp_wialon_id: string, driver: Driver, ri_wialon_id: string; pType: PLATAFORM_TYPE }) {
    try {
      context.dispatch('sys/showNotificationMessage', {
        title: 'Ejecutando peticion...',
        color: 'info'
      }, { root: true })
      switch (pType) {
        case PLATAFORM_TYPE.CONSOLA: {
          if (isBinded) {
            await context.dispatch('toggleBindConsoleDriver', { id_report_item: null, id_driver: driver.id })
            context.dispatch('sys/showNotificationMessage', {
              title: `Conductor ${driver.n} desvinculado`,
              color: 'success'
            }, { root: true })
          } else {
            await context.dispatch('toggleBindConsoleDriver', { id_report_item, id_driver: driver.id })
            context.dispatch('sys/showNotificationMessage', {
              title: `Conductor ${driver.n} vinculado`,
              color: 'success'
            }, { root: true })
          }

          break
        }
        case PLATAFORM_TYPE.WIALON: {
          // @ts-ignore
          const resource = await getAVLResourceItem(parseInt(gp_wialon_id), wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.base | wialon.item.Resource.dataFlag.drivers | wialon.item.MDriver.flags.driver, 0)
          await toogleDriverBind(resource, parseInt(ri_wialon_id), driver)

          if (isBinded) {
            context.dispatch('sys/showNotificationMessage', {
              title: `Conductor ${driver.n} desvinculado`,
              color: 'success'
            }, { root: true })
          } else {
            context.dispatch('sys/showNotificationMessage', {
              title: `Conductor ${driver.n} vinculado`,
              color: 'success'
            }, { root: true })
          }
          break
        }
        case PLATAFORM_TYPE.MAPON: {
          if (isBinded) {
            await MaponSDK.actions.AUnit.toogleDriver({ driver_id: 0, unit_id: parseInt(ri_wialon_id) })
            context.dispatch('sys/showNotificationMessage', {
              title: `Conductor ${driver.n} desvinculado`,
              color: 'success'
            }, { root: true })
          } else {
            // @ts-ignore
            await MaponSDK.actions.AUnit.toogleDriver({ driver_id: parseInt(driver.id), unit_id: parseInt(ri_wialon_id) })
            context.dispatch('sys/showNotificationMessage', {
              title: `Conductor ${driver.n} vinculado`,
              color: 'success'
            }, { root: true })
          }
          break
        }
      }
    } catch (error) {
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se puedo realizar la peticion',
        color: 'error'
      }, { root: true })
      console.error(error)
      // @ts-ignore
      throw Error(error)
    }
  },
  async getAllConsoleDrivers ({ dispatch, commit }: ActionContext<DriversState, string>): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'drivers/get/all',
          method: 'GET'
        },
        { root: true }
      )

      dispatch('setConsoleDrivers', { drivers: data })
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'DRIVERS_GET_ALL', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async createConsoleDriver ({ dispatch, commit }: ActionContext<DriversState, string>, payload: { drv_name: string; drv_phone: string; id_group: number }): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'drivers/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'DRIVERS_CREATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async updateConsoleDriver ({ dispatch, commit }: ActionContext<DriversState, string>, payload: { drv_name: string; drv_phone: string; id_group: number }): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'drivers/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'DRIVERS_UPDATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async deleteConsoleDriver ({ dispatch, commit }: ActionContext<DriversState, string>, payload: { id_driver: number; drv_username: string }): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'drivers/delete',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'DRIVERS_DELETED', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async toggleBindConsoleDriver ({ dispatch, commit }: ActionContext<DriversState, string>, data: { id_driver: number; id_report_item: number }): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'drivers/assing',
          method: 'POST',
          data
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'DRIVERS_DELETED', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  }
}

const getters = {
  resDriver (state: DriversState) {
    return (id: number) => {
      return state.resourceDrivers.find(obj => obj.id === id)
    }
  },
  getArrDrivers (state: DriversState) {
    return Object.values(state.drivers).map(obj => Object.values(obj).map(drv => Object.values(drv).flat()).flat()).flat()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
