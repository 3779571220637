import { RouteConfig } from 'vue-router';
import store from '@/store/index';
import { USER_TYPE } from '@/store/catalogs/USER_TYPE';

const Login: RouteConfig = {
  path: '/',
  name: 'Login',
  component: () => import('../views/Login.vue'),
  beforeEnter: async (to, from, next) => {
    try {
      // @ts-ignore
      const isLogged: boolean = store.state.auth.is_auth;
      if (!isLogged) {
        next();
      } else {
        if (!isLogged) {
          await next()
        } else {
          // @ts-ignore
          if (store.state.auth.user_data.user_type.id === USER_TYPE.CLIENTE) {
            await next({
              replace: true,
              name: 'Cliente'
            });
            // @ts-ignore
          } else if (store.state.auth.user_data.user_type.id === USER_TYPE.CONDUCTOR) {
            await next({
              replace: true,
              name: 'Conductor'
            });
          } else {
            await next({
              replace: true,
              name: 'Monitoreo'
            });
          }
        }
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
};

export default Login;
