import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { State } from '..'

export default {
  getPFUnits (state: State) {
    return (pType: PLATAFORM_TYPE) => {
      return state.unitsstate.plataformUnits[pType] ?? []
    }
  }
}
