import { RouteConfig } from 'vue-router'
import store from '@/store/index'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'

const Cliente: RouteConfig = {
  path: '/Cliente',
  name: 'Cliente',
  component: () => import('../views/Cliente.vue'),
  async beforeEnter (to, from, next) {
    // @ts-ignore
    const isLogged: boolean = store.state.auth.is_auth
    // @ts-ignore
    if (isLogged) {
      try {
        await store.dispatch(
          'sys/setLoaderDialogState',
          {
            isLoader: true,
            dialogMessage: 'Iniciando consola'
          },
          { root: true }
        )
        await store.dispatch('auth/isTokenExpired')
        await store.dispatch('console/getConsoleGroups')
        // @ts-ignore
        const wialon = await store.dispatch('auth/havePlataform', PLATAFORM_TYPE.WIALON)
        const mapon = await store.dispatch('auth/havePlataform', PLATAFORM_TYPE.MAPON)
        const tracksolid = await store.dispatch('auth/havePlataform', PLATAFORM_TYPE.TRACKSOLID)

        if (wialon) {
          await store.dispatch('wialon/init')
        }
        if (mapon) {
          await store.dispatch('mapon/init')
        }
        if (tracksolid) {
          await store.dispatch('tracksolid/init')
        }

        // Se inicia la consola y se obtiene la informacion necesaria, ademas de iniciar los eventos del socket
        await store.dispatch('sys/init')

        await store.dispatch('sys/requestNotificationPermission')
        await store.dispatch('sys/requestGeolocationPermission')

        await store.dispatch('sys/setLoaderDialogState', {
          isLoader: true,
            dialogMessage: 'Bienvenido'
          }
        )

        setTimeout(() => {
          store.dispatch('sys/setLoaderDialogState', { isLoader: false })
        }, 2000)

        next()
      } catch (error) {
        console.error(error)
        store.dispatch('sys/setLoaderDialogState', { dialogMessage: 'No se pudo iniciar session, se limpiara el navegador localmente' })
        setTimeout(async () => {
          await store.dispatch('auth/closeSession')
        }, 2500)
      }
    } else {
      next({
        replace: true,
        name: 'Login'
      })
    }
  }
}

export default Cliente
