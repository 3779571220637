import { ActionContext } from 'vuex'
import { State } from '../..'
import { IScheduleUserDone, IScheduleUserPeriod, IScheduleStatisticItem } from '@/store/interfaces/Schedule'
import ScheduleUserPeriod from '@/store/packages/Schedules/ScheduleUserPeriod'
import ScheduleUserDone from '@/store/packages/Schedules/ScheduleUserDone'

export default {
  async getSchedulesDataByDates ({ dispatch }: ActionContext<State, string>, payload: { start_date: string, end_date: string }): Promise<{ schedule_user_periods: IScheduleUserPeriod[]; schedule_user_dones: IScheduleUserDone[] }> {
    try {
      const { data }: { data: { schedule_user_periods: IScheduleUserPeriod[]; schedule_user_dones: IScheduleUserDone[] } } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/statistics/get/by_dates',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_SCHEDULES_DATA_BY_DATES', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getSchedulesStatisticsByDates ({ dispatch }: ActionContext<State, string>, payload: { start_date: string, end_date: string }): Promise<any> {
    const data: { schedule_user_periods: IScheduleUserPeriod[]; schedule_user_dones: IScheduleUserDone[] } = await dispatch('getSchedulesDataByDates', payload)
    const schedule_user_periods = data.schedule_user_periods.map((item: IScheduleUserPeriod) => new ScheduleUserPeriod({ data: item }))
    const schedule_user_dones = data.schedule_user_dones.map((item: IScheduleUserDone) => {
      const scheduleUserDone = new ScheduleUserDone({ data: item })
      scheduleUserDone.resizeToBoundsLimits(payload.start_date, payload.end_date)
      return scheduleUserDone
    })

    // Union de los periodos y los dones
    const scheduleEvents = [...schedule_user_periods, ...schedule_user_dones]
    // Obtengo los usuarios de los periodos y de los dones
    const scheduleUsers = scheduleEvents.map((item: ScheduleUserPeriod | ScheduleUserDone) => item.user)
    // Obtengo los usuarios unicos
    const users = [...new Map(scheduleUsers.map((user) => [user.id_user, user])).values()]

    const usersStatistics: IScheduleStatisticItem[] = users.map(user => {
      const userScheduleTurns = scheduleEvents.reduce((prev, curr) => {
        if (curr.user.id_user !== user.id_user) return prev
        if (!prev[curr.eventName]) {
          prev[curr.eventName] = {
            turn: curr.eventName,
            color: curr.eventColor,
            dones: [],
            periods: [],
            dones_total_time: 0,
            periods_total_time: 0
          }
        }

        if (curr instanceof ScheduleUserPeriod) {
          prev[curr.eventName].periods.push(curr)
          prev[curr.eventName].periods_total_time += curr.secondsPeriodTime
        }

        if (curr instanceof ScheduleUserDone) {
          prev[curr.eventName].dones.push(curr)
          prev[curr.eventName].dones_total_time += curr.secondsPeriodTime
        }

        return prev
       }, {} as IScheduleStatisticItem['turns'])

      const { turn_dones_total_time, turn_periods_total_time } = Object.values(userScheduleTurns).reduce((prev, curr) => {
        prev.turn_dones_total_time += curr.dones_total_time
        prev.turn_periods_total_time += curr.periods_total_time
        return prev
      }, { turn_dones_total_time: 0, turn_periods_total_time: 0 })

      return {
        id_user: user.id_user,
        us_name: user.us_name,
        user,
        turns: userScheduleTurns,
        turn_dones_total_time,
        turn_periods_total_time
      }
    })

    return usersStatistics
  }
}
