import scheduleEventsActions from './scheduleEventsActions';
import scheduleTurnPeriodType from './scheduleTurnPeriodType';
import scheduleTurnsActions from './scheduleTurnsActions';
import ScheduleTurnPeriod from './scheduleTurnPeriod';
import scheduleUserPeriod from './scheduleUserPeriod';
import scheduleUserDone from './scheduleUserDone';
import scheduleStatistics from './scheduleStatistics';

export default {
  ...scheduleEventsActions,
  ...scheduleTurnsActions,
  ...scheduleTurnPeriodType,
  ...ScheduleTurnPeriod,
  ...scheduleUserPeriod,
  ...scheduleUserDone,
  ...scheduleStatistics
}
