import { State, SelectedCameras } from './state'

export default {
  SET_CAMERA_PROPERTIES (state: State, camera_properties: any[]): void {
    state.camera_properties = camera_properties
  },
  ADD_CAMERA_PROPERTIES (state: State, camera_properties: any): void {
    state.camera_properties.push(camera_properties)
  },
  UPDATE_CAMERA_PROPERTIES (state: State, { index, camera_properties }: { index: number; camera_properties: any; }): void {
    Object.assign(state.camera_properties[index], camera_properties)
  },
  DELETE_CAMERA_PROPERTIES (state: State, index: number): void {
    state.camera_properties.splice(index, 1)
  },
  ADD_SELECTED_CAMERA (state: State, payload: SelectedCameras) {
    state.selectedCameras.push(payload)
  },
  REMOVE_SELECTED_CAMERA (state: State, index: number) {
    state.selectedCameras.splice(index, 1)
  }
}
