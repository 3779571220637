import { DateTime, DurationUnits } from 'luxon'

export default (isoDate: string): string => {
  const dateOptions = DateTime.fromMillis(Date.parse(isoDate))
  const stringDate = `${dateOptions.day < 10 ? '0' : ''}${dateOptions.day}/${dateOptions.month < 10 ? '0' : ''}${dateOptions.month}/${dateOptions.year} - ${dateOptions.hour < 10 ? '0' : ''}${dateOptions.hour}:${dateOptions.minute < 10 ? '0' : ''}${dateOptions.minute}:${dateOptions.second < 10 ? '0' : ''}${dateOptions.second} hrs`
  return stringDate
}

export function timesBetweenIso (startDate: string, endDate: string, units: DurationUnits) {
  const date1 = DateTime.fromMillis(Date.parse(startDate))
  const date2 = DateTime.fromMillis(Date.parse(endDate))

  const diff = date1.diff(date2, units)
  return diff
}

export function formatFromISO8601 (isoDate: string) {
  const date = isoDate.replace(' ', 'T').substring(0, 26);
  const timeoffset = DateTime.now().setZone(DateTime.local().zoneName).offset / 60
  return DateTime.fromISO(date).plus({ hours: timeoffset }).toFormat('dd/LL/yyyy - HH:mm:ss')
}

export function formatFromISO8601ToDateTime (isoDate: string) {
  const date = isoDate.replace(' ', 'T').substring(0, 26);
  const timeoffset = DateTime.now().setZone(DateTime.local().zoneName).offset / 60
  return DateTime.fromISO(date).plus({ hours: timeoffset })
}
