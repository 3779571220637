import { IParameters, IResponce } from '../interfaces/login.interface';
import { Optional } from '../interfaces/utils.interface';
import Api from './Api';
import ApiRequest from '../decorators/ApiRequest'

export type IAlarmListParams = Optional<
  Pick<
    IParameters,
    'access_token' | 'imei' | 'begin_time' | 'end_time' | 'alertTypeId'
  >,
  'access_token' | 'begin_time' | 'alertTypeId' | 'end_time'
>;

export type IAlarmListResponce = IResponce<
  Pick<
    IParameters,
    | 'deviceName'
    | 'imei'
    | 'model'
    | 'account'
    | 'alertTypeId'
    | 'alarmTypeName'
    | 'alertTime'
    | 'lng'
    | 'lat'
    | 'speed'
    | 'status'
    | 'createTime'
    | 'geoid'
  >[]
>;

export default class Message extends Api {
  @ApiRequest({ method: 'jimi.device.alarm.list' })
  public static async alarmList (additParams: IAlarmListParams): Promise<IAlarmListResponce> {
    // @ts-ignore
    return additParams
  }
}
