import { ICredential } from '@/store/interfaces/Manager.interface'
import { State } from '..'

export default {
  SET_COMPANY_CREDENTIAL (state: State, credentials: ICredential) {
    state.admin.credentials.push(credentials)
  },
  DELETE_COMPANY_CREDENTIAL (state: State, index: number) {
    state.admin.credentials.splice(index, 1)
  }
}
