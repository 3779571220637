import { AdminData } from '@/store/interfaces/Manager.interface'
import { ActionContext } from 'vuex'

// Company
import companystate from './company/state'
import companymutatios from './company/mutations'
import companyactions from './company/actions'

// Dashboard
import dashactions from './dashboard/actions'
import dashmutations from './dashboard/mutations'
import dashstate from './dashboard/state'

// Credentials
import credentialsstate from './credentials/state'
import credentialsactions from './credentials/actions'
import credentialsmutatios from './credentials/mutations'
import credentialsgetters from './credentials/getters'

// Users
import usersactions from './users/actions'
import usersmutations from './users/mutations'
import usersstate from './users/state'
import usersgetters from './users/getters'

// Groups
import groupsstate from './groups/state'
import groupsmutatios from './groups/mutations'
import groupsactions from './groups/actions'

// Classification
import classsstate from './classification/state'
import classsactions from './classification/actions'
import classmutatios from './classification/mutations'
import classgetters from './classification/getters'

// Units
import unitsstate from './units/state'
import unitsmutatios from './units/mutations'
import unitsactions from './units/actions'
import unitsgetters from './units/getters'

// Steppers
import steppersstate from './steppers/state'
import steppersactions from './steppers/actions'
import steppersmutatios from './steppers/mutations'
import steppersgetters from './steppers/getters'

// Steppers
import stepsstate from './steps/state'
import stepsactions from './steps/actions'
import stepsmutatios from './steps/mutations'

// Schedule
import schedulestate from './schedule/state'
import scheduleactions from './schedule/actions'
import schedulemutatios from './schedule/mutations'
import schedulegetters from './schedule/getters'

// History
import historystate from './history/state'
import historyactions from './history/actions'
import historymutatios from './history/mutations'
import BreakAlarm from '@/store/packages/Break/BreakAlarm'

// Users History
import usershistorystate from './usershistory/state'
import usershistoryyactions from './usershistory/actions'
import usershistorymutatios from './usershistory/mutations'

const state = {
  companystate,
  dashstate,
  credentialsstate,
  unitsstate,
  usersstate,
  groupsstate,
  classsstate,
  steppersstate,
  stepsstate,
  historystate,
  usershistorystate,
  schedulestate,
  managerComponent: 'Dashboard',
  admin: {} as AdminData
}

export type State = typeof state

const actions = {
  ...unitsactions,
  ...usersactions,
  ...dashactions,
  ...companyactions,
  ...groupsactions,
  ...classsactions,
  ...steppersactions,
  ...stepsactions,
  ...historyactions,
  ...credentialsactions,
  ...usershistoryyactions,
  ...scheduleactions,
  async init (context: ActionContext<State, string>) {
    await context.dispatch('initUnits')
    context.dispatch('initSocketBreakTime')
  },
  changeTab (context: ActionContext<State, string>, component: string) {
    context.dispatch('sys/changeDrawerTab', 'tab-operations', { root: true })
    context.commit('SET_MANAGER_COMPONENT', component)
  }
}

const mutations = {
  ...unitsmutatios,
  ...usersmutations,
  ...dashmutations,
  ...companymutatios,
  ...groupsmutatios,
  ...classmutatios,
  ...steppersmutatios,
  ...stepsmutatios,
  ...historymutatios,
  ...credentialsmutatios,
  ...usershistorymutatios,
  ...schedulemutatios,
  SET_STATE (state: State, payload: typeof state) {
    let usersAlarms = []
    // @ts-ignore
    if (payload.admin.horarios) {
      // @ts-ignore
       usersAlarms = payload.admin.horarios.map(h => new BreakAlarm(h))
    }
    // @ts-ignore
    payload.admin.horarios = usersAlarms
    Object.assign(state, payload)
  },
  SET_ADMIN_STATE (state: State, payload: typeof state) {
    Object.assign(state.admin, payload)
  },
  SET_MANAGER_COMPONENT (state: State, component: string) {
    state.managerComponent = component
  }
}

const getters = {
  ...unitsgetters,
  ...steppersgetters,
  ...usersgetters,
  ...classgetters,
  ...credentialsgetters,
  ...schedulegetters,
  statistics (state: State) {
    return state.admin.console_statistics
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
