import { State } from '../index'
import { ICompany } from '@/store/interfaces/Manager.interface'

export default {
  UNSHIFT_COMPANY (state: State, payload: ICompany) {
    state.admin.companies.unshift(payload)
  },
  PUSH_COMPANY (state: State, payload: ICompany) {
    state.admin.companies.push(payload)
  },
  UPDATE_COMPANY (state: State, { index, data }: { data: ICompany; index: number }) {
    Object.assign(state.admin.companies[index], data)
  },
  DELETE_COMPANY (state: State, index: number) {
    state.admin.companies.splice(index, 1)
  }
}
