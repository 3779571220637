import ReportItemData from '@/store/packages/ReportItem/ReportItemData'

export default {
  getRIWithOnlineCameras (state: any, getters:any, rootState:any, rootGetters:any) {
    return rootGetters['reports/riWithPlataformCameras'].filter((ri: ReportItemData<unknown, unknown>) => ri.cameraPlataformWithData.some(camera => camera.online === true))
  },
  riWithHVCameras (state: any, getters:any, rootState:any, rootGetters:any) {
    return rootGetters['reports/riWithPlataformCameras'].filter((ri: ReportItemData<unknown, unknown>) => ri.ri_camera_plataform.some(rcp => rcp?.cpt_properties?.EhomeID))
  },
  hvPlataformCamerasWithRI (state: any, getters:any, rootState:any) {
    const ri_with_plataform_cameras = []
    for (let i = 0; i < rootState.reports.reports.length; i++) {
      const rg = rootState.reports.reports[i];

      for (let j = 0; j < rg.unitData.ri_items.length; j++) {
        const ri: ReportItemData<unknown, unknown> = rg.unitData.ri_items[j];

        for (let k = 0; k < ri.ri_camera_plataform.length; k++) {
          const rcp = ri.ri_camera_plataform[k];
          if (rcp?.cpt_properties?.EhomeID) { ri_with_plataform_cameras.push({ report_item: ri, camera: rcp }) }
        }
      }
    }

    return ri_with_plataform_cameras
  },
  getAllHikCameraEhomeIDs (state: any, getters:any, rootState:any, rootGetters:any) {
    return (rootGetters['reports/riWithPlataformCameras'] as ReportItemData<unknown, unknown>[])
      .reduce((prev: string[], curr) => {
        const keys: string[] = curr.ri_camera_plataform.filter(rcp => rcp?.cpt_properties?.EhomeID).map(rcp => rcp?.cpt_properties?.EhomeID)
        if (curr.ri_camera_plataform.some(rcp => rcp?.cpt_properties?.EhomeID)) {
          prev = prev.concat(keys)
        }
        return prev
      }, [] as string[])
  }
}
