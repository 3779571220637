import { ActionContext } from 'vuex'
import Note from '@/store/interfaces/Note'
import NoteTracing from '@/store/interfaces/NoteTracing'
import NoteItem from './NoteItem'
import { Socket } from 'socket.io-client'
import { serializeError } from 'serialize-error'
import ActualizerItemData from '@/store/packages/Actualizer/ActualizerItemData'

interface NotesState {
  notesOpened: any
  actualizerItem: ActualizerItemData<unknown> | null
  currentNotes: Array<NoteItem>
  selectedConsoleGroups: Array<number>
  isOldNotesDialogOpen: boolean
  isDialogNotesOpen: boolean
  isDialogNotesAddOpen: boolean
  isDialogNoteAlarmDateOpen: boolean
  isDialogNoteEditOpen: boolean
}

const state: NotesState = {
  notesOpened: [],
  actualizerItem: null,
  currentNotes: [],
  selectedConsoleGroups: [],
  isDialogNotesOpen: false,
  isOldNotesDialogOpen: false,
  isDialogNotesAddOpen: false,
  isDialogNoteAlarmDateOpen: false,
  isDialogNoteEditOpen: false
}

const mutations = {
  SET_DIALOG_NOTES_STATE (state: NotesState, dialogState: boolean): void {
    state.isDialogNotesOpen = dialogState
  },
  SET_DIALOG_NOTES_ADD_STATE (state: NotesState, dialogState: boolean): void {
    state.isDialogNotesAddOpen = dialogState
  },
  SET_NOTES (state: NotesState, notes: Array<NoteItem>): void {
    state.currentNotes = notes
  },
  ADD_NOTE (state: NotesState, note: NoteItem): void {
    state.currentNotes.unshift(note)
  },
  ADD_TRACING_NOTE (state: NotesState, noteTracingData: { noteIndex: number, noteTracing: NoteTracing }): void {
    state.currentNotes[noteTracingData.noteIndex].notes_tracing.push(noteTracingData.noteTracing)
  },
  REMOVE_NOTE (state: NotesState, noteIndex: number): void {
    state.currentNotes.splice(noteIndex, 1)
  },
  SET_SELECTED_GROUP (state: NotesState, id_group: number): void {
    state.selectedConsoleGroups = [id_group]
  },
  SET_ALARM_DIALOG_STATE (state: NotesState, toggle: boolean): void {
    state.isDialogNoteAlarmDateOpen = toggle
  },
  SET_ACTUALIZER_ITEM (state: NotesState, actI: ActualizerItemData<unknown> | null): void {
    state.actualizerItem = actI
  },
  SET_NOTES_FOCUS (state: NotesState, indexs: number[]): void {
    state.notesOpened = indexs
  }
}

const actions = {
  init (context: ActionContext<NotesState, string>) {
    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    // @ts-ignore
    socket.on('note_alarm_changed', data => context.dispatch('socket_noteAlarmChanged', data))

    socket.on('note_created', data => context.dispatch('socket_noteCreated', data))

    socket.on('changed_note_content', data => context.dispatch('socket_noteChanged', data))

    socket.on('note_tracing_created', data => context.dispatch('socket_noteTracingCreated', data))

    // socket.on('changed_note_content', data => context.dispatch('socket_noteChanged', data))

    socket.on('note_finished', data => context.dispatch('socket_noteFinished', data))
  },
  focusDialogNote (context: ActionContext<NotesState, string>, id_note: number) {
    const index = context.state.currentNotes.findIndex(nt => nt.id_note === id_note)
    if (index !== -1) {
      context.commit('SET_NOTES_FOCUS', [index])
      context.commit('SET_DIALOG_NOTES_STATE', true)
    }
  },
  toggleNotesDialog (context: ActionContext<NotesState, string>): void {
    context.commit('SET_DIALOG_NOTES_STATE', !(context.state.isDialogNotesOpen))
  },
  toggleNotesAddDialog (context: ActionContext<NotesState, string>): void {
    context.commit('SET_DIALOG_NOTES_ADD_STATE', !(context.state.isDialogNotesAddOpen))
  },
  openSelectedNotes (context: ActionContext<NotesState, string>, id_group:number): void {
    context.commit('SET_SELECTED_GROUP', id_group)
    context.dispatch('toggleNotesDialog')
  },
  async finishNote (context: ActionContext<NotesState, string>, notePayload: { id_note: string }): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'finish_note',
          method: 'post',
          data: notePayload
        },
        { root: true }
      )
    } catch (error) {
      console.log(error)
    }
  },
  async getAllAvaliableNotes (context: ActionContext<NotesState, string>, payload: { notes: Note[] }): Promise<void> {
    try {
      let dataNotes = payload?.notes
      if (!dataNotes) {
        const { data: { data } }: { data: { data: Note[] } } = await context.dispatch(
          'sys/axios',
          {
            url: 'get_all_availables_notes',
            method: 'get'
          },
          { root: true }
        )

        // @ts-ignore
        dataNotes = data
      }
      context.commit('SET_NOTES', dataNotes.map(note => new NoteItem(note)))
    } catch (error) {
      console.log(error)
    }
  },
  async getAllNotAvaliableNotes (context: ActionContext<NotesState, string>): Promise<void> {
    try {
      const { data: { data } }: { data: { data: Note[] } } = await context.dispatch(
        'sys/axios',
        {
          url: 'get_all_not_avaliable_notes',
          method: 'get'
        },
        { root: true }
      )

      context.commit('SET_NOTES', data)
    } catch (error) {
      console.log(error)
    }
  },
  async createNote (context: ActionContext<NotesState, string>, noteData: { nt_title: string, nt_description: string, id_group: string, id_user: number }): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'create_note',
          method: 'post',
          data: noteData
        },
        { root: true }
      )
      context.dispatch('sys/playSound', { type: 'done' }, { root: true })
    } catch (error) {
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudo crear la nota actual',
        color: 'error'
      }, { root: true })
      console.error(error)
      throw Error()
    }
  },
  async createNoteTracing (context: ActionContext<NotesState, string>, noteTracingData: { id_note: string, id_user: string, ntg_description: string }): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'add_tracing_note',
          method: 'post',
          data: noteTracingData
        },
        { root: true }
      )
    } catch (error) {
      console.log(error)
    }
  },

  changeDialogAlarm (context: ActionContext<NotesState, string>, toggle: boolean): void {
    context.commit('SET_ALARM_DIALOG_STATE', toggle)
  },
  findAndStopAlarm (context: ActionContext<NotesState, string>, id_note: number): void {
    const note_index = context.state.currentNotes.findIndex(note => note.id_note === id_note)
    if (note_index === -1) return
    const note = context.state.currentNotes[note_index]
    note.stopCounterAlarm()
    // @ts-ignore
    context.dispatch('toggleNotesDialog')
    // @ts-ignore
    context.dispatch('focusDialogNote', id_note)
  },
  async getPaginationNotes (context: ActionContext<NotesState, string>, payload: { page: number; items: number; ids_groups: number[]; show_ungroup_notes: boolean }): Promise<Note[]> {
    try {
      const { data } = await context.dispatch(
        'sys/axios',
        {
          url: 'get_all_not_avaliable_notes_pagination',
          method: 'post',
          data: payload
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      context.commit(
        'sys/ADD_LOG',
        {
          title: 'NOTES_PAGINATION',
          color: 'error',
          message: '',
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async changeAlarmDate (context: ActionContext<NotesState, string>, noteTracingData: { id_note: number, nt_alarm_date: string }): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: 'change_note_alarm',
          method: 'post',
          data: noteTracingData
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
    }
  },
async changeNoteContent (context: ActionContext<NotesState, string>, notePayload: {title: string, description: string}): Promise<void> {
  try {
    await context.dispatch('sys/axios', {
      url: 'change_note_content',
      method: 'post',
      data: notePayload
    },
    { root: true }
    )
  } catch (error) {
    console.log(error)
  }
},
  socket_noteAlarmChanged (context: ActionContext<NotesState, string>, { id_note, nt_alarm_date, us_name }: { id_note: number, nt_alarm_date: string, us_name: string }): void {
    const note_index = context.state.currentNotes.findIndex(note => note.id_note === id_note)
    if (note_index !== -1) {
      const note = context.state.currentNotes[note_index]
      note.nt_alarm_date = nt_alarm_date
      note.resetCounterAlarm()
      context.dispatch('sys/showNotificationMessage', {
        title: `${us_name} a cambiado la alarma de "${note.nt_title}"`,
        color: 'info'
      }, { root: true })
      context.dispatch('sys/playSound', { type: 'done' }, { root: true })
    }
  },
  socket_noteChanged (context: ActionContext<NotesState, string>, dataChanged: { id_note: number, nt_title: string, nt_description: string, us_name: string}): void {
    const note_index = context.state.currentNotes.findIndex(note => note.id_note === dataChanged.id_note)
    if (note_index !== -1) {
      const note = context.state.currentNotes[note_index]
      note.nt_title = dataChanged.nt_title
      note.nt_description = dataChanged.nt_description
      context.dispatch('sys/showNotificationMessage', {
      title: `${dataChanged.us_name} a cambiado la nota de "${note.nt_title}"`,
        color: 'info'
      }, { root: true })
      context.dispatch('sys/playSound', { type: 'done' }, { root: true })
    }
  },
  socket_noteCreated (context: ActionContext<NotesState, string>, note: Note): void {
    context.commit('ADD_NOTE', new NoteItem(note))
    context.dispatch('sys/showNotificationMessage', {
      title: `El ${note.user} a creado una nota`,
      color: 'info'
    }, { root: true })
    context.dispatch('sys/playSound', { type: 'done' }, { root: true })
  },
  socket_noteTracingCreated (context: ActionContext<NotesState, string>, noteTracing: NoteTracing): void {
    const noteIndex = context.state.currentNotes.findIndex(note => note.id_note === noteTracing.id_note)
    if (noteIndex !== -1) {
      context.commit('ADD_TRACING_NOTE', { noteIndex, noteTracing })
    } else {
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudo realizar la operacion',
        color: 'error'
      }, { root: true })
    }
  },
  socket_noteFinished (context: ActionContext<NotesState, string>, notePayload: { id_note: number, us_name: string }): void {
    const noteIndex = context.state.currentNotes.findIndex(note => note.id_note === notePayload.id_note)
    if (noteIndex !== -1) {
      const note = context.state.currentNotes[noteIndex]
      note.stopCounterAlarm()
      context.commit('REMOVE_NOTE', noteIndex)
      context.dispatch('sys/showNotificationMessage', {
        title: `${notePayload.us_name} finalizo una nota`,
        color: 'info'
      }, { root: true })
      context.dispatch('sys/playSound', { type: 'done' }, { root: true })
    } else {
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudo realizar la operacion',
        color: 'error'
      }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
