export enum CONSOLE_NOTIFICATIONS {
    UNIT_DESTINATION_LEAVE = -1,
    UNIT_DESTINATION_RETURN = -2,
    UNIT_NOTIFICATION_MAX_SPEED = -3,
    UNIT_NOTIFICATION_LOW_GPS_BATERY = -4,
    UNIT_NOTIFICATION_LOW_UNIT_BATERY = -5,
    UNIT_NOTIFICATION_NOT_REPORTING_ON_IGNITION_TRUE = -6,
    UNIT_NOTIFICATION_NOT_REPORTING_ON_IGNITION_FALSE = -7,
    UNIT_NOTIFICATION_SHOW_ON_SIGNAL_LOST = -8
}
