export enum USER_TYPE {
    ADMINISTRADOR = 1,
    MONITOREO = 2,
    CLIENTE = 3,
    OPERACIONES = 4,
    CONDUCTOR = 6,
    TECNICO = 7
}

export const USER_TYPE_ITEMS = [
  {
    id_user_type: USER_TYPE.ADMINISTRADOR,
    ut_name: 'ADMINISTRADOR'
  },
  {
    id_user_type: USER_TYPE.OPERACIONES,
    ut_name: 'OPERACIONES'
  },
  {
    id_user_type: USER_TYPE.MONITOREO,
    ut_name: 'MONITOREO'
  },
  {
    id_user_type: USER_TYPE.CLIENTE,
    ut_name: 'CLIENTE'
  },
  {
    id_user_type: USER_TYPE.CONDUCTOR,
    ut_name: 'CONDUCTOR'
  },
  {
    id_user_type: USER_TYPE.TECNICO,
    ut_name: 'TECNICO'
  }
]
