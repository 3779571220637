import { State } from '..'

export default {
  /**
 * A function that sets credentials data in the given state using a map.
 * The map key is the combination of id_company_credential_type and id_company to avoid duplicates the same credential type and company
 *
 * @param {State} state - the state object
 * @return {Map} a map containing the credentials data
 */
  mapCredentialsData: (state: State) => {
    return new Map(state.admin.credentials.map(obj => [
      `${obj.id_company_credential_type}${obj.id_company}`,
      obj
    ]))
  }
}
