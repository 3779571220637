import { IScheduleTurnPeriodType, IScheduleUserDone } from '@/store/interfaces/Schedule'
import { ScheduleTurn } from '@/store/packages/Schedules/ScheduleTurn'
import ScheduleUserDone from '@/store/packages/Schedules/ScheduleUserDone'
import ScheduleUserPeriod from '@/store/packages/Schedules/ScheduleUserPeriod'
import Timer from 'easytimer.js'

const state = {
  // Datos del manager
  schedulesTurns: [] as ScheduleTurn[],
  schedulesTurnsPeriodTypes: [] as IScheduleTurnPeriodType[],
  scheduleUsersPeriods: [] as ScheduleUserPeriod[],
  scheduleUserDonePeriods: [] as IScheduleUserDone[],

  // Datos de monitoreo
  scheduleAvaliableUserPeriodsTypes: [] as IScheduleTurnPeriodType[], // tipos de periodos disponibles
  nearestScheduleUserPeriods: [] as ScheduleUserPeriod[], // proximos periodos asignados al usuario
  currScheduleTimer: new Timer({ precision: 'minutes' }),
  currScheduleUserPeriodDone: null as null | ScheduleUserDone, // Periodo actual del usuario
  currFormatedLabelTime: '00:00'
}

export type State = typeof state

export default state
