import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '@/store'
import { ActualizerNote } from '@/store/interfaces/ActualizerNotes'
import timeFormating from '@/utils/timeFormating'
import Timer from 'easytimer.js'
const C24HRS_IN_SECONDS = 86400
/**
 * Buils a object that manages a unit that the last message is more than 24hrs
 */
export default abstract class ActualizerItemData<AIType> {
  abstract pType: PLATAFORM_TYPE
  unit_item: AIType
  unit_timer: Timer
  unit_time_report: string
  unit_time_report_seconds: number
  is_reporting: boolean
  /**
     * Create a point.
     * @param {any} unit_item - Wialon item unit, generated by the util metho.
     */
  constructor (unit_item: any) {
    this.is_reporting = false
    this.unit_item = unit_item
    this.unit_time_report = ''
    this.unit_time_report_seconds = this.getSecondsDiff()
    this.unit_timer = new Timer({
      startValues: { seconds: this.unit_time_report_seconds }
    })
    this.isReporting()
    this.getUnitTimeReport()
  }

  get notes () {
    // @ts-ignore
    const notes: ActualizerNote[] = store.state.actualizer.actualizerNotes
    const aiNotes = notes.filter(nt => `${nt.ain_plataform_unit_id}` === `${this.getPlataformId()}`)
    return aiNotes.reverse()
  }

  abstract getSecondsDiff(): number

  isReporting (): boolean {
    if (this.unit_time_report_seconds > C24HRS_IN_SECONDS) {
      this.is_reporting = false
    } else {
      this.is_reporting = true
    }
    return this.is_reporting
  }

  getUnitTimeReportSeconds (): number {
    return this.unit_time_report_seconds
  }

  getUnitTimeReport (): string {
    this.unit_time_report = timeFormating(this.unit_timer)
    return this.unit_time_report
  }

  abstract getPlataformId (): number

  abstract getName (): string

  abstract getResourceId (): number

  abstract getIcon (): string
}
