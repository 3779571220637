import { BinnacleData, Technician } from '@/store/interfaces/Binnacle'

export interface BinnacleState {
  techModalProperties: {
    modal: boolean
    technician: Technician | null
  }
  keyComponent: number
  data: BinnacleData,
  component: string
}

const state: BinnacleState = {
  keyComponent: 1,
  data: {
    actions: [],
    columns: [],
    companies: [],
    equipments: [],
    plans: [],
    sims: [],
    technicians: [],
    brands: [],
    models: [],
    installations: [],
    default_columns: [],
    equipments_class: [],
    inv_groups: {},
    inv_items: {}
  },
  techModalProperties: {
    modal: false,
    technician: null
  },
  component: ''
}

export type State = typeof state

export default state
