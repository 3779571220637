import { ActionContext } from 'vuex';
import { State } from '..';

export default {
  async getAllREL ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/register_event_log/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { register_event_logs: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'REGISTER_EVENT_LOGS_GET_ALL', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
