export interface SelectedCameras {
  id_report_group: number
  report_group_index: number
  report_item_index: number
  id_report_item: number
  camera_index: number
  id_camera: string
}

const state = {
  camera_properties: [] as any[],
  selectedCameras: [] as SelectedCameras[]
}

export type State = typeof state

export default state
