import store from '@/store/index'
import { Technician as ITechnician, TechnicianEquipment, TechnicianSims, Equipment as IEquipment, Sim as ISim, TechnicianCost, ITechnicianInventory } from '@/store/interfaces/Binnacle'
import Equipment from './Equipment'
import Sim from './Sim'
import InventoryItem from './InventoryItem'

export default class Technician implements ITechnician {
  id_technician!: number
  tec_name!: string
  tec_properties: any
  tec_number!: string
  is_deleted!: boolean
  equipments!: TechnicianEquipment[]
  sims!: TechnicianSims[]
  costs!: TechnicianCost[]
  inv_items!: ITechnicianInventory[]

  constructor ({ data }: { data: ITechnician }) {
    Object.assign(this, data)
  }

  public get avalEquipments () {
    // @ts-ignore
    const equiments: IEquipment[] = store.state.binnacle.data.equipments.reduce((prev, eqp) => {
      const techEquipment = this.equipments.find(obj => obj.id_equipment === eqp.id_equipment)
      if (techEquipment) {
        eqp.user = techEquipment.user
        eqp.id_user = techEquipment.id_user
        eqp.bindedAt = techEquipment.createdAt
        prev.push(eqp)
      }
      return prev
    }, [])

    return equiments
  }

  public get avalSims () {
    // @ts-ignore
    const ids_sims_equipments: number[] = this.avalEquipments.reduce((acc: number[], obj) => {
      if (obj.id_sim && !obj.eq_status_active) {
        acc.push(obj.id_sim);
      }
      return acc;
    }, []);
    const ids_sims: number[] = this.sims.reduce((acc: number[], obj) => {
      if (!ids_sims_equipments.includes(obj.id_sim)) {
        acc.push(obj.id_sim);
      }
      return acc;
    }, []);
    // @ts-ignore
    const sims: ISim[] = store.state.binnacle.data.sims.filter(sim => ids_sims.includes(sim.id_sim))
    return sims
  }

  getEquipmentListCopy (): { id_equipment: number; id_technician: number; eq_imei: string, eq_esn: string, bra_name: string, mod_name: string, sim_line: string }[] {
    return this.avalEquipments.map((equip) => {
      equip as Equipment
      return {
        id_equipment: equip.id_equipment,
        id_technician: this.id_technician,
        eq_imei: equip.eq_imei,
        eq_esn: equip.eq_esn,
        // @ts-ignore
        user: equip.user,
        // @ts-ignore
        bindedAt: equip.bindedAt,
        // @ts-ignore
        bra_name: equip.bra_name,
        // @ts-ignore
        mod_name: equip.mod_name,
        // @ts-ignore
        sim_line: equip.sim_line,
        id_sim: equip.id_sim,
        // @ts-ignore
        plan_name: equip.plan_name,
        // @ts-ignore
        classification: equip.classification
      }
    });
  }

  getSimsListCopy (): { id_sim: number; sim_ccid: string, sim_line: string, plan_name: string }[] {
    return this.avalSims.map((sim) => {
      sim as Sim
      return {
        id_sim: sim.id_sim,
        id_technician: this.id_technician,
        sim_ccid: sim.sim_ccid,
        sim_line: sim.sim_line,
        // @ts-ignore
        plan_name: sim.plan_name
      }
    });
  }

  /**
   * Retrieves the available inventory items for a technician.
   *
   * @returns An array of inventory items with their corresponding btii_amount.
   *
   * @example
   * const technician = new Technician({ data: technicianData });
   * const inventory = technician.inventory;
   * console.log(inventory);
   *
   * Output:
   * [
   *   {
   *     id_bit_inventory_item: 1,
   *     item_name: "Item 1",
   *     btii_amount: 5
   *   },
   *   {
   *     id_bit_inventory_item: 2,
   *     item_name: "Item 2",
   *     btii_amount: 10
   *   }
   * ]
   */
  public get inventory () {
    const avaliableInv = this.inv_items.reduce((prev, curr) => {
      // @ts-ignore
      if ((store.state.binnacle.data.inv_items as { [key: number]: InventoryItem })[curr.id_bit_inventory_item]) {
        // @ts-ignore
        prev.push({
          // @ts-ignore
          ...(store.state.binnacle.data.inv_items as { [key: number]: InventoryItem })[curr.id_bit_inventory_item],
          btii_amount: curr.btii_amount
        })
      }
      return prev
    }, [] as (InventoryItem & { btii_amount: number })[])

    return avaliableInv
  }

  public get inventoryIndexes () {
    return this.inv_items.reduce((prev, curr) => {
      prev[curr.id_bit_inventory_item] = curr
      return prev
    }, {} as { [key: number]: ITechnicianInventory })
  }
}
