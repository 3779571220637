export enum CAMERA_PLATAFORM {
  HIKVISION = 1
}

export const CAMERA_PLATAFORM_ARR = [
  {
    id: 1,
    name: 'HIKVISION'
  }
]
