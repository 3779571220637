import { UserConected } from '@/interfaces/Comunications.interface'
import { CALL_STATE } from '@/store/catalogs/CALL_STATE'
import { CALL_TYPE } from '@/store/catalogs/CALL_TYPE'
import Peer, { DataConnection, MediaConnection } from 'peerjs'

const state = {
  key: 1,
  callsEnable: false,
  users_conected: [] as UserConected[],
  sid: '',
  peer: null as Peer | null,
  peerId: '',
  devices: [] as MediaDeviceInfo[],
  audioControls: { deviceId: 'default' } as MediaTrackConstraints,
  videoControls: {} as MediaTrackConstraints,
  controls: {
    audio: true,
    video: true
  } as MediaStreamConstraints,
  call: {
    stream: null as MediaStream | null,
    connection: null as DataConnection | null,
    call: null as MediaConnection | null,
    audio: new Audio(),
    incomingCall: CALL_STATE.FREE_TO_CALL as CALL_STATE,
    type: CALL_TYPE.ONE_TO_ONE as CALL_TYPE,
    users: [] as (UserConected & { stream: MediaStream })[],
    enableMicrophone: true,
    enableWebcam: true
  },
  modalComponent: {
    modal: false,
    component: '',
    allowCall: false
  }
}

export type State = typeof state

export default state
