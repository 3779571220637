<template lang="pug">
v-app#mainElement
    router-view
    notification-message
    comunication-message
    LoderDialog(v-if="$store.state.sys.loaderDialog.isLoader")
</template>

<script>
import Vue from 'vue'
import NotificationMessage from '@/components/Layout/NotificationMessage.vue'
import ComunicationMessage from '@/components/Layout/ComunicationMessage.vue'
import '@/sass/customScrolls.sass'
import LoderDialog from '@/components/utils/LoderDialog'

export default Vue.extend({
  name: 'App',
  components: {
    LoderDialog,
    NotificationMessage,
    ComunicationMessage
  }
})
</script>
