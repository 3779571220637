/* eslint-disable @typescript-eslint/no-unused-vars */
import { IObject } from '../../interfaces/Object.interface'
import { IParameters } from '../../interfaces/parameters.interface'
import { Optional } from '../../interfaces/utils.interface'
import AssingData from '../../utils/AssingData'
import Action from './Action'
import pip from 'point-in-polygon'
// @ts-ignore
import extract from 'extract-string'

export type IListUnitParams = Optional<
  Pick<
    IParameters,
    | 'key'
    | 'id'
    | 'name'
    | 'group_id'
    | 'deleted'
    | 'updated_from'
    | 'updated_till'
    | 'wkt_lon_first'
  >,
  | 'key'
  | 'id'
  | 'name'
  | 'group_id'
  | 'deleted'
  | 'updated_from'
  | 'updated_till'
  | 'wkt_lon_first'
>;

@AssingData()
export default class AObject extends Action<IObject> implements IObject {
  id!: number;
  name!: string;
  wkt!: string;
  user_id!: string;
  group_id!: string;
  private!: string;
  updated!: Date;
  created!: Date;
  deleted!: string;
  deleted_gmt!: Date;

  public static async list (params: IListUnitParams = {}, { raw } = { raw: false }): Promise<AObject[]> {
    const res = await super.AList<{ objects: IObject[] }>(params, { action: 'object' })
    if (!raw) {
      const aObjects = res.data.objects.map((obj) => new AObject({ data: obj }))
      return aObjects
    } else {
      // @ts-ignore
      return res.data.objects
    }
  }

  public getPoints () {
    if (!this.wkt) return []

    const payload = extract(this.wkt).pattern('POLYGON(({points}))')
    const pointsStr:string = payload[0].points

    if (!pointsStr) return []

    const pointsStrArr = pointsStr.split(',')
    const points = pointsStrArr.map(pointStr => {
      const point = pointStr.split(' ')
      const pointParsed = point.map(pnt => parseFloat(pnt))
      return pointParsed
    })
    return points
  }

  isUpdated (data: IObject): boolean {
    return data.updated !== this.updated
  }

  public isPointInside (coordinates: { lat: number; lng: number }) {
    const points = this.getPoints()
    const isInside = pip([coordinates.lat, coordinates.lng], points)
    return isInside
  }
}
