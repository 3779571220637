import Vue from 'vue'
import axios from 'axios'
import '@/sass/index.sass'
import '@/plugins/notification.ts'
import '@/plugins/FormRules'
import '@/plugins/axios'
import vuetify from './plugins/vuetify'
// @ts-ignore
import PortalVue from 'portal-vue'
import App from './App.vue'
import { serializeError } from 'serialize-error'
import './registerServiceWorker'
import store from './store'
import router from './router'
import LoadScript from 'vue-plugin-load-script'
// @ts-ignore
import 'leaflet/dist/leaflet.css'
import 'vue2-datepicker/index.css'
import 'photoviewer/dist/photoviewer.css';
import { Icon } from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'leaflet-rotatedmarker'
import './plugins/videojs.ts';
import 'leaflet-polylinedecorator/dist/leaflet.polylineDecorator.js'
import '@/plugins/prism.ts'

// @ts-ignore
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

// @ts-ignore
Vue.use(LoadScript)
Vue.config.productionTip = false

Vue.use(PortalVue)

const initApp = async () => {
  // @ts-ignore
  const { data: strings } = await axios.get('/images/.env.files/strings.json').catch(console.error)
  new Vue({
    router,
    store,
    vuetify,
    async beforeCreate () {
      store.commit('sys/SET_STRINGS', strings, { root: true })

      window.onerror = (msg, url, lineNo, columnNo, error) => {
        this.$store.commit('sys/ADD_LOG', { title: 'APP_ERROR', color: 'error', message: msg, payload: JSON.stringify({ url, lineNo, columnNo, error }) })
        return false
      }

      window.onbeforeunload = (e) => {
        // @ts-ignore
        if (store.state.sys.hideBeforeCloseDialog) return
        e = e || window.event

        // For IE and Firefox prior to version 4
        if (e) {
          e.returnValue = 'Sure?'
        }
        setTimeout(async () => {
          // @ts-ignore
          this.$store.dispatch('sys/connectSockets')

          setTimeout(async () => {
            await this.$store.dispatch('comunications/getUsersConected')
          }, 1000)
        }, 5000)
        // For Safari
        return 'Sure?'
      }
    },
    errorCaptured (err, vm, info) {
      this.$store.commit('sys/ADD_LOG', { title: 'ERROR_CAPTURED', color: 'error', message: info, payload: serializeError(err) })
      return true
    },
    // @ts-ignore
    warnHandler (msg: any, vm: any, trace: any) {
      this.$store.commit('sys/ADD_LOG', { title: 'WARN_CAPTURED', color: 'warning', message: msg, payload: JSON.stringify(trace) })
      return true
    },
    render: h => h(App)
  }).$mount('#app')
}

initApp()
