/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ActualizerItemData from '@/store/packages/Actualizer/ActualizerItemData'

export default abstract class ResourceItemData<RIType> {
  resource_item: RIType
  resource_id: number
  units: Array<ActualizerItemData<unknown>>
  units_not_reporting: Array<ActualizerItemData<unknown>>

  constructor (resource_item: any, resource_id: number) {
    this.units = []
    this.units_not_reporting = []
    this.resource_item = resource_item
    this.resource_id = resource_id
    this.loadUnitsNotReporting()
  }

  abstract getName (): string

  getResourceId (): number {
    return this.resource_id
  }

  addUnit (unit:ActualizerItemData<unknown>): void {
    this.units.push(unit)
    this.loadUnitsNotReporting()
  }

  getUnitsLength (): number {
    return this.units.length
  }

  loadUnitsNotReporting (): void {
    const unitsNotReporting = this.units.filter(unit => !unit.isReporting()).sort(function (a, b) {
      return a.getUnitTimeReportSeconds() - b.getUnitTimeReportSeconds()
    })
    this.units_not_reporting = unitsNotReporting
  }

  getUnitsNotReporting () {
    return this.units_not_reporting
  }

  get unitsNotReportingLength () {
    return this.units_not_reporting.length
  }

  getUnits (): Array<ActualizerItemData<unknown>> {
    return this.units.sort(function (a, b) {
      return a.getUnitTimeReportSeconds() - b.getUnitTimeReportSeconds()
    })
  }

  getVariables () {
    const units_names = this.units_not_reporting.map(unit => unit.getName()).join(', ')
    const units_names_with_time = this.units_not_reporting.map(unit => `${unit.getName()} - ${unit.unit_time_report}`).join(', ')
    const units_count = this.units_not_reporting.length

    return {
      units_names,
      units_names_with_time,
      units_count
    }
  }
}
