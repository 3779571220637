 /**
* Starts the request of the camera and microphone
*
* @param {Object} callbacks
*/
export default async function requestLocalVideo (params: MediaStreamConstraints = { audio: true, video: false }) {
   // Monkeypatch for crossbrowser geusermedia
   // @ts-ignore
   const getUserMedia = navigator.mediaDevices.getUserMedia || navigator?.getUserMedia || navigator?.webkitGetUserMedia || navigator?.mozGetUserMedia;

   if (!getUserMedia) throw Error('No user media allowed')
   // Request audio an video
   return await getUserMedia(params);
}
