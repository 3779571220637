import Api from "./Api";
import axios from "axios";
import { IParameters } from "../interfaces/login.interface";
import { Optional } from "../interfaces/utils.interface";

export type IGetAddressParams = Optional<
  Pick<IParameters, "lng" | "lat" | "language">,
  "language"
>;

export interface IGetAddressResponce {
  code: number;
  msg: string;
  ok: boolean;
}

export default class Utils extends Api {
  public static async getAddress(params: IGetAddressParams): Promise<IGetAddressResponce> {
    const { data } = await axios.get(
        `https://us.tracksolidpro.com/getAddress?lat=${params.lat}&lng=${params.lng}&language=${params.language || "en"}`
      );

      return data;
  }
}
