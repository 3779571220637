/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Geofence } from '@/store/interfaces/Geofences'
import { ReportLayout, ReportItemPayload, ReportDataConfig } from '@/store/interfaces/ReportModules'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData'
import { RouteDestination } from '../geofences'
import { State } from './state'

export default {
  CURR_REPORT_ITEM_FOCUSED (state: State, id_report_item: number): void {
    state.curr_id_report_item = id_report_item
  },
  SET_REPORTS_DOCUMENTS_DATA (state: State, rdg: typeof state.reportsDocuments): void {
    Object.assign(state.reportsDocuments, rdg)
  },
  SET_REPORTS_VIDEO_HISTORY (state: State, rvh: typeof state.reportsVideoHistory): void {
    Object.assign(state.reportsVideoHistory, rvh)
  },
  SET_DESTINATION_GROUP_DATA (state: State, rdg: typeof state.reportDestinationGroup): void {
    Object.assign(state.reportDestinationGroup, rdg)
  },
  CLEAN_LAST_REPORT_SELECTED (state: State): void {
    state.lastReportsSelected = []
  },
  CHANGE_CURRENT_COMPONENT (state: State, component: string): void {
    state.routesDialog.currentTabComponent = component
  },
  CLEAN_ROUTES_DESTINATIONS (state: State): void {
    state.routesDialog.route_data.rd_geofences = []
    state.routesDialog.routes_destinations = []
  },
  TOOGLE_ROUTE_DESTINATION (state: State, rdPayload: { route_destination_index: number, routeDestination: RouteDestination }): void {
    if (rdPayload.route_destination_index === -1) {
      state.routesDialog.routes_destinations.push(rdPayload.routeDestination)
    } else {
      state.routesDialog.routes_destinations.splice(rdPayload.route_destination_index, 1)
    }
  },
  ADD_REPORT_SELECTED: (state: State, report_selected: {report_group_index: number, report_item_index: number; id_report_item: number}): void => {
    state.reportsSelected.push(report_selected)
    if (state.lastReportsSelected.findIndex(rs => rs.id_report_item === report_selected.id_report_item) === -1) {
      state.lastReportsSelected.push(report_selected)
    }
  },
  REMOVE_REPORT_SELECTED: (state: State, reportIndex: number): void => {
    state.reportsSelected.splice(reportIndex, 1)
    if (state.lastReportsSelected[reportIndex]) {
      state.lastReportsSelected.splice(reportIndex, 1)
    }
  },
  REMOVE_ALL_REPORTS: (state: State): void => {
    state.reports = []
  },
  ASSING_REPORT_DATA: (state: State, reportPayload: Array<ReportLayout>): void => {
    state.reports = reportPayload
  },
  CHANGE_DESTINATIONS_ADD (state: State, destinationsPayload: RouteDestination & { id_group: number, is_creating: boolean }): void {
    Object.assign(state.routesDialog, destinationsPayload)
    Object.assign(state.routesDialog.route_data, destinationsPayload)
  },
  SET_DESTINATIONS_ADD_GEOFENCES (state: State, geofences: Array<Geofence>): void {
    state.routesDialog.route_data.rd_geofences = geofences
  },
  REMOVE_DESTINATIONS_A_GEOFENCES (state: State, geofence_index: number): void {
    state.routesDialog.route_data.rd_geofences.splice(geofence_index, 1)
  },
  CHANGE_TABLE_COLOR: (state: State, reportColorData: { report_index: number, rg_color: string }): void => {
    state.reports[reportColorData.report_index].unitData.rg_color = reportColorData.rg_color
  },
  CHANGE_REPORT_USER (state: State, reportUserChange: { us_name: string, report_group_index: number }): void {
    const reportEdit = state.reports[reportUserChange.report_group_index]
    reportEdit.unitData.user = reportUserChange.us_name
  },
  SET_REPORT_GEOFENCES_ID (state: State, geofencesPayload: { geofencesId: Array<{ id: number, n: string; pType: number }>, report_item_index: number, report_group_index: number }): void {
    state.reports[geofencesPayload.report_group_index].unitData.ri_items[geofencesPayload.report_item_index].last_data.DESTINATION.geofences = geofencesPayload.geofencesId
  },
  CHANGE_REPORT_ITEM_DATA: (state: State, reportItemData: ReportItemPayload & { reportGroupIndex: number, reportItemIndex: number }): void => {
    if ('STATUS' in reportItemData.last_data) {
      // @ts-ignore
      state.reports[reportItemData.reportGroupIndex].unitData.ri_items[reportItemData.reportItemIndex].last_data.STATUS = reportItemData.last_data.STATUS
    } else if ('DESTINATION' in reportItemData.last_data) {
      // @ts-ignore
      state.reports[reportItemData.reportGroupIndex].unitData.ri_items[reportItemData.reportItemIndex].last_data.DESTINATION = reportItemData.last_data.DESTINATION
    } else if ('MESSAGE' in reportItemData.last_data) {
      // @ts-ignore
      state.reports[reportItemData.reportGroupIndex].unitData.ri_items[reportItemData.reportItemIndex].last_data.MESSAGE = reportItemData.last_data.MESSAGE
    } else {
      console.error('Error en el Payload', reportItemData)
    }
  },
  CHANGE_ROUTES_DIALOG (state: State, routesPayload: { id_group: number, wialon_id: number, id_report_item:number }): void {
    Object.assign(state.routesDialog, routesPayload)
  },
  ASSING_REPORT_LAYOUT (state: State, layout: any): void {
    state.layout = layout
  },
  ASSING_LAST_REPORT_LAYOUT (state: State, layout: any): void {
    state.lastLayout = layout
  },
  SET_REPORT_PROPERTIES_MODAL_STATE (state: State, payload: { modal: boolean, reportItem: ReportItemData<unknown, unknown> }) {
    Object.assign(state.riModalProperties, payload)
  },
  REPLACE_REPORT_ITEM (state: State, { report_group_index, report_item, report_item_index }: { report_group_index: number; report_item_index: number; report_item: ReportItemData<unknown, unknown> }) {
    state.reports[report_group_index].unitData.ri_items[report_item_index] = report_item
  },
  SET_CELL_ITEMS_DATA (state: State, payload: typeof state.riCellItems) {
    Object.assign(state.riCellItems, payload)
  },
  UPDATE_REPORT_DATA_CONFIG_BY_ID (state: State, { reportDataConfig, id_report_group }: { id_report_group: number; reportDataConfig: ReportDataConfig }) {
    const index = state.reports.findIndex(report => report.unitData.id_report_group === id_report_group)
    Object.assign(state.reports[index].unitData.rg_config, reportDataConfig)
  }
}
