import { ActionContext } from 'vuex';
import { State } from '..';
import { IGroupStepper, IStepper } from '@/store/interfaces/Manager.interface'

export default {
  async getAllSteppers ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/steppers/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { steppers: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_STEPPERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getById ({ dispatch }: ActionContext<State, string>, id_stepper: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/steppers/get/${id_stepper}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_BY_ID_STEPPERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createStepper ({ dispatch, commit }: ActionContext<State, string>, payload: IStepper) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'steps/stepper/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('UNSHIFT_STEPPER', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_STEPPERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createStepperWithSteps ({ dispatch, commit }: ActionContext<State, string>, payload: IStepper) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/steppers/create_with_steps',
          method: 'POST',
          data: payload,
          headers: {
            'Content-Type': 'multipart/form-data;'
          }
        },
        { root: true }
      )

      commit('UNSHIFT_STEPPER', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_STEPPERS_WITH_STEPS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateStepper ({ dispatch, commit, state }: ActionContext<State, string>, payload: IStepper) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'steps/stepper/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      const index = state.admin.steppers.findIndex(obj => obj.id_stepper === payload.id_stepper)

      if (index !== -1) {
        commit('UPDATE_STEPPER', { index, data })
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_STEPPERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteStepper ({ dispatch, commit, state }: ActionContext<State, string>, id_stepper: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'steps/stepper/delete',
          method: 'DELETE',
          data: { id_stepper }
        },
        { root: true }
      )

      const index = state.admin.steppers.findIndex(obj => obj.id_stepper === id_stepper)

      if (index !== -1) {
        commit('DELETE_STEPPER', index)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_STEPPERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async changeStepperStepOrder (
    { dispatch, state }: ActionContext<State, string>, payload: { id_stepper: number; steps: [number, number] }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/step/toggle_priority',
          method: 'POST',
          data: payload
        },
        { root: true }
      );

     const stepper = state.admin.steppers.find(obj => obj.id_stepper === payload.id_stepper)

     if (!stepper) return

     const index1: number = stepper.steps.findIndex((obj: { id_step: number; }) => obj.id_step === payload.steps[0]);
     const index2: number = stepper.steps.findIndex((obj: { id_step: number; }) => obj.id_step === payload.steps[1]);

     // eslint-disable-next-line no-unexpected-multiline
     // @ts-ignore
     [stepper.steps[index1], stepper.steps[index2]] = [stepper.steps[index2], stepper.steps[index1]];
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'STEPPER_TOGGLE_PRIORITY', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async stepperStepAdd (
    { dispatch }: ActionContext<State, string>, payload: any) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/step/add',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'STEPPER_STEP_ADD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async stepperStepRemove (
    { state, dispatch }: ActionContext<State, string>, payload: { id_stepper: number; id_step: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/step/remove',
          method: 'POST',
          data: payload
        },
        { root: true }
      );

      const stepper = state.admin.steppers.find(obj => obj.id_stepper === payload.id_stepper)
      if (!stepper) return

      const step_index = stepper.steps.findIndex(obj => obj.id_step === payload.id_step)
      if (step_index === -1) return

      stepper.steps.splice(step_index, 1)
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'STEPPER_STEP_REMOVE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createStepAndBind (
    { state, dispatch }: ActionContext<State, string>, payload: FormData) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/steppers/step/create_and_bind',
          method: 'POST',
          data: payload
        },
        { root: true }
      );

      const id_stepper = parseInt(payload.get('id_stepper')?.toString() || '')
      const stepper = state.admin.steppers.find(obj => obj.id_stepper === id_stepper)
      if (!stepper) return

      stepper.steps.push(data)

      return data
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'STEPPER_STEP_CREATE_AND_BIND', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async unbindStepperGroupNotification (
    { state, dispatch }: ActionContext<State, string>, payload: { id_group_steppers: number; id_stepper: number }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/steppers/unbind_group_notification/${payload.id_group_steppers}`,
          method: 'DELETE'
        },
        { root: true }
      );

      const index = state.admin.steppers.findIndex(obj => obj.id_stepper === payload.id_stepper)
      if (index === -1) return

      const group_notification_index = state.admin.steppers[index].groups_notifications.findIndex(obj => obj.id_group_steppers === payload.id_group_steppers)
      if (group_notification_index === -1) return

      state.admin.steppers[index].groups_notifications.splice(group_notification_index, 1)

      return data
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'UNBIND_NOTIFICATION_GROUP', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async bindStepperGroupNotification (
    { state, dispatch }: ActionContext<State, string>, payload: { id_group: number; id_stepper: number; gs_plataform_notification_id: any; gs_plataform_notification_name: string }) {
    try {
      const { data }: { data: IGroupStepper } = await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/group/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );

      const stepper_index = state.admin.steppers.findIndex(obj => obj.id_stepper === payload.id_stepper)
      if (stepper_index === -1) return

      state.admin.steppers[stepper_index].groups_notifications.push(data)
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'BIND_NOTIFICATION_GROUP', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
