import Note from '@/store/interfaces/Note'
import NoteTracing from '@/store/interfaces/NoteTracing'
import { Timer } from 'easytimer.js'
import { DateTime } from 'luxon'
import store from '@/store'

export default class NoteItem implements Note {
  id_note!: number;
  nt_title!: string;
  nt_description!: string;
  nt_is_available!: boolean;
  nt_alarm_date!: string;
  nt_created_at!: string;
  user!: string;
  gp_name!: string;
  id_group!: number;
  gp_color!: string;
  nt_plataform_unit_id?: string
  nt_fk_plataform_type?: number
  notes_tracing!: NoteTracing[];
  timer: Timer | undefined
  tillHavingTime = true
  audio: HTMLAudioElement | undefined

  constructor (note: Note) {
    Object.assign(this, note)
    // this.nt_alarm_date = JSON.parse(this.nt_alarm_date)
    this.initCountDate()
  }

  initCountDate (): void {
    if (this.nt_alarm_date && this.nt_is_available) {
      const alarmDate = DateTime.fromJSDate(new Date(this.nt_alarm_date))
      const dateNow = DateTime.now()

      const { seconds } = alarmDate.diff(dateNow, ['seconds'])

      if (seconds > 0) {
        this.timer = new Timer()
        this.timer.start({ countdown: true, startValues: { seconds } })
        this.tillHavingTime = true
        this.timer.addEventListener('targetAchieved', this.onTargetAchieved)
      } else {
        this.tillHavingTime = false
      }
    }
  }

  stopCounterAlarm (): void {
    if (!this.audio && !this.timer) return

    this.audio?.pause()
    this.timer?.reset()
    this.timer?.stop()
    this.timer?.removeEventListener('targetAchieved', this.onTargetAchieved)
    this.tillHavingTime = false
  }

  resetCounterAlarm (): void {
    this.stopCounterAlarm()
    this.initCountDate()
  }

  private onTargetAchieved = async () => {
    this.tillHavingTime = false
    this.audio = await store.dispatch('sys/playSound', {
      url: '/audio/alarm.mp3'
    })
    await store.dispatch('sys/showNotificationMessage', {
      id: this.id_note,
      title: `Alarma de la nota ${this.nt_title}`,
      duration: -1,
      color: 'info',
      data: {
        action: 'notes/findAndStopAlarm',
        payload: this.id_note,
        btnText: 'Ver nota'
      }
    })
    await store.dispatch('sys/showNavigatorMessage', {
      title: `Alarma de la nota ${this.nt_title}`,
      icon: require('@/assets/alarm.png'),
      callback: () => {
        this.audio?.pause()
      }
    })
    setTimeout(() => {
      this.stopCounterAlarm()
    }, 20000)
  }
}
