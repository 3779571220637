import store from '@/store';
import { IInventoryGroup, IInventoryItem } from '@/store/interfaces/Binnacle';
import { formatFromISO8601 } from '@/utils/formatLuxonDate';

export default class InventoryItem implements IInventoryItem {
  id_inv_item!: number;
  bii_name!: string;
  bii_count!: number;
  id_bit_inventory_group!: number;
  bii_created_at!: string;
  id_company!: number;

  constructor (params: { data: IInventoryItem }) {
    this.initInsData(params)
  }

  public initInsData ({ data }: { data: IInventoryItem }) {
    Object.assign(this, data)
  }

  public get group (): IInventoryGroup | undefined {
    // @ts-ignore
    return store.state.binnacle.data.inv_groups[this.id_bit_inventory_group]
  }

  public get group_big_name (): string | undefined {
    // @ts-ignore
    return store.state.binnacle.data.inv_groups[this.id_bit_inventory_group]?.big_name
  }

  public get group_big_description (): string | undefined {
    // @ts-ignore
    return store.state.binnacle.data.inv_groups[this.id_bit_inventory_group]?.big_description
  }

  public get group_big_color (): string | undefined {
    // @ts-ignore
    return store.state.binnacle.data.inv_groups[this.id_bit_inventory_group]?.big_color
  }

  public get group_big_created_at (): string | undefined {
    // @ts-ignore
    return store.state.binnacle.data.inv_groups[this.id_bit_inventory_group]?.big_created_at
  }

  public get bii_created_at_formated (): string {
    return formatFromISO8601(this.bii_created_at)
  }
}
