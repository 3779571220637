/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { PLATAFORM_TYPE } from '../catalogs/PLATAFORM_TYPE'
import { Geofence } from './Geofences'

/* eslint-disable @typescript-eslint/ban-types */
export default interface ResourceNotification<RNType> {
  geofence?: Geofence
  pType: PLATAFORM_TYPE
  unitData?: RNType
  blink: number
  color: string
  f: number
  name: string
  nid: number
  p: {}
  rt: number
  t: number
  tp: string
  txt: string
  unit: number
  url: string
  x: number
  y: number
  resources?: RNResource[]
}

export enum RNRESOURCES_TYPES {
  IMAGE = 1,
  VIDEO = 2,
  HV_PICTURE_ID = 3,
  HV_VIDEO_ID = 4,
}

export interface RNResource {
  name: string;
  url: string;
  icon?: string;
  type: RNRESOURCES_TYPES;
}
