import { extend } from 'vee-validate'
import { required, email, min, max, image, confirmed, numeric, max_value, min_value, size, double } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: "El campo '{_field_}' es requerido"
})

extend('email', {
  ...email,
  message: "El campo '{_field_}' debe ser de un correo electronico valido"
})

extend('min', {
  ...min,
  message: "El campo '{_field_}' debe tener un minimo de {length} caracteres"
})

extend('max', {
  ...max,
  message: "El campo '{_field_}' debe tener un maximo de {length} caracteres"
})

extend('image', {
  ...image,
  message: 'Seleccione una archivo de imagen valido'
})

extend('confirmed', {
  ...confirmed,
  message: 'El campo debe ser igual al otro'
})

extend('numeric', {
  ...numeric,
  message: "El campo '{_field_}' debe ser de tipo numerico"
})

extend('max_value', {
  ...max_value,
  message: "El campo '{_field_}' solo permite un valor maximo a {max}"
})

extend('min_value', {
  ...min_value,
  message: "El campo '{_field_}' solo permite un valor minimo a {min}"
})

extend('url', {
  validate: value => {
    const expresion = /^(ftp|http|https):\/\/[^ "]+$/gm
    const regex = new RegExp(expresion)
    return regex.test(value)
  },
  message: "El campo '{_field_}' debe ser una URL valida"
})

extend('elements', {
  validate: value => {
    return value.length >= 2
  },
  message: "El campo '{_field_}' debe tener almenos 2 elementos"
})

extend('items', {
  validate: (value, { count }: any) => {
    return value.length >= Number(count)
  },
  params: [{ name: 'count', default: 1 }]
});

extend('size', {
  ...size,
  // @ts-ignore
  message (fieldName: string, params: any) {
    return `El campo '${fieldName}' no debe ser mayor a ${params.size} kb`
  }
})

extend('double', {
  ...double,
  // @ts-ignore
  message (fieldName: string, params: any) {
    return `El campo '${fieldName}' debe ser numerico y contener maximo ${params.decimals} decimales`
  }
})

extend('str_comma', {
  validate: value => {
    const expresion = /^(?:\d+(?:,\d+)*)?$/
    const regex = new RegExp(expresion)
    return regex.test(value)
  },
  message: "El campo '{_field_}' debe ser de tipo numerico separado por comas"
})
