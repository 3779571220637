import { IChatUser } from '@/store/interfaces/Chat';
import BaseClass from '../BaseClass';
import store from '@/store';
import { UserSchemaSimple } from '@/store/interfaces/User';
import capitalizeText from '@/utils/capitalize';

export default class ChatUser extends BaseClass<IChatUser> implements IChatUser {
  chu_is_hidden!: boolean;
  chu_createdAt!: string;
  chu_is_admin!: boolean;
  id_chat_item!: number;
  id_chat_user!: number;
  chu_silent_chat!: boolean;
  id_user!: number | null;
  chu_last_seen_message_id!: number | null
  /** SI el usuario esta eliminado o esta devinculado, user sera nulo */
  user!: UserSchemaSimple | null;
  chu_user_saved_data!: UserSchemaSimple;

  public onInitInstance (): void {
    // ---
  }

  public get curr_id_user () {
    return this.id_user || this.chu_user_saved_data.id_user
  }

  public get us_name () {
    return this.user?.us_name || this.chu_user_saved_data.us_name
  }

  public get us_description () {
    return this.user?.us_description || this.chu_user_saved_data.us_description
  }

  public get last_time_conected () {
    return this.user?.last_time_conected
  }

  public get ut_name () {
    return this.user?.ut_name || this.chu_user_saved_data.ut_name
  }

  public get userThumbnail () {
    const user_thumbnail = this.user?.thumbnail_file?.fl_url
    if (!user_thumbnail) return '#'

    return `${store.getters['sys/SS_API_URL']}${this.user?.thumbnail_file?.fl_url}`
  }

  public get isUserConected () {
    return (store.getters['chat/objSetUsersConectedIds'] as Set<number>).has(this.curr_id_user)
  }

  public get isAvaliable () {
    return Boolean(this.id_user)
  }

  public get naturalLastTimeConected () {
    if (!this.last_time_conected) return ''
    // @ts-ignore
    return this.getNaturalFormatedDate({ offset: store.state.sys.timeoffset, isoDate: this.last_time_conected })
  }

  public get userStringMention () {
    return `@${capitalizeText(this.us_name).replaceAll(' ', '_')}`
  }

  public static usNameToMention (name: string) {
    return `@${capitalizeText(name).replaceAll(' ', '_')}`
  }
}
