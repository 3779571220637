import Api from "./Api";
import ApiRequest from "../decorators/ApiRequest";
import { IParameters } from "../interfaces/login.interface";
import { Optional } from "../interfaces/utils.interface";

export type IDeviceLocationListParams = Optional<
  Pick<IParameters, "map_type" | "target">,
  "map_type" | "target" 
>;

export type IUserCommonParams = Optional<
  Pick<IParameters, "target">,
  "target" 
>;

export type IDeviceLocationListResponce = Pick<
  IParameters,
  | "imei"
  | "deviceName"
  | "icon"
  | "status"
  | "posType"
  | "lat"
  | "lng"
  | "hbTime"
  | "accStatus"
  | "powerValue"
  | "speed"
  | "gpsNum"
  | "direction"
  | "activationFlag"
  | "expireFlag"
  | "electQuantity"
  | "locDesc"
  | "distance"
  | "temperature"
  | "trackerOil"
>;



export default class User extends Api {
  @ApiRequest({ method: "jimi.user.child.list" })
  public static async childList(params?: IUserCommonParams) {
    return { target: Api.config.user_id, ...params };
  }

  @ApiRequest({ method: "jimi.user.device.list" })
  public static async deviceList(params?: IUserCommonParams) {
    return { target: Api.config.user_id, ...params };
  }

  @ApiRequest({ method: "jimi.user.device.location.list" })
  public static async deviceLocationList(params?: IDeviceLocationListParams): Promise<IDeviceLocationListResponce[]> {
    // @ts-ignore
    return { target: Api.config.user_id, ...params };
  }
}
