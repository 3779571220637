import { ActionContext } from 'vuex';
import { State } from '..';

export default {
  async getAllGroups ({ dispatch }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'group/get/all',
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_GROUPS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getAllReportGroups ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'report_group/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { groups: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_GROUPS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createGroup ({ dispatch, commit }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'group/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('UNSHIFT_GROUP', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_GROUP', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateGroup ({ dispatch, commit, state }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'group/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.admin.groups.findIndex(obj => obj.id_report_group === data.id_report_group)

      if (index !== -1) {
        commit('UPDATE_GROUP', { data, index })
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_GROUP', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteReportGroup ({ dispatch, commit, state }: ActionContext<State, string>, id_report_group: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `groups/delete/${id_report_group}`,
          method: 'DELETE'
        },
        { root: true }
      )

      const index = state.admin.groups.findIndex(obj => obj.id_report_group === id_report_group)

      if (index !== -1) {
        commit('DELETE_GROUP', index)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_UNITS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
