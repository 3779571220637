import Api from "./Api";
import ApiRequest from '../decorators/ApiRequest'
import { IParameters } from "../interfaces/login.interface";
import { Optional } from "../interfaces/utils.interface";

export type IDeviceDetailParams = Optional<
  Pick<
    IParameters,
    "access_token" | "imei" 
  >,
  "access_token"
>;

export default class Scooter extends Api {

  @ApiRequest({ method: 'jimi.scooter.device.detail' })
  public static async deviceDetail(params:IDeviceDetailParams) { 
      return params
   }

}
