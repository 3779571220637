import { ActionContext } from 'vuex';
import { State } from '../../index';
import { IScheduleTurn } from '@/store/interfaces/Schedule';
import { ScheduleTurn } from '@/store/packages/Schedules/ScheduleTurn';

export default {
  async getAllSchedulesTurns ({ dispatch, commit }: ActionContext<State, string>, payload: { sche_start: string; sche_end: string }): Promise<IScheduleTurn[]> {
    try {
      const { data }: { data: IScheduleTurn[] } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/turn/get/all',
          method: 'GET',
          data: payload
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { schedulesTurns: data.map(scheData => new ScheduleTurn({ data: scheData })) })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_SCHEDULES_TURNS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createScheduleTurn ({ dispatch, commit }: ActionContext<State, string>, payload: Partial<IScheduleTurn>): Promise<IScheduleTurn> {
    try {
      const { data }: { data: IScheduleTurn } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/turn/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('ADD_SCHEDULE_TURN', new ScheduleTurn({ data }))

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_SCHEDULE_TURN', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateScheduleTurn ({ dispatch, state }: ActionContext<State, string>, payload: Partial<IScheduleTurn>): Promise<IScheduleTurn> {
    try {
      const { data }: { data: IScheduleTurn } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/turn/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const scheTurn = state.schedulestate.schedulesTurns.find(obj => obj.id_schedule_turn === data.id_schedule_turn)

      if (scheTurn) {
        scheTurn.rehidratateData(data)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_SCHEDULE_TURN', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteScheduleTurn ({ dispatch, state, commit }: ActionContext<State, string>, id_schedule_turn: number): Promise<{ id_schedule_turn: number }> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `schedule/turn/delete/${id_schedule_turn}`,
          method: 'DELETE'
        },
        { root: true }
      )

      const index = state.schedulestate.schedulesTurns.findIndex(obj => obj.id_schedule_turn === id_schedule_turn)

      commit('DELETE_SCHEDULE_TURN', index)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_SCHEDULE_TURN', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
