import { ActionContext } from 'vuex';
import { State } from '../state';
import { IInventoryItem } from '@/store/interfaces/Binnacle';
import InventoryItem from '@/store/packages/Binnacle/InventoryItem';
import Vue from 'vue';

export default {
  async createBitInventoryItem (
    { dispatch }: ActionContext<State, string>,
    payload: IInventoryItem
  ) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'inventory/item/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
      return data
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'CREATE_BIT_INVENTORY_ITEM', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getAllBitInventoryItem (
    { dispatch, commit }: ActionContext<State, string>
  ) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'inventory/item/get/all',
          method: 'GET'
        },
        { root: true }
      );
      commit('SET_STATE_DATA', { inv_items: data })
      return data
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'GET_ALL_INVENTORY_ITEM', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateBitInventoryItem (
    { dispatch }: ActionContext<State, string>,
    payload: IInventoryItem
  ) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'inventory/item/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      );
      return data
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'UPADTE_INVENTORY_ITEM', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteBitInventoryItem (
    { dispatch }: ActionContext<State, string>,
    payload: IInventoryItem
  ) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `inventory/item/delete/${payload.id_inv_item}`,
          method: 'DELETE',
          data: payload
        },
        { root: true }
      );
      return data
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'DELETE_INVENTORY_ITEM', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  socket_InventoryItemCreated ({ state }: ActionContext<State, string>, data: IInventoryItem) {
    Vue.set(state.data.inv_items, data.id_inv_item, new InventoryItem({ data }));
  },
  socket_InventoryItemDeleted ({ state }: ActionContext<State, string>, data: IInventoryItem) {
    if (!state.data.inv_items[data.id_inv_item]) return
    Vue.delete(state.data.inv_items, data.id_inv_item)
  },
  socket_InventoryItemUpdated ({ state }: ActionContext<State, string>, data: IInventoryItem) {
    if (!state.data.inv_items[data.id_inv_item]) return
    state.data.inv_items[data.id_inv_item].initInsData({ data })
  }
}
