import { DateTime } from 'luxon';
import { DefaultParams } from '../defaultParams';
import { ICommonParameters, ITrackSolidParams } from '../interfaces/login.interface';
import { Optional } from '../interfaces/utils.interface';
import md5 from 'md5';
import axios, { AxiosRequestConfig } from 'axios';

export default class Api {
  declare config: ITrackSolidParams & DefaultParams;
  declare static config: ITrackSolidParams & DefaultParams;

  public getCommonParameters (
    params: Optional<
      ICommonParameters,
      'timestamp' | 'app_key' | 'sign' | 'sign_method' | 'v' | 'format'
    >
  ) {
    const parameters = {
      timestamp: DateTime.now().toUTC().toFormat('yyyy-LL-dd HH:mm:ss'),
      app_key: this.config.app_key,
      sign_method: 'md5',
      v: '1.0',
      format: 'json',
      ...params
    };

    const sortedObject = Object.fromEntries(Object.entries(parameters).sort());
    const sign = Object.entries(sortedObject).reduce((prev, current) => {
      return `${prev}${current[0]}${current[1]}`;
    }, '');
    // @ts-ignore
    parameters.sign = md5(
      `${this.config.app_secret}${sign}${this.config.app_secret}`
    ).toUpperCase();

    return parameters;
  }

  public static getCommonParameters (
    params: Optional<
      ICommonParameters,
      'timestamp' | 'app_key' | 'sign' | 'sign_method' | 'v' | 'format'
    >
  ) {
    const parameters = {
      timestamp: DateTime.now().toUTC().toFormat('yyyy-LL-dd HH:mm:ss'),
      app_key: this.config.app_key,
      sign_method: 'md5',
      v: '1.0',
      format: 'json',
      ...params
    };

    const sortedObject = Object.fromEntries(Object.entries(parameters).sort());
    const sign = Object.entries(sortedObject).reduce((prev, current) => {
      return `${prev}${current[0]}${current[1]}`;
    }, '');
    // @ts-ignore
    parameters.sign = md5(
      `${Api.config.app_secret}${sign}${Api.config.app_secret}`
    ).toUpperCase();

    return parameters;
  }

  public static axios (config: AxiosRequestConfig<any> = {}) {
    return axios.create({
      baseURL: Api.config.baseURL,
      method: 'POST',
      ...config
    })
  }
}
