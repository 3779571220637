import { ActionContext } from 'vuex';
import { State } from '..';
import { Socket } from 'socket.io-client'

export default {
  initSocketBreakTime (context: ActionContext<State, string>) {
   // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    socket.on('break_time_updated', data => context.dispatch('socket_breakTimeUpdated', data))
    socket.on('user_week_updated', data => context.commit('UPDATE_HORARIO_USER', data))
  },
  async getAllUsers ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { users: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_USERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getAllUsersSimple ({ dispatch }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/get/all/simple',
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_USERS_SIMPLE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async paginateUser ({ dispatch, commit }: ActionContext<State, string>, payload: { page: number; items: number }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/paginate',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { users: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'PAGINATE_USERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getUserById ({ dispatch }: ActionContext<State, string>, id_user: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/users/get/${id_user}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_USER_BY_ID', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createUser ({ dispatch, commit }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('UNSHIFT_USER', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_USERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateManyUsers ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/update_many',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_MANY_USERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateUser ({ dispatch, commit, state }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.admin.users.findIndex(obj => obj.id_user === data.id_user)

      if (index !== -1) {
        commit('UPDATE_USER', { data, index })
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_USERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateUserSimple ({ dispatch, commit, state }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/update/simple',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.admin.users.findIndex(obj => obj.id_user === data.id_user)

      if (index !== -1) {
        commit('UPDATE_USER', { data, index })
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_USER_SIMPLE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteUser ({ dispatch, commit, state }: ActionContext<State, string>, id_user: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/users/delete/${id_user}`,
          method: 'DELETE'
        },
        { root: true }
      )

      const index = state.admin.users.findIndex(obj => obj.id_user === id_user)

      if (index !== -1) {
        commit('DELETE_USER', index)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_USERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteUserGroups ({ dispatch, state }: ActionContext<State, string>, payload: { id_user: number; ids_user_groups: number[] }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/users/groups/delete/${payload.id_user}`,
          method: 'DELETE',
          data: { ids_user_groups: payload.ids_user_groups }
        },
        { root: true }
      )

      const user = state.admin.users.find(obj => obj.id_user === payload.id_user)

      if (user) {
        for (let i = 0; i < user.us_groups.length; i++) {
          const ug = user.us_groups[i];
          if (payload.ids_user_groups.includes(ug.id_user_groups)) {
            user.us_groups.splice(i, 1)
          }
        }
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_USERS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async togggleEnableUser ({ dispatch, state }: ActionContext<State, string>, id_user: number) {
    try {
      const { data: user } = await dispatch(
        'sys/axios',
        {
          url: `admin/users/toggle_enable/${id_user}`,
          method: 'PUT'
        },
        { root: true }
      )

      const index = state.admin.users.findIndex(obj => obj.id_user === id_user)
      if (index === -1) return

      state.admin.users[index].active = user.active
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'TOGGLE_ENABLE_USER', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async matchdayUsersData ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/matchday',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'MATCHDAT_USER_DATA', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getUserSchedule ({ dispatch }: ActionContext<State, string>, idUser: number) {
    console.log(idUser)
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `bitacora_horarios/get_user/${idUser}`,
          method: 'GET'
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_USER_SCHEDULE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async setUserSchedule ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
        await dispatch(
          'sys/axios',
          {
            url: 'bitacora_horarios/set_user',
            method: 'POST',
            data: payload
          },
          { root: true }
        )
        dispatch('sys/showMessageSuccess', 'Horario guardado', { root: true })
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'SET_USER_SCHEDULE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async setBreakTime ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'bitacora_horarios/set_break',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      dispatch('sys/showMessageSuccess', 'Break guardado', { root: true })
    } catch (error) {
      dispatch('sys/showMessageAlert', 'No se pudo guardar el Break', { root: true })
      console.error(error)
      throw error
    }
  },
  async findAndStopAlarmBreak (context: ActionContext<State, string>, { id_user, isFirst }: { id_user: number, isFirst: boolean }) {
    // @ts-ignore
    const break_index = context.state.admin.horarios.findIndex(h => h.id_user === id_user)
    if (break_index !== -1) {
      // @ts-ignore
      const breakTime = context.state.admin.horarios[break_index]
      breakTime.stopCounterAlarm(isFirst)
    }
  },
  async matchdayUsersReportPDF ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/matchday/report/pdf',
          method: 'POST',
          data: payload,
          responseType: 'blob'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'MATCHDAT_USER_REPORT_PDF', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async historyCommandsUsersData ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/history/commands',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'HISTORY_USER_COMMADS_DATA', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  socket_breakTimeUpdated ({ state, commit }: ActionContext<State, string>, payload: any) {
    console.log(payload)
    // @ts-ignore
      const horarioIndex = state.admin.horarios.findIndex(h => h.id_user === payload.id_user)
      if (horarioIndex !== -1) {
        commit('UPDATE_USER_BREAK_TIME', { payload, horarioIndex })
      }
  }
}
