import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import AUnit from '@/submodules/maponjssdk/dist/classes/actions/AUnit.action'
import getSecondsDiffFromNow from '@/utils/getSecondsDiffFromNow'
import { DateTime } from 'luxon'
import ActualizerItemData from './ActualizerItemData'

export default class AIMapon extends ActualizerItemData<AUnit> {
  pType: PLATAFORM_TYPE

  constructor (unit_item: any) {
    super(unit_item)
    this.pType = PLATAFORM_TYPE.MAPON
  }

  getSecondsDiff (): number {
    const millis = (DateTime.fromISO(this.unit_item.last_update).toMillis() / 1000)
    return getSecondsDiffFromNow(millis)
  }

  getPlataformId (): number {
    return this.unit_item?.unit_id
  }

  getName (): string {
    return this.unit_item?.number
  }

  getResourceId (): number {
    return 0
  }

  getIcon (): string {
    return require('@/assets/svg/s_a_35.svg')
  }
}
