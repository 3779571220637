/**
 * Downloads a file from the specified URL.
 *
 * @param url - The URL of the file to download.
 * @param filename - The name to save the downloaded file as.
 * @returns void
 */
export default function download (url: string, filename: string) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}

export async function downloadAsync (url: string, filename: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

/**
 * Downloads data as a file.
 *
 * @param filename - The name of the file to be downloaded.
 * @param text - The text content to be downloaded.
 * @param type - The MIME type of the file. Defaults to 'text/plain'.
 *
 * @returns void
 */
export function downloadData (filename: string, text: string, type = 'text/plain') {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${type};charset=utf-8,` + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

/**
 * Downloads a file using a Blob object.
 *
 * @param {Blob} blob - The Blob object representing the file to be downloaded.
 * @param {string} filename - The name of the file to be downloaded.
 * @returns {void}
 */
export function downloadByBlob (blob: Blob, filename: string) {
  console.log(typeof blob)
  const imgURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = imgURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
