import { ActualizerNote, ActualizerNoteInput } from './../interfaces/ActualizerNotes';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex';
import ActualizerPlataform from '../packages/Actualizer/ActualizerPlataform';
import { PLATAFORM_TYPE } from '../catalogs/PLATAFORM_TYPE';
import { Socket } from 'socket.io-client';
import { serializeError } from 'serialize-error';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ActualizerState {
  componentKey: number;
  actualizersPlataforms: ActualizerPlataform[];
  actualizerNotes: ActualizerNote[]
}

const state: ActualizerState = {
  componentKey: 1,
  actualizersPlataforms: [],
  actualizerNotes: []
};

const mutations = {
  PUSH_RESOURCES_ACTUALIZER (
    state: ActualizerState,
    actualizerPlataform: ActualizerPlataform
  ): void {
    state.actualizersPlataforms.push(actualizerPlataform);
  },
  CLEAN_RESOURCES_ACTUALIZER (state: ActualizerState): void {
    state.actualizersPlataforms = [];
  },
  SET_ACTUALIZER_NOTES (state: ActualizerState, actualizerNotes: ActualizerNote[]): void {
    state.actualizerNotes = actualizerNotes;
  },
  PUSH_ACTUALIZER_NOTE (state: ActualizerState, actualizerNotes: ActualizerNote): void {
    state.actualizerNotes.push(actualizerNotes)
  }
};

const actions = {
  init (context: ActionContext<ActualizerState, string>) {
    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    socket.on('actualizer_note_created', data => context.dispatch('socket_actualizerNoteCreate', data))
  },
  changeComponentKey (context: ActionContext<ActualizerState, string>): void {
    context.state.componentKey++;
  },
  changeActualizerTab (context: ActionContext<ActualizerState, string>): void {
    context.dispatch('sys/changeMonitTab', 'tab-Actualizer', { root: true });
  },
  async reloadResourcesActualizer ({
    dispatch,
    commit
  }: ActionContext<ActualizerState, string>) {
    commit('CLEAN_RESOURCES_ACTUALIZER');
    const promises = []
    const wialon = await dispatch('auth/havePlataform', PLATAFORM_TYPE.WIALON, { root: true })
    const mapon = await dispatch('auth/havePlataform', PLATAFORM_TYPE.MAPON, { root: true })
    const tracksolid = await dispatch('auth/havePlataform', PLATAFORM_TYPE.TRACKSOLID, { root: true })

    if (wialon) promises.push(dispatch('wialon/getResourcesActualizer', {}, { root: true }))
    if (mapon) promises.push(dispatch('mapon/getResourcesActualizer', {}, { root: true }))
    if (tracksolid) promises.push(dispatch('tracksolid/getResourcesActualizer', {}, { root: true }))

    await Promise.all(promises)
  },
  async createActualizerNote (context: ActionContext<ActualizerState, string>, actualizerNote: ActualizerNoteInput) {
    try {
      // @ts-ignore
      await context.dispatch(
        'sys/axios',
        {
          url: 'actualizer/item/create',
          method: 'POST',
          data: actualizerNote
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      context.dispatch('sys/showNotificationMessage', {
        title: 'No se pudo crear la nota',
        type: 'error'
      }, {
        root: true
      })
      context.commit('sys/ADD_LOG', { title: 'ACTUALIZER_NOTE_CREATE', color: 'error', message: '', payload: serializeError(error) }, { root: true })

      throw error
    }
  },
  socket_actualizerNoteCreate (context: ActionContext<ActualizerState, string>, actualizerNote: ActualizerNote) {
    context.commit('PUSH_ACTUALIZER_NOTE', actualizerNote)
  }
};

const getters = {
  units (state: ActualizerState) {
    const unitItems = state.actualizersPlataforms.map(ap => ap.resourcesItem.map(resI => resI.units_not_reporting)).flat(2)
    return unitItems
  },
  unitsLength (state: ActualizerState) {
    const unitItems = state.actualizersPlataforms.map(ap => ap.resourcesItem.map(resI => resI.units_not_reporting)).flat(2)
    return unitItems.length
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
