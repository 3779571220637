import { DateTime } from 'luxon';
import TrackSolidSDK from '..';
import Device, {
  ILocationGetParams,
  ILocationGetResponce
} from '../api/Device.api';
import Utils from '../api/Utils.api';
import AssingData from '../decorators/AssingData';
import { ItemConstructor } from '../interfaces/Item.interface';
import Item from './Item';
@AssingData()
export default class DeviceItem
  extends Item<ILocationGetResponce>
  implements ILocationGetResponce {
  imei!: string | string[];
  deviceName!: string;
  icon!: string;
  status!: '0' | '1';
  posType!: 'GPS' | 'LBS' | 'WIFI' | 'BEACON';
  lat!: number;
  lng!: number;
  hbTime!: string;
  accStatus!: string;
  powerValue!: string | null;
  speed!: string;
  gpsNum!: string;
  gpsTime!: string;
  direction!: string;
  activationFlag!: string;
  expireFlag!: string;
  electQuantity!: string;
  locDesc!: string | null;
  distance!: string;
  temperature!: string | null;
  trackerOil!: string | null;

  constructor (config: ItemConstructor) {
    super(config)
    TrackSolidSDK.event.addListener(this.CHANGE_EVENT, (data: ILocationGetResponce) => {
      const updated = this.setData({ data })
      if (updated) {
        TrackSolidSDK.event.emit(`MESSAGE_CHANGED_${this.getId()}`, data)
      }
    })
  }

  public static async observeMessages () {
    if (DeviceItem.ids.length) {
        const devices_data = await Device.locationGet({ imeis: DeviceItem.ids })
        for (let i = 0; i < devices_data.length; i++) {
            const res = devices_data[i];
            // @ts-ignore
            TrackSolidSDK.event.emit(`ITEM_CHANGE_${res.imei}`, res)
        }
    }
  }

  public addEventListener (type: 'MESSAGE_CHANGED' | 'POSITION_CHANGED', callback: (data: ILocationGetResponce) => void) {
    DeviceItem.susbscribeUnitToData(this.getId())
    switch (type) {
      case 'MESSAGE_CHANGED': {
        TrackSolidSDK.event.addListener(`MESSAGE_CHANGED_${this.getId()}`, callback)
        break
      }
      case 'POSITION_CHANGED': {
        TrackSolidSDK.event.addListener(`POSITION_CHANGED_${this.getId()}`, callback)
        break
      }
    }
  }

  public removeEventListener (type: 'MESSAGE_CHANGED' | 'POSITION_CHANGED', callback: (data: ILocationGetResponce) => void) {
    if (DeviceItem.ids.length <= 0) {
      DeviceItem.unsusbscribeUnitToData(this.getId())
    }
    switch (type) {
      case 'MESSAGE_CHANGED': {
        TrackSolidSDK.event.removeListener(`MESSAGE_CHANGED_${this.getId()}`, callback)
        break
      }
      case 'POSITION_CHANGED': {
        TrackSolidSDK.event.removeListener(`POSITION_CHANGED_${this.getId()}`, callback)
        break
      }
    }
  }

  protected onDataUpdated (prevData: ILocationGetResponce | null, lastData: ILocationGetResponce): void {
    if (prevData) {
      if (prevData?.lat !== lastData.lat || prevData?.lng !== lastData.lng) {
        TrackSolidSDK.event.emit(`POSITION_CHANGED_${this.getId()}`, lastData)
      }
    }
  }

  isUpdated (data: ILocationGetResponce): boolean {
    return this.gpsTime !== data.gpsTime;
  }

  public getName () {
    return this.deviceName;
  }

  public getId (): string {
      // @ts-ignore
    return this.imei;
  }

  public getSpeed () {
    return this.speed;
  }

  public getLastUpdate () {
    return this.gpsTime;
  }

  public static async getItemsByIds (params: ILocationGetParams) {
    const devices = await Device.locationGet(params);
    const deviceItems = devices.map((dv) => new DeviceItem({ data: dv }));

    return deviceItems;
  }

  public getISOGpsTime () {
    return DateTime.fromFormat(this.gpsTime, 'yyyy-MM-dd hh:mm:ss').toUTC().toISO()
  }

  public googleLink () {
    return `http://maps.google.com/?q=${this.lat},${this.lng}`;
  }

  public getIcon () {
    return this.icon;
  }

  public getCoordinates () {
    return `${this.lat},${this.lng}`;
  }

  public getCoordinatesRaw () {
    return { x: this.lat, y: this.lng };
  }

  public async getUbication () {
    return await Utils.getAddress({ lat: this.lat, lng: this.lng })
  }

  public getBateryPercentage () {
    if (!this.electQuantity) return null
    return parseInt(this.electQuantity)
  }
}
