import { ActionContext } from 'vuex';
import { State } from './state';
import { Socket } from 'socket.io-client';
import ConsoleGroup from '@/store/interfaces/ConsoleGroup';
import { serializeError } from 'serialize-error';
import { IStep } from '@/interfaces/Steps.interface';

export default {
  async init ({ dispatch, rootState }: ActionContext<State, string>) {
    // @ts-ignore
    const socket: Socket = rootState.sys.socket;
    // @ts-ignore
    socket.on('stepper_group_create', (data) => {
      dispatch('socket_stepperGroupCreated', data);
    });

    // @ts-ignore
    socket.on('stepper_updated', (data) => {
      dispatch('socket_stepperUpdated', data);
    });

    // @ts-ignore
    socket.on('stepper_created', (data) => {
      dispatch('socket_stepperCreated', data);
    });

    // @ts-ignore
    socket.on('stepper_deleted', (data) => {
      dispatch('socket_stepCreated', data);
    });

    // @ts-ignore
    socket.on('step_created', (data) => {
      dispatch('socket_stepCreated', data);
    });

    // @ts-ignore
    socket.on('step_updated', (data) => {
      dispatch('socket_stepUpdated', data);
    });

    // @ts-ignore
    socket.on('step_deleted', (data) => {
      dispatch('socket_stepDeleted', data);
    });
  },
  socket_stepDeleted ({ commit, state }: ActionContext<State, string>, data: IStep) {
    const index = state.steps.findIndex(obj => obj.id_step === data.id_step)
    if (index === -1) return
    commit('DELETE_STEP', { index })
  },
  socket_stepperCreated ({ commit }: ActionContext<State, string>, data: { id_stepper: number; sp_name: string; }) {
    commit('PUSH_STEPPER', data)
  },
  socket_stepperDeleted ({ state, commit }: ActionContext<State, string>, data: { id_stepper: number; sp_name: string; }) {
    const index = state.steppers.findIndex(obj => obj.id_stepper === data.id_stepper)
    if (index === -1) return
    commit('DELETE_STEPPER', { index })
  },
  socket_stepCreated ({ commit }: ActionContext<State, string>, data: IStep) {
    commit('PUSH_STEP', data)
  },
  socket_stepUpdated ({ commit, state }: ActionContext<State, string>, step: IStep) {
    const index = state.steps.findIndex(obj => obj.id_step === step.id_step)
    if (index === -1) return
    commit('UPDATE_STEP', { index, step })
  },
  socket_stepperGroupCreated (
    { rootState, state }: ActionContext<State, string>,
    data: any
  ) {
    // @ts-ignore
    const console_groups: Array<ConsoleGroup> = rootState.console.consoleGroups;
    const ids_groups = console_groups.map((cg) => cg.id_group);
    const steppers_ids: number[] = state.steppers.map((stp) => stp.id_stepper);
    if (
      ids_groups.includes(data.id_group) &&
      !steppers_ids.includes(data.id_stepper)
    ) {
      state.steppers.push(data);
    }
  },
  socket_stepperUpdated (
    { commit, state }: ActionContext<State, string>,
    data: any
  ) {
    const index = state.steppers.findIndex(
      (stp) => data.id_stepper === stp.id_stepper
    );
    if (index === -1) return
    commit('UPDATE_STEPPER', { index, stepper: data });
    state.steppers.push(data);
  },
  async getSteppersByUser (
    { dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'get/stepper/user',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STEPPERS', data)
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEPPERS_GET_BY_USER',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getStepsByUser (
    { dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'steps/step/get/all',
          method: 'GET'
        },
        { root: true }
      );

      commit('SET_STEPS', data)
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEPS_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async toggleStepOptions (
    { dispatch, commit }: ActionContext<State, string>, payload: { id_step: number; id_step_option: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'steps/options/toggle',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEP_TOGGLE_OPTIONS',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async stepUpdate (
    { dispatch, commit }: ActionContext<State, string>, payload: { id_step: number; st_description: number; st_name: string }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'steps/step/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEP_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateFileTag (
    { dispatch, commit }: ActionContext<State, string>, payload: { fl_tagname: string; id_file: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'file/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'FILE_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async stepDelete (
    { dispatch, commit }: ActionContext<State, string>, payload: { id_step: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'steps/step/delete',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEP_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async stepAddFile (
    { dispatch, commit }: ActionContext<State, string>, payload: { fl_tagname: number; file: File; id_step: string }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'steps/file/add',
          method: 'POST',
          data: payload
        },
        { root: true }
      );

      return data
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEP_ADD_FILE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async stepDeleteFile (
    { dispatch, commit }: ActionContext<State, string>, payload: { id_file: number; id_step: string }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'steps/file/delete',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEP_DELETE_FILE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createStepper (
    { dispatch, commit }: ActionContext<State, string>, payload: { sp_name: string; id_steps: number[] }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEPPER_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async deleteStepper (
    { dispatch, commit }: ActionContext<State, string>, payload: { id_stepper: number }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/delete',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEPPER_DELETED',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async changeStepperStepOrder (
    { dispatch, commit, state }: ActionContext<State, string>, payload: { id_stepper: number; steps: [number, number] }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/step/toggle_priority',
          method: 'POST',
          data: payload
        },
        { root: true }
      );

     const stepper = state.steppers.find(obj => obj.id_stepper === payload.id_stepper)

     const index1: number = stepper.steps.findIndex((obj: { id_step: number; }) => obj.id_step === payload.steps[0]);
     const index2: number = stepper.steps.findIndex((obj: { id_step: number; }) => obj.id_step === payload.steps[1]);

     // eslint-disable-next-line no-unexpected-multiline
     // @ts-ignore
     [stepper.steps[index1], stepper.steps[index2]] = [stepper.steps[index2], stepper.steps[index1]];
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEPPER_TOGGLE_PRIORITY',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async updateStepper (
    { dispatch, commit }: ActionContext<State, string>, payload: any) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEPPER_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async stepperStepAdd (
    { dispatch, commit }: ActionContext<State, string>, payload: any) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/step/add',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEPPER_STEP_ADD',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async stepperStepRemove (
    { dispatch, commit }: ActionContext<State, string>, payload: any) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/stepper/step/remove',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEPPER_STEP_REMOVE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async createStep (
    { dispatch, commit }: ActionContext<State, string>, payload: any) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: '/steps/step/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'STEP_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  }
};
