/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import isFunction from '@/utils/isFunction'
import isPromise from '@/utils/isPromise'
import { ActionContext } from 'vuex'
import { WMESSAGE_TYPE } from '../../catalogs/WMESSAGE_TYPE'
import format from 'string-template'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData'
import { Socket } from 'socket.io-client'

export interface WhatsAppMessage {
  id_wmessage: number;
  wm_title: string;
  wm_message: string;
  id_wmessage_type: number;
}

const state = {
  messages: [] as WhatsAppMessage[],
  messageTemplates: {
    [WMESSAGE_TYPE.UNITS_MESSAGES]: [
      {
        wv_name: 'unit_name',
        wv_description: 'Nombre de la unidad'
      },
      {
        wv_name: 'current_destinations',
        wv_description: 'Destinos de la unidad'
      },
      {
        wv_name: 'google_link',
        wv_description: 'Link de google'
      },
      {
        wv_name: 'current_drivers',
        wv_description: 'Conductores actuales'
      },
      {
        wv_name: 'ubication',
        wv_description: 'Ubicacion o direccion'
      },
      {
        wv_name: 'unit_state',
        wv_description: 'Estado (Detenida o en movimiento)'
      },
      {
        wv_name: 'unit_time_report',
        wv_description: 'Tiempo actual de la unidad desde su ultimo mensaje'
      },
      {
        wv_name: 'back_unit_time_report',
        wv_description: 'Tiempo actual del respaldo de la unidad desde su ultimo mensaje'
      },
      {
        wv_name: 'gps_battery',
        wv_description: 'Bateria actual del gps (si no disponible -1)'
      },
      {
        wv_name: 'unit_battery',
        wv_description: 'Bateria actual de la unidad (si no disponible -1)'
      },
      {
        wv_name: 'speed',
        wv_description: 'Velocidad actual de la unidad'
      },
      {
        wv_name: 'max_speed_limit',
        wv_description: 'Limite de velocidad de la unidad (default 100 km/h)'
      },
      {
        wv_name: 'notification_alert',
        wv_description: 'Limite de tiempo de una unidad sin reportar con ignicion encendida'
      },
      {
        wv_name: 'notification_low_gps_battery',
        wv_description: 'Limite de bateria baja del gps permitido de la unidad'
      },
      {
        wv_name: 'curr_matches_geofences',
        wv_description: 'Geocercas en donde se encuentra la unidad (de sus destinos)'
      },
      {
        wv_name: 'curr_matches_route_destinations',
        wv_description: 'Rutas de geocercas en donde se encuentra la unidad (de sus destinos)'
      }
    ]
  }
}

export type State = typeof state

const mutations = {
  SET_MESSAGES (state: State, messages: any[]): void {
    state.messages = messages
  },
  ADD_MESSAGE (state: State, mess: WhatsAppMessage): void {
    state.messages.push(mess)
  },
  DELETE_MESSAGE (state: State, id_wmessage: number): void {
    const index = state.messages.findIndex(
      m => m.id_wmessage === id_wmessage
    )
    state.messages.splice(index, 1)
  },
  UPDATE_MESSAGE (state: State, mess: WhatsAppMessage): void {
    const index = state.messages.findIndex(
      m => m.id_wmessage === mess.id_wmessage
    )
    Object.assign(state.messages[index], mess)
  }
}

const actions = {
  init (context: ActionContext<State, string>) {
    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    socket.on('wmessage_added', data => context.dispatch('socket_messageCreated', data))
    socket.on('wmessage_updated', data => context.dispatch('socket_messageUpdated', data))
    socket.on('wmessage_deleted', data => context.dispatch('socket_messageDeleted', data))
  },
  async getAll ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Obteniendo mensajes',
          type: 'warning'
        },
        {
          root: true
        }
      )

      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'wmessage/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_MESSAGES', data)

      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Mensajes obtenidos',
          type: 'info'
        },
        {
          root: true
        }
      )
    } catch (error) {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'A ocurrido un error',
          type: 'error'
        },
        {
          root: true
        }
      )
      console.error(error)
    }
  },
  async create (
    { dispatch }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Creando mensaje',
          type: 'warning'
        },
        {
          root: true
        }
      )

      await dispatch(
        'sys/axios',
        {
          url: 'wmessage/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      // commit('ADD_MESSAGE', data)

      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Mensaje creado',
          type: 'info'
        },
        {
          root: true
        }
      )
    } catch (error) {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'A ocurrido un error',
          type: 'error'
        },
        {
          root: true
        }
      )
      console.error(error)
    }
  },
  async update (
    { dispatch }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Actualizando mensaje',
          type: 'warning'
        },
        {
          root: true
        }
      )

      await dispatch(
        'sys/axios',
        {
          url: 'wmessage/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      // commit('UPDATE_MESSAGE', data)

      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Mensaje creado',
          type: 'info'
        },
        {
          root: true
        }
      )
    } catch (error) {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'A ocurrido un error',
          type: 'error'
        },
        {
          root: true
        }
      )
      console.error(error)
    }
  },
  async delete (
    { dispatch }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Eliminando mensaje',
          type: 'warning'
        },
        {
          root: true
        }
      )

      await dispatch(
        'sys/axios',
        {
          url: 'wmessage/delete',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      // commit('DELETE_MESSAGE', data)

      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Mensaje eliminado',
          type: 'info'
        },
        {
          root: true
        }
      )
    } catch (error) {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'A ocurrido un error',
          type: 'error'
        },
        {
          root: true
        }
      )
      console.error(error)
    }
  },
  async copyWMessage (
    { dispatch }: ActionContext<State, string>,
    msPayload: {
      message: WhatsAppMessage;
      variables: any;
      messageCalback?: CallableFunction;
    }
  ): Promise<void> {
    try {
      dispatch(
        'sys/showNotificationMessage',
        {
          title:
            'Copiando mensaje',
          color: 'warning'
        },
        { root: true }
      )
      const ms = format(msPayload.message.wm_message, msPayload.variables)
      await navigator.clipboard.writeText(ms)
      if (msPayload.messageCalback && isFunction(msPayload.messageCalback)) {
        if (isPromise(msPayload.messageCalback)) {
          await msPayload.messageCalback()
        } else {
          msPayload.messageCalback()
        }
      }
      dispatch(
        'sys/showNotificationMessage',
        {
          title:
            'Mensaje copiado',
          color: 'info'
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      dispatch(
        'sys/showNotificationMessage',
        {
          title:
            'Error al copiar el mensaje',
          color: 'error'
        },
        { root: true }
      )
    }
  },
  async copyReportMessage ({ dispatch }: ActionContext<State, string>, { id_report_item, mess }: { id_report_item: number; mess: WhatsAppMessage }) {
    const report_item: ReportItemData<unknown, unknown> = await dispatch('reports/reportItemById', id_report_item, { root: true })
    await report_item.copyWMessage({ message: mess.wm_message })
    // dispatch('copyWMessage', { message: mess, variables })
  },
  socket_messageCreated ({ commit }: ActionContext<State, string>, mess: WhatsAppMessage) {
    commit('ADD_MESSAGE', mess)
  },
  socket_messageUpdated ({ commit }: ActionContext<State, string>, mess: WhatsAppMessage) {
    commit('UPDATE_MESSAGE', mess)
  },
  socket_messageDeleted ({ commit }: ActionContext<State, string>, payload: { id_wmessage: number }) {
    commit('DELETE_MESSAGE', payload)
  }
}

const getters = {
  getWMessagesByType (state: State) {
    return (rType: WMESSAGE_TYPE): WhatsAppMessage[] => {
      return state?.messages?.filter(mess => mess.id_wmessage_type === rType) || []
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
