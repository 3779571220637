export default function ({ lon, lat }: { lon: number, lat: number }): Promise<string> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    wialon.util.Gis.getLocations([{ lon, lat }], function (code, address) {
      // @ts-ignore
      if (code) {
        // @ts-ignore
        reject(wialon.core.Errors.getErrorText(code))
      } else {
        resolve(address)
      }
    })
  })
}
