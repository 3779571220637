import { Timer } from 'easytimer.js'
import { DateTime } from 'luxon'
import store from '@/store'
interface Horarios {
  week_payload: any

  id_user: number

  pay_per_day: number

  pay_per_hour: number

  user_last_move: string

  break_time_start: string

  break_time_finish: string
}

export default class BreakAlarm implements Horarios {
  week_payload: any // Objeto de semanas array

  id_user!: number

  pay_per_day!: number

  pay_per_hour!: number

  user_last_move!: string

  break_time_start!: string

  break_time_finish!: string

  timerStart!: Timer | undefined

  timerFinish!: Timer | undefined

  tillHavingTime!: boolean

  audio!: HTMLAudioElement | undefined

  constructor (horario: Horarios) {
    Object.assign(this, horario)
    // this.nt_alarm_date = JSON.parse(this.nt_alarm_date)
    this.initCountDate()
  }

  // get userName() {
  //   console.log(store.state.manager.admin.users)
  //   return store.state.manager.admin.users
  // }

  worksToday () {
    // @ts-ignore
    const currWeek = Object.values(this.week_payload)[0]
    const today = DateTime.now().weekday
    // @ts-ignore
    const todayMatch = currWeek.find(day => day.dayOrder === today)
    if ((todayMatch.entrada.hour !== '00' || todayMatch.entrada.min !== '00') &&
        (todayMatch.salida.hour !== '00' || todayMatch.salida.min !== '00')) {
          return true
    } else {
      return false
    }
  }

  initCountDate (): void {
    if (this.break_time_start && this.break_time_finish && this.worksToday()) {
        const startTimeArray = this.break_time_start.split(':').map(Number);
        const finishTimeArray = this.break_time_finish.split(':').map(Number);

        // Crear objetos DateTime solo con horas y minutos
        const alarmStartTime = DateTime.now().set({ hour: startTimeArray[0], minute: startTimeArray[1], second: 0, millisecond: 0 });
        const alarmFinishTime = DateTime.now().set({ hour: finishTimeArray[0], minute: finishTimeArray[1], second: 0, millisecond: 0 });

        const diffToStart = alarmStartTime.diffNow(['seconds']).seconds;
        const diffToFinish = alarmFinishTime.diffNow(['seconds']).seconds;

        if (diffToStart > 0) {
            this.timerStart = new Timer();
            this.timerStart.start({ countdown: true, startValues: { seconds: diffToStart } });
            this.tillHavingTime = true;
            this.timerStart.addEventListener('targetAchieved', () => this.onTargetAchieved());
        }

        if (diffToFinish > 0) {
            this.timerFinish = new Timer();
            this.timerFinish.start({ countdown: true, startValues: { seconds: diffToFinish } });
            this.tillHavingTime = true;
            this.timerFinish.addEventListener('targetAchieved', () => this.onTargetAchieved(false))
        } else {
            this.tillHavingTime = false;
        }
    }
}

  stopCounterAlarm (firstAlarm = true): void {
    if (firstAlarm) {
      this.audio?.pause()
      this.timerStart?.reset()
      this.timerStart?.stop()
      this.timerStart?.removeEventListener('targetAchieved', () => this.onTargetAchieved())
      this.tillHavingTime = false
    } else {
      this.audio?.pause()
      this.timerFinish?.reset()
      this.timerFinish?.stop()
      this.timerFinish?.removeEventListener('targetAchieved', () => this.onTargetAchieved())
      this.tillHavingTime = false
    }
  }

  resetCounterAlarm (): void {
    this.stopCounterAlarm()
    this.initCountDate()
  }

  private onTargetAchieved = async (init = true) => {
    // @ts-ignore
    const user_name = store.state.manager.admin.users.find(u => u.id_user === this.id_user).us_name
    const title = (init) ? `Inicia descanso de ${user_name}` : `Termina descanso de ${user_name}`
    this.tillHavingTime = false
    this.audio = await store.dispatch('sys/playSound', {
      url: '/audio/alarm.mp3'
    })
    await store.dispatch('sys/showNotificationMessage', {
      // @ts-ignore
      title,
      duration: -1,
      color: 'info',
      data: {
        action: 'manager/findAndStopAlarmBreak',
        payload: { id_user: this.id_user, isFirst: init },
        btnText: 'Detener',
        isBreak: true
      }
    })
  }
}
