import { ActionContext } from 'vuex';
import { State } from '../state';
import { IChatPlataformNotification } from '@/store/interfaces/Chat';

export default {
  async createChatPlataformNotification ({ dispatch }: ActionContext<State, any>, payload: Partial<IChatPlataformNotification>) {
    try {
      const { data }: { data: IChatPlataformNotification } = await dispatch(
        'axios',
        {
          url: 'chat/item/notification/add',
          method: 'POST',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_CHAT_PLATAFORM_NOTIFICATION', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getChatPlataformNotificationByChatId ({ dispatch }: ActionContext<State, any>, id_chat_item: number) {
    try {
      const { data }: { data: IChatPlataformNotification[] } = await dispatch(
        'axios',
        {
          url: `chat/item/notification/get_by_chat_id/${id_chat_item}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_CHAT_PLATAFORM_NOTIFICATION_BY_CHAT_ID', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getChatPlataformNotificationByChatIdAndPlataformId ({ dispatch }: ActionContext<State, any>, payload: { id_chat_item: number; id_plataform: number }) {
    try {
      const { data }: { data: IChatPlataformNotification[] } = await dispatch(
        'axios',
        {
          url: `chat/item/notification/get_by_chat_id/${payload.id_chat_item}/${payload.id_plataform}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_CHAT_PLATAFORM_NOTIFICATION_BY_CHAT_ID_AND_PLATAFORM_ID', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteChatPlataformNotification ({ dispatch }: ActionContext<State, any>, id_chat_plataform_notification: number) {
    try {
      const { data }: { data: IChatPlataformNotification[] } = await dispatch(
        'axios',
        {
          url: `chat/item/notification/delete/${id_chat_plataform_notification}`,
          method: 'DELETE'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_CHAT_PLATAFORM_NOTIFICATION', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatPlataformNotification ({ dispatch }: ActionContext<State, any>, payload: IChatPlataformNotification) {
    try {
      const { data }: { data: IChatPlataformNotification[] } = await dispatch(
        'axios',
        {
          url: `chat/item/notification/update/${payload.id_chat_plataform_notification}`,
          method: 'PUT',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_CHAT_PLATAFORM_NOTIFICATION', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
