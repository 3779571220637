import { ActionContext } from 'vuex'
import { State } from '..'

export default {
  async createCompanyCredentials ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/company/credentials/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_COMPANY_CREDENTIALS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getAllCompanyCredentials ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/company/credentials/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { credentials: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_COMPANY_CREDENTIALS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateCompanyCredentials ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/company/credentials/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_COMPANY_CREDENTIALS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteCompanyCredentials ({ dispatch }: ActionContext<State, string>, id_company_credential: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/company/credentials/delete/${id_company_credential}`,
          method: 'DELETE'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_COMPANY_CREDENTIALS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
