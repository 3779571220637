import { ActionContext } from 'vuex';
import { State } from './state';
import { CAMERA_TYPE } from '@/store/catalogs/CAMERA_TYPE';
import ReportItemData from '@/store/packages/ReportItem/ReportItemData';
import { ReportLayout } from '@/store/interfaces/ReportModules';
import { SocketEvent } from '@/store/interfaces/SocketEvents';
import { RNRESOURCES_TYPES, RNResource } from '@/store/interfaces/ResourceNotification';
import { trycatchSync } from '@/utils/trycatch';
import { ICameraPlataform } from '@/store/interfaces/CameraPlataform';

export default {
  init (context: ActionContext<State, string>) {
    context.dispatch('subscribeEventNotificationsMessages')
  },
  subscribeEventNotificationsMessages (context: ActionContext<State, string>) {
    if ((context.getters.getAllHikCameraEhomeIDs as string[]).length) {
      context.dispatch('events/susbcribeToEventMessajesByKeys', context.getters.getAllHikCameraEhomeIDs, { root: true })
    }
  },
  async createCamerasByHIKSDK ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/encoding/create/device',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      await dispatch('sys/showNotificationMessage', { title: 'No se a podido añadir la camara, consulte al equipo tecnico', color: 'error' }, { root: true })
      dispatch('sys/addLogWithError', { title: 'HIK_ADD_DEVICE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteCamerasByHIKSDK ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/encoding/delete/device',
          method: 'DELETE',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'HIK_DELETE_DEVICE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getDeviceChannelsByHIKSDK ({ dispatch, rootGetters }: ActionContext<State, string>, { camera, payload }: { camera: ICameraPlataform; payload: any }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/encoding/channels/get/device',
          method: 'POST',
          data: {
            ...payload,
            dg_url: rootGetters['auth/hvEntryURL'],
            password: rootGetters['auth/hvPassword'],
            user: rootGetters['auth/hvUsername']
          }
        },
        { root: true }
      )

      if (data.data.device.Device.devStatus === 'offline') {
        dispatch('sys/showNotificationMessage', {
          title: `El dispositivo ${data.data.device.Device.devName} se encuentra actualmente apagado`,
          color: 'warning',
          duration: -1
        }, { root: true })
      }

      let cameras = data.data.urls

      const avaliableChannels = new Set<string>((camera.cpt_properties?.channelsVisibles || '').split(',').filter((c: string) => c))

      if (avaliableChannels.size) {
        cameras = cameras.filter((cam: any) => avaliableChannels.has(String(cam.Channel.id)))
      }

      cameras = cameras.map((cam: any) => ({
        id_camera: `${cam.Channel.id}-${cam.Channel.name}`,
        cam_name: `#${cam.Channel.id} - ${cam.Channel.name}`,
        cam_type: CAMERA_TYPE.HV_RTSP,
        cam_url: cam.url,
        cam_edit: false,
        cam_delete: false,
        cam_data: cam,
        device: data.data.device.Device,
        fk_camera_properties: 0
      }))

      const report_item: ReportItemData<unknown, unknown> = await dispatch('reports/reportItemById', parseInt(payload.id_report_item), { root: true })
      if (report_item) {
        cameras.forEach((camera: any) => {
          const haveCamera = report_item.ri_camera.find((cam: any) => cam.id_camera === camera.id_camera)
          if (!haveCamera) report_item.ri_camera.push(camera)
        });
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'HIK_GET_DEVICE_CHANNELS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getVideoPlayback ({ dispatch, rootGetters }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/video/playback/get',
          method: 'POST',
          data: {
            ...payload,
            dg_url: rootGetters['auth/hvEntryURL'],
            password: rootGetters['auth/hvPassword'],
            user: rootGetters['auth/hvUsername']
          }
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_VIDEO_PLAYBACK', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async donwloadVideoPlayback ({ dispatch }: ActionContext<State, string>, payload: { playbackURI: string; devIndex: string; startTime: string; endTime: string; channel: number }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/video/download/get',
          method: 'POST',
          data: {
            ...payload
          },
          responseType: 'blob'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_VIDEO_PLAYBACK', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getPictureById ({ dispatch }: ActionContext<State, string>, payload: { picid: string; name: string }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `hikvision/picture/download/get/${payload.picid}`,
          method: 'GET',
          data: payload,
          responseType: 'blob'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_PICTURE_BY_ID', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getVideoById ({ dispatch }: ActionContext<State, string>, payload: { picid: string; name: string }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `hikvision/video/download/get/${payload.picid}`,
          method: 'GET',
          data: payload,
          responseType: 'blob'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_VIDEO_BY_ID', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getPictureId ({ dispatch }: ActionContext<State, string>, payload: { channelID: string; key: string }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/picture/id/get',
          method: 'POST',
          data: payload,
          responseType: 'blob'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_PICTURE_ID', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  getRIByDeviceId ({ dispatch, rootState }: ActionContext<State, string>, id: string) {
    try {
      // @ts-ignore
      const rgroups: ReportLayout[] = rootState.reports.reports
      for (let i = 0; i < rgroups.length; i++) {
        const rg = rgroups[i];

        for (let j = 0; j < rg.unitData.ri_items.length; j++) {
          const ri: ReportItemData<unknown, unknown> = rg.unitData.ri_items[j];
          if (!ri.ri_camera_plataform) continue

          const isId = ri.ri_camera_plataform.some(rcp => {
            return rcp?.cpt_properties?.EhomeID === id
          })
          if (isId) return ri
        }
      }

      return null
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_RI_BY_DEVICE_ID', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getHikResources (_: ActionContext<State, string>, { event, objname, report_item }: { objname: string; event: SocketEvent; report_item: ReportItemData<unknown, unknown> }) {
    let resources = []
    // @ts-ignore
    const resourcesHVPictures = await trycatchSync(() => event?.data?.EventNotificationAlert[objname].ImageInfo.map((obj: any) => {
      const url = new URL(obj.Image.resourcesContent);
      const id = url.search.substring(1); // Quitamos el primer caracter que es el signo de interrogación
      return { name: `${report_item.ri_unit.replaceAll(' ', '_')}-${objname}-${event?.data?.EventNotificationAlert.channelName}.jpeg`, type: RNRESOURCES_TYPES.HV_PICTURE_ID, url: id, icon: 'mdi-image' } as RNResource
    }), [])

    resources = [...resourcesHVPictures]

    const resourcesHVVideo = await trycatchSync(() => {
      const obj = event?.data?.EventNotificationAlert[objname].VideoInfo
      const url = new URL(obj.Video.resourcesContent);
      const id = url.search.substring(1); // Quitamos el primer caracter que es el signo de interrogación
      return { name: `${report_item.ri_unit.replaceAll(' ', '_')}-${objname}-${event?.data?.EventNotificationAlert.channelName}.mp4`, type: RNRESOURCES_TYPES.HV_VIDEO_ID, url: id, icon: 'mdi-file-video' } as RNResource
    }, null)

    if (resourcesHVVideo) resources.push(resourcesHVVideo)

    return resources
  },
  async getPDFEvents ({ dispatch }: ActionContext<State, string>, payload: { timezone: string; events: string[]; unitDevices: { id_report_item: number; ri_unit: string; EhomeID: number }[] }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/events/pdf',
          method: 'POST',
          data: payload,
          responseType: 'blob'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_PDF_EVENTS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getSerachDeviceData ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/searchdevice/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_STATE', { SearchResult: data?.SearchResult })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_SEARCH_DEVICE_DATA', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getHikEventsAnalitics ({ dispatch }: ActionContext<State, string>, payload: { startTime: string; endTime: string; timezone: string; events: string[]; unitDevices: { id: number; unit_name: string; EhomeID: number }[] }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'hikvision/events/analitics',
          method: 'POST',
          data: { ...payload, id_column_name: 'id_event_message' }
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_HIK_ANALITICS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
};
