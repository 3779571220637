import ConsoleGroup from '@/store/interfaces/ConsoleGroup'
import Driver from '@/store/interfaces/Driver'
import UnitNotificationData from '@/store/packages/UnitNotification/UnitNotificationData'
import UnitNotificationGroup from '@/store/packages/UnitNotification/UnitNotificationGroup'

export interface ConsoleState {
  consoleGroups: ConsoleGroup[],
  stepperData: {
    isOpen: boolean
    notificationData: UnitNotificationData<unknown>
  },
  consoleNotifications: UnitNotificationGroup[],
  consoleNotificationsEvents: UnitNotificationGroup[],
  driversList: Driver[],
  consoleDraggable: boolean,
  modalSaveNotification: {
    modal: boolean
    unitNotificationGroup: UnitNotificationGroup | null
  },
  modalListNotification: {
    modal: boolean
  }
}

export default {
  consoleGroups: [],
  stepperData: {
    isOpen: false,
    notificationData: {}
  },
  consoleNotifications: [],
  consoleNotificationsEvents: [],
  driversList: [],
  consoleDraggable: false,
  modalSaveNotification: {
    modal: false,
    unitNotificationGroup: null as UnitNotificationGroup | null
  },
  modalListNotification: {
    modal: false
  }
}
