/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Driver from '@/store/interfaces/Driver'

export default (resource: any, unit_id: number, driver: Driver): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    // @ts-ignore
    // @ts-ignore
    const unit = sess.getItem(unit_id)
    // console.log(unit_id)
    const resDriver: Driver = resource.getDriver(driver.id)
    // console.log(resDriver)
    const isBind = resDriver.bu !== unit_id
    // console.log(isBind)
    // @ts-ignore
    resource.bindDriverToUnit(resDriver, unit, 0, isBind, qx.lang.Function.bind(function (_res, _driver, code, _result) {
      if (code) {
        // @ts-ignore
        reject(wialon.core.Errors.getErrorText(code))
      } else {
        resolve(isBind)
      }
    }, this, resource, resDriver))
  })
}
