import { DateTime } from 'luxon'

export default function (date: string) {
  const formatDate = new Date(date)
  // const stringDate = `${formatDate.getDay()}/${formatDate.getMonth()}/${formatDate.getFullYear()} - ${formatDate.getHours()}:${formatDate.getMinutes()}`
  const stringDate = `${
    formatDate.getDate() < 10 ? '0' : ''
  }${formatDate.getDate()}/${
    formatDate.getMonth() + 1 < 10 ? '0' : ''
  }${formatDate.getMonth() + 1}/${
    formatDate.getFullYear() < 10 ? '0' : ''
  }${formatDate.getFullYear()} - ${
    formatDate.getHours() < 10 ? '0' : ''
  }${formatDate.getHours()}:${
    formatDate.getMinutes() < 10 ? '0' : ''
  }${formatDate.getMinutes()}`
  return stringDate
}

export function datetimeOffset (date: string) {
  const formatDate = new Date(date)
  const offsetMinutes = DateTime.now().offset
  const offsetDate = DateTime.fromJSDate(formatDate).plus({ minutes: offsetMinutes }).toJSDate()

  const stringDate = `${
    offsetDate.getDate() < 10 ? '0' : ''
  }${offsetDate.getDate()}/${
    offsetDate.getMonth() + 1 < 10 ? '0' : ''
  }${offsetDate.getMonth() + 1}/${
    offsetDate.getFullYear() < 10 ? '0' : ''
  }${offsetDate.getFullYear()} - ${
    offsetDate.getHours() < 10 ? '0' : ''
  }${offsetDate.getHours()}:${
    offsetDate.getMinutes() < 10 ? '0' : ''
  }${offsetDate.getMinutes()}`
  return stringDate
}

export function daysOffset (pressitionDate: string) {
  // Crear un objeto DateTime para la fecha actual
  const ahora = DateTime.now();
  const offsetMinutes = DateTime.now().offset

  // Crear un objeto DateTime para la fecha dada
  const fechaDada = DateTime.fromJSDate(new Date(pressitionDate)).plus({ minutes: offsetMinutes });

  // Calcular la diferencia en días
  const diferencia = ahora.diff(fechaDada, 'days').days;

  // Redondear y devolver la diferencia en días
  return Math.floor(diferencia);
}

export const getCurrentDate = function () {
  const formatDate = new Date()

  const stringDate = `${formatDate.getUTCFullYear() < 10 ? '0' : ''}${formatDate.getUTCFullYear()}-${formatDate.getUTCMonth() + 1 < 10 ? '0' : ''}${formatDate.getUTCMonth() + 1}-${formatDate.getUTCDate() < 10 ? '0' : ''}${formatDate.getUTCDate()}`

  return stringDate
}

export const formatTime = (timeDiff: number): string => {
  const secondsInMinute = 60;
  const secondsInHour = 3600;

  if (timeDiff < secondsInMinute) {
    return `${timeDiff} seg`;
  } else if (timeDiff < secondsInHour) {
    const minutes = Math.floor(timeDiff / secondsInMinute);
    return `${minutes} min`;
  } else {
    const hours = Math.floor(timeDiff / secondsInHour);
    const minutes = Math.floor((timeDiff % secondsInHour) / secondsInMinute);
    return `${hours} h ${minutes} min`;
  }
}

export const hourFormat = (time: number): string => {
  const date = new Date(time * 1000 - 6 * 60 * 60 * 1000); // Multiplica por 1000 para convertir a milisegundos
  const h = date.getUTCHours();
  const m = date.getUTCMinutes();
  const s = date.getUTCSeconds();
  const hourFormat = (value: number): string => (value < 10 ? '0' : '') + value; // Añadir cero adelante si es menor a 10
  return `${hourFormat(h)}:${hourFormat(m)}:${hourFormat(s)}`
}
