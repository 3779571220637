import { ActionContext } from 'vuex';
import { State } from '../state';
import { ITechnicianInventory } from '@/store/interfaces/Binnacle';

export default {
  async technicianInventoryAdd (
    { dispatch }: ActionContext<State, string>,
    payload: { id_technician: number; id_inv_item: number; btii_amount: number }
  ) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'technician/inventory/add',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
      return data
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'TECHNICIAN_INVENTORY_ADD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async technicianInventoryRemove (
    { dispatch }: ActionContext<State, string>,
    payload: { id_technician: number; id_inv_item: number; btii_amount: number }
  ) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'technician/inventory/remove',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
      return data
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'TECHNICIAN_INVENTORY_REMOVE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  socket_technicianInventoryAdd ({ state }: ActionContext<State, string>, payload: ITechnicianInventory) {
    const technician = state.data.technicians.find(obj => obj.id_technician === payload.id_bit_technician)
    if (!technician) return // Retorna si no encuentra al tecnico

    const invItem = state.data.inv_items[payload.id_bit_inventory_item]
    if (!invItem) return // Retorna si no encuentra al inventario

    const techInventory = technician.inv_items.find(obj => obj.id_tech_inv_item === payload.id_tech_inv_item)
  // Guardo la diferencia entre el valor actual que tiene y el nuevo para sumarselo al inventario general
  const amountDiff = payload.btii_amount - (techInventory?.btii_amount ?? 0)

    // Si ya cuenta con ese inventario, le establece el nuevo valor que tiene
    if (techInventory) {
      techInventory.btii_amount = payload.btii_amount
    } else {
    // Sino, añade ese nuevo inventario a su array
      technician.inv_items.push(payload)
    }

    // Le resto al inventario la cantidad que se le añadio al tecnico
    invItem.bii_count = invItem.bii_count - amountDiff
  },
  socket_technicianInventoryRemove ({ state }: ActionContext<State, string>, payload: ITechnicianInventory) {
    const technician = state.data.technicians.find(obj => obj.id_technician === payload.id_bit_technician)
    if (!technician) return // Retorna si no encuentra al tecnico

    const invItem = state.data.inv_items[payload.id_bit_inventory_item]
    if (!invItem) return // Retorna si no encuentra al inventario

    const techInventory = technician.inv_items.find(obj => obj.id_tech_inv_item === payload.id_tech_inv_item)
    if (!techInventory) return // Si no tiene el inventario sobre el cual se le removio, retorna

    // Guardo la diferencia entre el valor actual que tiene y el nuevo para sumarselo al inventario general
    const amountDiff = techInventory.btii_amount - payload.btii_amount
    // Se establece el nuevo valor al inventario del tecnico
    techInventory.btii_amount = payload.btii_amount

    // Elimino el inventario del tecnico si es menor o igual a cero
    if (techInventory.btii_amount <= 0) {
        const index = technician.inv_items.findIndex(obj => obj.id_tech_inv_item === payload.id_tech_inv_item)
        technician.inv_items.splice(index, 1)
    }

    if (payload.return_to_inventory) {
      // Le sumo al inventario la diferencia de amountDiff si esta especificado en el evento
      invItem.bii_count = invItem.bii_count + amountDiff
    }
  }
}
