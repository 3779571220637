import ResourceItemData from './ResourceItemData'

export default class ActualizerPlataform {
    name: string
    resourcesItem: ResourceItemData<unknown>[]

    constructor ({ name, resourcesItem }: { name: string; resourcesItem: ResourceItemData<unknown>[] }) {
      this.name = name
      this.resourcesItem = resourcesItem
    }

    getResourceLength () {
      return this.resourcesItem.length
    }
}
