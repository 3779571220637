import promiseRetry from "promise-retry";
import AccessToken from "../api/AccessToken.api";
import makeApiRequest from "./makeApiRequest";
import ApiRequestConfig from "../interfaces/ApiRequestConfig.interface";


export default function wrapper(config: ApiRequestConfig) {
  return function decorator(t: any, n: any, descriptor: any) {
    const original = descriptor.value;
    if (typeof original === "function") {
      // @ts-ignore
      descriptor.value = async function (...args) {
        return await promiseRetry(
          async (retry) => {
            let refreshTokenOnFail: boolean = true

            try {
              const additParams = await original.call(this, ...args);
              if (additParams?.refreshTokenOnFail !== undefined) {
                refreshTokenOnFail = additParams?.refreshTokenOnFail
              }
              return await makeApiRequest(additParams, config)
            } catch (error) {
              if (refreshTokenOnFail) {
                await AccessToken.refreshLoginToken();
              }
              console.error(error);
              return retry(error);
            }
          },
          { retries: 1, minTimeout: 2000 }
        );
      };
    }
    return descriptor;
  };
}
