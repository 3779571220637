import ActualizerPlataform from '@/store/packages/Actualizer/ActualizerPlataform';
import AITrackSolid from '@/store/packages/Actualizer/AITrackSolid';
import RTrackSolid from '@/store/packages/Actualizer/RTrackSolid';
import RITrackSolid from '@/store/packages/ReportItem/RITrackSolid';
import TrackSolidSDK from '@/submodules/tracksolidjavacriptsdk';
import { serializeError } from 'serialize-error';
import { ActionContext } from 'vuex';
import { PLATAFORM_TYPE } from '../../catalogs/PLATAFORM_TYPE';
import { Plataform } from '../auth';
import { State } from './state';
import { trycatch } from '@/utils/trycatch';

export default {
  async init ({ dispatch, commit, state }: ActionContext<State, string>) {
    try {
      await Promise.all([dispatch('getDrivers')]);
      const data: Plataform = await dispatch(
        'auth/getPlataformData',
        PLATAFORM_TYPE.TRACKSOLID,
        {
          root: true
        }
      );
      const sdk = new TrackSolidSDK({ ...data.pf_data, intervalTime: 15000 });
      try {
        if (state.lastTokens.refresh_token && state.lastTokens.access_token) {
          const res = await sdk.api.AccessToken.refreshLoginToken(
            state.lastTokens
          );
          state.lastTokens.access_token = res.accessToken;
          state.lastTokens.refresh_token = res.refreshToken;
        } else {
          const res = await sdk.api.AccessToken.login();
          state.lastTokens.access_token = res.accessToken;
          state.lastTokens.refresh_token = res.refreshToken;
        }
        commit('SET_SDK', sdk);

        await dispatch('getResourcesActualizer');
      } catch (error) {
        commit(
          'sys/ADD_LOG',
          {
            title: 'TRACKSOLID',
            color: 'error',
            message: 'SDK_INIT_ERROR',
            payload: serializeError(error)
          },
          { root: true }
        );
        const res = await sdk.api.AccessToken.login();
        state.lastTokens.access_token = res.accessToken;
        state.lastTokens.refresh_token = res.refreshToken;
      }
      commit('SET_SDK', sdk);
    } catch (error) {
      commit(
        'sys/ADD_LOG',
        {
          title: 'TRACKSOLID',
          color: 'error',
          message: 'SDK_INIT_ERROR',
          payload: serializeError(error)
        },
        { root: true }
      );
      dispatch('clean');
      console.error(error);
    }
  },
  async getDrivers ({ commit }: ActionContext<State, string>) {
    commit(
      'drivers/SET_DRIVERS',
      { drivers: { 0: { 0: [] } }, pType: PLATAFORM_TYPE.TRACKSOLID },
      { root: true }
    );
  },
  async sendUploadHistory (
    { dispatch, commit, rootState }: ActionContext<State, string>, payload: { camera: number; fileName: string, imei:string }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: '/report/history_upload',
          method: 'POST',
          // data: payload
          // @ts-ignore
          data: { socket_id: rootState.sys.socket.id, ...payload }
        },
        { root: true }
      );
      return data
    } catch (error) {
      console.error(error);
      commit(
        'sys/ADD_LOG',
        {
          title: 'HISTORY_UPLOAD',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async getResourcesActualizer ({
    commit,
    state
  }: ActionContext<State, string>) {
    const units = await state.sdk.api.User.deviceLocationList();
    const resourceItem = new RTrackSolid(null, 0);
    const tsActualizer = new ActualizerPlataform({
      name: 'TRACKSOLID',
      resourcesItem: [resourceItem]
    });
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      resourceItem.addUnit(new AITrackSolid(unit));
    }
    commit('actualizer/PUSH_RESOURCES_ACTUALIZER', tsActualizer, {
      root: true
    });
  },
  clean ({ commit }: ActionContext<State, string>) {
    commit('CLEAN_TOKENS');
  },
  async createReportItemByPlataformID (context: ActionContext<State, string>, data: { id: any; pType: PLATAFORM_TYPE }): Promise<void> {
    const unitItem = (await context.state.sdk.item.DeviceItem.getItemsByIds({ imeis: [data.id] })).at(0);
    if (unitItem) {
      // @ts-ignore
      const reportItem = new RITrackSolid({}, { unitItem, type: data.pType })
      // @ts-ignore
      return reportItem
    }
  },
  async getUnits (context: ActionContext<State, string>) {
    const units = await trycatch(() => context.state.sdk.api.User.deviceLocationList(), []);
    return units.map((obj: any) => ({ ...obj, id: obj.imei, name: obj.deviceName }))
  }
};
