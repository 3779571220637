/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionContext } from 'vuex'
import { Socket } from 'socket.io-client'
import { SocketEvent } from '@/store/interfaces/SocketEvents'
import { ET_HIKVISION, EVENTS_TYPES } from '@/store/catalogs/EVENTS_TYPES'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData'
import datetimeToDate from '@/utils/datetimeToDate'
import toPascalCase from '@/utils/toPascalCase'
import ResourceNotification from '@/store/interfaces/ResourceNotification'

const state = {
  hvEvents: {
    hvUnknownNotifications: true,
    hvDisableNotifications: false,
    hvNotifications: Object.keys(ET_HIKVISION)
      .reduce((obj, key) => {
        obj[key] = true;
        return obj;
      }, {} as Record<string, boolean>)
  }
}

export type State = typeof state

const mutations = {
}

const actions = {
  init (context: ActionContext<State, string>) {
    // @ts-ignore
    const socketEvents: Socket = context.rootState.sys.socketEvents
    if (!socketEvents) return;

    socketEvents.on('event_uploaded', data => context.dispatch('socket_eventUploaded', data))
  },
  async socket_eventUploaded ({ dispatch, state, commit }: ActionContext<State, string>, event: SocketEvent) {
    if (!event.data) return
    switch (event.type) {
      case EVENTS_TYPES.HIKVISION_DG_EVENTS: {
        // Retorna si las notificaciones HV estas deshabilitadas
        if (state.hvEvents.hvDisableNotifications) return

        // Retorna si es una notificacion de tipo GPS upload
        if (event.data?.EventNotificationAlert?.eventType === ET_HIKVISION.GPSUpload.type) return

        const knowNotification = Object.values(ET_HIKVISION).map(obj => obj.type).includes(event.data?.EventNotificationAlert?.eventType)

        if (!state.hvEvents.hvUnknownNotifications && !knowNotification) return

        // Retorna si esa notificacion del tipo de evento no esta habilitada
        // @ts-ignore
        const isEnabled:boolean = state.hvEvents.hvNotifications[event?.data?.EventNotificationAlert?.eventType as string]
        if (!isEnabled && knowNotification) return

        // Retorna no se encontro la camara en ninguna unidad (RepotItemData)
        const report_item: ReportItemData<unknown, unknown> = await dispatch('hikvision/getRIByDeviceId', event.data?.EventNotificationAlert?.deviceSerial, { root: true })
        if (!report_item) return

        // Retorna si no estan hailitadas las notificaciones del grupo de la unidad
        const isGroupNotificationsActive: boolean = await dispatch('console/isSubscribedToGroup', report_item.id_group, { root: true })
        if (!isGroupNotificationsActive) return

        const baseNOT: ResourceNotification<unknown> = {
          color: '',
          pType: report_item.ri_type,
          blink: 0,
          f: 0,
          name: '',
          // @ts-ignore
          nid: event.data?.EventNotificationAlert?.eventType,
          p: {
            x: report_item?.last_message?.pos?.x,
            y: report_item?.last_message?.pos?.y
          },
          rt: 0,
          t: Date.now(),
          x: report_item?.last_message?.pos?.x,
          y: report_item?.last_message?.pos?.y,
          unitData: report_item.unit_item,
          // @ts-ignore
          unit: report_item.ri_wialon_id,
          tp: '',
          txt: `Se ha detectado una notificacion de tipo "${event.data?.EventNotificationAlert?.eventDescription}" en la unidad ${report_item.ri_unit} a las ${datetimeToDate(event.data?.EventNotificationAlert?.dateTime)}`,
          url: '',
          resources: await dispatch('hikvision/getHikResources', { objname: toPascalCase(event.data?.EventNotificationAlert?.eventType), event, report_item }, { root: true })
        }

        // @ts-ignore
        const et_hikvision = ET_HIKVISION[event.data.EventNotificationAlert.eventType as string]

        if (!et_hikvision) {
          if (state.hvEvents.hvUnknownNotifications) {
            baseNOT.color = '#bfbfbf' // Rojo mas intenzo
            baseNOT.name = 'Alerta desconocida'
            dispatch('console/addNotification', baseNOT, { root: true })
          }
        } else {
          baseNOT.color = et_hikvision.color
          baseNOT.name = et_hikvision.getNotName({ event, report_item })
          baseNOT.txt = et_hikvision.getNotMessage({ event, report_item }) || baseNOT.txt
          dispatch('console/addNotification', baseNOT, { root: true })
        }
        break;
      }
      case EVENTS_TYPES.HIKVISION_DG_SEARCH_DATA: {
        commit('hikvision/SET_STATE', { SearchResult: event.data?.SearchResult }, { root: true })
        break
      }
    }
  },
  async getEvents ({ dispatch }: ActionContext<State, string>, payload: { event_type: number }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'events/get/data/all',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_EVENTS_ALL', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async disableAllHVNotifications ({ state }: ActionContext<State, string>) {
    for (const not in state.hvEvents.hvNotifications) {
      state.hvEvents.hvNotifications[not] = false
    }
    state.hvEvents.hvUnknownNotifications = false
  },
  async susbcribeToEventMessajesByKeys (context: ActionContext<State, string>, keys: string[]) {
    // @ts-ignore
    const socketEvents: Socket = context.rootState.sys.socketEvents
    socketEvents.emit('connect_to_messages', keys)
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
