import store from '@/store/index'
import { Sim as ISim, Plan } from '@/store/interfaces/Binnacle'
import Equipment from './Equipment'

export default class Sim implements ISim {
  id_plan!: number
  id_sim!: number
  sim_ccid!: string
  sim_line!: string
  sim_status_active!: boolean
  is_deleted!: boolean
  fk_bit_plan!: number

  constructor ({ data }: { data: ISim }) {
    Object.assign(this, data)
  }

  public get equipment (): Equipment | undefined {
    // @ts-ignore
    const equipment = store.state.binnacle.data.equipments.find(eqp => eqp.id_sim === this.id_sim)
    return equipment
  }

  public get plan (): Plan | undefined {
    // @ts-ignore
    const plan = store.state.binnacle.data.plans.find(obj => obj.id_plan === this.id_plan)
    return plan
  }

  public get plan_name () {
    return this.plan?.plan_name
  }

  public get plan_sms () {
    return this.plan?.plan_sms
  }

  public get plan_voice () {
    return this.plan?.plan_voice
  }

  public get eq_imei () {
    return this.equipment?.eq_imei
  }

  public get mod_name () {
    return this.equipment?.model?.mod_name
  }
}
