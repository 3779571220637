import Api from './Api';
import ApiRequest from '../decorators/ApiRequest'
import { Optional } from '../interfaces/utils.interface';
import { IParameters } from '../interfaces/login.interface';

export type IDeviceFenceCreate = Optional<
  Pick<
    IParameters,
    'access_token' | 'imei' | 'fence_name' | 'alarm_type' | 'report_mode' | 'alarm_switch' | 'lng' | 'lat' | 'radius' | 'zoom_level' | 'map_type'
  >,
  'access_token'
>;
export default class Geofence extends Api {
  @ApiRequest({ method: 'jimi.open.device.fence.list' })
  public static async deviceFenceList () {}

  @ApiRequest({ method: 'jimi.open.device.fence.create' })
  public static async deviceFenceCreate (params: IDeviceFenceCreate) {
    return params
  }
}
