import { DateTime, Duration } from "luxon";
import { IParameters } from "../interfaces/login.interface";
import { Optional } from "../interfaces/utils.interface";
import Api from "./Api";
import ApiRequest from "../decorators/ApiRequest";
import splitArrInChunks from "../utils/splitArrInChunks";
import makeApiRequest from "../decorators/makeApiRequest";

export type ITrackListParams = Optional<
  Pick<
    IParameters,
    "access_token" | "imei" | "begin_time" | "end_time" | "map_type"
  >,
  "access_token" | "begin_time" | "map_type" | "end_time"
>;

export type ILocationGetParams = Optional<
  Pick<IParameters, "imeis" | "map_type">,
  "map_type"
>;

export type ILocationGetResponce = Pick<
  IParameters,
  | "imei"
  | "deviceName"
  | "icon"
  | "status"
  | "posType"
  | "lat"
  | "lng"
  | "hbTime"
  | "accStatus"
  | "powerValue"
  | "speed"
  | "gpsNum"
  | "gpsTime"
  | "direction"
  | "activationFlag"
  | "expireFlag"
  | "electQuantity"
  | "locDesc"
  | "distance"
  | "temperature"
  | "trackerOil"
>;

export type ITrackListResponce = Pick<
  IParameters,
  | "lat"
  | "lng"
  | "gpsTime"
  | "direction"
  | "gpsSpeed"
  | "posType"
  | "satellite"
  | "timestamp"
>;

export type IMediaURLParams = Optional<
  Pick<
    IParameters,
    | "access_token"
    | "imei"
    | "camera"
    | "media_type"
    | "start_time"
    | "end_time"
    | "token"
    | "page_no"
    | "page_size"
  >,
  "access_token" | "start_time" | "end_time" | "token" | "page_no" | "page_size"
>;

export type IMediaURLResponce = Pick<
  IParameters,
  | "thumb_URL"
  | "file_URL"
  | "mime_type"
  | "create_time"
  | "media_type"
  | "camera"
  | "file_size"
>;

export type ILivePageURLParams = Optional<
  Pick<
    IParameters,
    | "access_token"
    | "imei"
    | "V"
    | "token"
  >,
  | "access_token"
  | "V"
  | "token"
>;

export type ILivePageURLResponce = Pick<
  IParameters,
  | "lat"
  | "lng"
  | "gpsTime"
  | "direction"
  | "gpsSpeed"
  | "posType"
  | "satellite"
  | "VIN"
  | "plateNo"
  | "UrlCamera"
>;


export type ICommandParams = Optional<
  Pick<
    IParameters,
    | "access_token"
    | "imei"
    | "inst_param_json"
  >,
  | "access_token"
  | "imei"
  | "inst_param_json"
>;
// export type ICommandParams = Pick<
//   IParameters,
//   | "access_token"
//   | "imei"
//   | "inst_param_json"
// >;

export type ICommandURLResponce = Pick<
  IParameters,
  | "code"
  | "message"
  | "result"
>;


export type ICommandListParams = Optional<
  Pick<
    IParameters,
    | "access_token"
    | "imei"
  >,
  | "access_token"
  | "imei"
>;
export type ICommandListResponce = Pick<
  IParameters,
  | "code"
  | "message"
  | "result"
  | "id"
  | "orderName"
  | "orderContent"
  | "orderExplain"
  | "orderMsg"
  | "isOffLine"
>;



export type ICommandScooterParams = Optional<
  Pick<
    IParameters,
    | "access_token"
    | "imei"
    | "cmd_code"
  >,
  | "access_token"
  | "imei"
  | "cmd_code"
>;

export type ICommandScooterResponce = Pick<
  IParameters,
  | "code"
  | "message"
>;



export default class Device extends Api {
  public static async locationGet(
    params: ILocationGetParams
  ): Promise<ILocationGetResponce[]> {
    const responces = [];
    const arrImeis = Array.isArray(params.imeis)
      ? params.imeis
      : params.imeis.split(",");
    const imeisChunks = splitArrInChunks<string>(arrImeis, 99);
    for (let i = 0; i < imeisChunks.length; i++) {
      const imeis = imeisChunks[i].join(",");
      const locations: ILocationGetResponce[] = await makeApiRequest(
        {
          ...params,
          // @ts-ignore
          imeis,
        },
        { method: "jimi.device.location.get" }
      );
      responces.push(locations);
    }

    return responces.flat();
  }

  @ApiRequest({ method: "jimi.device.track.list" })
  public static async trackList(
    tracListParams?: ITrackListParams
  ): Promise<ITrackListResponce[]> {
    // @ts-ignore
    return {
      begin_time: DateTime.now()
        .toUTC()
        .plus({ hours: -1 })
        .toFormat("yyyy-LL-dd HH:mm:ss"),
      end_time: DateTime.now().toUTC().toFormat("yyyy-LL-dd HH:mm:ss"),
      ...tracListParams,
    };
  }

  public static async trackListByTimestamp(params: {
    imei: string;
    milliseconds: number;
  }): Promise<ITrackListResponce[]> {
    const MILLIS_48HRS = 172800000;
    const totalData = [];
    const { hours } = Duration.fromMillis(params.milliseconds)
      .shiftTo("hours")
      .toObject();
    // @ts-ignore
    const peerLoops = Math.ceil(hours / 48);

    for (let index = 1; index <= peerLoops; index++) {
      const begin_time = DateTime.now()
        .toUTC()
        .plus({ milliseconds: -(MILLIS_48HRS * index) })
        .toFormat("yyyy-LL-dd HH:mm:ss");

      const end_time = DateTime.now()
        .toUTC()
        .plus({ milliseconds: -(MILLIS_48HRS * (index - 1)) })
        .toFormat("yyyy-LL-dd HH:mm:ss");

      const list = await this.trackList({
        imei: params.imei,
        begin_time,
        end_time,
      });
      // @ts-ignore
      const listMap = list.map((obj) => ({
        ...obj,
        timestamp: DateTime.fromFormat(
          obj.gpsTime,
          "yyyy-LL-dd HH:mm:ss"
        ).toMillis(),
      }));
      totalData.push(listMap);
    }

    const flated = totalData.flat();
    const sorted = flated.sort(
      (first, second) => second.timestamp - first.timestamp
    );
    // @ts-ignore
    return sorted;
  }

  @ApiRequest({ method: "jimi.device.media.URL" })
  public static async mediaURL(
    mediaURLParams?: IMediaURLParams
  ): Promise<IMediaURLResponce[]> {
    // @ts-ignore
    return {
      ...mediaURLParams,
    };
  }

  @ApiRequest({ method: "jimi.device.live.page.url" })
  public static async livePageURL(
    livePageURLParams?: ILivePageURLParams
  ): Promise<ILivePageURLResponce> {
    // @ts-ignore
    return {
      ...livePageURLParams,
    };
  }
  @ApiRequest({ method: "jimi.open.instruction.send" })
  public static async sendCommand(
    commandSendParams?: ICommandParams
  ): Promise<ICommandURLResponce> {
    // @ts-ignore
    return {
      ...commandSendParams,
    };
  }

  @ApiRequest({ method: "jimi.open.instruction.list" })
  public static async listCommand(
    commandListParams?: ICommandListParams
  ): Promise<ICommandListResponce> {
    // @ts-ignore
    return {
      ...commandListParams,
    };
  }
  @ApiRequest({ method: "jimi.scooter.instruction.send" })
  public static async sendCommandScooter(
    commandScooterParams?: ICommandScooterParams
  ): Promise<ICommandScooterResponce> {
    // @ts-ignore
    return {
      ...commandScooterParams,
    };
  }
}
