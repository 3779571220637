import { State } from '..'

export default {
  getUsersByCompanyId (state: State) {
    return (id_company: number) => {
      return state.admin.users.filter(obj => obj.id_company === id_company)
    }
  },
  getUsersByAuthCompanyId (state: State, getters:any, rootState:any, rootGetters:any) {
    return () => {
      return state.admin.users.filter(obj => obj.id_company === rootGetters['auth/idCompany'])
    }
  }
}
