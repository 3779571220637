import { IStep } from '@/interfaces/Steps.interface';
import { State } from './state'
import managerStore from '@/store/modules/manager/'

export default {
  SET_STEPPERS (state: State, steppers: any[]): void {
    state.steppers = steppers
  },
  UPDATE_STEPPER (state: State, { index, stepper }: { index: number; stepper: any }): void {
    Object.assign(state.steppers[index], stepper)
  },
  DELETE_STEP (state: State, { index }: { index: number }): void {
    const { id_step } = state.steps[index]
    managerStore.state.admin.steppers.forEach(stpr => {
    const indexDel = stpr.steps.findIndex(step => step.id_step === id_step)
      if (indexDel !== -1) {
        stpr.steps.splice(indexDel, 1)
        stpr.steps_count--;
      }
      })
    state.steps.splice(index, 1)
  },
  PUSH_STEPPER_STEP (state: State, { index, step }: { index: number; step: any }): void {
    state.steppers[index].steps.push(step)
  },
  DELETE_STEPPER_STEP (state: State, { index_stepper, index_step }: { index_stepper: number; index_step: number; }): void {
    state.steppers[index_stepper].steps.splice(index_step, 1)
  },
  DELETE_STEPPER (state: State, { index }: { index: number }): void {
    state.steppers.splice(index, 1)
  },
  PUSH_STEPPER (state: State, stepper: any): void {
    state.steppers.push(stepper)
  },
  PUSH_STEP (state: State, step: IStep): void {
    state.steps.push(step)
  },
  SET_STEPS (state: State, steps: any[]): void {
    state.steps = steps
  },
  UPDATE_STEP (state: State, { index, step }: { index: number; step: any }): void {
    Object.assign(state.steps[index], step)
  }
}
