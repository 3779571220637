import { ActionContext } from 'vuex'
import { State } from '../state'
import { Pagination } from '@/store/interfaces/Pagination'
import { IChatLogData } from '@/store/interfaces/Chat';

export default {
  async getChatLogPagination ({ dispatch }: ActionContext<State, any>, { id_chat_item, items, page }: { id_chat_item: number; page: number; items: number }): Promise<Pagination<IChatLogData>> {
    try {
      const { data }: { data: Pagination<any> } = await dispatch(
        'axios',
        {
          url: `chat/item/logs/pagination/${id_chat_item}/${page}/${items}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_CHAT_LOG_PAGINATION', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
