import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { State } from '../index'
import { IReportItem } from '@/store/interfaces/Manager.interface'

export default {
  UNSHIFT_UNIT (state: State, payload: IReportItem) {
    state.admin.units.unshift(payload)
  },
  PUSH_UNIT (state: State, payload: IReportItem) {
    state.admin.units.push(payload)
  },
  UPDATE_UNIT (state: State, { index, data }: { data: IReportItem; index: number }) {
    Object.assign(state.admin.units[index], data)
  },
  DELETE_UNIT (state: State, index: number) {
    state.admin.units.splice(index, 1)
  },
  SET_PLATAFORM_UNITS (state: State, { pType, units }: { pType: PLATAFORM_TYPE; units: any[] }) {
    state.unitsstate.plataformUnits[pType] = units
  }
}
