import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { IDeviceLocationListResponce } from '@/submodules/tracksolidjavacriptsdk/dist/api/User.api'
import getSecondsDiffFromNow from '@/utils/getSecondsDiffFromNow'
import { DateTime } from 'luxon'
import ActualizerItemData from './ActualizerItemData'

export default class AITrackSolid extends ActualizerItemData<IDeviceLocationListResponce> {
  pType: PLATAFORM_TYPE

  constructor (unit_item: any) {
    super(unit_item)
    this.pType = PLATAFORM_TYPE.TRACKSOLID
  }

  getSecondsDiff (): number {
    // @ts-ignore
    const millis = DateTime.fromISO(DateTime.fromFormat(this.unit_item.gpsTime, 'yyyy-MM-dd hh:mm:ss').toUTC().toISO()).toMillis() / 1000
    return getSecondsDiffFromNow(millis)
  }

  getPlataformId (): number {
    // @ts-ignore
    return this.unit_item.imei
  }

  getName (): string {
    return this.unit_item.deviceName
  }

  getResourceId (): number {
    return 0
  }

  getIcon (): string {
    return require('@/assets/svg/s_a_35.svg')
  }
}
