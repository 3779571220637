
import { ActionContext } from 'vuex';
import { SelectedCameras, State } from './state';
import { serializeError } from 'serialize-error';
import { Socket } from 'socket.io-client';

export default {
  init (context: ActionContext<State, string>) {
    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket

    socket.on('camera_properties_created', (data: any) => context.dispatch('socket_cameraPropertiesCreated', data))
    socket.on('camera_properties_updated', (data: any) => context.dispatch('socket_cameraPropertiesUpdated', data))
    socket.on('camera_properties_deleted', (data: any) => context.dispatch('socket_cameraPropertiesDeleted', data))
  },
  async cpGetAll (
    { dispatch, commit }: ActionContext<State, string>
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'camera/get/all/properties',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_CAMERA_PROPERTIES', data)

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'RI_CAMERA_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async cpCreate (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'camera/create/properties',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'RI_CAMERA_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async cpDelete (
    { dispatch, commit }: ActionContext<State, string>,
    cpt_id: number
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `camera/delete/properties/${cpt_id}`,
          method: 'DELETE'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'CP_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async cpUpdate (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `camera/update/properties/${payload.cpt_id}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'CP_PUT',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async cPlataformUpdate (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `camera/update/camera_plataform/${payload.id_camera_plataform}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'CAMERA_PLATAFORM_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async RIgetAll (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      const { data: cameras } = await dispatch(
        'sys/axios',
        {
          url: `camera/report_item/get/all/${payload.id_report_item}`,
          method: 'GET'
        },
        { root: true }
      )

      const report_item = await dispatch('reports/reportItemById', parseInt(payload.id_report_item), { root: true })
      if (report_item) {
        report_item.ri_camera = []
        cameras.forEach((camera: any) => {
          const haveCamera = report_item.ri_camera.find((cam: any) => cam.id_camera === camera.id_camera)
          if (!haveCamera) report_item.ri_camera.push(camera)
        });
      }

      return cameras
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'RI_CAMERA_GET_ALL',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async RIgetAllCameraPlataform (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `camera/report_item/get/all/camera_plataform/${payload.id_report_item}`,
          method: 'GET'
        },
        { root: true }
      )

      const report_item = await dispatch('reports/reportItemById', parseInt(payload.id_report_item), { root: true })
      report_item.ri_camera_plataform = data

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'RI_CAMERA_GET_ALL_CAMERA_PLATAFORM',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async RIcreate (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'camera/report_item/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'RI_CAMERA_CREATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async update (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `camera/update/${payload.id_camera}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'CAMERA_UPDATE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  async RIdelete (
    { dispatch, commit }: ActionContext<State, string>,
    payload: any
  ): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `camera/report_item/delete/${payload.id_camera}/${payload.id_report_item}`,
          method: 'DELETE'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      commit(
        'sys/ADD_LOG',
        {
          title: 'RI_CAMERA_DELETE',
          color: 'error',
          message: error,
          payload: serializeError(error)
        },
        { root: true }
      );
      throw error;
    }
  },
  socket_cameraPropertiesCreated ({ commit }: ActionContext<State, string>, data: any) {
    commit('ADD_CAMERA_PROPERTIES', data)
  },
  socket_cameraPropertiesUpdated ({ state, commit }: ActionContext<State, string>, data: any) {
    const index = state.camera_properties.findIndex(obj => obj.cpt_id === data.cpt_id)
    if (index === -1) return
    commit('UPDATE_CAMERA_PROPERTIES', { camera_properties: data, index })
  },
  socket_cameraPropertiesDeleted ({ state, commit }: ActionContext<State, string>, data: any) {
    const index = state.camera_properties.findIndex(obj => obj.cpt_id === data.cpt_id)
    if (index === -1) return
    commit('DELETE_CAMERA_PROPERTIES', index)
  },
  addSelectedCamera ({ rootState, dispatch, commit, state }: ActionContext<State, string>, data: any) {
    const index = state.selectedCameras.findIndex(obj => obj.id_camera === data.id_camera && obj.id_report_item === data.id_report_item)

    if (index !== -1) {
      dispatch('sys/showNotificationMessage', {
        title: 'La camara ya se encuentra seleccionada',
        color: 'error'
      }, { root: true })

      return
    }

    // @ts-ignore
    const report_group_index: number = rootState.reports.reports.findIndex(obj => obj.unitData.id_report_group === data.id_report_group)
    if (report_group_index === -1) {
      dispatch('sys/showNotificationMessage', {
        title: 'No se pudo encontrar el grupo correspondiente',
        color: 'error'
      }, { root: true })
      return
    }

    // @ts-ignore
    const report_item_index = rootState.reports.reports[report_group_index].unitData.ri_items.findIndex(obj => obj.id_report_item === data.id_report_item)
    if (report_item_index === -1) {
      dispatch('sys/showNotificationMessage', {
        title: 'No se pudo encontrar la unidad correspondiente',
        color: 'error'
      }, { root: true })
      return
    }

    commit('ADD_SELECTED_CAMERA', { ...data, report_group_index, report_item_index } as SelectedCameras)
  },
  removeSelectedCamera ({ state, dispatch, commit }: ActionContext<State, string>, { id_camera, id_report_item }: { id_camera: string; id_report_item: number }) {
    const index = state.selectedCameras.findIndex(obj => obj.id_camera === id_camera && obj.id_report_item === id_report_item)
    if (index === -1) {
      dispatch('sys/showNotificationMessage', {
        title: 'No se pudo encontrar la camara a eliminar',
        color: 'error'
      }, { root: true })
      return
    }

    commit('REMOVE_SELECTED_CAMERA', index)
  }
}
