/* eslint-disable @typescript-eslint/no-unused-vars */
import { IObject } from '../../interfaces/Object.interface'
import { IParameters } from '../../interfaces/parameters.interface'
import { Optional } from '../../interfaces/utils.interface'
import AssingData from '../../utils/AssingData'
import Action from './Action'
import { Alert } from '../../interfaces/Alert.interface'
import { DateTime } from 'luxon'
import MaponSDK from '../..'

export type IListUnitParams = Optional<
  Pick<
    IParameters,
    | 'key'
    | 'from'
    | 'till'
    | 'unit_id'
    | 'alert_type'
    | 'driver'
    | 'include'
    | 'limit'
    | 'page'
  >,
  'key' | 'unit_id' | 'alert_type' | 'driver' | 'include' | 'limit' | 'page'
>;

@AssingData()
export default class AAlert extends Action<Alert> implements Alert {
  unit_id!: number;
  time!: Date;
  alert_type!: string;
  alert_val!: string;
  msg!: string;
  id!: number;
  location!: string;
  address!: string;

  public static isSubscribed = false
  public static queueMessages: Map<string, Alert> = new Map();
  public static iterationCallCount = 0

  public static async observeMessages() {
    if (!AAlert.isSubscribed) return
    const messages = await AAlert.list({
      from: DateTime.utc().plus({ hours: -2 }).toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'"),
      till: DateTime.utc().toFormat("yyyy-LL-dd'T'HH:mm:ss'Z'")
    }, { raw: true })

    messages.forEach(mess => {
      const key = `${mess.unit_id}-${mess.time}-${mess.alert_type}-${mess.alert_val}`
      const isMessageRegistered = AAlert.queueMessages.has(key)
      // Retorna si el mensaje ya se a añadio o si en ningun momento se a llamado al metodo observeMessages
      if (isMessageRegistered || AAlert.iterationCallCount === 0) return

      AAlert.queueMessages.set(key, mess)
      MaponSDK.event.emit('ALERT_MESSAGE_CHANGED', mess)
    })

    // Si es la primera vez que se llama a este metodo, si rellena con los mensajes obtenidos del servidor
    if (AAlert.iterationCallCount === 0) {
      messages.forEach(mess => {
        const key = `${mess.unit_id}-${mess.time}-${mess.alert_type}-${mess.alert_val}`
        AAlert.queueMessages.set(key, mess)
      })
    }

    AAlert.iterationCallCount++
  }

  public static addEventListener(type: 'ALERT_MESSAGE_CHANGED', callback: (alert: Alert) => void) {
    switch (type) {
      case 'ALERT_MESSAGE_CHANGED':
        AAlert.isSubscribed = true
        MaponSDK.event.addListener(type, callback)
        break
    }
  }

  public static removeEventListener(type: 'ALERT_MESSAGE_CHANGED', callback: (alert: Alert) => void) {
    switch (type) {
      case 'ALERT_MESSAGE_CHANGED':
        AAlert.isSubscribed = false
        MaponSDK.event.removeListener(type, callback)
        break
    }
  }

  public static async list(
    params: IListUnitParams,
    { raw } = { raw: false }
  ): Promise<AAlert[]> {
    const res = await super.AList<Alert[]>(params, {
      action: 'alert'
    })
    if (!raw) {
      const aAlerts = res.data.map((obj) => new AAlert({ data: obj }))
      return aAlerts
    } else {
      // @ts-ignore
      return res.data
    }
  }

  isUpdated(data: any): boolean {
    return false
  }
}
