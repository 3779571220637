import { ICommand } from '@/store/interfaces/Command';
import Driver from '@/store/interfaces/Driver';
import { ReportItem } from '@/store/interfaces/ReportModules';
import { Sensors } from '@/store/interfaces/Sensors';
import { ILocationGetResponce } from '@/submodules/tracksolidjavacriptsdk/dist/api/Device.api';
import DeviceItem from '@/submodules/tracksolidjavacriptsdk/dist/classes/Device.item';
import osmGetAddress from '@/utils/osmGetAddress';
import { DateTime } from 'luxon';
import ReportItemData from './ReportItemData';
import store from '@/store/index';
import getSecondsDiffFromNow from '@/utils/getSecondsDiffFromNow'

export default class RITrackSolid
  extends ReportItemData<DeviceItem, ILocationGetResponce>
  implements ReportItem {
  getCurrPlataformGeofencesIds (message: { y: any; x: any; }): Promise<number[]> {
    throw new Error('Method not implemented.');
  }

  public bateryFlag = '%'

  getUnitName () {
    return this.unit_item.getName() || this.ri_unit
  }

  unsusbscribeToUnitMessages (): void {
    this.unit_item.removeEventListener('MESSAGE_CHANGED', (data) => {
      this.last_message = { ...data, pos: { x: data.lat, y: data.lng, c: parseInt(data.direction) } };
      this.onUnitDataChanged()
    });
  }

  susbscribeToUnitMessages () {
    // @ts-ignore
    this.last_message = { ...this.unit_item.lastData, pos: { x: this.unit_item.lastData?.lat, y: this.unit_item.lastData?.lng } }
    try {
      this.unit_item.addEventListener('MESSAGE_CHANGED', (data) => {
        this.last_message = { ...data, pos: { x: data.lat, y: data.lng, c: parseInt(data.direction) } };
        this.onUnitDataChanged()
      });
    } catch (error) {
      console.error(error)
    }
  }

  getLastMessageTimeInMillisecons () {
    return DateTime.fromISO(this.unit_item.getISOGpsTime()).toMillis() / 1000
  }

  async getUbication (): Promise<string> {
    const ubication = await osmGetAddress(
      this.unit_item.lat,
      this.unit_item.lng
    );
    return ubication?.display_name || '';
  }

  async isOnZone (message: { y: any; x: any }): Promise<boolean> {
    const isOnConsoleGeofences = this.isOnZoneConsoleGeofences(message);
    return isOnConsoleGeofences;
  }

  public initLocationObserver () {
    // @ts-ignore
    if (this.location.keyListener) return;
    const lastData = this.unit_item.lastData;
    this.last_message = {
      ...lastData,
      // @ts-ignore
      pos: { x: lastData?.lat, y: lastData?.lng, c: parseInt(lastData.direction) }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.unit_item.addEventListener('POSITION_CHANGED', (data) => {
      this.makeLocationRequest({ x: data.lat, y: data.lng });
    });

    this.location.keyListener = 'SUBSCRIBED';
    // @ts-ignore
    this.makeLocationRequest({ x: this.unit_item.lat, y: this.unit_item.lng });
  }

  stopLocationObserver () {
    this.unit_item.removeEventListener('POSITION_CHANGED', (data) => {
      this.makeLocationRequest({ x: data.lat, y: data.lng });
    });
    this.location.onZone = true;
    this.location.keyListener = '';
  }

  getIcon (): string {
    return require('@/assets/svg/s_a_35.svg');
  }

  async getIgnitionState (): Promise<number> {
    return 0;
  }

  getCoordinates (): string {
    return this.unit_item.getCoordinates();
  }

  speed (): number {
    return parseInt(this.unit_item.getSpeed());
  }

  getGoogleLink (): string {
    return this.unit_item.googleLink();
  }

  // @ts-ignore
  getCurrentCoordinatesRaw (): { x: any; y: any } {
    return this.unit_item.getCoordinatesRaw();
  }

  async getLinkCamera () {
    // @ts-ignore
    // const resp = (await store.state.tracksolid.sdk.api.Device.livePageURL({ imei: this.unit_item.imei }))?.UrlCamera ?? '';
    const unit_item = await store.dispatch('reports/reportItemById', this.id_report_item, { root: true })
    unit_item.ri_camera = []
    const { socketUrl } = await store.dispatch('reports/getSocketCamera', this.unit_item.imei)
    const socket = await new WebSocket(socketUrl)
    const getUrlCH1 = `{"imei":"${this.unit_item.imei}","cmdCode":256,"actionType":1,"ver":3,"channel":0}`
    const getUrlCH2 = `{"imei":"${this.unit_item.imei}","cmdCode":256,"actionType":1,"ver":3,"channel":1}`
    // ACA SE ABRE LA CONEXION SOCKET PARA LAS URL DE LAS CAMERAS
    socket.onopen = function (event) {
      socket.send(getUrlCH1);
      socket.send(getUrlCH2);
    };
    socket.onmessage = async (e) => {
      const { data } = e
      const json = JSON.parse(data)
      if (json.data) {
        console.log(json);
        if (unit_item) {
          const camera = { cam_name: `CH${(json.data.channel === 0) ? '1' : '2'}`, cam_url: json.data.rtmpUrl, cam_type: 1 }
          if (!camera.cam_url) {
            store.dispatch('sys/showNotificationMessage', {
              title: `Camara ${camera.cam_name} no disponible, intentar mas tarde`,
              color: 'warning'
            })
            return
          }
          unit_item.ri_camera.push(camera)
        }
      }
    }
   return ''
  }

  async getHistoryVideo (config?: { camera?: number, date: string }) {
    // @ts-ignore
    const start = new Date(config?.date).setUTCHours(6, 0, 0, 0);
    // @ts-ignore
    const end = new Date(config?.date).setUTCHours(29, 59, 59, 999);
    const { socketUrl } = await store.dispatch('reports/getVideoHistory', this.unit_item.imei)
    const socket = await new WebSocket(socketUrl)
    const sendData = `{"actionType":1, "ver":3, "cmdCode":270,"imei":"${this.unit_item.imei}", "channel":${config?.camera}, "cmdData":{ "startTime":${Math.round(start / 1000)}, "endTime":${Math.round(end / 1000)} }}`
    console.log(Math.round(start / 1000));
    console.log(Math.round(end / 1000));
    socket.onopen = function (event) {
      socket.send(sendData);
    };
    socket.onmessage = async (e) => {
      const data = JSON.parse(e.data);
      const dataHistory = { ...data }
      if (dataHistory.code === 228 || dataHistory.code === 225) {
        // @ts-ignore
        store.state.tracksolid.loading = false
        store.dispatch('sys/playSound', { type: 'alert' }, { root: true })
        await store.dispatch('sys/showNotificationMessage', {
          title: 'Unidad sin conexion',
          color: 'warning',
          duration: 10000
        }, { root: true });
        return
      }
      const intervals = {}
      const dataPayloadSocket = {
        imei: dataHistory.data.imei,
        camera: dataHistory.data.channel,
        intervals: []
      }
      const dataFormatted = dataHistory.data?.cmdData?.fileList.map((date:any) => {
        console.log(dataHistory.data?.cmdData?.fileList);
        const [year, month, day, hour, min, seg] = (date.split('.')[0]).split('_')
        const segment = `${year}-${month}-${day}-${hour}`
        const interval = `${year}-${month}-${day} ${hour}:${min}:${seg}`
        if (!Object.prototype.hasOwnProperty.call(intervals, segment)) {
          // @ts-ignore
          intervals[segment] = {
            title: `${year}-${month}-${day} ${hour}:00:00`,
            times: []
          }
        }
        // @ts-ignore
        intervals[segment].times.push({ interval: interval, originalFile: date })
        return date
      })
      // @ts-ignore
      store.state.tracksolid.loading = false
      // @ts-ignore
      dataPayloadSocket.intervals = intervals
      store.commit('tracksolid/SET_DATA_VIDEO', dataPayloadSocket)
   }
    return ''
  }

  getResDrivers (): Driver[] {
    return [];
  }

  stoppedTime (): string {
    return ''
}

  async getSensors (): Promise<Sensors[]> {
    return [];
  }

  getCommandList (): ICommand[] {
    return [];
  }

  async executeCommand (payload: any): Promise<void> {
    // ---
  }

  batteryGPSLevel (): number {
    const level = this.unit_item.electQuantity ? parseInt(this.unit_item.electQuantity) : -1
    return level
  }

  batteryUnitLevel (): number {
      return -1
  }

  async automaticReport () {
    // --
  }

  initAutomaticReportObserver (): void {
    // --
  }

  stopAutomaticReportObserver (): void {
    // --
  }
}
