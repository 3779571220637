/* eslint-disable @typescript-eslint/no-explicit-any */

import Driver from '@/store/interfaces/Driver'
import getWialonDriveImage from './getWialonDriveImage'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default (resources:Array<number> = [], aditionalFlags = 0): Promise<Array<{ id:number, n: string, drivers: { [key: string]: Driver } }>> => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    // @ts-ignore
    const flags = wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.drivers | (aditionalFlags)
    sess.updateDataFlags(
      [{ type: 'type', data: 'avl_resource', flags: flags, mode: 0 }],
      (code: string) => {
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          let ress = [] // sess.getItems('avl_resource')

          // @ts-ignore
          if (resources.length) {
            for (let i = 0; i < resources.length; i++) {
              const res = sess.getItem(resources[i])
              ress.push(res)
            }
          } else {
            ress = sess.getItems('avl_resource')
          }

          if (!ress || !ress.length) {
            reject(Error('Not resources found!'))
          } else {
            const resourceDrivers = {}

            for (let i = 0; i < ress.length; i++) {
              const resource = ress[i]
              const drivers = resource?.getDrivers()
              if (drivers) {
                const driversArray = Object.values(drivers)

                // console.log(driversArray)
                for (let i = 0; i < driversArray.length; i++) {
                  // @ts-ignore
                  const driver: Driver = driversArray[i]
                  // @ts-ignore
                  if (!resourceDrivers[resource._id]) {
                    // @ts-ignore
                    resourceDrivers[resource._id] = {}
                  }
                  // @ts-ignore
                  if (!resourceDrivers[resource._id][driver.bu]) {
                    // @ts-ignore
                    resourceDrivers[resource._id][driver.bu] = []
                  }
                  // @ts-ignore
                  resourceDrivers[resource._id][driver.bu].push({
                    ...driver, image: getWialonDriveImage(resource._id, driver.id, driver.r)
                  })
                }
              }
            }

            // @ts-ignore
            resolve(resourceDrivers)
          }
        }
      })
  })
}
