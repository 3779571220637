/* eslint-disable @typescript-eslint/no-explicit-any */
import store from '@/store'
import ReportItemData from '../ReportItem/ReportItemData'
import UnitNotificationData from './UnitNotificationData'
// @ts-ignore
import { Duration } from 'luxon'
import { serializeError } from 'serialize-error'
import { ReportLayout } from '@/store/interfaces/ReportModules'

export default class UnitNotificationGroup {
  unitNotifications: UnitNotificationData<unknown>[]
  createdID: number
  locked: boolean
  openProtocoloOnInit: boolean
  openInMilliseconds: number | undefined
  reportItem: ReportItemData<unknown, unknown> | null

  // First unit properties
  public unitId: number
  public unitName: string
  public icon: string
  public notificationName: string
  public unitNotificationMessage: string
  public notificationDateFormatted: string

  constructor (unitNotification: UnitNotificationData<unknown>) {
    this.locked = false
    this.createdID = Date.now() + Math.random()
    this.unitNotifications = [unitNotification]
    this.openProtocoloOnInit = false
    this.reportItem = null
    this.getReportItemUnit()

    this.unitId = this.firstNotification.unitId
    this.unitName = this.firstNotification.unitName
    this.icon = this.firstNotification.icon
    this.notificationName = this.firstNotification.notificationName
    this.unitNotificationMessage = this.firstNotification.unitNotificationMessage
    this.notificationDateFormatted = this.firstNotification.notificationDateFormatted
  }

  public get firstNotification (): UnitNotificationData<unknown> {
    return this.unitNotifications[0]
  }

  public get restNotifications (): UnitNotificationData<unknown>[] {
    return this.unitNotifications.slice(1)
  }

  setOpenInMilliseconds (milliseconds: number) {
    this.openInMilliseconds = milliseconds
  }

  async initArgumentsData () {
    try {
      const args = await store.dispatch('console/getNotificationArgs', this.firstNotification.resourceNotification?.name)
      if (!args._.length) return;

      // Lista de argumentos
      const openProtocole = Boolean(args.op)

      if (args.tm) {
        const milliseconds = Duration.fromObject({ minutes: parseInt(args.tm || '0') }).as('milliseconds')
        store.dispatch('console/addEventNotification', {
          resNotification: this.firstNotification.resourceNotification,
          milliseconds,
          openProtocole
        })
      }

      this.openProtocoloOnInit = openProtocole
    } catch (error) {
      console.error(error)
      store.commit('sys/ADD_LOG', { title: 'NOTIFICATION_ARGS_ERROR', color: 'error', message: error, payload: serializeError(error) }, { root: true })
    }
  }

  getNotificationId (): number {
    return this.firstNotification.getNotificationId()
  }

  addNotification (unitNotification: UnitNotificationData<unknown>): void {
    this.unitNotifications.push(unitNotification)
  }

  removeNotification (unitNotification: UnitNotificationData<unknown>): void {
    const index = this.unitNotifications.findIndex(not => not.createdID === unitNotification.createdID)
    this.unitNotifications.splice(index, 1)
  }

  getResourceId (): number {
    return this.firstNotification.getResourceId()
  }

  getId (): number {
    return this.firstNotification.getId()
  }

  toggleLock (): void {
    this.locked = !this.locked
  }

  setLockState (state: boolean) {
    this.locked = state
  }

  getVariables (): any {
    const variables = {
      unit_name: this.firstNotification.getUnitName(),
      google_link: this.firstNotification.getGoogleLink(),
      notification: this.firstNotification.getMessage()
    }

    return variables
  }

  async getReportItemUnit (): Promise<ReportItemData<unknown, unknown> | null> {
    const reportItem = await store.dispatch('reports/getReportItemByID', { ri_wialon_id: this.getId() })
    this.reportItem = reportItem

    return this.reportItem
  }

  get reportLayout (): ReportLayout | undefined {
    // @ts-ignore
    const reports = store.state.reports.reports as ReportLayout[]
    const reportLayoutData = reports.find(rp => rp.unitData.id_report_group === this.reportItem?.id_report_group)

    return reportLayoutData
  }

  get reportGroupName (): string | undefined {
    return this.reportLayout?.unitData.rg_name
  }

  get reportGroupColor (): string | undefined {
    return this.reportLayout?.unitData.rg_color
  }

  getNotificationName () {
    return this.firstNotification.getNotificationName()
  }

  get resources () {
    // @ts-ignore
    return (this.firstNotification.resourceNotification?.resources ?? []).concat(this.unitNotifications.filter(obj => obj.resourceNotification?.resources).map(not => not.resourceNotification.resources).flat())
  }

  async openUnitInMap () {
    if (!this.reportItem) return false

    await store.dispatch('maps/displayRIOnMap', { id_report_item: this.reportItem.id_report_item })
    return true
  }
}
