import promiseRetry from 'promise-retry'
import { ActionContext } from 'vuex'
import { State } from '../state'
import Vue from 'vue'
import loginToken from '@/utils/wialonSDK/loginToken'
import { Geofence, ResourceGeofences } from '@/store/interfaces/Geofences'
import ConsoleGroup from '@/store/interfaces/ConsoleGroup'
import getAVLResourceItems from '@/utils/wialonSDK/getAVLResourceItems'
import Driver from '@/store/interfaces/Driver'
import gettingAVLUnitItems from '@/utils/wialonSDK/gettingAVLUnitItems'
import getSearchItems from '@/utils/wialonSDK/getSearchItems'
import ActualizerPlataform from '@/store/packages/Actualizer/ActualizerPlataform'
import RWialon from '@/store/packages/Actualizer/RWialon'
import AIWialon from '@/store/packages/Actualizer/AIWialon'
import getWialonDriveImage from '@/utils/wialonSDK/getWialonDriveImage'
import getAVLDriversEvents from '@/utils/wialonSDK/getAVLDriversEvents'
import getAVLResourceGeofences from '@/utils/wialonSDK/getAVLResourceGeofences'
import { serializeError } from 'serialize-error'
import RIWialon from '@/store/packages/ReportItem/RIWialon'
import { trycatch } from '@/utils/trycatch'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import sleep from '@/utils/sleep'
import logout from '@/utils/wialonSDK/logout'
import getReportTimeUnit from '@/utils/wialonSDK/getReportTimeUnit'
import getGeofencesIdByCoordinates from '@/utils/wialonSDK/getGeofencesIdByCoordinates'

export default {
  async init ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      dispatch(
        'sys/setLoaderDialogState',
        {
          isLoader: true,
          dialogMessage: 'Cargando librerias'
        },
        { root: true }
      )
      // @ts-ignore
      await Vue.loadScript('//hst-api.wialon.com/wsdk/script/wialon.js')
      // @ts-ignore
      const sess = wialon.core.Session.getInstance() // get instance of current Session
      await Promise.all([
        sess.initSession('https://hst-api.wialon.com'), // initialize Wialon session
        sess.loadLibrary('itemIcon'), // load Icon Library
        sess.loadLibrary('resourceNotifications'), // load Notification Library
        sess.loadLibrary('unitCommandDefinitions'), // load Commands Library
        sess.loadLibrary('unitSensors'), // load Sensor Library
        sess.loadLibrary('resourceDrivers'),
        sess.loadLibrary('resourceZones'), // load Geofences Library
        sess.loadLibrary('itemDriver') // load Geofences Library
      ])
      dispatch(
        'sys/setLoaderDialogState',
        {
          dialogMessage: 'Validando usuario'
        },
        { root: true }
      )
      await dispatch('login')
      sess.addListener('invalidSession', () => {
        dispatch('sys/showNotificationMessage', {
          title: 'La session de wialon a expirado, recargue el navegador',
          color: 'error',
          duration: -1
        }, { root: true });
        dispatch('sys/playSound', { type: 'alert' }, { root: true })
        commit('SET_STATE', { sessionState: false })
        // dispatch('refreshWialonSessionLogin')
      })
      commit('SET_STATE', { sessionState: true })
      await Promise.all([dispatch('getResourcesActualizer'), dispatch('getWResourcesData')])
      await Promise.all([dispatch('getDrivers'), dispatch('getGeofences')])
      await dispatch('initDriversHandlers')
    } catch (error) {
      commit('sys/ADD_LOG', { title: 'WIALON', color: 'error', message: 'SDK_INIT_ERROR', payload: serializeError(error) }, { root: true })
      console.error(error)
    }
  },
  async refreshWialonSessionLogin ({ dispatch, rootState, commit }: ActionContext<State, any>) {
    try {
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Recargando sesion de wialon',
          color: 'info',
          duration: -1
        }, { root: true }
      )
      await dispatch('logout')
      await dispatch('login')
      const groups_subscribed = (rootState.console.consoleGroups as ConsoleGroup[]).filter(cg => cg.keyListener)

      groups_subscribed.forEach(cg => {
        dispatch('console/suscribeNotificationGroup', cg.id_group, { root: true })
      })

      // @ts-ignore
      const sess = wialon.core.Session.getInstance()
      await trycatch(async () => await getReportTimeUnit(sess), null)

      dispatch('sys/cleanNotifications', {}, { root: true })
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Sesion de wialon recargada',
          color: 'success',
          duration: 10000
        }, { root: true }
      )
      commit('SET_STATE', { sessionState: true })
    } catch (error) {
      console.error(error)
      dispatch(
        'sys/showNotificationMessage',
        {
          title: 'Error al recargar la sesion de wialon',
          color: 'error',
          duration: -1
        }, { root: true }
      )
    }
  },
  async login ({ dispatch }: ActionContext<State, any>) {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance() // get instance of current Session
    await promiseRetry(
      async (retry, number) => {
        try {
          const wialonToken = await dispatch(
            'auth/getToken',
            PLATAFORM_TYPE.WIALON,
            { root: true }
          )
          // @ts-ignore
          return await loginToken(sess, wialonToken)
        } catch (error) {
          dispatch(
            'sys/setLoaderDialogState',
            {
              dialogMessage: `(${number}) Reintentado validar usuario`
            },
            { root: true }
          )
          return retry(error)
        }
      },
      { retries: 1, minTimeout: 500 }
    )
  },
  async logout (_: ActionContext<State, any>) {
    await logout()
    await sleep(3000)
  },

  async reloadGeofences (context: ActionContext<State, string>, { gp_wialon_id }: { gp_wialon_id: number }) {
    // @ts-ignore
    const [resGeofence] = await getAVLResourceGeofences([gp_wialon_id])
    const geofences = Object.values(resGeofence.geofences).map(g => ({ ...g, pType: PLATAFORM_TYPE.WIALON }))
    const rGeofence = {
      id: resGeofence.id,
      name: resGeofence.name,
      geofences: geofences,
      auxGeofences: geofences,
      inputSearch: '',
      pType: PLATAFORM_TYPE.WIALON
    }

    // @ts-ignore
    const rGeofences: ResourceGeofences[] = context?.rootState?.geofences?.geofences
    const index = rGeofences.findIndex(rg => rg.id === rGeofence.id)

    context.commit('geofences/SET_GEOFENCES_BY_INDEX', { rGeofence, index }, { root: true })
  },
  async getGeofences (context: ActionContext<State, string>): Promise<void> {
    // @ts-ignore
    const geofences = await context.dispatch('getGroupsGeofences')
    // @ts-ignore
    const resourcesGeofences: Array<ResourceGeofences> = geofences.map(function (geofence) {
      // @ts-ignore
      const geofences = Object.values(geofence.geofences).map(g => ({ ...g, pType: PLATAFORM_TYPE.WIALON }))
      return {
        id: geofence.id,
        name: geofence.name,
        geofences: geofences,
        auxGeofences: geofences,
        inputSearch: '',
        pType: PLATAFORM_TYPE.WIALON
      }
    })

    resourcesGeofences.forEach(rg => context.commit('geofences/PUSH_GEOFENCES', rg, { root: true }))
  },
  async getGroupsGeofences (context: ActionContext<State, string>): Promise<{
    id: number;
    name: string;
    geofences: Array<Geofence>;
  }[]> {
    const resources = context.state.resources
    const resources_geofences: Array<{ id: number, name: string, geofences: Array<Geofence> }> = []
    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i]
      resources_geofences.push({
        // @ts-ignore
        id: resource.getId(),
        // @ts-ignore
        name: resource.getName(),
        // @ts-ignore
        geofences: resource.getZones()
      })
    }
    return resources_geofences
  },
  async getResourcesActualizer (
    context: ActionContext<State, string>
  ): Promise<void> {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance();
    const units = await gettingAVLUnitItems(1024);
    const resourcesIds: number[] = [...new Set(units.map((u) => u.$$user_accountId))];
    const resources = await getSearchItems(sess, {
      // @ts-ignore
      flags: wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.base | wialon.item.Item.dataFlag.messages,
      itemsType: 'avl_resource',
      propValueMask: resourcesIds.join(',')
    });

    const unitsGroups = {};
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      // @ts-ignore
      const resourceID = unit.$$user_accountId || 'DESCONOCIDO';
      // @ts-ignore
      if (unitsGroups[`${resourceID}`]) {
        // @ts-ignore
        unitsGroups[`${resourceID}`].addUnit(new AIWialon(unit));
      } else {
        // @ts-ignore
        const resource = resources.find(r => r._id === resourceID);
        if (resource) {
          // @ts-ignore
          unitsGroups[`${resourceID}`] = new RWialon(resource, resourceID);
          // @ts-ignore
          unitsGroups[`${resourceID}`].addUnit(new AIWialon(unit));
        }
      }
    }

    const wialonActualizer = new ActualizerPlataform({
      name: 'WIALON',
      resourcesItem: Object.values(unitsGroups)
    });
    context.commit('actualizer/PUSH_RESOURCES_ACTUALIZER', wialonActualizer, { root: true });
  },
  async getWResourcesData (context: ActionContext<State, string>) {
    // @ts-ignore
    wialon.item.MDriver.registerDriverProperties()
    // @ts-ignore
    const consoleGroups: Array<ConsoleGroup> = context.rootState.console.consoleGroups
    const groupsWialon = consoleGroups.filter(cg => cg.id_plataform === PLATAFORM_TYPE.WIALON && cg.gp_wialon_id)
    const resources_id = groupsWialon.map(cGroup => cGroup.gp_wialon_id)
    const resources = await getAVLResourceItems(
      // @ts-ignore
      (wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.base | wialon.item.Resource.dataFlag.drivers | wialon.item.Resource.dataFlag.zones | wialon.item.Item.dataFlag.messages | wialon.item.MDriver.flags.driver),
      resources_id
    )
    context.commit('SET_RESOURCES', resources)
  },
  async getDrivers (context: ActionContext<State, string>) {
    const resources = context.state.resources
    const driversArray: Driver[] = []

    for (let j = 0; j < resources.length; j++) {
      const res = resources[j] // get loaded 'avl_resource's items
      // @ts-ignore
      const drivers = res.getDrivers() || {} // get loaded driver`s items from any resource
      for (const drv in drivers) {
        const driver: Driver = drivers[drv] // iterate all drivers
        driver.plataform = PLATAFORM_TYPE.WIALON
        driversArray.push(driver)
      }
    }

    const resourceDrivers = {}

    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i]
      // @ts-ignore
      const drivers = resource?.getDrivers()
      if (drivers) {
        const driversData = Object.values(drivers)

        // console.log(driversData)
        for (let i = 0; i < driversData.length; i++) {
          // @ts-ignore
          const driver: Driver = driversData[i]
          // @ts-ignore
          if (!resourceDrivers[resource._id]) {
            // @ts-ignore
            resourceDrivers[resource._id] = {}
          }
          // @ts-ignore
          if (!resourceDrivers[resource._id][driver.bu]) {
            // @ts-ignore
            resourceDrivers[resource._id][driver.bu] = []
          }
          // @ts-ignore
          resourceDrivers[resource._id][driver.bu].push({
            // @ts-ignore
            ...driver, image: getWialonDriveImage(resource._id, driver.id, driver.r)
          })
        }
      }
    }

    await context.commit('drivers/SET_DRIVERS', { drivers: resourceDrivers, pType: PLATAFORM_TYPE.WIALON }, { root: true })
    await context.dispatch('console/setDrivers', driversArray, { root: true })
  },
  async initDriversHandlers (context: ActionContext<State, string>): Promise<void> {
    // @ts-ignore
    const resourcesIDS = Object.keys(context.rootState.drivers.drivers[PLATAFORM_TYPE.WIALON]).map(id => parseInt(id))
    await getAVLDriversEvents(resourcesIDS, (data: any) => context.dispatch('drivers/onDriverChanged', data, { root: true }))
  },
  async createReportItemByPlataformID (context: ActionContext<State, string>, data: { id: any; pType: PLATAFORM_TYPE }): Promise<void> {
    // @ts-ignore
    const groups: ConsoleGroup[] = context.rootState.console.consoleGroups

    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    const unitItem = sess.getItem(data.id)
    if (unitItem && groups.length) {
      // @ts-ignore
      const group = groups.find(group => group.gp_wialon_id === `${unitItem.$$user_accountId}`)
      if (!group) return
      // @ts-ignore
      const reportItem = new RIWialon({}, { unitItem, type: data.pType, id_group: group?.id_group })
      // @ts-ignore
      return reportItem
    }
  },
  async getUnits (context: ActionContext<State, string>): Promise<any[]> {
    // @ts-ignore
    const units = await trycatch(() => gettingAVLUnitItems(1024), []);
    // @ts-ignore
    return units.map(obj => ({ ...obj, id: `${obj._id}`, name: obj.getName(), consoleUnit: context.rootState.manager.admin.units.find(unit => `${unit.ri_wialon_id}` === `${obj._id}`) }))
  },
  async getResources () {
    return await trycatch(() => getAVLResourceItems(), [])
  },
  async getAvaliableGeofencesByCoords ({ rootGetters }: ActionContext<State, string>, { resource_ids, lat, lng }: { resource_ids: number[]; lat: number; lng: number }) {
    const ids: number[] = await getGeofencesIdByCoordinates(resource_ids, lat, lng, [], 100)

    const geofences = resource_ids.map(resource_id => {
      const resGeofences: Geofence[] = rootGetters['geofences/geofencesItems'](PLATAFORM_TYPE.WIALON, { id_wialon: resource_id })
      return resGeofences.filter(geofence => ids.includes(geofence.id))
    }).flat()

    return geofences
  }
}
