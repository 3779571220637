import TrackSolidSDK from '@/submodules/tracksolidjavacriptsdk/dist';
import { io, Socket } from 'socket.io-client';
import { State } from './state';

export default {
  SET_SDK (state: State, sdk: TrackSolidSDK): void {
    state.sdk = sdk;
  },
  SET_DATA_VIDEO: (state: State, data_video: any): void => {
    // @ts-ignore
    state.data_video = data_video
  },
  CLEAN_TOKENS (state: State): void {
    state.lastTokens = { access_token: '', refresh_token: '' };
  }
};
