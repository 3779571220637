<template>
<div class="loaderLogo">
  <svg id="LogoLoader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1082.8 161.3">
    <g id="VectorNormal">
      <text class="path1" transform="translate(0 115.5)"><tspan xml:space="preserve">SUD - S    </tspan><tspan class="path2" x="573.8" y="0">L</tspan><tspan x="639.6" y="0">UTIONS</tspan></text>
    </g>
  </svg>
  <arrow-spinner class="arrow-circle" />
</div>
</template>

<script>
import Vue from 'vue'
import ArrowSpinner from '@/assets/vue-assets/ArrowSpinner'

export default Vue.extend({
  name: 'SUDLogoLoader',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ArrowSpinner
  }
})
</script>

<style lang="sass">
@import "../../sass/textBorderShadow"

.loaderLogo
  position: relative

.path1
  font-size: 138.55px
  font-family: FranklinGothic-Book, Franklin Gothic Book
  @include textBorderShadow(#fff, 5px, 5px, 10px)
  // box-sizing: border-box
  padding: 0 10px
  margin: 0 10px

.path2
  letter-spacing: -0.02em

.path3
  fill: #649d39
  // transform: translate(1.4 11.3)
  // animation: rotating 1s linear infinite

.arrow-circle
  position: absolute
  height: 85%
  top: 0%
  left: 40.5%
  animation: rotating 3s linear infinite

@keyframes rotating
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)

</style>
