import { ActionContext } from 'vuex';
import { State } from '../..';
import { IScheduleTurnPeriodType, IScheduleUserDone, IScheduleUserPeriod } from '@/store/interfaces/Schedule';
import ScheduleUserDone from '@/store/packages/Schedules/ScheduleUserDone';
import ScheduleUserPeriod from '@/store/packages/Schedules/ScheduleUserPeriod';

export default {
  initScheduleUserDone ({ dispatch, state, getters }: ActionContext<State, string>): void {
    dispatch('updateScheduleUserPeriodTimer')
    if (getters.areUserPeriodTypes) {
      setTimeout(() => {
        setInterval(() => {
          // Si no se a tomado ningun turno
          if (!state.schedulestate.currScheduleUserPeriodDone) {
            dispatch('sys/showNotificationMessage', {
              title: 'No se ha tomado ningun turno',
              duration: 10000,
              color: 'warning'
            }, { root: true })
            dispatch('sys/playSound', { type: 'done' }, { root: true })
          }
          // 5 minutos de espera para tomar un nuevo turno
        }, 240000)
        // 30 de delay para empezar a zonar la alerta
      }, 30000)
    }
  },
  async updateScheduleUserPeriodTimer ({ state, commit, dispatch }: ActionContext<State, string>): Promise<void> {
    if (!state.schedulestate.currScheduleUserPeriodDone) {
      commit('SET_SCHEDULE_STATE', { currFormatedLabelTime: '00:00' })
      return
    }
    commit('SET_SCHEDULE_STATE', { currFormatedLabelTime: state.schedulestate.currScheduleUserPeriodDone.getStartTimerLabel() })

    if (!state.schedulestate.currScheduleTimer.isRunning()) {
      // Cada minuto
      state.schedulestate.currScheduleTimer.addEventListener('minutesUpdated', function () {
        // Actualiza el label del timer para saber cuanto tiempo lleva en ese turno
        if (state.schedulestate.currScheduleUserPeriodDone) {
          commit('SET_SCHEDULE_STATE', { currFormatedLabelTime: state.schedulestate.currScheduleUserPeriodDone.getStartTimerLabel() })
        }

        // Alerta si se esta cerca de empezar un nuevo periodo o turno y alerta al usuario
        state.schedulestate.nearestScheduleUserPeriods.forEach((period, index) => {
          const prevPeriod = state.schedulestate.nearestScheduleUserPeriods.at(index - 1)
          const isSamePeriod = period.eventName === prevPeriod?.eventName

          // Si el turno nuevo esta a menos de 5 minutos y no se ha alertado y no es el mismo turno
          if (period.isNearThan(5) && !period.$schup_isNearNotification && !isSamePeriod) {
            // @ts-ignore
            period.updateProperty('$schup_isNearNotification', true)
            dispatch('sys/showNotificationMessage', {
              title: `${period.eventName} en menos de 5 minutos`,
              duration: -1,
              color: period.eventColor
            }, { root: true })
            dispatch('sys/playSound', { type: 'done' }, { root: true })
          }
        })
      });

      // Cada hora
      state.schedulestate.currScheduleTimer.addEventListener('hoursUpdated', function () {
        // Se actualizan los periodos cercanos
        dispatch('getNearestScheduleUserPeriods')
      });

      state.schedulestate.currScheduleTimer.start()
    }
  },
  async changeScheduleUserPeriod ({ dispatch, commit }: ActionContext<State, string>, id_schedule_turn_weekday_period_type: number): Promise<IScheduleUserDone> {
    try {
      const { data }: { data: IScheduleUserDone } = await dispatch(
        'sys/axios',
        {
          url: `schedule/user/done/change_period/${id_schedule_turn_weekday_period_type}`,
          method: 'PUT'
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { currScheduleUserPeriodDone: new ScheduleUserDone({ data }) })
      dispatch('updateScheduleUserPeriodTimer')

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CHANGE_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async doneScheduleUserPeriod ({ dispatch, commit }: ActionContext<State, string>, id_schedule_user_done: number): Promise<IScheduleUserDone> {
    try {
      const { data }: { data: IScheduleUserDone } = await dispatch(
        'sys/axios',
        {
          url: `schedule/user/done/end_period/${id_schedule_user_done}`,
          method: 'PUT'
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { currScheduleUserPeriodDone: null })
      dispatch('updateScheduleUserPeriodTimer')

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DONE_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getNearestScheduleUserPeriodsTypes ({ dispatch, commit }: ActionContext<State, string>): Promise<IScheduleTurnPeriodType[]> {
    try {
      const { data }: { data: IScheduleTurnPeriodType[] } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/done/get/nearest_periods_types',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { scheduleAvaliableUserPeriodsTypes: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_NEAREST_SCHEDULE_USER_PERIODS_TYPES', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getNearestScheduleUserPeriods ({ dispatch }: ActionContext<State, string>): Promise<IScheduleUserPeriod[]> {
    try {
      const { data }: { data: IScheduleUserPeriod[] } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/done/get/nearest_periods',
          method: 'GET'
        },
        { root: true }
      )

      dispatch('saveNearestSchedulePeriods', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_NEAREST_SCHEDULE_USER_PERIODS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async endLastPeriod ({ dispatch, commit }: ActionContext<State, string>): Promise<IScheduleUserDone | null> {
    try {
      const { data }: { data: IScheduleUserDone | null } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/done/end_last_period',
          method: 'PUT'
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { currScheduleUserPeriodDone: null })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DONE_LAST_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async last_current_period ({ dispatch, commit }: ActionContext<State, string>): Promise<IScheduleUserDone | null> {
    try {
      const { data }: { data: IScheduleUserDone | null } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/done/last_current_period',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { currScheduleUserPeriodDone: data ? new ScheduleUserDone({ data }) : null })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DONE_LAST_CURRENT_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getScheduleUserDonesByDates ({ dispatch, commit }: ActionContext<State, string>, { start_date, end_date }: { start_date: string; end_date: string }): Promise<IScheduleUserDone[]> {
    try {
      const { data }: { data: IScheduleUserDone[] } = await dispatch(
        'sys/axios',
        {
          url: `schedule/user/done/get/by_dates/${start_date}/${end_date}`,
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { scheduleUserDonePeriods: data.map(scheData => new ScheduleUserDone({ data: scheData })) })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_SCHEDULE_USER_DONES_BY_DATES', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async loadAvaliableUserSchedulePeriodTypes ({ dispatch, commit }: ActionContext<State, string>): Promise<void> {
    const scheduleAvaliableUserPeriodsTypes: IScheduleTurnPeriodType[] = await dispatch('getAllScheduleTurnPeriodType')
    commit('SET_SCHEDULE_STATE', { scheduleAvaliableUserPeriodsTypes })
  },
  async saveLastCurrentPeriod ({ dispatch, commit }: ActionContext<State, string>, payload: IScheduleUserDone | null): Promise<void> {
    try {
      commit('SET_SCHEDULE_STATE', { currScheduleUserPeriodDone: payload ? new ScheduleUserDone({ data: payload }) : null })
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'SAVE_CURRENT_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async saveNearestSchedulePeriods ({ dispatch, commit, state }: ActionContext<State, string>, payload: IScheduleUserPeriod[]): Promise<void> {
    try {
      const nearestScheduleUserPeriods = payload.map(scheData => new ScheduleUserPeriod({ data: scheData }))
      commit('SET_SCHEDULE_STATE', { nearestScheduleUserPeriods })

      const scheduleAvaliableUserPeriodsTypes: IScheduleTurnPeriodType[] = nearestScheduleUserPeriods.reduce((acc, curr) => {
        // Si no tiene period_type no se agrega
        if (!curr.period_type) return acc

        // Si el tipo ya existe no se agrega
        const isSameTypeSaved = acc.some(period => period.id_schedule_turn_weekday_period_type === curr.period_type?.id_schedule_turn_weekday_period_type)
        if (isSameTypeSaved) return acc

        acc.push(curr.period_type)

        return acc
      }, state.schedulestate.scheduleAvaliableUserPeriodsTypes)

      commit('SET_SCHEDULE_STATE', { scheduleAvaliableUserPeriodsTypes })
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'SAVE_NEAREST_SCHEDULE_PERIODS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
