import { ActionContext } from 'vuex'
import { State } from '../state'
import { IPlataformCommonPlaces } from '@/store/interfaces/Plataform'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData';

export default {
  async loadAnaliticsUnitCommonPlaces (
    { dispatch, commit }: ActionContext<State, any>,
    payload: { data: { units: { id_plataform: number, resource_ids: string[], unit_id: string }[] }; report_item: ReportItemData<unknown, unknown> }
  ) {
    commit('SET_COMMON_PLACES_STATE', { loading: true })
    try {
      const { data }: { data: IPlataformCommonPlaces } = await dispatch(
        'sys/axios',
        {
          url: 'plataform/analitics/units_commom_places',
          method: 'POST',
          data: payload.data
        },
        {
          root: true
        }
      )

      const unitData = data[payload.report_item.ri_wialon_id]

      if (unitData) {
        commit('SET_COMMON_PLACES_STATE', { places: unitData.results, reportItem: payload.report_item })

        dispatch('center', {
          lat: unitData.center[0], lng: unitData.center[1]
        })
        dispatch('zoom', 10)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ANALITICS_UNIT_COMMOM_PLACES', color: 'error', message: '', error }, { root: true })
      throw error
    } finally {
      commit('SET_COMMON_PLACES_STATE', { loading: false })
    }
  }
}
