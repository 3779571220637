import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import Driver from '@/store/interfaces/Driver'
import { ReportItemConfig, ReportLayout } from '@/store/interfaces/ReportModules'
import ResourceNotification, { RNRESOURCES_TYPES, RNResource } from '@/store/interfaces/ResourceNotification'
import UnitNotificationData from '@/store/packages/UnitNotification/UnitNotificationData'
import UnitNotificationGroup from '@/store/packages/UnitNotification/UnitNotificationGroup'
import UNMapon from '@/store/packages/UnitNotification/UNMapon'
import UNTrackSolid from '@/store/packages/UnitNotification/UNTrackSolid'
import UNWialon from '@/store/packages/UnitNotification/UNWialon'
import getAVLResourceItem from '@/utils/wialonSDK/getAVLResourceItem'
import { serializeError } from 'serialize-error'
import { Socket } from 'socket.io-client'
import { ActionContext } from 'vuex'
import { ConsoleState } from './state'
import { DateTime } from 'luxon'
// @ts-ignore
import yp from 'yargs-parser/browser'
import { downloadByBlob } from '@/utils/download'
import { INotificationItem } from '@/store/interfaces/Notification'
import timeFormating from '@/utils/timeFormating'

export default {
  init (context: ActionContext<ConsoleState, string>) {
    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    const groups = context.state.consoleGroups

    for (let index = 0; index < groups.length; index++) {
      const group = groups[index];
      socket.on(`group_report_item_config_changed_${group.id_group}`, data => context.dispatch('socket_groupReportItemConfigChanged', data))
    }

    context.dispatch('initIntervalEventsNotifications')
    context.dispatch('initInvervalUpdateNotificationsTimers')
  },
  /**
   * Updates the time of each unit notification in the console notifications state
   * every 5 seconds.
   */
  initInvervalUpdateNotificationsTimers (context: ActionContext<ConsoleState, string>) {
    setInterval(() => {
      context.state.consoleNotifications.forEach(not => {
        not.unitNotifications.forEach(un => {
          un.time = timeFormating(un.timer)
        })
      })
    }, 5000)
  },
  initIntervalEventsNotifications (context: ActionContext<ConsoleState, string>) {
    setInterval(() => {
      const notificationEvents = context.state.consoleNotificationsEvents
      for (let i = 0; i < notificationEvents.length; i++) {
        const notification = notificationEvents[i];
        if (notification.openInMilliseconds && notification.openInMilliseconds < DateTime.now().toMillis()) {
          // console.log('openInMilliseconds: ', notification.openInMilliseconds)
          // console.log('Now: ', DateTime.now().toMillis())
          // console.log('Diff: ', notification.openInMilliseconds - DateTime.now().toMillis())
          context.dispatch('addNotification', notification)
          context.commit('REMOVE_NOTIFICATION_EVENT', i)
        }
      }
    }, 5000)
  },
  cleanConsoleNotifications (context: ActionContext<ConsoleState, string>): void {
    const notifications = context.state.consoleNotifications.filter(not => not.locked)
    context.commit('SET_NOTIFICATIONS', notifications)
  },
  clearStore (context: ActionContext<ConsoleState, string>): void {
    context.commit('CLEAN_DRIVERS_LIST')
    context.commit('CLEAN_CONSOLE_GROUPS')
    context.commit('CLEAN_NOTIFICATIONS')
  },
  changeConsoleGroupColor (context: ActionContext<ConsoleState, string>, cgData: { id_group: number, gp_color: string }): void {
    const groupIndex = context.state.consoleGroups.findIndex(cGroup => cGroup.id_group === cgData.id_group)
    if (groupIndex !== -1) {
      context.commit('SET_CGROUP_COLOR', { groupIndex, gp_color: cgData.gp_color })
    }
  },
  toggleStepper: (context: ActionContext<ConsoleState, string>, notification?: UnitNotificationData<unknown>): void => {
    if (notification) {
      context.commit('SET_STEPPER_DATA', notification)
      context.commit('TOGGLE_STEPPER')
    } else {
      context.commit('TOGGLE_STEPPER')
    }
  },
  async addNotification (context: ActionContext<ConsoleState, string>, resourceNotification: ResourceNotification<any> | UnitNotificationGroup): Promise<void> {
    const notification = (() => {
      if (resourceNotification instanceof UnitNotificationGroup) return resourceNotification;

      switch (resourceNotification.pType) {
        case PLATAFORM_TYPE.WIALON: {
          // @ts-ignore
          const sess = wialon.core.Session.getInstance()
          // @ts-ignore
          const notificationData = resourceNotification
          const unit = sess.getItem(notificationData.unit)
          if (!unit) return
          notificationData.unitData = unit
          return new UNWialon(resourceNotification)
        }
        case PLATAFORM_TYPE.MAPON: {
          return new UNMapon(resourceNotification)
        }
        case PLATAFORM_TYPE.TRACKSOLID: {
          return new UNTrackSolid(resourceNotification)
        }
      }
    })()
    if (!notification) return

    for (let i = 0; i < context.state.consoleNotifications.length; i++) {
      const notificationGroup = context.state.consoleNotifications[i]
      const isSameResource = notificationGroup.getResourceId() === notification.getResourceId()
      const isSameNotification = notificationGroup.getNotificationId() === notification.getNotificationId()
      const isSameUnit = notificationGroup.getId() === notification.getId()
      if (isSameResource && isSameNotification && isSameUnit) {
        if (notification instanceof UnitNotificationGroup) {
          notificationGroup.addNotification(notification.firstNotification)
        } else {
          notificationGroup.addNotification(notification)
        }
        return
      }
    }
    if (notification instanceof UnitNotificationGroup) {
      notification.firstNotification.playNotificationAudio()
      context.commit('ADD_NOTIFICATION', notification)
    } else {
      const groupNotification = new UnitNotificationGroup(notification)
      groupNotification.initArgumentsData()
      groupNotification.firstNotification.playNotificationAudio()
      context.commit('ADD_NOTIFICATION', groupNotification)
    }
  },
  addEventNotification (context: ActionContext<ConsoleState, string>, { resNotification, milliseconds, openProtocole }: { resNotification: ResourceNotification<any>; milliseconds: number; openProtocole: boolean; }) {
    const timeNotificationDisplay = DateTime.now().plus({ milliseconds }).toMillis()
    // console.log('Millis recibidos: ', milliseconds)
    // console.log('timeNotificationDisplay: ', timeNotificationDisplay)
    // console.log('Diff time: ', timeNotificationDisplay - DateTime.now().toMillis())
    let notData = null
    switch (resNotification.pType) {
      case PLATAFORM_TYPE.WIALON: {
        notData = new UNWialon(resNotification)
        break;
      }
      case PLATAFORM_TYPE.MAPON: {
        notData = new UNMapon(resNotification)
        break;
      }
      case PLATAFORM_TYPE.TRACKSOLID: {
        notData = new UNTrackSolid(resNotification)
        break;
      }
    }
    if (!notData) return;
    // @ts-ignore
    const notification = new UnitNotificationGroup(notData)
    notification.openProtocoloOnInit = openProtocole
    notification.setOpenInMilliseconds(timeNotificationDisplay)
    context.commit('ADD_NOTIFICATION_EVENT', notification)
  },
  getNotificationArgs (context: ActionContext<ConsoleState, string>, name: string) {
    const input = typeof name !== 'string' ? '' : name
    const args = yp(input, {
      alias: {
        tm: 'time', // Tiempo que sonara la notificacion pasado el tiempo (minutos) especificado
        op: 'openProtocole', // Indica si debe abrirse el stepper nada mas llegar la notificacion
        sosl: 'showOnSignalLost' //  Mostrara la notificacion si la unidad perdio señal sobre una geocerca con esta variable
      }
    });

    return args
  },
  removeNotification (context: ActionContext<ConsoleState, string>, notGroup: UnitNotificationGroup): void {
    const notGruopIndex = context.state.consoleNotifications.findIndex(not => not.createdID === notGroup.createdID)
    if (notGruopIndex > -1) {
      context.commit('REMOVE_NOTIFICATION', notGruopIndex)
    } else {
      context.dispatch('sys/showNotificationMessage', {
        title: 'A ocurrido un error en la aplicacion',
        color: 'error'
      }, { root: true })
    }
  },
  setKeyListenerId (context: ActionContext<ConsoleState, string>, groupData: { gp_wialon_id: number, keyListener: string }): void {
    const groupIndex = context.state.consoleGroups.findIndex(group => group.gp_wialon_id === groupData.gp_wialon_id)
    context.commit('SET_KEY_LISTENER', { groupIndex, keyListener: groupData.keyListener })
  },
  async unsuscribeNotificationGroup (context: ActionContext<ConsoleState, string>, id_group: number): Promise<void> {
    try {
      const groupIndex = context.state.consoleGroups.findIndex(groupData => groupData.id_group === id_group)
      const group = context.state.consoleGroups[groupIndex]
      switch (group.id_plataform) {
        case PLATAFORM_TYPE.WIALON: {
          // @ts-ignore
          const sess = wialon.core.Session.getInstance()
          await getAVLResourceItem(sess)
          const res = sess.getItem(group.gp_wialon_id)
          res.removeListenerById(group.keyListener)
          context.commit('CLEAN_KEY_LISTENER', groupIndex)
          break;
        }
        case PLATAFORM_TYPE.MAPON: {
          context.commit('CLEAN_KEY_LISTENER', groupIndex)
          const isMaponNotificationInitiated = context.state.consoleGroups.filter(gp => gp.id_plataform === PLATAFORM_TYPE.MAPON).some(gp => gp.keyListener)
          if (!isMaponNotificationInitiated) {
            context.dispatch('mapon/initNotifications', false, { root: true })
          }
          break;
        }
        case PLATAFORM_TYPE.TRACKSOLID: {
          context.commit('CLEAN_KEY_LISTENER', groupIndex)
          break;
        }
      }
      context.dispatch('sys/showNotificationMessage', {
        title: `Desuscrito a ${group.gp_name}`,
        color: 'info'
      }, { root: true })
    } catch (error) {
      console.error(error)
    }
  },
  async suscribeNotificationGroup (context: ActionContext<ConsoleState, string>, id_group: number): Promise<void> {
    try {
      const groupIndex = context.state.consoleGroups.findIndex(group => group.id_group === id_group)
      if (groupIndex === -1) return

      const group = context.state.consoleGroups[groupIndex]
      switch (group.id_plataform) {
        case PLATAFORM_TYPE.WIALON: {
          if (!group.keyListener) {
            const res = await getAVLResourceItem(group.gp_wialon_id) // Info de un grupo
            // @ts-ignore
            const keyListener = res.addListener('messageRegistered', async (event) => {
              await context.dispatch('addNotification', { ...event.getData(), pType: PLATAFORM_TYPE.WIALON })
            })
            context.commit('SET_KEY_LISTENER', { groupIndex, keyListener })
          }
          break;
        }
        case PLATAFORM_TYPE.MAPON: {
          const isMaponNotificationInitiated = context.state.consoleGroups.filter(gp => gp.id_plataform === PLATAFORM_TYPE.MAPON).some(gp => gp.keyListener)
          if (!isMaponNotificationInitiated) {
            context.dispatch('mapon/initNotifications', true, { root: true })
          }
          if (!group.keyListener) {
            context.commit('SET_KEY_LISTENER', { groupIndex, keyListener: 'SUBSCRIBED' })
          }
          break;
        }
        case PLATAFORM_TYPE.TRACKSOLID: {
          if (!group.keyListener) {
            context.commit('SET_KEY_LISTENER', { groupIndex, keyListener: 'SUBSCRIBED' })
          }
          break;
        }
      }
      context.dispatch('sys/showNotificationMessage', {
        title: `Suscrito a ${group.gp_name}`,
        color: 'success'
      }, { root: true })
    } catch (error) {
      context.dispatch('sys/showNotificationMessage', {
        icon: 'mdi-alert-outline',
        title: 'No se pudo suscribir al grupo',
        color: 'error'
      }, { root: true })
    }
  },
  setDrivers (context: ActionContext<ConsoleState, string>, driversList: Array<Driver>): void {
    context.commit('SET_DRIVERS', driversList)
  },
  async getConsoleGroups (context: ActionContext<ConsoleState, string>): Promise<void> {
    try {
      const data = await context.dispatch(
        'sys/axios',
        {
          url: 'get_groups',
          method: 'get'
        },
        { root: true }
      )
      context.commit('SET_GROUPS', data.data.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async getStepperData (context: ActionContext<ConsoleState, string>, payload: { id_group: number; gs_plataform_notification_id: string }): Promise<void> {
    try {
      const data = await context.dispatch(
        'sys/axios',
        {
          url: 'steps/stepper/get/by_group_and_notification',
          method: 'post',
          data: payload
        },
        { root: true }
      )

      return data.data
    } catch (error) {
      console.error(error)
      context.commit('sys/ADD_LOG', { title: 'ENDPOINT_ERROR', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  isSubscribedToGroup (context: ActionContext<ConsoleState, string>, id_group: number) {
    const index = context.state.consoleGroups.findIndex(gp => gp.id_group === id_group)
    if (index === -1) return false
    const group = context.state.consoleGroups[index]
    if (group.keyListener) {
      return true
    } else {
      return false
    }
  },
  socket_groupReportItemConfigChanged (context: ActionContext<ConsoleState, string>, { id_group, ...ri_config }: ReportItemConfig & { id_group: number }) {
    // @ts-ignore
    const reports: ReportLayout[] = context.rootState.reports.reports
    const report_groups = reports.filter(report => report.unitData.id_group === id_group)
    report_groups.forEach(rg => {
      rg.unitData.ri_items.forEach(ri => {
        ri.ri_config = ri_config
        ri.onUnitDataChanged()
      })
    })
  },
  async getResourceItemNotification ({ dispatch }: ActionContext<ConsoleState, string>, res: RNResource) {
    switch (res.type) {
      case RNRESOURCES_TYPES.HV_PICTURE_ID: {
        const img = await dispatch('hikvision/getPictureById', { picid: res.url, name: res.name }, { root: true })
        downloadByBlob(img, `${res.name}-${DateTime.now().toLocal().toString()}.jpeg`)
        break
      }
      case RNRESOURCES_TYPES.HV_VIDEO_ID: {
        const video = await dispatch('hikvision/getVideoById', { picid: res.url, name: res.name }, { root: true })
        downloadByBlob(video, `${res.name}-${DateTime.now().toLocal().toString()}.mp4`)
        break
      }
    }
  },
  openConsoleDraggable ({ commit }: ActionContext<ConsoleState, string>) {
    commit('SET_STATE', { consoleDraggable: true })
  },
  async createNotification ({ dispatch }: ActionContext<ConsoleState, string>, payload: Partial<INotificationItem>) {
    try {
      const { data }: { data: INotificationItem } = await dispatch(
        'sys/axios',
        {
          url: 'notifications/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_NOTIFICATION_ITEM', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getAllNotifications ({ dispatch }: ActionContext<ConsoleState, string>) {
    try {
      const { data }: { data: INotificationItem } = await dispatch(
        'sys/axios',
        {
          url: 'notifications/get/all',
          method: 'GET'
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_NOTIFICATIONS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
