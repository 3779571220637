/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default (sess: any, config: {
  propValueMask: string;
  itemsType: string;
  flags: number;
}) => {
  return new Promise<any[]>((resolve, reject) => {
    // @ts-ignore
    sess.searchItems(
      {
        itemsType: config.itemsType,
        propName: 'sys_id',
        propValueMask: config.propValueMask,
        sortType: 'sys_name',
        propType: 'property',
        or_logic: false
      },
      0,
      config.flags,
      0,
      0,
      function (code: string, data: any) {
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code));
        } else {
          resolve(data.items);
        }
      }
    );
  });
};
