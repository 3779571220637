import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import getSecondsDiffFromNow from '@/utils/getSecondsDiffFromNow'
import ActualizerItemData from './ActualizerItemData'

export default class AIWialon extends ActualizerItemData<any> {
  pType: PLATAFORM_TYPE

  constructor (unit_item: any) {
    super(unit_item)
    this.pType = PLATAFORM_TYPE.WIALON
  }

  getSecondsDiff (): number {
    const diff = getSecondsDiffFromNow(this.unit_item.getLastMessage() ? this.unit_item.getLastMessage().t : 0)
    return diff
  }

  getPlataformId (): number {
    return this.unit_item.getId()
  }

  getName (): string {
    return this.unit_item.getName()
  }

  getResourceId (): number {
    return this.unit_item?.getAccountId() || 0
  }

  getIcon (): string {
    return this.unit_item.getIconUrl()
  }
}
