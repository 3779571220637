import { ActionContext } from 'vuex';
import { State } from '../state';
import { IScheduleEvent } from '@/store/interfaces/Schedule';

export default {
  async getSchedulesEventsByDates ({ dispatch }: ActionContext<State, string>, payload: { sche_start: string; sche_end: string }): Promise<IScheduleEvent[]> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/event/get/by_dates',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_SCHEDULES_BY_DATES', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createScheduleEvent ({ dispatch }: ActionContext<State, string>, payload: Partial<IScheduleEvent>): Promise<IScheduleEvent> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/event/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_SCHEDULE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteScheduleEvent ({ dispatch }: ActionContext<State, string>, id_schedule_event: number): Promise<{ id_schedule_event: number }> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `schedule/event/delete/${id_schedule_event}`,
          method: 'DELETE'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_SCHEDULE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateScheduleEvent ({ dispatch }: ActionContext<State, string>, payload: Partial<IScheduleEvent>): Promise<IScheduleEvent> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/event/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_SCHEDULE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
