import { IScheduleTurn } from '@/store/interfaces/Schedule';
import BaseClass from '../BaseClass';
import ScheduleTurnPeriod from './ScheduleTurnPeriod';
import Vue from 'vue';

export class ScheduleTurn extends BaseClass<IScheduleTurn> implements IScheduleTurn {
  id_company!: number
  id_schedule_turn!: number
  scht_name!: string
  scht_color!: string
  scht_createdAt!: string
  periods!: ScheduleTurnPeriod[]

  public onInitInstance (): void {
    Vue.set(this, 'periods', this.periods.map((period) => new ScheduleTurnPeriod({ data: period })));
  }
}
