import { Geofence } from '@/store/interfaces/Geofences'

/* eslint-disable @typescript-eslint/no-explicit-any */
export default async function (resources_id = [], aditionalFlags = 0): Promise<Array<{ id: number, name: string, geofences: Array<Geofence> }>> {
  return new Promise(function (resolve, reject) {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    // flags to specify what kind of data should be returned
    // @ts-ignore
    const flags = wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.zones | (aditionalFlags)
    const data = resources_id.length ? resources_id : 'avl_resource'
    const type = resources_id.length ? 'col' : 'type'
    sess.updateDataFlags( // load items to current session
      [{ type, data, flags, mode: 1 }],
      function (code: string) { // updateDataFlags callback
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          if (resources_id.length) {
            const resources_geofences: Array<{ id: number, name: string, geofences: Array<Geofence> }> = []
            for (let i = 0; i < resources_id.length; i++) {
              const resource_id = resources_id[i]
              const resource = sess.getItem(resource_id)
              if (resource) {
                resources_geofences.push({
                  // @ts-ignore
                  id: resource.getId(),
                  name: resource.getName(),
                  geofences: resource.getZones()
                })
              }
            }

            resolve(resources_geofences)
          } else {
            const res = sess.getItems('avl_resource')
            if (!res || !res.length) {
              reject(Error('No resources found'))
            } else {
              const geofences: Array<{ id: number, name: string, geofences: Array<Geofence> }> = res.map(function (resource: { getId: () => any; getName: () => any; getZones: () => any }) {
                return {
                  id: resource.getId(),
                  name: resource.getName(),
                  geofences: resource.getZones()
                }
              })

            console.log(geofences)
            resolve(geofences)
            }
          }
        }
      })
  })
}
