import { IScheduleTurnPeriodType, IScheduleUserPeriod } from '@/store/interfaces/Schedule';
import BaseClass from '../BaseClass';
import { DateTime } from 'luxon';
import { IUser } from '@/store/interfaces/Manager.interface';
import Vue from 'vue';
import ICalendarEvent from '@/store/interfaces/vuetify/CalendarEvent';
import formatLuxonDate from '@/utils/formatLuxonDate';
import secondsToHumanHours from '@/utils/secondsToHumanHours';

export default class ScheduleUserPeriod extends BaseClass<IScheduleUserPeriod> implements IScheduleUserPeriod, ICalendarEvent {
  id_schedule_user_period!: number;
  schup_title!: string;
  schup_date!: string;
  schup_start!: string;
  schup_end!: string;
  id_schedule_turn!: number;
  id_schedule_turn_weekday_period!: number;
  id_schedule_turn_weekday_period_type!: number;
  id_user!: number;
  schu_createdAt!: string;
  user!: Pick<IUser, 'id_user' | 'id_user_type' | 'us_name' | 'ut_name' | 'us_color'>
  period_type!: IScheduleTurnPeriodType | null;

  eventStartTimestamp!: number;
  eventEndTimestamp!: number;
  eventTimed!: boolean;
  $schup_isNearNotification!: boolean;

  public onInitInstance (): void {
    this.eventTimed = true
    this.updateTimestamps();
    this.$schup_isNearNotification = false
  }

  public get secondsPeriodTime (): number {
    const start = DateTime.fromISO(this.getStartISOstring());
    const end = DateTime.fromISO(this.getEndISOstring());
    const diff = end.diff(start, 'seconds').seconds
    if (diff <= 0) return 0
    return diff
  }

  public get eventId (): number {
    return this.id_schedule_user_period
  }

  public get eventColor (): string {
    return this?.period_type?.stwpt_color || '#eeeeee'
  }

  public get eventName (): string {
    return this.schup_title
  }

  public get eventDate (): string {
    return DateTime.fromMillis(this.eventStartTimestamp).toFormat('yyyy-MM-dd')
  }

  public rehidratateData (data: IScheduleUserPeriod): void {
    super.rehidratateData(data)
    if (data.period_type) {
      Vue.set(this, 'period_type', data.period_type);
    }
  }

  public isNearThan (dffMinutes = 5): boolean {
    const startDatetime = DateTime.fromISO(this.getStartISOstring());
    const now = DateTime.now();
    const diffInMinutes = startDatetime.diff(now, 'minutes').minutes
    if (diffInMinutes <= 0) return false

    return diffInMinutes <= dffMinutes
  }

  public get isDeletable (): boolean {
    return DateTime.now().minus({ days: 14 }).toMillis() < this.eventStartTimestamp
  }

  public get us_color (): string {
    return this.user?.us_color || 'disable'
  }

  public get labelTimePeriod (): string {
    return `${this.schup_start.slice(0, -3)} - ${this.schup_end.slice(0, -3)}`
  }

  public get us_name (): string {
    return this.user?.us_name
  }

  public getStartISOstring (): string {
    return `${this.schup_date}T${this.schup_start}`;
  }

  public getEndISOstring (): string {
    return `${this.schup_date}T${this.schup_end}`;
  }

  public get startFormatted (): string {
    return formatLuxonDate(this.getStartISOstring())
  }

  public get endFormatted (): string {
    return formatLuxonDate(this.getEndISOstring())
  }

  public get humanPeriodTime (): string {
    return secondsToHumanHours(this.secondsPeriodTime)
  }

  public updateTimestamps (): void {
    const startISOstring = this.getStartISOstring();
    const endISOstring = this.getEndISOstring();

    // Crea un objeto DateTime con Luxon
    // @ts-ignore
    this.eventStartTimestamp = DateTime.fromISO(startISOstring).toMillis();
    // @ts-ignore
    this.eventEndTimestamp = DateTime.fromISO(endISOstring).toMillis();
  }
}
