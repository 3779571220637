import { ActionContext } from 'vuex';
import { State } from '..';
import { IClassification } from '@/store/interfaces/Manager.interface'

export default {
  async getAllClassifications ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/classification/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { classifications: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_CLASSIFICATIONS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getById ({ dispatch }: ActionContext<State, string>, id_clasificacion: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/classification/get/${id_clasificacion}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_BY_ID_CLASSIFICATIONS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createClassification ({ dispatch, commit }: ActionContext<State, string>, payload: IClassification) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/classification/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('UNSHIFT_CLASSIFICATION', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_CLASSIFICATIONS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateClasification ({ dispatch, commit, state }: ActionContext<State, string>, payload: IClassification) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/classification/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.admin.classifications.findIndex(obj => obj.id_clasificacion === payload.id_clasificacion)

      if (index !== -1) {
        commit('UPDATE_CLASSIFICATION', { index, data })
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_CLASSIFICATIONS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteClasification ({ dispatch, commit, state }: ActionContext<State, string>, id_clasificacion: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/classification/delete/${id_clasificacion}`,
          method: 'DELETE'
        },
        { root: true }
      )

      const index = state.admin.classifications.findIndex(obj => obj.id_clasificacion === id_clasificacion)

      if (index !== -1) {
        commit('DELETE_CLASSIFICATION', index)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_CLASSIFICATIONS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
