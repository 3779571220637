<template lang="pug">
transition(name="fade")
  .loaderDialog(v-show="$store.state.sys.loaderDialog.isLoader")
    .loaderContainer
      .logo_loader: img.logo_bar(src="/images/.env.files/VARUNA_1024X200.png")
      .loaderInfoText
        | {{ $store.state.sys.loaderDialog.dialogMessage }}
        v-progress-circular.ml-3(:size="$vuetify.breakpoint.mdAndDown ? 40 : 70" :width="$vuetify.breakpoint.mdAndDown ? 4 : 7" color="white" indeterminate)

</template>

<script>
import Vue from 'vue'
import SUDLogoLoader from '@/assets/vue-assets/SUDLogoLoader'

export default Vue.extend({
  name: 'LoaderDialog',
  components: {
    SUDLogoLoader
  }
})
</script>

<style lang="sass">
.loaderDialog
    position: fixed
    min-width: 100vw
    min-height: 100vh
    max-width: 100vw
    max-height: 100vh
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(0,0,0, .85)
    z-index: 100
    overflow: hidden

    .loaderContainer
      width: 75%

      .logo_loader
        width: 100%

        .logo_bar
          width: 100%
          object-fit: contain

      .loaderInfoText
        text-align: center
        color: white
        font-size: 25px
        font-weight: 100
</style>
