import { point, lineString, buffer, booleanPointInPolygon, helpers } from '@turf/turf';

// Hecho con phind
export function isPointOnLinearGeofence (lat: number, lon: number, coords: helpers.Position[], width: number) {
  // Crear un punto geoespacial
  const punto = point([lon, lat]);

  // Crear una línea geoespacial
  const linea = lineString(coords);

  // Crear una geocerca (buffer) alrededor de la línea
  const geocerca = buffer(linea, width, { units: 'meters' });

  // Verificar si el punto está dentro de la geocerca
  return booleanPointInPolygon(punto, geocerca);
}
