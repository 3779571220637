import getAVLResourceGeofences from '@/utils/wialonSDK/getAVLResourceGeofences'
import { ActionContext } from 'vuex'
import { Geofence, ICGeofence, ResourceGeofences } from '@/store/interfaces/Geofences'
import { ReportLayout } from '@/store/interfaces/ReportModules'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { serializeError } from 'serialize-error'
import ConsoleGroup from '@/store/interfaces/ConsoleGroup'
import { Socket } from 'socket.io-client'

export interface RouteDestination {
  id_route_destinations: number
  rd_geofences: Array<{ id: number, n: string; pType?: PLATAFORM_TYPE }>
  rd_name: string
  geofences?: Geofence[]
}

interface GeofencesState {
  geofences: Array<ResourceGeofences>,
  geofencesCrud: {
    modal: boolean,
    id_group: number
  },
  routesDestinationsGeofences: Array<{
    id_group: number;
    id_plataform: number;
    route_destinations: Array<RouteDestination>
  }>,
  editRoute: {
    isEditRouteDestination: false,
    routeDestination: RouteDestination
  }
}

const state: GeofencesState = {
  geofences: [],
  geofencesCrud: {
    modal: false,
    id_group: 0
  },
  routesDestinationsGeofences: [],
  editRoute: {
    isEditRouteDestination: false,
    routeDestination: {
      id_route_destinations: 0,
      rd_geofences: [],
      rd_name: ''
    }
  }
}

const mutations = {
  SET_MODAL_STATE (state: GeofencesState, payload: {
    modal: boolean
    id_group: number
  }): void {
    state.geofencesCrud = payload
  },
  UPDATE_CONSOLE_GEOFENCE (state: GeofencesState, { geofence, geofence_index, index }: { geofence: Geofence; index: number; geofence_index: number }): void {
    Object.assign(state.geofences[index].geofences[geofence_index], geofence)
    Object.assign(state.geofences[index].auxGeofences[geofence_index], geofence)
  },
  SET_GEOFENCES_BY_INDEX (state: GeofencesState, { rGeofence, index }: { rGeofence: ResourceGeofences; index: number }): void {
    state.geofences[index].geofences = rGeofence.geofences
    state.geofences[index].auxGeofences = rGeofence.auxGeofences
  },
  UPDATE_GEOFENCES (state: GeofencesState, { index, geofences }: { index: number; geofences: ResourceGeofences }): void {
    state.geofences[index] = geofences
  },
  SET_GEOFENCES (state: GeofencesState, geofences: Array<ResourceGeofences>): void {
    state.geofences = geofences
  },
  PUSH_GEOFENCES (state: GeofencesState, geofences: ResourceGeofences) {
    const index = state.geofences.findIndex(obj => obj.id === geofences.id && obj.pType === geofences.pType)
    if (index === -1) {
      state.geofences.push(geofences)
    } else {
      Object.assign(state.geofences[index], geofences)
    }
  },
  SET_AUX_GEOFENCES (state: GeofencesState, geoPayload: { resource_index: number, geofences?: Array<Geofence> }): void {
    const resource = state.geofences[geoPayload.resource_index]
    if (geoPayload.geofences) {
      resource.auxGeofences = geoPayload.geofences
    } else {
      resource.auxGeofences = resource.geofences
    }
  },
  SET_INPUT_VALUE (state: GeofencesState, val: { resource_index: number, value: string }): void {
    state.geofences[val.resource_index].inputSearch = val.value
  },
  SET_ROUTES_DESTINATIONS (state: GeofencesState, routeDestinations: Array<{ id_group: number, id_plataform: number; route_destinations: Array<RouteDestination> }>): void {
    state.routesDestinationsGeofences = routeDestinations
  },
  ADD_ROUTE_DESTINATION (state: GeofencesState, rdPayload: { route_destination_index: number, route_destination: RouteDestination }): void {
    state.routesDestinationsGeofences[rdPayload.route_destination_index].route_destinations.push(rdPayload.route_destination)
  },
  UPDATE_ROUTE_DESTINATION (state: GeofencesState, rdPayload: { route_destinations_index: number, group_index: number, route_destination: RouteDestination }): void {
    const obj1 = state.routesDestinationsGeofences[rdPayload.group_index].route_destinations[rdPayload.route_destinations_index]
    const obj2 = rdPayload.route_destination
    Object.assign(obj1, obj2)
  },
  REMOVE_ROUTE_DESTINATION (state: GeofencesState, rdPayload: { group_index: number, route_destinations_index: number }): void {
    state.routesDestinationsGeofences[rdPayload.group_index].route_destinations.splice(rdPayload.route_destinations_index, 1)
  }
}

const actions = {
  init (context: ActionContext<GeofencesState, string>) {
    // @ts-ignore
    const console_groups: ConsoleGroup[] = context.rootState.console.consoleGroups

    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    socket.on('geofence_console_created', data => context.dispatch('socket_geofenceConsoleCreated', data))
    socket.on('geofence_console_updated', data => context.dispatch('socket_geofenceConsoleUpdated', data))
    socket.on('geofence_console_deleted', data => context.dispatch('socket_geofenceConsoleDeleted', data))
    socket.on('geofence_console_created_many', data => context.dispatch('socket_geofenceConsoleCreatedMany', data))

    for (let i = 0; i < console_groups.length; i++) {
      const console_group = console_groups[i]
      socket.on(`geofence_route_destination_created_${console_group.id_group}`, data => context.dispatch('socket_geofencesRouteDestinationCreated', data))
      socket.on(`geofence_route_destination_edit_${console_group.id_group}`, data => context.dispatch('socket_geofenceRouteDestinationEdit', data))
      socket.on(`geofence_route_destination_deleted_${console_group.id_group}`, data => context.dispatch('socket_geofenceRuteDestinationDeleted', data))
    }
  },
  socket_geofenceConsoleCreatedMany ({ state, commit }: ActionContext<GeofencesState, string>, geofences: ICGeofence[]) {
    for (let i = 0; i < geofences.length; i++) {
      const geofence = geofences[i];
      const index = state.geofences.findIndex(rG => rG.id === geofence.id_group && rG.pType === PLATAFORM_TYPE.CONSOLA)
      const geoFormated = {
        id: geofence.id_geofence,
        n: geofence.geo_name,
        d: geofence.geo_description,
        c: geofence.geo_color,
        geo_payload: geofence.geo_payload,
        pType: PLATAFORM_TYPE.CONSOLA
      }
      if (index !== -1) {
        // @ts-ignore
        state.geofences[index].auxGeofences.push(geoFormated)
        // @ts-ignore
        state.geofences[index].geofences.push(geoFormated)
      } else {
        const rGeofences = {
          id: geofence.id_group,
          name: '',
          geofences: [geoFormated],
          auxGeofences: [geoFormated],
          inputSearch: '',
          pType: PLATAFORM_TYPE.CONSOLA
        }
        commit('PUSH_GEOFENCES', rGeofences)
      }
    }
  },
  socket_geofenceConsoleCreated ({ state, commit }: ActionContext<GeofencesState, string>, geofence: ICGeofence) {
    const index = state.geofences.findIndex(rG => rG.id === geofence.id_group && rG.pType === PLATAFORM_TYPE.CONSOLA)
    const geoFormated = {
      id: geofence.id_geofence,
      n: geofence.geo_name,
      d: geofence.geo_description,
      c: geofence.geo_color,
      geo_payload: geofence.geo_payload,
      pType: PLATAFORM_TYPE.CONSOLA
    }
    if (index !== -1) {
      // @ts-ignore
      state.geofences[index].auxGeofences.push(geoFormated)
      // @ts-ignore
      state.geofences[index].geofences.push(geoFormated)
    } else {
      const rGeofences = {
        id: geofence.id_group,
        name: '',
        geofences: [geoFormated],
        auxGeofences: [geoFormated],
        inputSearch: '',
        pType: PLATAFORM_TYPE.CONSOLA
      }
      commit('PUSH_GEOFENCES', rGeofences)
    }
  },
  socket_geofenceConsoleUpdated ({ state, commit }: ActionContext<GeofencesState, string>, geofence: ICGeofence) {
    const index = state.geofences.findIndex(rG => rG.id === geofence.id_group && rG.pType === PLATAFORM_TYPE.CONSOLA)
    if (index === -1) return
    const res_geofences = state.geofences[index]
    const geoFormated = {
      id: geofence.id_geofence,
      n: geofence.geo_name,
      d: geofence.geo_description,
      c: geofence.geo_color,
      geo_payload: geofence.geo_payload,
      pType: PLATAFORM_TYPE.CONSOLA
    }
    const geofence_index = res_geofences.auxGeofences.findIndex(g => g.id === geofence.id_geofence)
    commit('UPDATE_CONSOLE_GEOFENCE', { geofence: geoFormated, geofence_index, index })
  },
  socket_geofenceConsoleDeleted ({ state }: ActionContext<GeofencesState, string>, geofence: ICGeofence) {
    const index = state.geofences.findIndex(rG => rG.id === geofence.id_group && rG.pType === PLATAFORM_TYPE.CONSOLA)
    if (index !== -1) {
      const res_geofences = state.geofences[index]
      const geofence_index = res_geofences.auxGeofences.findIndex(g => g.id === geofence.id_geofence)
      // @ts-ignore
      res_geofences.auxGeofences.splice(geofence_index, 1)
      // @ts-ignore
      res_geofences.geofences.splice(geofence_index, 1)
    }
  },
  async createConsoleGeofence ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'geofences/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_CREATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async createConsoleGeofenceMany ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'geofences/create/many',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_CREATE_MANY', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async updateConsoleGeofence ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'geofences/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_UPDATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async deleteConsoleGeofence ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'geofences/delete',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_DELETE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async reloadConsoleGeofences ({ dispatch, commit }: ActionContext<GeofencesState, string>, data: any): Promise<void> {
    try {
      let geofences = null
      if (data) {
        geofences = data
      } else {
        const { data } = await dispatch(
          'sys/axios',
          {
            url: 'geofences/get/all',
            method: 'GET'
          },
          { root: true }
        )
        geofences = data
      }
      // @ts-ignore
      const consoleGeofences = geofences.reduce((curr, geofence) => {
        // @ts-ignore
        const rGeofences = curr.find(rg => rg.id === geofence.id_group)
        const geoFormated = {
          id: geofence.id_geofence,
          n: geofence.geo_name,
          d: geofence.geo_description,
          c: geofence.geo_color,
          geo_payload: geofence.geo_payload,
          pType: PLATAFORM_TYPE.CONSOLA
        }
        if (rGeofences) {
          rGeofences.auxGeofences.push(geoFormated)
          rGeofences.geofences.push(geoFormated)
        } else {
          const rGeofences = {
            id: geofence.id_group,
            name: geofence.gp_name,
            geofences: [geoFormated],
            auxGeofences: [geoFormated],
            inputSearch: '',
            pType: PLATAFORM_TYPE.CONSOLA
          }
          curr.push(rGeofences)
        }

        return curr
      }, [])

      consoleGeofences.forEach((rg: any) => commit('PUSH_GEOFENCES', rg));
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'RELOAD_CONSOLE_GEOFENCES', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async reloadGeofences (context: ActionContext<GeofencesState, string>, id_group: number) {
    // @ts-ignore
    const console_groups: Array<ConsoleGroup> = context.rootState.console.consoleGroups
    const group = console_groups.find(g => g.id_group === id_group)

    if (!group) {
      await context.dispatch('sys/showNotificationMessage', {
        title: 'No se encontro el grupo',
        color: 'error',
        duration: -1
      }, { root: true });

      return
    }

    try {
      await context.dispatch('sys/showNotificationMessage', {
        title: 'Recargando geocercas...',
        color: 'info',
        duration: -1
      }, { root: true });

      switch (group.id_plataform) {
        case PLATAFORM_TYPE.WIALON: {
          await context.dispatch('wialon/reloadGeofences', { gp_wialon_id: group.gp_wialon_id }, { root: true })
          break;
        }

        case PLATAFORM_TYPE.MAPON: {
          await context.dispatch('mapon/getGeofences', {}, { root: true })
          break;
        }
        default:
          await context.dispatch('sys/showNotificationMessage', {
            title: 'Recurso desconocido',
            color: 'warning',
            duration: -1
          }, { root: true });
          break;
      }

      await context.dispatch('sys/cleanNotifications', {}, { root: true });

      await context.dispatch('sys/showNotificationMessage', {
        title: 'Geocercas cargadas',
        color: 'success',
        duration: 10000
      }, { root: true });
    } catch (error) {
      await context.dispatch('sys/showNotificationMessage', {
        title: 'A ocurrido un error al cargar las geocercas...',
        color: 'error',
        duration: -1
      }, { root: true });
      console.error(error)
      context.commit('sys/ADD_LOG', { title: 'GEOFENCES_RELOAD_ERROR', color: 'error', message: error, payload: serializeError(error) }, { root: true })
    }
  },
  async getAllGeofences (context: ActionContext<GeofencesState, string>): Promise<void> {
    const geofences = await getAVLResourceGeofences()
    context.commit('SET_GEOFENCES', geofences)
  },
  async getRoutesDestinations (context: ActionContext<GeofencesState, string>): Promise<void> {
    const { data } = await context.dispatch(
      'sys/axios',
      {
        url: 'get_routes_destinations',
        method: 'post'
      },
      { root: true }
    )
    context.commit('SET_ROUTES_DESTINATIONS', data)
  },
  setInputValue (context: ActionContext<GeofencesState, string>, val: { pType: PLATAFORM_TYPE, wialon_id: string, value: string }): void {
    if (val.pType === PLATAFORM_TYPE.WIALON) {
      const resource_index = context.state.geofences.findIndex(resource => resource.id === parseInt(val.wialon_id))
      context.commit('SET_INPUT_VALUE', {
        resource_index,
        value: val.value
      })
      context.dispatch('filtlerGeofences', { resource_index, value: val.value })
    } else if (val.pType === PLATAFORM_TYPE.MAPON) {
      const resource_index = context.state.geofences.findIndex(resource => resource.pType === val.pType)
      context.commit('SET_INPUT_VALUE', {
        resource_index,
        value: val.value
      })
      context.dispatch('filtlerGeofences', { resource_index, value: val.value })
    }
  },
  filtlerGeofences (context: ActionContext<GeofencesState, string>, val: { resource_index: number, value: string }): void {
    const searchString = val.value.toLowerCase()
    if (searchString) {
      const filteredGeofences = context.state.geofences[val.resource_index].geofences.filter(geofence => {
        return (
          geofence.n.toLowerCase().includes(searchString)
        )
      })
      context.commit('SET_AUX_GEOFENCES', { resource_index: val.resource_index, geofences: filteredGeofences })
    } else {
      context.commit('SET_AUX_GEOFENCES', { resource_index: val.resource_index })
    }
  },
  createGeofenceInPlataform (context: ActionContext<GeofencesState, string>, payload: { plataform: PLATAFORM_TYPE[], geofence: ICGeofence }) {
    // ---
  },
  socket_geofencesRouteDestinationCreated (context: ActionContext<GeofencesState, string>, routeDestination: RouteDestination & { id_group: number }): void {
    const route_destination_index = context.state.routesDestinationsGeofences.findIndex(rDestination => rDestination.id_group === routeDestination.id_group)
    context.commit('ADD_ROUTE_DESTINATION', {
      route_destination_index,
      route_destination: routeDestination
    })
  },
  socket_geofenceRouteDestinationEdit (context: ActionContext<GeofencesState, string>, routeDestination: RouteDestination & { id_group: number }): void {
    const group_index = context.state.routesDestinationsGeofences.findIndex(rdg => rdg.id_group === routeDestination.id_group)
    const route_destinations_index = context.state.routesDestinationsGeofences[group_index].route_destinations.findIndex(rd => rd.id_route_destinations === routeDestination.id_route_destinations)

    context.commit('UPDATE_ROUTE_DESTINATION', {
      route_destinations_index,
      group_index,
      route_destination: routeDestination
    })

    // @ts-ignore
    const report_layouts: ReportLayout[] = context.rootState.reports.reports.filter(report_layout => report_layout.unitData.id_group === routeDestination.id_group)

    for (let i = 0; i < report_layouts.length; i++) {
      const report_items = report_layouts[i].unitData.ri_items

      for (let j = 0; j < report_items.length; j++) {
        const report_item = report_items[j]

        const route_destinations = report_item.last_data.DESTINATION.route_destinations

        for (let k = 0; k < route_destinations.length; k++) {
          const route_destination = route_destinations[k]

          if (route_destination.id_route_destinations === routeDestination.id_route_destinations && report_item.location.keyListener) {
            report_item.makeLocationRequest()
            break
          }
        }
      }
    }
  },
  socket_geofenceRuteDestinationDeleted (context: ActionContext<GeofencesState, string>, routeDPayload: { id_route_destinations: number, id_group: number }): void {
    const group_index = context.state.routesDestinationsGeofences.findIndex(rdg => rdg.id_group === routeDPayload.id_group)
    const route_destinations_index = context.state.routesDestinationsGeofences[group_index].route_destinations.findIndex(rd => rd.id_route_destinations === routeDPayload.id_route_destinations)

    context.commit('REMOVE_ROUTE_DESTINATION', {
      group_index,
      route_destinations_index
    })

    // @ts-ignore
    const report_layouts: ReportLayout[] = context.rootState.reports.reports.filter(report_layout => report_layout.unitData.id_group === routeDPayload.id_group)

    for (let i = 0; i < report_layouts.length; i++) {
      const report_items = report_layouts[i].unitData.ri_items

      for (let j = 0; j < report_items.length; j++) {
        const report_item = report_items[j]

        const route_destinations = report_item.last_data.DESTINATION.route_destinations

        for (let k = 0; k < route_destinations.length; k++) {
          const route_destination = route_destinations[k]

          if (route_destination.id_route_destinations === routeDPayload.id_route_destinations) {
            // @ts-ignore
            const routes = report_item.last_data.DESTINATION.route_destinations.filter(rd => rd.id_route_destinations !== routeDPayload.id_route_destinations)
            report_item.last_data.DESTINATION.route_destinations = routes
            if (report_item.location.keyListener) {
              report_item.makeLocationRequest()
              break
            }
          }
        }
      }
    }
  }
}

const getters = {
  geofencesItems (state: GeofencesState) {
    // @ts-ignore
    return (pType: PLATAFORM_TYPE, params: { id_wialon?: string, id_group?: number }): Geofence[] => {
      const console_geofences = state.geofences.find(rGeofence => (rGeofence.id === params.id_group && rGeofence.pType === PLATAFORM_TYPE.CONSOLA))?.auxGeofences || []
      switch (pType) {
        case PLATAFORM_TYPE.CONSOLA: {
          // @ts-ignore
          const rGeofence = state.geofences.find(rGeofence => (rGeofence.id === params.id_group && rGeofence.pType === PLATAFORM_TYPE.CONSOLA))
          return [...(rGeofence?.auxGeofences || [])]
        }
        case PLATAFORM_TYPE.WIALON: {
          // @ts-ignore
          const rGeofence = state.geofences.find(rGeofence => (rGeofence.id === parseInt(params.id_wialon) && rGeofence.pType === PLATAFORM_TYPE.WIALON))
          return [...(rGeofence?.auxGeofences || []), ...console_geofences]
        }
        case PLATAFORM_TYPE.MAPON: {
          const rGeofence = state.geofences.find(rGeofence => rGeofence.pType === PLATAFORM_TYPE.MAPON)
          return [...(rGeofence?.auxGeofences || []), ...console_geofences]
        }
        case PLATAFORM_TYPE.TRACKSOLID: {
          // @ts-ignore
          const rGeofence = state.geofences.find(rGeofence => (rGeofence.id === params.id_group && rGeofence.pType === PLATAFORM_TYPE.CONSOLA))
          return [...(rGeofence?.auxGeofences || []), ...console_geofences]
        }
        default: {
          return []
        }
      }
    }
  },
  routesItems (state: GeofencesState) {
    return (pType: PLATAFORM_TYPE, params: { id_group: number }): RouteDestination[] => {
      if (pType === PLATAFORM_TYPE.WIALON) {
        // @ts-ignore
        const routesItems = state?.routesDestinationsGeofences?.find(rDGeofences => rDGeofences.id_group === params.id_group)
        if (routesItems) {
          return routesItems.route_destinations
        }
      } else if (pType === PLATAFORM_TYPE.MAPON) {
        const routeGroupItems = state.routesDestinationsGeofences.filter(rdg => rdg.id_plataform === PLATAFORM_TYPE.MAPON)
        const route_destinations = routeGroupItems.flatMap(rdg => rdg.route_destinations)
        if (route_destinations.length) {
          return route_destinations
        }
      } else if (pType === PLATAFORM_TYPE.TRACKSOLID) {
        const routeGroupItems = state.routesDestinationsGeofences.filter(rdg => rdg.id_plataform === PLATAFORM_TYPE.TRACKSOLID && rdg.id_group === params.id_group)
        const route_destinations = routeGroupItems.flatMap(rdg => rdg.route_destinations)
        if (route_destinations.length) {
          return route_destinations
        }
      }

      return []
    }
  },
  inputResource (state: GeofencesState) {
    return (pType: PLATAFORM_TYPE, params: { id_wialon?: string }): string => {
      if (pType === PLATAFORM_TYPE.WIALON) {
        // @ts-ignore
        const rGeofence = state.geofences.find(rGeofence => (rGeofence.id === parseInt(params.id_wialon) && rGeofence.pType === PLATAFORM_TYPE.WIALON))
        if (rGeofence) {
          return rGeofence.inputSearch
        }
      } else if (pType === PLATAFORM_TYPE.MAPON) {
        const rGeofence = state.geofences.find(rGeofence => rGeofence.pType === PLATAFORM_TYPE.MAPON)
        if (rGeofence) {
          return rGeofence.inputSearch
        }
      }

      return ''
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
