/* eslint-disable no-undef */
/* eslint-disable no-tabs */
import ResourceNotification from '@/store/interfaces/ResourceNotification'
import Timer from 'easytimer.js'
import timeFormating from '@/utils/timeFormating'
import Command from '@/store/interfaces/Command'
import store from '@/store/index'
import Driver from '../../interfaces/Driver'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import phpToJavascriptDate from '@/utils/phpToJavascriptDate'

export default abstract class UnitNotificationData<UNType> {
  public resourceNotification: ResourceNotification<UNType> | null
  public timer: Timer
  public time: string
  public createdID: number

  // Unit properties
  public unitId: number
  public unitName: string
  public icon: string
  public notificationName: string
  public unitNotificationMessage: string
  public notificationDateFormatted: string

  constructor (resourceNotification?: ResourceNotification<UNType>) {
    this.resourceNotification = resourceNotification || null

    this.createdID = Date.now()
    this.time = resourceNotification ? 'Hace 0s' : 'Actualizando'
    this.timer = new Timer()
    this.timer.start()
    this.time = timeFormating(this.timer)
    this.unitId = this.getId()
    this.unitName = this.getUnitName()
    this.icon = this.getIcon()
    this.notificationName = this.getNotificationName()
    this.unitNotificationMessage = this.getMessage()
    this.notificationDateFormatted = phpToJavascriptDate(this.getPhpNotificationTime())
  }

  getNotificationId (): number {
    if (this.resourceNotification) {
      return this.resourceNotification.nid
    } else {
      return 0
    }
  }

  getId (): number {
    if (this.resourceNotification && this.resourceNotification.unit) {
      return this.resourceNotification.unit
    } else {
      return 0
    }
  }

  async playNotificationAudio (): Promise<void> {
    try {
      if (this.resourceNotification?.url) {
        const audio = new Audio(this.resourceNotification?.url)
        await audio.play()
      } else {
        const audio = new Audio('/audio/notify.wav')
        await audio.play()
      }
    } catch (error) {
      const audio = new Audio('/audio/notify.wav')
      await audio.play()
      console.error(error)
    }
  }

  getColor (): string {
    if (this.resourceNotification && this.resourceNotification.color) {
      return this.resourceNotification.color
    } else {
      return '#85fff9'
    }
  }

  abstract getResourceId (): number

  abstract getIcon (): string

  abstract getUnitName (): string

  getMessage (): string {
    if (this.resourceNotification) {
      return this.resourceNotification.txt
    } else {
      return ''
    }
  }

  getCoordinates (): Promise<{ x: number, y: number }> {
    return new Promise((resolve, reject) => {
      if (this.resourceNotification?.x && this.resourceNotification?.y) {
        resolve({
          x: this.resourceNotification?.x,
          y: this.resourceNotification?.y
        })
      } else {
        reject(Error('There arent x and y properties'))
      }
    })
  }

  getNotificationName (): string {
    if (this.resourceNotification) {
      return this.resourceNotification.name
    } else {
      return ''
    }
  }

  getGoogleLink (): string {
    if (this.resourceNotification) {
      return `http://maps.google.com/?q=${this.resourceNotification.y},${this.resourceNotification.x}`
    } else {
      return ''
    }
  }

  getCreatedID (): number {
    return this.createdID
  }

  getPhpNotificationTime (): number {
    if (this.resourceNotification) {
      return this.resourceNotification.t
    } else {
      return (Date.now() / 1000)
    }
  }

  getTime (): string {
    return this.time
  }

  getDriversInfo (): Driver[] {
    if (this.resourceNotification) {
      // @ts-ignore
      const drivers: Array<Driver> = store.state.console.driversList
      const driversFilter = drivers.filter(driver => driver.bu === this.resourceNotification?.unit)
      return driversFilter
    } else {
      return []
    }
  }

  getPlataformId (): PLATAFORM_TYPE {
    if (this.resourceNotification) {
      return this.resourceNotification.pType
    } else {
      return PLATAFORM_TYPE.CONSOLA
    }
  }

  getCMDList (): Promise<Array<Command>> {
    return new Promise((resolve, reject) => {
      if (!this.resourceNotification) {
        resolve([])
      } else {
        // @ts-ignore
        const sess = wialon.core.Session.getInstance()
        // @ts-ignore
        const flags = wialon.item.Item.dataFlag.base | wialon.item.Unit.dataFlag.commands | wialon.item.Unit.dataFlag.commandAliases
        sess.updateDataFlags([{ type: 'type', data: 'avl_unit', flags: flags, mode: 0 }], (code: string) => {
          // @ts-ignore
          if (code) { reject(wialon.core.Errors.getErrorText(code)); return }
          const unitID = this.resourceNotification?.unit
          const unit = sess.getItem(unitID) // get unit by id
          const cmds = unit.getCommandDefinitions() // get unit's commands
          const cmdsArray = Object.values(cmds)
          // @ts-ignore
          const cmdsFilter = cmdsArray.filter(cmd => !(cmd.n.match(/^__app__.*$/)))
          // @ts-ignore
          resolve(cmdsFilter)
        })
      }
    })
  }

  executeCommand (n: string, l: string, p: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const sess = wialon.core.Session.getInstance()
      const unitID = this.resourceNotification?.unit
      const unit = sess.getItem(unitID) // get unit by id
      unit.remoteCommand(n, l, p, 0, (code: string) => { // execute command callback
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          console.log('Command executed successfully')
          resolve()
        }
      })
    })
  }

  getWhatsAppMessages (): Array<{ title: string; message: string; }> {
    const mes = [
      {
        title: 'Destino',
        message: `Unidad *${this.getUnitName()}* me comparte su destino?`
      },
      {
        title: 'Parada',
        message: `Unidad *${this.getUnitName()}* se detiene en la siguiente ubicacion ${this.getGoogleLink()}`
      },

      {
        title: 'Notificacion',
        message: this.getMessage()
      }
    ]
    return mes
  }
}
