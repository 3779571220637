export const WEEKDAY = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7
}

export const WEEKDAY_NAME = {
  [WEEKDAY.MONDAY]: 'Lunes',
  [WEEKDAY.TUESDAY]: 'Martes',
  [WEEKDAY.WEDNESDAY]: 'Miercoles',
  [WEEKDAY.THURSDAY]: 'Jueves',
  [WEEKDAY.FRIDAY]: 'Viernes',
  [WEEKDAY.SATURDAY]: 'Sabado',
  [WEEKDAY.SUNDAY]: 'Domingo'
}
