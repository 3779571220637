export default function getZonesDataByIds (resId: number, zonesIds: number[]) {
  return new Promise((resolve, reject) => {
    // get resource by selected id
    // @ts-ignore
    const res = wialon.core.Session.getInstance().getItem(resId);
    // get resource Zones, use 'showParams()' function as callback
    res.getZonesData(zonesIds, function (code: any, data: any) {
      // @ts-ignore
      if (code) { reject(wialon.core.Errors.getErrorText(code)) }
      resolve(data)
    });
  })
}

export function getResZonesDataByIds (res: any, zonesIds: number[]) {
  if (!zonesIds.length) return [] as any[]
  return new Promise<any[]>((resolve, reject) => {
    res.getZonesData(zonesIds, function (code: any, data: any) {
      // @ts-ignore
      if (code) { reject(wialon.core.Errors.getErrorText(code)) }
      resolve(data)
    });
  })
}
