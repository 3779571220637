import Api from "./Api";
import ApiRequest from "../decorators/ApiRequest";
import { IParameters } from "../interfaces/login.interface";
import { Optional } from "../interfaces/utils.interface";

export type IDeviceDetailParams = Optional<
  Pick<IParameters, "access_token" | "imei">,
  "access_token"
>;

export type IDeviceDetailResponce = Pick<IParameters, "orderName" | "orderContent" | "id" | "orderExplain" | "orderMsg" | "isOffLine">;

export default class Command extends Api {
  @ApiRequest({ method: "jimi.open.instruction.list" })
  public static async openInstructionList(
    params: IDeviceDetailParams
  ): Promise<IDeviceDetailResponce[]> {
    // @ts-ignore
    return params;
  }
}
