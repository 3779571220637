import { BINNACLE_DEFAULT_COLUMNS } from './../../catalogs/BINNACLE_DEFAULT_COLUMNS';
import { Brand, Column, Company, Equipment, Model, Plan, Sim, Technician } from '@/store/interfaces/Binnacle';
import { State } from './state';
import bit_inventory_item from './getters/bit_inventory_item';
import bit_inventory_group from './getters/bit_inventory_group';

export default {
  getCustomColumnNameById (state: State) {
    return (custom_column_id: string | undefined) => {
      if (!custom_column_id) return custom_column_id
      if (custom_column_id.startsWith('custom_column_')) {
        const id = custom_column_id.split('custom_column_')[1]
        const column = state.data.columns.find(col => col.id_column === parseInt(id))
        return column?.col_name
      } else {
        const column = BINNACLE_DEFAULT_COLUMNS.find(col => col.field === custom_column_id)
        return column?.label
      }
    }
  },
  getModelsByBrand (state: State) {
    return (id_brand: number): Model[] => {
      return state.data.models.filter(mod => mod.id_brand === id_brand)
    };
  },
  getModelById (state: State) {
    return (id_model: number): Model | undefined => {
      return state.data.models.find(mod => mod.id_model === id_model)
    };
  },
  getBrandById (state: State) {
    return (id_brand: number): Brand | undefined => {
      return state.data.brands.find(obj => obj.id_brand === id_brand)
    };
  },
  getCompanyById (state: State) {
    return (id_company: number): Company | undefined => {
      return state.data.companies.find(obj => obj.id_company === id_company)
    };
  },
  getEquipmentById (state: State) {
    return (id_equipment: number): Equipment | undefined => {
      return state.data.equipments.find(obj => obj.id_equipment === id_equipment)
    };
  },
  getPlanById (state: State) {
    return (id_plan: number): Plan | undefined => {
      return state.data.plans.find(obj => obj.id_plan === id_plan)
    };
  },
  getSimById (state: State) {
    return (id_sim: number): Sim | undefined => {
      return state.data.sims.find(obj => obj.id_sim === id_sim)
    };
  },
  getTechnicianById (state: State) {
    return (id_technician: number): Technician | undefined => {
      return state.data.technicians.find(obj => obj.id_technician === id_technician)
    };
  },
  getColumnById (state: State) {
    return (id_column: number): Column | undefined => {
      return state.data.columns.find(obj => obj.id_column === id_column)
    };
  },
  getTechnicalRemainingEquipmentsById (state: State) {
    return (id_technician: number): Equipment[] => {
      const technician = state.data.technicians.find(obj => obj.id_technician === id_technician)
      if (!technician) return []
      const ids_equiments = technician.equipments.map(eqp => eqp.id_equipment)
      const equiments = state.data.equipments.filter(eqp => !ids_equiments.includes(eqp.id_equipment))
      return equiments
    };
  },
  getTechnicalEquipmentsById (state: State) {
    return (id_technician: number): Equipment[] => {
      const technician = state.data.technicians.find(obj => obj.id_technician === id_technician)
      if (!technician) return []
      const ids_equiments = technician.equipments.map(eqp => eqp.id_equipment)
      const equiments = state.data.equipments.filter(eqp => ids_equiments.includes(eqp.id_equipment))
      return equiments
    };
  },
  getTechnicalSimsById (state: State, getters: any) {
    return (id_technician: number): Sim[] => {
      const technician = state.data.technicians.find(obj => obj.id_technician === id_technician)
      if (!technician) return []
      const equipments: Equipment[] = getters.getTechnicalEquipmentsById(id_technician)
      // @ts-ignore
      const ids_sims_equipments: number[] = equipments.filter(equip => equip.id_sim).map(equip => equip.id_sim)
      const ids_sims = [...new Set(technician.sims.map(sim => sim.id_sim).concat(ids_sims_equipments))]
      const sims = state.data.sims.filter(sim => ids_sims.includes(sim.id_sim))
      return sims
    };
  },
  getTechnicalByEquipmentId (state: State) {
    return (id_equipment: number): Technician | undefined => {
      const technician = state.data.technicians.find(tech => tech.equipments.some(eqp => eqp.id_equipment === id_equipment))
      return technician
    };
  },
  getTechnicianEquipmentBindedData (state: State) {
    const equipments = state.data.equipments
    const avaliableEquipments = equipments.map(eqp => ({ ...eqp, technician: state.data.technicians.find(tech => tech.equipments.find(te => te.id_equipment === eqp.id_equipment)) }))
    return avaliableEquipments
  },
  getTechnicianEquipmentStatusById (state: State) {
    return (id_technician: number): Equipment[] => {
      const technician = state.data.technicians.find(tech => tech.id_technician === id_technician)
      if (!technician) return []

      const ids_equipment = technician.equipments.map(eqp => eqp.id_equipment)
      const equipments = state.data.equipments.map(eqp => ({ ...eqp, is_binded: ids_equipment.includes(eqp.id_equipment) }))

      return equipments
    };
  },
  getTechnicianSimsBindedData (state: State, getters: any) {
    const simsAvaliable = state.data.sims.map(sim =>
      ({
        ...sim,
        technician: state.data.technicians.find(tech => getters.getTechnicalSimsById(tech.id_technician).find((techSim: Sim) => techSim.id_sim === sim.id_sim))
        // technician: getters.getTechnicalSimsById(sim.)
      }))
    return simsAvaliable
  },
  getEquipmentSimsStatusById (state: State) {
    return (id_equipment: number): Sim[] => {
      const equipment = state.data.equipments.find(eqp => eqp.id_equipment === id_equipment)
      if (!equipment) return []

      const sims = state.data.sims.map(sim => ({ ...sim, is_binded: sim.id_sim === equipment.id_sim }))
      return sims
    };
  },
  getEquipmentBySimId (state: State) {
    return (id_sim: number): Equipment | undefined => {
      const equipment = state.data.equipments.find(eqp => eqp.id_sim === id_sim)
      return equipment
    };
  },
  getTechniciansFilter (state: State) {
    return state.data.technicians.filter(obj => !obj.is_deleted)
  },
  getTechniciansMap (state: State) {
    return state.data.technicians.map(obj => obj.tec_name)
  },
  getActionsMap (state: State) {
    return state.data.actions.map(obj => obj.act_name)
  },
  getActionsFilter (state: State) {
    return state.data.actions.filter(obj => !obj.is_deleted)
  },
  getEquipmentsMap (state: State) {
    // @ts-ignore
    return (filter) => {
      return state.data.equipments.filter(filter).map(obj => obj.eq_imei)
    }
  },
  getEquipmentsFilter (state: State) {
    return state.data.equipments.filter(obj => !obj.is_deleted)
  },
  getSIMMap (state: State) {
    return state.data.sims.map(obj => obj.sim_line)
  },
  getSIMSFilter (state: State) {
    return state.data.sims.filter(obj => !obj.is_deleted)
  },
  getModelMap (state: State) {
    return state.data.models.map(obj => obj.mod_name)
  },
  getModelsFilter (state: State) {
    return state.data.models.filter(obj => !obj.is_deleted)
  },
  getBrandMap (state: State) {
    return state.data.brands.map(obj => obj.bra_name)
  },
  getBrandsFilter (state: State) {
    return state.data.brands.filter(obj => !obj.is_deleted)
  },
  getCompanyMap (state: State) {
    return state.data.companies.map(obj => obj.com_name)
  },
  getCompanysFilter (state: State) {
    return state.data.companies.filter(obj => !obj.is_deleted)
  },
  getPlanMap (state: State) {
    return state.data.plans.map(obj => obj.plan_name)
  },
  getPlansFilter (state: State) {
    return state.data.plans.filter(obj => !obj.is_deleted)
  },
  getColumnsFilter (state: State) {
    return state.data.columns.filter(obj => !obj.is_deleted).sort((a, b) => a.col_order - b.col_order)
  },
  getColumnsComponent (state: State) {
    return state.data.columns.filter(obj => !obj.is_deleted && obj.col_enabled).sort((a, b) => a.col_order - b.col_order)
  },
  getEquipmentsCount (state: State) {
    return state.data.equipments.length
  },
  getEquipmentsWorking (state: State) {
    return state.data.equipments.filter(obj => obj.eq_status_work).length
  },
  getEquipmentsNotWorking (state: State) {
    return state.data.equipments.filter(obj => !obj.eq_status_work).length
  },
  equipments_class (state: State) {
    return state.data.equipments_class
  },
  getInstallationById (state: State) {
    return (id: number) => {
      return state.data.installations.find(ins => ins.id_installation === id)
    }
  },
  ...bit_inventory_item,
  ...bit_inventory_group
};
