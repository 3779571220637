/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Layout } from '@/components/Reports/createReportLayout';
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE';
import { ReportLayout } from '@/store/interfaces/ReportModules'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData';
import { RouteDestination } from '../geofences'
import { REPORT_DOCUMENTS_LIST } from '@/store/catalogs/REPORT_DOCUMENTS_LIST';
import { REPORT_ITEM_CELL_TYPE } from '@/store/catalogs/REPORT_ITEM_CELL_TYPE';

export interface ReportState {
  curr_id_report_item: number
  lastLayout: Layout[];
  layout: Layout[];
  reports: ReportLayout[]
  lastReportsSelected: Array<{ report_group_index: number; report_item_index: number; id_report_item: number}>
  reportsSelected: Array<{ report_group_index: number; report_item_index: number; id_report_item: number}>
  reportsDocuments: {
    data: {
      id_report_group: number | null
      pType: PLATAFORM_TYPE | null
      id_group: number | null
    }
    isWating: boolean
    dialog: boolean,
    reports: {
      rdl_id: number
      rdl_name: string
      rdl_action: string
    }[]
  }
  reportsVideoHistory: {
    isWating: boolean
    dialog: boolean,
    unitVideoHistory: ReportItemData<unknown, unknown> | null
  }
  reportDestinationGroup: {
    pType: PLATAFORM_TYPE | null
    id_wialon: number | null
    id_group: number | null
    id_report_group: number | null
    dialog: boolean
  }
  routesDialog:{
    route_data: {
      id_route_destinations: number,
      rd_name: string,
      rd_geofences: Array<{ id: number, n: string }>
    },
    is_creating: boolean
    id_report_item: number
    id_report_group: number
    currentTabComponent: string
    id_group: number
    wialon_id: number
    isReportRoutesDialog: boolean
    routes_destinations: RouteDestination[]
  }
  riModalProperties: {
    modal: boolean
    reportItem: ReportItemData<unknown, unknown> | null
  }
  riConfigMenu: {
    modal: boolean;
    report_group_index: number;
    report_item_index: number
  }
  riCellItems: {
    key: number
    report_item: null | ReportItemData<unknown, unknown>,
    cellColor: string,
    isChanged: boolean,
    [REPORT_ITEM_CELL_TYPE.STATUS]: {
      value: string,
      $ref: any
      $refDraggable: null
    },
    [REPORT_ITEM_CELL_TYPE.DESTINATION]: {
      value: '',
      $ref: any
      $refDraggable: null
    },
    [REPORT_ITEM_CELL_TYPE.MESSAGE]: {
      value: '',
      $ref: any
      $refDraggable: null
    }
  }
}

const state: ReportState = {
  curr_id_report_item: 0,
  lastLayout: [],
  layout: [],
  reports: [
  ],
  lastReportsSelected: [
  ],
  reportsSelected: [
  ],
  reportsDocuments: {
    isWating: false,
    dialog: false,
    data: {
      id_report_group: null,
      pType: null,
      id_group: null
    },
    reports: [
      {
        rdl_id: REPORT_DOCUMENTS_LIST.HV_EVENTS,
        rdl_action: 'getHVEvents',
        rdl_name: 'Informe de eventos de camaras HikVision'
      },
      {
        rdl_id: REPORT_DOCUMENTS_LIST.REPORT_USERS_CHANGED,
        rdl_action: 'reportUserChanges',
        rdl_name: 'Informe de cambios de usuarios'
      }
    ]
  },
  reportsVideoHistory: {
    isWating: false,
    dialog: false,
    unitVideoHistory: null
  },
  reportDestinationGroup: {
    id_report_group: null,
    id_group: null,
    id_wialon: null,
    pType: null,
    dialog: false
  },
  routesDialog: {
    route_data: {
      id_route_destinations: 0,
      rd_name: '',
      rd_geofences: []
    },
    is_creating: true,
    id_report_item: 0,
    id_report_group: 0,
    currentTabComponent: 'report-select-routes',
    id_group: 0,
    wialon_id: 0,
    isReportRoutesDialog: false,
    routes_destinations: []
  },
  riModalProperties: {
    modal: false,
    reportItem: null
  },
  riConfigMenu: {
    modal: false,
    report_group_index: -1,
    report_item_index: -1
  },
  riCellItems: {
    key: 1,
    report_item: null,
    cellColor: '',
    isChanged: false,
    [REPORT_ITEM_CELL_TYPE.STATUS]: {
      value: '',
      $ref: null,
      $refDraggable: null
    },
    [REPORT_ITEM_CELL_TYPE.DESTINATION]: {
      value: '',
      $ref: null,
      $refDraggable: null

    },
    [REPORT_ITEM_CELL_TYPE.MESSAGE]: {
      value: '',
      $ref: null,
      $refDraggable: null

    }
  }
}

export type State = typeof state;

export default state
