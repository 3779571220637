/* eslint-disable @typescript-eslint/no-unused-vars */
import { IParameters } from '../../interfaces/parameters.interface'
import { Optional } from '../../interfaces/utils.interface'
import AssingData from '../../utils/AssingData'
import Action from './Action'
import { ApplicationFieldsEntity, Driver } from '../../interfaces/Driver.interface'
import { IListConfig } from '../../interfaces/actions.interface'

export type IListUnitParams = Optional<
  Pick<
    IParameters,
    | 'key'
    | 'id'
    | 'name'
    | 'group_id'
    | 'deleted'
    | 'updated_from'
    | 'updated_till'
    | 'wkt_lon_first'
  >,
  | 'key'
  | 'id'
  | 'name'
  | 'group_id'
  | 'deleted'
  | 'updated_from'
  | 'updated_till'
  | 'wkt_lon_first'
>;

@AssingData()
export default class ADriver extends Action<Driver> implements Driver {
    id!: number
    name!: string
    surname!: string
    email!: string
    phone!: string
    language!: string
    ibutton!: string
    tacho!: string
    access_to_route_sheet!: number
    blocked!: boolean
    blocked_text_fe!: string
    created!: string
    fields!: { [key: string]: any}
    application_fields?: ApplicationFieldsEntity[] | null | undefined

    public static async list (params: IListUnitParams = {}, { raw } = { raw: false }): Promise<ADriver[]> {
      const res = await super.AList<{ drivers: Driver[] }>(params, { action: 'driver' })
      if (!raw) {
        const aUnits = res.data.drivers.map((data) => new ADriver({ data }))
        return aUnits
      } else {
        // @ts-ignore
        return res.data.drivers
      }
    }

    public getFullName () {
      return `${this.name} ${this.surname}`
    }

    isUpdated (data: any): boolean {
      return false
    }
}
