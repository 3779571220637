import { RouteConfig } from 'vue-router'
import store from '@/store/index'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import isDev from '@/utils/isDev'

const Monitoreo: RouteConfig = {
  path: '/Monitoreo',
  name: 'Monitoreo',
  component: () => import('../views/Monitoreo.vue'),
  beforeEnter: async (to, from, next) => {
    const dev = isDev()
    if (dev) console.time('Iniciando consola')
    // @ts-ignore
    const isLogged: boolean = store.state.auth.is_auth
    // @ts-ignore
    if (isLogged) {
      try {
        await store.dispatch(
          'sys/setLoaderDialogState',
          {
            isLoader: true,
            dialogMessage: 'Iniciando consola'
          },
          { root: true }
        )
        if (dev) console.time('Checando expiracion token')
        await store.dispatch('auth/isTokenExpired')
        if (dev) console.timeEnd('Checando expiracion token')

        if (dev) console.time('Obteniendo datos iniciales')
        await Promise.all([
          store.dispatch('console/getConsoleGroups'),
          store.dispatch('auth/getLastLogin')
        ])
        if (dev) console.timeEnd('Obteniendo datos iniciales')

        const wialon = await store.dispatch('auth/havePlataform', PLATAFORM_TYPE.WIALON)
        const mapon = await store.dispatch('auth/havePlataform', PLATAFORM_TYPE.MAPON)
        const tracksolid = await store.dispatch('auth/havePlataform', PLATAFORM_TYPE.TRACKSOLID)

        if (dev) console.time('Iniciando plataformas')
        await Promise.all([
          (wialon ? store.dispatch('wialon/init') : {}),
          (mapon ? store.dispatch('mapon/init') : {}),
          (tracksolid ? store.dispatch('tracksolid/init') : {})
        ])
        if (dev) console.timeEnd('Iniciando plataformas')

        // Se inicia la consola y se obtiene la informacion necesaria, ademas de iniciar los eventos del socket
        if (dev) console.time('Ejecutando metodo init')
        await store.dispatch('sys/init')
        if (dev) console.timeEnd('Ejecutando metodo init')

        next()

        if (dev) console.time('Solicitando permisos')
        await store.dispatch('sys/requestNotificationPermission')
        await store.dispatch('sys/requestGeolocationPermission')
        if (dev) console.timeEnd('Solicitando permisos')

        await store.dispatch('sys/setLoaderDialogState', {
            isLoader: true,
            dialogMessage: 'Bienvenido'
          }
        )

        setTimeout(() => {
          store.dispatch('sys/setLoaderDialogState', { isLoader: false })
        }, 2000)
        if (dev) console.timeEnd('Iniciando consola')
      } catch (error) {
        console.error('Init console error -> ', error)
        store.dispatch('sys/setLoaderDialogState', { dialogMessage: 'No se pudo iniciar session, se limpiara el navegador localmente' })
        setTimeout(async () => {
          await store.dispatch('auth/closeSession')
        }, 2500)
      }
    } else {
      next({
        replace: true,
        name: 'Login'
      })
    }
  }
}

export default Monitoreo
