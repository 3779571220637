export default function splitArrInChunks<type>(inputArray: any[], ms: number) {
  const perChunk = ms; // items per chunk

  const result: type[][] = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return result
}
