/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ReportItemConfig } from '@/store/interfaces/ReportModules'

export default function (ri_config:ReportItemConfig) {
  return {
    // @ts-ignore
    unit_notification_alert_without_ignition: parseInt(ri_config?.unit_notification_alert_without_ignition || 86400),
    // @ts-ignore
    unit_notification_alert: parseInt(ri_config?.unit_notification_alert || 90),
    // @ts-ignore
    unit_notification_max_speed: parseInt(ri_config?.unit_notification_max_speed || 100),
    // @ts-ignore
    unit_notification_low_gps_battery: parseInt(ri_config?.unit_notification_low_gps_battery || -1),
    // @ts-ignore
    unit_notification_low_unit_battery: parseInt(ri_config?.unit_notification_low_unit_battery || -1)
  }
}
