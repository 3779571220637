<template lang="pug">
notifications.notification_message(
  group="notification",
  position="top right",
  :width="$vuetify.breakpoint.mdAndDown ? viewportWidth(80) : viewportWidth(40)",
  ref="notificationComponent"
)
  template(slot="body", slot-scope="{ item, close }")
    .d-flex.flex-column.pa-1.my-1.mr-1(:class="item.type" :style="`background-color: ${item.type};`")
      .d-flex.flex-row
        .icon.pr-1.text-bgColor-shadow
          v-icon(v-if="item.icon") {{ item.icon }}
          v-icon(v-else) mdi-exclamation
        .text_color.subtitle-1.text-justify.font-weight-bold.text-bgColor-shadow
          | {{ item.title }}
      .d-flex.flex-row
        div.mr-2
          | {{ item.text }}
          v-btn(v-if="item.data && item.data.action" @click="() => dispatchAction(item)") {{ item.data.btnText }}
        .close.d-flex.align-center.px-2.ml-auto
          v-icon(@click="close", color="text", size="2vh") mdi-close

</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  data: () => ({
    isMounted: false,
    btn_clear_all_element: null
  }),
  methods: {
    createElementFromHTML (htmlString) {
      const div = document.createElement('div')
      div.innerHTML = htmlString.trim()

      // Change this to div.childNodes to support multiple top-level nodes
      return div.firstChild
    },
    dispatchAction (item) {
      this.$store.dispatch(item.data.action, item.data.payload)
    },
    goToNote (item) {
      this.dispatchAction(item)
      this.$store.dispatch('notes/toggleNotesDialog')
      this.$store.dispatch('notes/focusDialogNote', item.id)
    }
  },
  computed: {
    ...mapGetters({
      viewportWidth: 'sys/viewportWidth'
    }),
    haveNotifications () {
      if (!this.isMounted) {
        return false
      } else {
        return this.$refs.notificationComponent.list.length
      }
    }
  },
  watch: {
    haveNotifications (value) {
      if (value === 0) {
        this.btn_clear_all_element.style.display = 'none'
      } else {
        this.btn_clear_all_element.style.display = 'block'
      }
    }
  },
  mounted () {
    const notificationComponent = this.$refs.notificationComponent
    const closeNotifications = notificationComponent.destroyAll
    const dv = this.createElementFromHTML(`
      <button id="btn_clear_all" type="button" class="btn_clear_all ml-auto float-right v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--small">
        <span class="v-btn__content">Cerrar todas</span>
      </button>
    `)
    notificationComponent.$el.appendChild(dv)
    const btn_clear_all = document.getElementById('btn_clear_all')
    btn_clear_all.addEventListener('click', e => {
      e.stopPropagation()
      closeNotifications()
    })
    this.btn_clear_all_element = btn_clear_all
    this.isMounted = true
  }
})
</script>

<style lang="sass">
.text_color
  color: var(--v-text-base)

.btn_clear_all
  position: absolute
  right: 0
</style>
