import { ActionContext } from 'vuex'
import { State } from '../../'
import { IScheduleTurnPeriodType } from '@/store/interfaces/Schedule'

export default {
  async createScheduleTurnPeriodType ({ dispatch, commit }: ActionContext<State, string>, payload: Partial<IScheduleTurnPeriodType>): Promise<IScheduleTurnPeriodType> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/turn/period/type/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('ADD_SCHEDULE_TURN_PERIOD_TYPE', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_SCHEDULE_TURN_PERIOD_TYPE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getAllScheduleTurnPeriodType ({ dispatch, commit }: ActionContext<State, string>): Promise<IScheduleTurnPeriodType[]> {
    try {
      const { data }: { data: IScheduleTurnPeriodType[] } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/turn/period/type/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { schedulesTurnsPeriodTypes: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_SCHEDULE_TURN_PERIOD_TYPE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateScheduleTurnPeriodType ({ dispatch, state }: ActionContext<State, string>, payload: Partial<IScheduleTurnPeriodType>): Promise<IScheduleTurnPeriodType> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/turn/period/type/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.schedulestate.schedulesTurnsPeriodTypes.findIndex(obj => obj.id_schedule_turn_weekday_period_type === data.id_schedule_turn_weekday_period_type)
      if (index !== -1) {
        Object.assign(state.schedulestate.schedulesTurnsPeriodTypes[index], data)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_SCHEDULE_TURN_PERIOD_TYPE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteScheduleTurnPeriodType ({ dispatch, state, commit }: ActionContext<State, string>, id_schedule_turn_weekday_period_type: number): Promise<{ id_schedule_turn: number }> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `schedule/turn/period/type/delete/${id_schedule_turn_weekday_period_type}`,
          method: 'DELETE'
        },
        { root: true }
      )

      const index = state.schedulestate.schedulesTurnsPeriodTypes.findIndex(obj => obj.id_schedule_turn_weekday_period_type === id_schedule_turn_weekday_period_type)
      if (index !== -1) commit('DELETE_SCHEDULE_TURN_PERIOD_TYPE', index)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_SCHEDULE_TURN_PERIOD_TYPE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
