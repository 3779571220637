import { ActionContext } from 'vuex';
import { State } from '..';
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE';

export default {
  async initUnits ({ dispatch, commit }: ActionContext<State, string>) {
    const wialon = await dispatch('auth/havePlataform', PLATAFORM_TYPE.WIALON, { root: true })
    const mapon = await dispatch('auth/havePlataform', PLATAFORM_TYPE.MAPON, { root: true })
    const tracksolid = await dispatch('auth/havePlataform', PLATAFORM_TYPE.TRACKSOLID, { root: true })

    const [wialonUnits, maponUnits, trackUnits] = await Promise.all([
      (wialon ? dispatch('wialon/getUnits', {}, { root: true }) : []),
      (mapon ? dispatch('mapon/getUnits', {}, { root: true }) : []),
      (tracksolid ? dispatch('mapon/getUnits', {}, { root: true }) : [])
    ])

    commit('SET_PLATAFORM_UNITS', { pType: PLATAFORM_TYPE.WIALON, units: wialonUnits })
    commit('SET_PLATAFORM_UNITS', { pType: PLATAFORM_TYPE.MAPON, units: maponUnits })
    commit('SET_PLATAFORM_UNITS', { pType: PLATAFORM_TYPE.TRACKSOLID, units: trackUnits })
  },
  async getAllUnits ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/units/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { units: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_UNITS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async paginateUnit ({ dispatch, commit }: ActionContext<State, string>, payload: { page: number; items: number }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/units/paginate',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { units: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'PAGINATE_UNITS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getUnitById ({ dispatch }: ActionContext<State, string>, id_report_item: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/units/get/${id_report_item}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_UNIT_BY_ID', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createUnit ({ dispatch, commit }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/units/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      if (data.length) {
        data.forEach((element: any) => commit('UNSHIFT_UNIT', element));
      } else {
        commit('UNSHIFT_UNIT', data)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_UNITS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateUnit ({ dispatch, commit, state }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/units/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.admin.units.findIndex(obj => obj.id_report_item === data.id_report_item)

      if (index !== -1) {
        commit('UPDATE_UNIT', { data, index })
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_UNITS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateManyUnits ({ dispatch }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/units/update_many',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_MANY_UNITS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteUnit ({ dispatch, commit, state }: ActionContext<State, string>, id_report_item: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/units/delete/${id_report_item}`,
          method: 'DELETE'
        },
        { root: true }
      )

      const index = state.admin.units.findIndex(obj => obj.id_report_item === id_report_item)

      if (index !== -1) {
        commit('DELETE_UNIT', index)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_UNITS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async togggleEnableUnit ({ dispatch, state }: ActionContext<State, string>, id_report_item: number) {
    try {
      const { data: unit } = await dispatch(
        'sys/axios',
        {
          url: `admin/units/toggle_enable/${id_report_item}`,
          method: 'PUT'
        },
        { root: true }
      )

      const index = state.admin.units.findIndex(obj => obj.id_report_item === id_report_item)
      if (index === -1) return

      state.admin.units[index].ri_status = unit.ri_status
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'TOGGLE_ENABLE_UNIT', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
