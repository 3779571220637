/**
 *
 * @param timeReport Time that we want to make the diference
 * @returns Time diff in seconds of the current time and the time gived
 */
export default function (timeReport: number): number {
  const currentTime = Math.ceil((new Date().getTime() / 1000))
  const timeDiff = currentTime - timeReport
  if (timeDiff < 1) {
    return 1
  } else {
    return timeDiff
  }
}
