import { DateTime } from 'luxon';

// useDefineForClassFields: false
export default abstract class BaseClass<Itype> {
  protected lastData: Itype | object;

  constructor (params: { data: Itype }) {
    this.lastData = {};
    this.rehidratateData(params.data);
    this.onInitInstance();
  }

  // @Override
  public abstract onInitInstance (): void;

  /**
   * Updates the value of a property in the class instance.
   *
   * @param propName The name of the property to update.
   * @param value The new value for the property.
   */
  public updateProperty (propName: keyof this, value: any) {
    if (
      // @ts-ignore
      typeof this[propName] === 'string' ||
      // @ts-ignore
      typeof this[propName] === 'number' ||
      // @ts-ignore
      typeof this[propName] === 'boolean' ||
      // @ts-ignore
      this[propName] === null ||
      // @ts-ignore
      this[propName] === undefined
    ) {
      // @ts-ignore
      this[propName] = value;
      // @ts-ignore
      this.lastData[propName] = value;
    } else {
      // @ts-ignore
      Object.assign(this[propName], value);
      // @ts-ignore
      Object.assign(this.lastData[propName], value);
    }
  }

  /**
   * Inicialice data for a class instance inherit
   * This method is always executed on the constructor
   *
   * @param data Initial data for this class instance
   */
  public rehidratateData (data: Itype) {
    Object.assign(this, data);
    this.lastData = data;
  }

  /**
   * Rehydrates the data of the class instance with the provided partial data.
   *
   * @param data The partial data to rehydrate the class instance with.
   */
  public rehidratateDataPartial (data: Partial<Itype>) {
    Object.assign(this, data);
    if (this.lastData) Object.assign(this.lastData, data);
  }

  /**
   * Set the last data with the given data.
   *
   * @param {Itype} data - the data to set as the last data
   * @return {void}
   */
  public setLastData (data: Itype) {
    this.lastData = data;
  }

  /**
   * Returns the current UTC date and time offset by the specified number of hours.
   *
   * @param offset The number of hours to offset the UTC date and time.
   * @returns The current UTC date and time offset by the specified number of hours.
   */
  public getUTCDateTimeOffset ({ offset }: { offset: number }) {
    const now = DateTime.utc().setLocale('es').plus({ hours: offset });
    return now;
  }

  public static getUTCDateTimeOffset ({ offset }: { offset: number }) {
    const now = DateTime.utc().setLocale('es').plus({ hours: offset });
    return now;
  }

  /**
   * A function to get the natural label date based on the provided offset and ISO date.
   *
   * @param {number} offset - the offset value to adjust the date
   * @param {string} isoDate - the ISO date string to be formatted
   * @return {object} an object containing the relative date and the formatted date
   */
  public static getNaturalLabelDate ({
    offset,
    isoDate
  }: {
    offset: number;
    isoDate: string;
  }) {
    const isoDatetime = DateTime.fromISO(isoDate)
      .setLocale('es')
      .plus({ hours: offset });
    // @ts-ignore
    const now = BaseClass.getUTCDateTimeOffset({ offset: offset });

    let labelDate;

    if (now.hasSame(isoDatetime, 'day')) {
      labelDate = 'hoy';
    } else if (now.minus({ days: 1 }).hasSame(isoDatetime, 'day')) {
      labelDate = 'ayer';
    } else {
      labelDate = isoDatetime.toFormat("dd 'de' LLLL");
    }

    return { labelDate, isoDatetime };
  }

  /**
   * Returns a formatted date string in a natural language format.
   *
   * @param offset - The number of hours to offset the date.
   * @param isoDate - The ISO date string to format.
   * @returns A formatted date string in a natural language format.
   */
  public getNaturalFormatedDate ({
    offset,
    isoDate
  }: {
    offset: number;
    isoDate: string;
  }) {
    const { labelDate, isoDatetime } = BaseClass.getNaturalLabelDate({
      offset,
      isoDate
    });
    const formattedDate = `${labelDate} a las ${isoDatetime.toFormat('t')}`;
    return formattedDate;
  }

  public static getNaturalFormatedDate ({
    offset,
    isoDate
  }: {
    offset: number;
    isoDate: string;
  }) {
    const { labelDate, isoDatetime } = BaseClass.getNaturalLabelDate({
      offset,
      isoDate
    });
    const formattedDate = `${labelDate} a las ${isoDatetime.toFormat('t')}`;
    return formattedDate;
  }

  // @ts-ignore
  public static createInstance (data: Partial<Itype>) {
    // @ts-ignore
    return new this({ data });
  }

  /**
   * Returns a new object containing only the properties of this object that match the given keys or exclude keys.
   *
   * @param {Object} options - An optional object containing keys and excludeKeys properties.
   * @param {Array} options.keys - An array of keys to include in the new object.
   * @param {Array} options.excludeKeys - An array of keys to exclude from the new object.
   */
  public getObject ({ keys = [] as (keyof Itype)[], excludeKeys = [] as (keyof Itype)[] } = {}): Partial<Itype> {
    // @ts-ignore
    return Object.fromEntries(
      Object.entries(this)
        // @ts-ignore
        .filter(([key]) => !keys.length || keys.includes(key))
        // @ts-ignore
        .filter(([key]) => !excludeKeys.includes(key))
    );
  }

  // public copy (): BaseClass<Itype> {
  //   return new BaseClass({ data: tris });
  // }

  public copy (): this {
    // Usamos Object.assign para copiar las propiedades
    const copy = Object.create(this.constructor.prototype);
    Object.assign(copy, this);
    return copy;
  }
}
