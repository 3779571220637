import { USER_TYPE } from '@/store/catalogs/USER_TYPE'
import { State } from './state'
import Driver from '@/store/interfaces/Driver'
import { CALL_TYPE } from '@/store/catalogs/CALL_TYPE'
import { CALL_STATE } from '@/store/catalogs/CALL_STATE'
import { CONSOLE_SOCKET_NAMESPACES } from '@/store/catalogs/CONSOLE_SOCKET_NAMESPACES'

export default {
  audioSelected (state: State) {
    return (deviceId: string) => {
      return state.audioControls.deviceId === deviceId
    }
  },
  videoSelected (state: State) {
    return (deviceId: string) => {
      return state.videoControls.deviceId === deviceId
    }
  },
  devices (state: State) {
    return state.devices
  },
  audioDevices (state: State) {
    return state.devices.filter(device => device.kind === 'audioinput')
  },
  videoDevices (state: State) {
    return state.devices.filter(device => device.kind === 'videoinput')
  },
  microphoneEnable (state: State) {
    return state.call.enableMicrophone
  },
  webcamEnable (state: State) {
    return state.call.enableWebcam
  },
  ownUserConected (state: State) {
    return state.users_conected.find(obj => obj.sid === state.sid)
  },
  callState (state: State) {
    return state.call
  },
  controlsState (state: State) {
    return state.controls
  },
  isType (state: State) {
    return (...type: CALL_TYPE[]) => {
      return type.includes(state.call.type)
    }
  },
  isState (state: State) {
    return (...cState: CALL_STATE[]) => {
      return cState.includes(state.call.incomingCall)
    }
  },
  isOwnUser (state: State) {
    return (sid: string) => {
      return state.sid === sid
    }
  },
  countUsersAll (state: State) {
    return state.users_conected.length
  },
  countUsersConductor (state: State, getters:any, rootState:any, rootGetters:any) {
    return state.users_conected.filter(user => {
      // Evito mostrar los usuarios que provienen del socket con namespace ded chat
      if (user.namespace === CONSOLE_SOCKET_NAMESPACES.CHAT) return false
      return user.id_user_type === USER_TYPE.CONDUCTOR && rootGetters['drivers/getArrDrivers'].some((drv: Driver) => drv.item?.id_user === user.id_user)
    }).length
  },
  countUsersMonitorista (state: State) {
    return state.users_conected.filter(user => {
      // Evito mostrar los usuarios que provienen del socket con namespace ded chat
      if (user.namespace === CONSOLE_SOCKET_NAMESPACES.CHAT) return false
      return user.id_user_type !== USER_TYPE.CONDUCTOR && user.id_user_type !== USER_TYPE.CLIENTE
    }).length
  },
  countUsersCliente (state: State) {
    return state.users_conected.filter(user => {
      // Evito mostrar los usuarios que provienen del socket con namespace ded chat
      if (user.namespace === CONSOLE_SOCKET_NAMESPACES.CHAT) return false
      return user.id_user_type === USER_TYPE.CLIENTE
    }).length
  },
  getAllUsers (state: State) {
    return state.users_conected
  },
  getUsersConected (state: State, getters:any, rootState:any, rootGetters:any) {
    return state.users_conected.filter(user => {
      // Evito mostrar los usuarios que provienen del socket con namespace ded chat
      if (user.namespace === CONSOLE_SOCKET_NAMESPACES.CHAT) return false
      if (rootGetters['auth/isRol'](USER_TYPE.CONDUCTOR)) {
        // Si el usuario es de tipo conductor, no se devuelve ningun cliente
        return false
      } else if (rootGetters['auth/isRol'](USER_TYPE.CLIENTE)) {
        return user.id_user_type !== USER_TYPE.CLIENTE || rootGetters['drivers/getArrDrivers'].some((drv: Driver) => drv.item?.id_user === user.id_user)
      } else {
        return true
      }
    })
  },
  objSetUsersConectedIds (state: State) {
    const users_ids = state.users_conected.map(obj => obj.id_user)
    return new Set(users_ids)
  }
}
