import Vue from 'vue'
import Meta from 'vue-meta'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/router/Login'
import Monitoreo from '@/router/Monitoreo'
import Cliente from '@/router/Cliente'
import Conductor from '@/router/Conductor'

Vue.use(VueRouter)
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

const routes: Array<RouteConfig> = [
  Login,
  Monitoreo,
  Cliente,
  Conductor
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
