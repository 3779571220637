import { State } from '../index'
import { IReportGroup } from '@/store/interfaces/Manager.interface'

export default {
  UNSHIFT_GROUP (state: State, payload: IReportGroup) {
    state.admin.groups.unshift(payload)
  },
  PUSH_GROUP (state: State, payload: IReportGroup) {
    state.admin.groups.push(payload)
  },
  UPDATE_GROUP (state: State, { index, data }: { data: IReportGroup; index: number }) {
    Object.assign(state.admin.groups[index], data)
  },
  DELETE_GROUP (state: State, index: number) {
    state.admin.groups.splice(index, 1)
  }
}
