import store from '@/store/index'
import { Equipment as IEquipment, Model as IModel, IEquipmentClass, Brand } from '@/store/interfaces/Binnacle'
import Sim from './Sim'
import format from '@/utils/formatLuxonDate'

export default class Equipment implements IEquipment {
  eq_esn!: string
  eq_imei!: string
  eq_status_active!: boolean
  eq_status_work!: boolean
  id_brand!: number
  id_equipment!: number
  id_model!: number
  id_sim!: number | null
  is_deleted!: boolean
  id_equipment_class!: number | null
  eq_created_at!: string

  constructor ({ data }: { data: IEquipment }) {
    Object.assign(this, data)
  }

  public get sim (): Sim | undefined {
    // @ts-ignore
    return store.state.binnacle.data.sims.find(sim => sim.id_sim === this.id_sim)
  }

  public get model (): IModel | undefined {
    // @ts-ignore
    return store.state.binnacle.data.models.find(mod => mod.id_model === this.id_model)
  }

  public get classification (): IEquipmentClass | undefined {
    // @ts-ignore
    return store.state.binnacle.data.equipments_class.find(obj => obj.id_equipment_class === this.id_equipment_class)
  }

  public get brand (): Brand | undefined {
    // @ts-ignore
    return store.state.binnacle.data.brands.find(obj => obj.id_brand === this.id_brand)
  }

  public get mod_name () {
    return this.model?.mod_name
  }

  public get eqc_name () {
    return this.classification?.eqc_name
  }

  public get sim_line () {
    return this.sim?.sim_line
  }

  public get sim_ccid () {
    return this.sim?.sim_ccid
  }

  public get sim_status_active () {
    return this.sim?.sim_status_active
  }

  public get bra_name () {
    return this.brand?.bra_name
  }

  public get plan_name () {
    return this.sim?.plan_name
  }

  public get plan_voice () {
    return this.sim?.plan_voice
  }

  public get plan_sms () {
    return this.sim?.plan_sms
  }

  public get eq_created_at_formated () {
    return format(this.eq_created_at)
  }
}
