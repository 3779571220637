import AUnit from './AUnit.action'
import AObjects from './AObjects.action'
import AAlert from './AAlert.action'
import ADriver from './ADriver.action'

const actions = {
  AUnit,
  AObjects,
  AAlert,
  ADriver
}

export default actions

export type IActions = typeof actions
