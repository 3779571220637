import { State } from '../index'
import { IUser } from '@/store/interfaces/Manager.interface'

export default {
  UNSHIFT_USER (state: State, payload: IUser) {
    state.admin.users.unshift(payload)
  },
  PUSH_USER (state: State, payload: IUser) {
    state.admin.users.push(payload)
  },
  UPDATE_USER (state: State, { index, data }: { data: IUser; index: number }) {
    Object.assign(state.admin.users[index], data)
  },
  DELETE_USER (state: State, index: number) {
    state.admin.users.splice(index, 1)
  },
  UPDATE_USER_BREAK_TIME (state: State, { payload, horarioIndex }: { payload: any, horarioIndex: number }) {
    // @ts-ignore
    const horario = state.admin.horarios[horarioIndex]
    horario.break_time_start = payload.break_time_start
    horario.break_time_finish = payload.break_time_finish
    horario.initCountDate()
  },
  UPDATE_HORARIO_USER (state: State, payload: any) {
    // @ts-ignore
    const horarioIndex = state.admin.horarios.findIndex(h => h.id_user === payload.id_user)
    if (horarioIndex !== -1) {
      // @ts-ignore
      const horario = state.admin.horarios[horarioIndex]
      Object.assign(horario, payload)
      horario.initCountDate()
    }
  }
}
