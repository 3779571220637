export default function (resourcesId: number | number[], lat: number, lon: number, geofencesIds: number[] = [], radius: number | null = null): Promise<Array<number>> {
  return new Promise((resolve, reject) => {
    let currResourcesId = resourcesId
    if (typeof currResourcesId === 'number' || typeof currResourcesId === 'string') {
      currResourcesId = [Number(currResourcesId)]
    }

    const params = {
      lat: lat,
      lon: lon,
      ...(radius ? { radius } : {}),
      zoneId: currResourcesId.reduce((acc, curr) => {
        acc[`${curr}`] = geofencesIds
        return acc
      }, {} as Record<string, number[]>)
    }

    // @ts-ignore
    wialon.util.Helper.getZonesInPoint(params, (code: string, result: Record<string, any>) => {
      if (code) {
        reject(code)
      } else {
        if (radius) {
          // @ts-ignore
          const geofencesIds: number[] = currResourcesId.reduce((acc: number[], curr: number) => {
            const currIds = Object.keys(result[`${curr}`] ?? {}).map(key => Number(key))
            return acc.concat(currIds)
          }, [] as number[])
          resolve(geofencesIds)
        } else {
          // @ts-ignore
          const geofencesIds: number[] = currResourcesId.reduce((acc: number[], curr: number) => {
            const currIds = result[`${curr}`]
            console.log(currIds)
            return acc.concat(currIds)
          }, [] as number[])
          resolve(geofencesIds)
        }
      }
    })
  })
}
// 19171370
// 18.030766
// -92.914761
