import { State } from '../state';

export default {
  invItemsObj (state: State) {
    return state.data.inv_items
  },
  invItemsArr (state: State) {
    return Object.values(state.data.inv_items)
  }
}
