import getSearchItems from './getSearchItems'

export default function (aditionalFlags?: number, resource_ids: number[] = []) {
  return new Promise<any[]>((resolve, reject) => {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    // flags to specify what kind of data should be returned
    // @ts-ignore
    const flags = wialon.item.Item.dataFlag.base | (aditionalFlags)
    const type = resource_ids.length ? 'col' : 'type'
    const data = resource_ids.length ? resource_ids : 'avl_resource'
    sess.updateDataFlags( // load items to current session
      [{ type, data, flags, mode: 0 }],
      async function (code: string) { // updateDataFlags callback
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          const res = sess.getItems('avl_resource')
          const propValueMask = resource_ids.length ? resource_ids.join(',') : '*'
          const resources = await getSearchItems(sess, { flags, itemsType: 'avl_resource', propValueMask })
          if (!res || !res.length) {
            reject(Error('No resources found'))
          } else {
            resolve(resources)
          }
        }
      })
  })
}
