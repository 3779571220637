import { ActionContext } from 'vuex';
import { State } from './state';
import { Socket } from 'socket.io-client';
import { ConnectionData, UserConected } from '@/interfaces/Comunications.interface';
import { Peer } from 'peerjs'
import { CALL_STATE } from '@/store/catalogs/CALL_STATE';
import { CALL_TYPE } from '@/store/catalogs/CALL_TYPE';
import requestLocalVideo from '@/utils/requestLocalVideo';
import { RTCIceConnectionState } from '@/store/catalogs/RTCIceConnectionState';
import { trycatch } from '@/utils/trycatch';

export default {
  async init ({ commit, dispatch, rootState, rootGetters }: ActionContext<State, string>) {
    if (!rootGetters['auth/comunicationsEnabled']) return
    // @ts-ignore
    const socket: Socket = rootState.sys.socket

    socket.on('connect', () => commit('SAVE_SID', socket.id))
    socket.on('users_changed', data => dispatch('socket_usersChanged', Object.values(data)))
    socket.on('user_conected', data => dispatch('socket_usersConected', data))
    socket.on('user_disconected', data => dispatch('socket_userDisconected', data))
    socket.on('user_requestConnect', data => dispatch('socket_userRequestConnect', data))
    socket.on('user_responceConnect', data => dispatch('socket_userResponceConnect', data))
    socket.on('recive_zumbido', data => dispatch('socket_reciveZumbido', data))

    commit('SET_STATE', { devices: await trycatch(async () => await navigator.mediaDevices.enumerateDevices(), []) })

    try {
      await dispatch('initPeerConection')
      commit('SET_STATE', { callsEnable: true })
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'PEER_SERVER_INIT', color: 'error', message: '', error }, { root: true })
    }
  },
  socket_reciveZumbido ({ dispatch }: ActionContext<State, string>, data: { id_user: number; us_name: string }) {
    dispatch('sys/makeZumbido', {}, { root: true })
    dispatch('sys/showNotificationMessage', {
      title: `¡${data.us_name} te ha enviado un zumbido!`.toUpperCase(),
      color: 'plum'
    }, { root: true });
  },
  socket_usersChanged (context: ActionContext<State, string>, data: UserConected[]) {
    context.commit('SET_USERS_CONECTED', data)
  },
  socket_usersConected (context: ActionContext<State, string>, data: UserConected) {
    context.commit('ADD_USER_CONECTED', data)
  },
  socket_userDisconected (context: ActionContext<State, string>, data: UserConected) {
    const index = context.state.users_conected.findIndex(obj => obj.sid === data.sid)
    if (index === -1) return
    context.commit('REMOVE_USER_CONECTED', index)
  },
  socket_userRequestConnect ({ state, rootState, commit }: ActionContext<State, string>, data: { homesid: string; foreingsid: string; type: CALL_TYPE, data: ConnectionData }) {
    commit('SET_CONTROLS_STATE', data.data.controls)
    commit('SET_CALL_STATE', { enableMicrophone: data.data.controls.audio })
    commit('SET_CALL_STATE', { enableWebcam: data.data.controls.video })
    // @ts-ignore
    const socket: Socket = rootState.sys.socket
    socket.emit('userResponceConnect', { ...data, peerId: state.peerId, homesid: data.homesid, foreingsid: data.foreingsid, type: data.type })
  },
  socket_userResponceConnect ({ state, dispatch }: ActionContext<State, string>, data: { peerId: string; homesid: string; foreingsid: string; type: CALL_TYPE, data: ConnectionData }) {
    console.log(data.data)
    const user = state.users_conected.find(obj => obj.sid === data.foreingsid)
    if (!user) return
    dispatch('doCall', { ...data, user, type: data.type, peerId: data.peerId, sid: data.foreingsid })
  },
  async getDevices ({ commit }: ActionContext<State, string>) {
    const devices = await trycatch(async () => await navigator.mediaDevices.enumerateDevices(), [])
    commit('SET_STATE', { devices })
  },
  async changeAudioControls ({ state, dispatch, commit }: ActionContext<State, string>, config: { deviceId: string }) {
    try {
      commit('SET_AUDIO_CONTROLS', config)
      if (state.call.incomingCall !== CALL_STATE.IS_ON_CALL) return;
      const stream = await requestLocalVideo({ video: false, audio: { deviceId: config.deviceId } })
      const track = stream.getAudioTracks().at(0)

      if (!track) {
        dispatch('sys/showNotificationMessage', {
          title: 'No se pudo cambiar a este dispositivo',
          color: 'error'
        }, { root: true });
        return
      }

      const sender = state.call.call?.peerConnection.getSenders().find(obj => obj.track?.kind === 'audio')
      if (!sender) return
      sender.replaceTrack(track)
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CHANGE_AUDIO_CONTROLS', color: 'error', message: '', error }, { root: true })
      dispatch('sys/showNotificationMessage', {
        title: 'No se pudo cambiar a este dispositivo',
        color: 'error'
      }, { root: true });
    }
  },
  async changeVideoControls ({ state, dispatch, commit }: ActionContext<State, string>, config: { deviceId: string }) {
    try {
      commit('SET_VIDEO_CONTROLS', config)
      if (state.call.incomingCall !== CALL_STATE.IS_ON_CALL) return;
      const stream = await requestLocalVideo({ video: { deviceId: config.deviceId }, audio: false })
      const track = stream.getVideoTracks().at(0)

      if (!track) {
        dispatch('sys/showNotificationMessage', {
          title: 'No se pudo cambiar a este dispositivo',
          color: 'error'
        }, { root: true });
        return
      }

      const sender = state.call.call?.peerConnection.getSenders().find(obj => obj.track?.kind === 'video')
      if (!sender || !sender.track) return
      sender.track.stop()
      sender.replaceTrack(track)
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CHANGE_VIDEO_CONTROLS', color: 'error', message: '', error }, { root: true })
      dispatch('sys/showNotificationMessage', {
        title: 'No se pudo cambiar a este dispositivo',
        color: 'error'
      }, { root: true });
    }
  },
  requestUserConnect ({ state, rootState }: ActionContext<State, string>, { type, sid }: { sid: string; type: CALL_TYPE }) {
    // @ts-ignore
    const socket: Socket = rootState.sys.socket
    socket.emit('userRequestConnect', { homesid: state.sid, foreingsid: sid, type, data: { controls: state.controls } })
  },
  async initPeerConection ({ commit, state, dispatch, rootState }: ActionContext<State, string>) {
    const peer = new Peer({
      // @ts-ignore
      host: rootState.sys.strings.PEER_HOST,
      // @ts-ignore
      path: rootState.sys.strings.PEER_URL,
      debug: 3,
      config: {
        // @ts-ignore
        iceServers: rootState.sys.strings.ICE_SERVERS
      }
    });

    peer.on('open', function () {
      commit('SAVE_PEER_ID', peer.id)
    });

    // When someone connects to your session:
    peer.on('connection', (connection) => {
      if (state.call.incomingCall === CALL_STATE.IS_ON_CALL) return
      commit('SET_CALL_STATE', { connection })
    });

    peer.on('call', function (call) {
      if (state.call.incomingCall === CALL_STATE.IS_ON_CALL) return
      switch (state.call.connection?.metadata.type) {
        case CALL_TYPE.ONE_TO_ONE: {
          const user: UserConected = state.call.connection?.metadata.user
          dispatch('sys/showNotificationMessage', {
            title: 'Llamada entrante',
            color: 'success'
          }, { root: true });
          commit('SET_CALL_STATE', { type: CALL_TYPE.ONE_TO_ONE, incomingCall: CALL_STATE.INCOMING_CALL, users: [user], call })
          dispatch('initCallPrompt')

          call.on('stream', function (stream) {
            dispatch('stopCallPromtAlarm')
            const user: UserConected = state.call.connection?.metadata.user
            const index = state.call.users.findIndex(obj => obj.sid === user.sid)
            if (index === -1) return
            commit('SET_USER_CALL_STREAM', { index, stream })
            state.call.users[index].stream = stream;
            commit('SET_STATE', { key: state.key + 1 })
          });
        }
      }

      // @ts-ignore
      call.on('iceStateChanged', type => {
        switch (type) {
          case RTCIceConnectionState.DISCONNECTED || RTCIceConnectionState.FAILED || RTCIceConnectionState.CLOSED: {
            dispatch('sys/showNotificationMessage', {
              title: 'La llamada a finalizado',
              color: 'warning'
            }, { root: true });
            dispatch('stopUserStreamCall')
            dispatch('cleanComunicationsMessage')
            commit('CLEAN_CALL_STATE')
            commit('SET_STATE', { key: state.key + 1 })
          }
        }
      })
    });

    peer.on('disconnected', function () {
      setTimeout(() => {
        dispatch('stopUserStreamCall')
        peer.disconnect()
        commit('SAVE_PEER', null)
        dispatch('initPeerConection')
      }, 10000)
    });

    commit('SAVE_PEER', peer)
  },
  async getUsersConected ({ dispatch }: ActionContext<State, string>) {
    try {
      const { data }: { data: UserConected[] } = await dispatch(
        'sys/axios',
        {
          url: 'users_conected/get/all',
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_USERS_CONECTED', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async playCallPromtAlarm ({ state }: ActionContext<State, string>, payload: { src: string }) {
    state.call.audio.src = payload?.src || '/audio/call.mp3'
    state.call.audio.loop = true
    await state.call.audio.play()
  },
  stopCallPromtAlarm ({ state }: ActionContext<State, string>) {
    state.call.audio.pause()
    state.call.audio.currentTime = 0
  },
  async initCallPrompt ({ state, dispatch }: ActionContext<State, string>): Promise<void> {
    if (state.call.incomingCall === CALL_STATE.IS_ON_CALL) return

    try {
      if (state.call.type === CALL_TYPE.ONE_TO_ONE) {
        const user = state.call.users.at(0)
        if (!user) return

        await dispatch('showComunicationMessage', {
          title: `Llamada entrante: ${user.us_name}`
        })
      }

      await dispatch('playCallPromtAlarm')
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'INIT_CALL_INIT', color: 'error', message: '', error }, { root: true })
    }
  },
  async declineCallPrompt ({ dispatch, commit }: ActionContext<State, string>): Promise<void> {
    try {
      dispatch('stopCallPromtAlarm')
      dispatch('stopUserStreamCall')
      dispatch('cleanComunicationsMessage')
      commit('CLEAN_CALL_STATE')
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DECLINE_CALL_PROMPT', color: 'error', message: '', error }, { root: true })
    }
  },
  async acceptCallPrompt ({ dispatch, commit, state }: ActionContext<State, string>): Promise<void> {
    try {
      dispatch('stopCallPromtAlarm')
      console.log(state.controls)
      const stream = await requestLocalVideo(state.controls)
      commit('SET_CALL_STATE', { incomingCall: CALL_STATE.IS_ON_CALL, stream })
      // @ts-ignore
      state.call?.call?.answer(stream);

      dispatch('sys/changeMonitTab', 'tab-Comunicaciones', { root: true })
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'ACCEPT_CALL_PROMPT', color: 'error', message: '', error }, { root: true })
    }
  },
  async toggleCallWebcam ({ dispatch, commit, state }: ActionContext<State, string>): Promise<void> {
    try {
      if (state.call.enableWebcam) {
        // @ts-ignore
        const sender = state.call.call.peerConnection.getSenders().find(obj => obj.track?.kind === 'video')
        if (!sender || !sender.track) return
        sender.track.enabled = false
        commit('SET_CALL_STATE', { enableWebcam: false })
      } else {
        // @ts-ignore
        const sender = state.call.call.peerConnection.getSenders().find(obj => obj.track?.kind === 'video')
        if (!sender || !sender.track) return
        sender.track.enabled = true
        commit('SET_CALL_STATE', { enableWebcam: true })
      }
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'TOGGLE_CALL_WEBCAM', color: 'error', message: '', error }, { root: true })
    }
  },
  async toggleCallMicrophone ({ dispatch, commit, state }: ActionContext<State, string>): Promise<void> {
    try {
      if (state.call.enableMicrophone) {
        // @ts-ignore
        const sender = state.call.call.peerConnection.getSenders().find(obj => obj.track?.kind === 'audio')
        if (!sender || !sender.track) return
        sender.track.enabled = false
        commit('SET_CALL_STATE', { enableMicrophone: false })
      } else {
        // @ts-ignore
        const sender = state.call.call.peerConnection.getSenders().find(obj => obj.track?.kind === 'audio')
        if (!sender || !sender.track) return
        sender.track.enabled = true
        commit('SET_CALL_STATE', { enableMicrophone: true })
      }
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'TOGGLE_CALL_MICROPHONE', color: 'error', message: '', error }, { root: true })
    }
  },
  async doCall ({ dispatch, state, getters, commit }: ActionContext<State, string>, { user, type, peerId, sid }: { user: UserConected; type: CALL_TYPE, peerId: string; sid: string; data: ConnectionData }): Promise<void> {
    try {
      switch (type) {
        case CALL_TYPE.ONE_TO_ONE: {
          const connection = state?.peer?.connect(peerId, {
            metadata: {
              user: getters.ownUserConected,
              type
            }
          });

          const stream = await requestLocalVideo(state.controls)
          commit('SAVE_USER_STREAM', stream)
          // @ts-ignore
          const call = state.peer?.call(peerId, stream);
          dispatch('sys/showNotificationMessage', {
            title: 'Iniciando llamada',
            color: 'success'
          }, { root: true });
          commit('SET_CALL_STATE', { connection, call, incomingCall: CALL_STATE.MAKING_CALL, users: [user], type })
          await dispatch('playCallPromtAlarm', { src: '/audio/waiting.mp3' })
          await dispatch('showComunicationMessage', {
            title: `Llamando a: ${user.us_name}`
          })

          // @ts-ignore
          call.on('stream', function (stream) {
            commit('SET_CALL_STATE', { incomingCall: CALL_STATE.IS_ON_CALL })
            dispatch('stopCallPromtAlarm')
            const index = state.call.users.findIndex(obj => obj.sid === sid)
            if (index === -1) return
            state.call.users[index].stream = stream;
            commit('SET_STATE', { key: state.key + 1 })
          });

          // @ts-ignore
          call.on('iceStateChanged', type => {
            switch (type) {
              case RTCIceConnectionState.DISCONNECTED || RTCIceConnectionState.FAILED || RTCIceConnectionState.CLOSED: {
                dispatch('sys/showNotificationMessage', {
                  title: 'La llamada a finalizado',
                  color: 'warning'
                }, { root: true });
                dispatch('stopUserStreamCall')
                dispatch('cleanComunicationsMessage')
                commit('CLEAN_CALL_STATE')
                commit('SET_STATE', { key: state.key + 1 })
              }
            }
          })
        }
      }
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DO_CALL', color: 'error', message: '', error }, { root: true })
    }
  },
  async cancelCall ({ dispatch, commit, state }: ActionContext<State, string>): Promise<void> {
    try {
      dispatch('stopCallPromtAlarm')

      dispatch('sys/showNotificationMessage', {
        title: 'La llamada a finalizado',
        color: 'warning'
      }, { root: true });
      dispatch('stopUserStreamCall')
      dispatch('cleanComunicationsMessage')
      commit('CLEAN_CALL_STATE')
      commit('SET_STATE', { key: state.key + 1 })
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CANCEL_CALL', color: 'error', message: '', error }, { root: true })
    }
  },
  stopUserStreamCall ({ state, commit }: ActionContext<State, string>) {
    if (!state.call.call) return;
    if (state.call.call.peerConnection) state.call.call.peerConnection.getSenders().forEach(obj => obj.track?.stop())
    if (state.call.stream) state.call.stream?.getTracks().forEach(function (track) { track.stop() });
    if (state.call?.call?.localStream) {
      state.call.call.localStream?.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    if (state.call?.call) state.call?.call?.close()
    if (state.call?.connection) state.call?.connection?.close()
    commit('SAVE_USER_STREAM', null)
  },
  showComunicationMessage (_: ActionContext<State, string>, notPayload: {
    title: string,
    color: string,
    icon?: string,
    text?: string,
    duration?: number,
    data?: any
    group?: string
  }): void {
    const properties = {
      title: notPayload.title,
      icon: notPayload.icon || 'mdi-phone',
      text: notPayload.text || '',
      type: notPayload.color || 'success',
      duration: notPayload.duration || -1,
      group: notPayload.group || 'comunication',
      speed: 1,
      data: notPayload.data,
      position: 'bottom right'
    }

    // @ts-ignore
    this._vm.$notify(properties)
  },
  cleanComunicationsMessage (_: ActionContext<State, string>) {
    // @ts-ignore
    this._vm.$notify({
      clean: true,
      group: 'comunication'
    });
  }
};
