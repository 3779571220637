/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (): Promise<void> => {
  return new Promise((resolve, reject) => {
    wialon.core.Session.getInstance().logout( // if user exist - logout
      function (code) { // logout callback
        if (code) reject(wialon.core.Errors.getErrorText(code)) // logout failed, print error
        else resolve() // logout suceed
      }
    )
  })
}
