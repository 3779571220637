import store from '@/store/index'
import { DateTime } from 'luxon';
import { IInstalation as IInstallation, InstallationFile } from '@/store/interfaces/Binnacle'

export default class Installation implements IInstallation {
  [key: string]: any;
  act_name!: string;
  eq_imei!: string;
  id_action!: number;
  id_equipment!: number;
  id_installation!: number;
  id_sim!: number;
  id_technician!: number;
  ins_data_additional!: { [key: string]: any; };
  ins_date_created!: string;
  ins_date_updated!: string;
  sim_line!: string;
  tec_name!: string;
  rowNum!: number
  ins_date_scheduled!: string | null;
  ins_files!: InstallationFile[];

  constructor (params: { data: IInstallation }) {
    this.initInsData(params)
  }

  public initInsData ({ data }: { data: IInstallation }) {
    delete data?.mod_name
    // Object.assign(this, data)
    const ins_date_created_data = data.ins_date_created.replace(' ', 'T').substring(0, 26);
    const ins_date_scheduled_data = data.ins_date_scheduled ? data.ins_date_scheduled.replace(' ', 'T').substring(0, 26) : '';
    const timeoffset = DateTime.now().setZone(DateTime.local().zoneName).offset / 60

    const ins_date_created = DateTime.fromISO(ins_date_created_data).plus({ hours: timeoffset }).toFormat('yyyy-LL-dd HH:mm')
    // @ts-ignore
    const ins_date_scheduled = ins_date_scheduled_data ? DateTime.fromISO(ins_date_scheduled_data).plus({ hours: timeoffset }).toFormat('yyyy-LL-dd HH:mm') : null

    Object.assign(this, { ...data, ins_date_created, ins_date_scheduled })
  }

  public get passScheduledDate () {
    if (!this.ins_date_scheduled) return false
    const fechaDada = DateTime.fromISO(this.ins_date_scheduled);
    const fechaActual = DateTime.now();
    return fechaDada > fechaActual
  }

  public get mod_name () {
    // @ts-ignore
    const equipment = store.state.binnacle.data.equipments.find(obj => obj.id_equipment === this.id_equipment)
    if (!equipment) return ''

    // @ts-ignore
    const model = store.state.binnacle.data.models.find(obj => obj.id_model === equipment.id_model)
    if (!model) return ''

    return model.mod_name
  }

  public get expirationDays () {
    if (!this.custom_column_ins_date_expiration) return 0

    const currDate = DateTime.now();
    const ins_date_expiration = DateTime.fromISO(this.custom_column_ins_date_expiration);

    const diff = ins_date_expiration.diff(currDate);

    // @ts-ignore
    const days = parseInt(diff.as('days')) + 1

    if (days <= 0) return 0

    return days
  }

  public get isExpired () {
    if (!this.custom_column_ins_date_expiration) return false
    if (this.expirationDays < 30) return true
  }
}
