export default function (dateFromPhp: number): string {
  const date = new Date(dateFromPhp * 1000)

  const values = {
    year: date.getFullYear(),
    month: (date.getMonth() + 1) < 9 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1),
    days: date.getDate() < 9 ? `0${date.getDate()}` : date.getDate(),
    hours: date.getHours() < 9 ? `0${date.getHours()}` : date.getHours(),
    minutes: date.getMinutes() < 9 ? `0${date.getMinutes()}` : date.getMinutes(),
    seconds: date.getSeconds() < 9 ? `0${date.getSeconds()}` : date.getSeconds()
  }

  return `${values.days}/${values.month}/${values.year} - ${values.hours}:${values.minutes}`
}
