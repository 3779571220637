import { ActionContext } from 'vuex';
import { State } from '../state';
import { IChatFileAttached } from '@/store/interfaces/Chat';
import { USER_TYPE } from '@/store/catalogs/USER_TYPE';

export default {
  async createChatFileAttached ({ dispatch }: ActionContext<State, any>, payload: FormData) {
    try {
      const { data }: { data: IChatFileAttached } = await dispatch(
        'axios',
        {
          url: 'chat/item/file/add',
          method: 'POST',
          data: payload,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_FILE_CHAT_ATTACHED', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteChatFileAttached ({ dispatch }: ActionContext<State, any>, id_chat_file_attached: number) {
    try {
      const { data }: { data: IChatFileAttached } = await dispatch(
        'axios',
        {
          url: `chat/item/file/delete/${id_chat_file_attached}`,
          method: 'DELETE'
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_FILE_CHAT_ATTACHED', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatFileAttached ({ dispatch }: ActionContext<State, any>, payload: Partial<IChatFileAttached>) {
    try {
      const { data }: { data: IChatFileAttached } = await dispatch(
        'axios',
        {
          url: `chat/item/file/update/properties/${payload.id_chat_file_attached}`,
          method: 'PUT',
          data: payload
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_FILE_CHAT_ATTACHED', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  socket_chatFileAttachedAdded (context: ActionContext<State, any>, data: IChatFileAttached) {
    const chat = context.state.chats[data.id_chat_item]
    if (!chat) return

    chat.chat_files_attached.push(data)

    if (!context.rootGetters['auth/isRol'](USER_TYPE.OPERACIONES, USER_TYPE.MONITOREO, USER_TYPE.ADMINISTRADOR)) return

    context.dispatch('sys/showNotificationMessage', {
      title: `Se cargo el documento ${data.cfa_title} al chat ${chat.chatName}`,
      color: 'info',
      duration: -1
    }, { root: true });

    context.dispatch('sys/playSound', { type: 'done' }, { root: true })
    chat.selectDefaultAttachedDocument()
  },
  socket_chatFileAttachedError ({ dispatch }: ActionContext<State, any>, data: { cfa_file_document_filename: string }) {
    dispatch('sys/showNotificationMessage', {
      title: `No se pudo cargar el documento ${data.cfa_file_document_filename}`,
      color: 'error',
      duration: -1
    }, { root: true });
  },
  socket_chatFileAttachedDeleted (context: ActionContext<State, any>, data: IChatFileAttached) {
    const chat = context.state.chats[data.id_chat_item]
    if (!chat) return

    const index = chat.chat_files_attached.findIndex(chat_file => chat_file.id_chat_file_attached === data.id_chat_file_attached)
    if (index === -1) return

    chat.chat_files_attached.splice(index, 1)
    chat.selectDefaultAttachedDocument()
  },
  socket_chatFileAttachedUpdated (context: ActionContext<State, any>, data: IChatFileAttached) {
    const chat = context.state.chats[data.id_chat_item]
    if (!chat) return

    const index = chat.chat_files_attached.findIndex(chat_file => chat_file.id_chat_file_attached === data.id_chat_file_attached)
    if (index === -1) return

    Object.assign(chat.chat_files_attached[index], data)
  }
}
