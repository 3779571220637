import { BinnacleData, IEquipmentClass, IInstalation as IInstallation } from '@/store/interfaces/Binnacle';
import { State } from './state';
import Installation from '@/store/packages/Binnacle/Installation';
import Technician from '@/store/packages/Binnacle/Technician';
import Equipment from '@/store/packages/Binnacle/Equipment';
import Sim from '@/store/packages/Binnacle/Sim';
import InventoryItem from '@/store/packages/Binnacle/InventoryItem';

export default {
  SET_CURR_COMPONENT (state: State, component: string) {
    state.component = component
  },
  SET_TECHNICIAN_PROPERTIES_MODAL_STATE (state: State, data: typeof state.techModalProperties) {
    Object.assign(state.techModalProperties, data)
  },
  UPDATE_KEY (state: State) {
    state.keyComponent++
  },
  SET_STATE_DATA (state: State, data: BinnacleData) {
    // @ts-ignore
    if (data?.installations) data.installations = data.installations.map((obj, i) => new Installation({ data: { ...obj, rowNum: i + 1 } }));
    if (data?.technicians) data.technicians = data.technicians.map(obj => new Technician({ data: obj }));
    if (data?.equipments) data.equipments = data.equipments.map(obj => new Equipment({ data: obj }));
    if (data?.sims) data.sims = data.sims.map(obj => new Sim({ data: obj }));
    if (data?.inv_items) data.inv_items = Object.fromEntries(Object.entries(data.inv_items).map(obj => [obj.at(0), new InventoryItem({ data: obj[1] })]))
    Object.assign(state.data, data)
  },
  UPDATE_INSTALLATION (state: State, { index, data }: { data: IInstallation, index: number }) {
    state.data.installations[index].initInsData({ data })
  },
  DELETE_INSTALLATION (state: State, index: number) {
    state.data.installations.splice(index, 1)
  },
  ADD_INSTALLATION (state: State, data: IInstallation) {
    // @ts-ignore
    state.data.installations.push(new Installation({ data }))
  },
  UPDATE_EQUIPMENT_CLASS (state: State, data: { index: number; data: IEquipmentClass }) {
    Object.assign(state.data.equipments_class[data.index], data.data)
  },
  DELETE_EQUIPMENT_CLASS (state: State, index: number) {
    state.data.equipments_class.splice(index, 1)
  },
  SET_COLUMN_WIDTH (state: State, { index, width }: { index: number; width: number }) {
    // @ts-ignore
    state.data.columns[index].width = width
    state.data.columns = Object.assign([], state.data.columns)
  }
}
