import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import sys from '@/store/modules/sys'

import reports from '@/store/modules/reports/'
import steppers from './modules/steppers'

import consoleModule from '@/store/modules/console/'
import auth from '@/store/modules/auth'

import notes from '@/store/modules/notes/'
import binnacle from './modules/binnacle'

import maps from '@/store/modules/maps'
import actualizer from '@/store/modules/actualizer'

import whatsapp from '@/store/modules/whatsapp/index'
import chat from '@/store/modules/chat/index'
import SecureLS from 'secure-ls'

import geofences from '@/store/modules/geofences/'
import drivers from './modules/drivers'
import camera from './modules/camera'

import mapon from './modules/mapon'
import wialon from './modules/wialon'
import tracksolid from './modules/tracksolid'
import hikvision from './modules/hikvision'
import comunications from './modules/comunications'
import events from './modules/events'

import manager from './modules/manager'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

const store = new Store({
  modules: {
    sys,
    auth,
    reports,
    console: consoleModule,
    notes,
    maps,
    actualizer,
    whatsapp,
    geofences,
    drivers,
    mapon,
    wialon,
    tracksolid,
    binnacle,
    steppers,
    camera,
    hikvision,
    comunications,
    events,
    manager,
    chat
  },
  plugins: [
    createPersistedState({
      paths: [
        'auth',
        'sys.logs',
        'tracksolid.lastTokens',
        'reports.lastLayout',
        'reports.lastReportsSelected'
      ],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })]
})

export default store
