import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/src/locale/es'
// @ts-ignore
// import i18n from '@/i18n'
// import '@/sass/overrides.sass'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // Colores de apliacacion
        main: '#AFC7FF',
        primary: '#A79DFF',
        secondary: '#D1A9FF',
        terceary: '#E7B6FF',
        // Colores fundamentales
        error: '#ff7575',
        info: '#47aeff',
        success: '#65c969',
        warning: '#f0b811',
        disable: '#97998E',
        enable: '#BFFF76',
        warningBold: '#f05858',
        plum: '#AB47BC',
        hotPink: '#FF69B4',
        royalBlue: '#4169E1',
        text: '#332e2e',
        bgColor: '#f2f2f2'
      },
      dark: {
        // Colores de apliacacion
        main: '#4b5d67',
        primary: '#322f3d',
        secondary: '#59405c',
        terceary: '#87556f',
        // Colores fundamentales
        error: '#de1d1d',
        info: '#117dd4',
        success: '#2c9931',
        warning: '#c99906',
        disable: '#4F5049',
        enable: '#71C413',
        warningBold: '#f05858',
        plum: '#8a008a',
        hotPink: '#e32785',
        royalBlue: '#1441c7',
        text: '#eddada',
        bgColor: '#434547'
      }
    }
  }
})
