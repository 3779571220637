import { State } from './state';

export default {
  SET_RESOURCES (state: State, resources: never[]) {
    state.resources = resources
  },
  SET_STATE (state: State, data: typeof state) {
    Object.assign(state, data)
  }
}
