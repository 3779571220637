import { ActionContext } from 'vuex'
import router from '@/router/index'
import { PLATAFORM_TYPE } from '../catalogs/PLATAFORM_TYPE'
import { USER_TYPE } from '../catalogs/USER_TYPE'
import { DateTime } from 'luxon'
import axios from 'axios'
import { trycatch } from '@/utils/trycatch'
import { IFile } from '@/interfaces/IFIle.interface'
import { Socket } from 'socket.io-client'

export interface Plataform {
  id_plataform: number
  pf_color: string
  pf_name: string
  pf_token: string
  pf_data: any
}

export interface US_Config {
  binnacle_active: boolean
  camera_without_limit: boolean
  comunications_disabled: boolean
  maps_disabled: boolean
  hv_entry_url: string
  hv_access_url: string;
  hv_username: string;
  hv_password: string;
}
interface AuthState {
  prevAuth: AuthState | null
  isMonitoristUser: boolean
  is_auth: boolean
  auth_token: string
  plataforms: Plataform[]
  user_data: {
    us_config: US_Config
    id_user: number
    us_name: string
    id_company: number
    user_type: {
      id: number,
      type: string
    },
    last_login: Date | null
    prev_last_login: Date | null
    last_break: Date | null
    prev_last_break: Date | null
  },
  user_thumbnail_file: IFile | null
}

const state: AuthState = {
  prevAuth: null,
  isMonitoristUser: false,
  is_auth: false,
  auth_token: '',
  plataforms: [],
  user_data: {
    us_config: {
      binnacle_active: false,
      camera_without_limit: false,
      comunications_disabled: false,
      maps_disabled: false,
      hv_entry_url: '',
      hv_access_url: '',
      hv_username: '',
      hv_password: ''
    },
    id_user: 0,
    us_name: '',
    id_company: 0,
    user_type: {
      id: 0,
      type: ''
    },
    last_login: null,
    prev_last_login: null,
    last_break: null,
    prev_last_break: null
  },
  user_thumbnail_file: null
}

const mutations = {
  SET_AUTH_DATA (state: AuthState, authData: AuthState): void {
    Object.assign(state, authData)
  },
  SET_US_CONFIG (state: AuthState, us_config: US_Config): void {
    state.user_data.us_config = {
      ...us_config,
      binnacle_active: us_config?.binnacle_active ?? false
    }
  },
  SET_LAST_LOGIN (state: AuthState, last_login: Date): void {
    state.user_data.last_login = last_login
  },
  SET_LAST_BREAK (state: AuthState, last_break: Date): void {
    state.user_data.last_break = last_break
  },
  SAVE_PREV_LAST_LOGIN (state: AuthState): void {
    state.user_data.prev_last_login = state.user_data.last_login
  },
  SAVE_PREV_LAST_BREAK (state: AuthState): void {
    state.user_data.prev_last_break = state.user_data.last_break
  },
  LOAD_PREV_LAST_LOGIN (state: AuthState): void {
    state.user_data.last_login = state.user_data.prev_last_login
  },
  CLEAR_STATE (state: AuthState): void {
    const emphyState: AuthState = {
      prevAuth: null,
      // Si el usuario es monitorista, administrador u operaciones
      isMonitoristUser: false,
      is_auth: false,
      auth_token: '',
      user_data: {
        us_config: {
          binnacle_active: false,
          camera_without_limit: false,
          comunications_disabled: false,
          maps_disabled: false,
          hv_entry_url: '',
          hv_access_url: '',
          hv_username: '',
          hv_password: ''
        },
        id_user: 0,
        us_name: '',
        id_company: 0,
        user_type: {
          id: 0,
          type: ''
        },
        last_login: null,
        prev_last_login: null,
        last_break: null,
        prev_last_break: null
      },
      plataforms: [],
      user_thumbnail_file: null
    }

    Object.assign(state, emphyState)
  },
  RESET_PREV_USER (state: AuthState): void {
    const prevState = state.prevAuth
    Object.assign(state, {
      ...prevState,
      prevAuth: null
    })
  }
}

const actions = {
  init (context: ActionContext<AuthState, string>) {
    context.commit('SET_AUTH_DATA', {
      isMonitoristUser: context.getters.isRol(USER_TYPE.ADMINISTRADOR, USER_TYPE.MONITOREO, USER_TYPE.OPERACIONES)
   })
  },
  async isTokenExpired ({ state, dispatch, rootGetters }: ActionContext<AuthState, string>) {
    try {
      const { data: isExpired, status } = await axios.post<boolean>(`${rootGetters['sys/SS_API_URL']}/is_token_expired`, {}, {
        headers: {
          Authorization: state.auth_token
        }
      })
      if (isExpired || status !== 200) {
        await dispatch('closeSession')
        throw new Error('Token expired')
      }
    } catch (error) {
      console.error(error)
      await dispatch('closeSession')
      throw error
    }
  },
  havePlataform ({ state }: ActionContext<AuthState, string>, type: PLATAFORM_TYPE) {
    return state.plataforms.some(plt => plt.id_plataform === type)
  },
  getPlataformData ({ state }: ActionContext<AuthState, string>, type: PLATAFORM_TYPE): Plataform | undefined {
    const plataform = state.plataforms.find(ptf => ptf.id_plataform === type)
    return plataform
  },
  getToken ({ state }: ActionContext<AuthState, string>, type: PLATAFORM_TYPE): string {
    const plataform = state.plataforms.find(ptf => ptf.id_plataform === type)
    return plataform?.pf_token || ''
  },
  async makeAuthSession (context: ActionContext<AuthState, string>, userPayload: { username: string, password: string }): Promise<void> {
    try {
      const { data } = await context.dispatch(
        'sys/axios',
        {
          url: 'login',
          method: 'POST',
          data: {
            us_name: userPayload.username,
            password: userPayload.password,
            additional_info: {
              timezone: DateTime.local().zoneName,
              timeoffset: DateTime.now().setZone(DateTime.local().zoneName).offset / 60
            }
          }
        },
        { root: true }
      )

      if (!(data.is_auth)) {
        await context.dispatch('sys/showNotificationMessage', { title: 'Las credenciales no son correctas', color: 'error' }, { root: true })
      } else {
        await context.commit('SET_AUTH_DATA', data)
        // @ts-ignore
        await context.dispatch('sys/showNotificationMessage', { title: `Bienvenido ${data.user_data.us_name}`, color: 'success' }, { root: true })
        if (data.user_data.user_type.id === USER_TYPE.CLIENTE) {
          await router.push({ name: 'Cliente' })
        } else if (data.user_data.user_type.id === USER_TYPE.CONDUCTOR) {
          await router.push({ name: 'Conductor' })
        } else {
          await router.push({ name: 'Monitoreo' })
        }
      }
    } catch (error) {
      context.dispatch('sys/showNotificationMessage', { title: 'Solicitud fallida', color: 'error' }, { root: true })
      console.error(error)
    }
  },
  async getLastLogin (context: ActionContext<AuthState, string>): Promise<void> {
    try {
      const { data } = await context.dispatch(
        'sys/axios',
        {
          url: 'last_login',
          method: 'GET'
        },
        { root: true }
      )

      context.commit('SAVE_PREV_LAST_LOGIN')
      context.commit('SET_LAST_LOGIN', data.last_login)
      context.commit('SET_AUTH_DATA', data.auth_data)
    } catch (error) {
      console.error(error)
    }
  },
  async finishWork (context: ActionContext<AuthState, string>): Promise<void> {
    try {
      await context.dispatch(
        'sys/axios',
        {
          url: `finish_work/${context.state.user_data.id_user}`,
          method: 'post'
        },
        { root: true }
      )

      context.commit('SAVE_PREV_LAST_LOGIN')
      context.commit('SET_LAST_LOGIN', null)
      context.commit('sys/ADD_LOG', { title: 'FINISHING_WORK', color: 'info', message: new Date().toISOString(), payload: { user_data: context.state.user_data } }, { root: true })
    } catch (error) {
      context.dispatch('sys/addLogWithError', { title: 'FINISH_WORK', color: 'error', message: '', error }, { root: true })
      context.dispatch('sys/showNotificationMessage', {
        title: 'Ha ocurrido un error al finalizar.',
        duration: -1,
        color: 'error'
      }, { root: true })
      console.error(error)
      throw error;
    }
  },
  async startWork (context: ActionContext<AuthState, string>, last_login: Date): Promise<void> {
    try {
      const data = await context.dispatch(
        'sys/axios',
        {
          url: 'start_work',
          method: 'post',
          data: { last_login }
        },
        { root: true }
      )
      context.commit('sys/ADD_LOG', { title: 'STARTING_WORK', color: 'info', message: new Date().toISOString(), payload: { user_data: context.state.user_data } }, { root: true })
      context.commit('SET_LAST_LOGIN', data.data.last_login)
    } catch (error) {
      context.dispatch('sys/addLogWithError', { title: 'START_WORK', color: 'error', message: '', error }, { root: true })
      context.dispatch('sys/showNotificationMessage', {
        title: 'Ha ocurrido un error al iniciar.',
        duration: -1,
        color: 'error'
      }, { root: true })
      console.error(error)
      throw error;
    }
  },
  async startBreak ({ dispatch, commit }: ActionContext<AuthState, string>, last_break: Date): Promise<void> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'start_break',
          method: 'post',
          data: { last_break }
        },
        { root: true }
      )
        commit('SET_LAST_BREAK', data.last_break)
    } catch (error) {
      console.error(error)
      dispatch('sys/showMessageAlert', 'Ha ocurrido un error al iniciar el break', { root: true })
      throw error
    }
  },
  async finishBreak ({ state, dispatch, commit }: ActionContext<AuthState, string>): Promise<void> {
    try {
      await dispatch(
        'sys/axios',
        {
          url: `finish_break/${state.user_data.id_user}`,
          method: 'post'
        },
        { root: true }
        )
        commit('SAVE_PREV_LAST_BREAK')
        commit('SET_LAST_BREAK', null)
        commit('sys/ADD_LOG', { title: 'FINISHING_BREAK', color: 'info', message: new Date().toISOString(), payload: { user_data: state.user_data } }, { root: true })
    } catch (error) {
      console.error(error)
      dispatch('sys/showMessageAlert', 'Ha ocurrido un error al finalizar el break', { root: true })
      throw error
    }
  },
  async closeSession (context: ActionContext<AuthState, string>): Promise<void> {
    context.commit('sys/SET_STATE', { hideBeforeCloseDialog: true }, { root: true })
    context.dispatch('sys/showNotificationMessage', { title: 'Cerrando sesion', color: 'warning', duration: -1 }, { root: true })
    context.dispatch('sys/setLoaderDialogState', { isLoader: true, dialogMessage: 'Cerrando sesion' }, { root: true })

    try {
      try {
        // @ts-ignore
        const currScheduleTurnId: number = context.rootState.manager.schedulestate.currScheduleUserPeriodDone.id_schedule_user_done
        if (currScheduleTurnId) {
          await trycatch(() => context.dispatch('manager/doneScheduleUserPeriod', currScheduleTurnId, { root: true }), null)
        }
      } catch (error) {
        console.error(error)
      }

      // @ts-ignore
      await trycatch(() => context.rootState.sys.socket.disconnect(), null)
      context.commit('CLEAR_STATE')

      // Limpia todo lo almacenado en el localstorage
      localStorage.clear()
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  },
  async checkEvent ({ dispatch }: ActionContext<AuthState, string>, init: boolean) {
    try {
      if (init) {
        await dispatch('finishWork')
      } else {
        await dispatch('startWork', Date())
      }
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'START_WORK', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getLoginAsUserByID ({ dispatch, commit, state }: ActionContext<AuthState, string>, id_user: number) {
    try {
      const { data } = await dispatch('sys/axios',
        {
          url: `get_auth_data_by_id/${id_user}`,
          method: 'post',
          data: {
            additional_info: {
              timezone: DateTime.local().zoneName,
              timeoffset: DateTime.now().setZone(DateTime.local().zoneName).offset / 60
            }
           }
        },
        { root: true }
      )
      const currAuthData = Object.assign({}, state)
      commit('SET_AUTH_DATA', {
        prevAuth: currAuthData,
        ...data
      })
      commit('sys/SET_STATE', { hideBeforeCloseDialog: true }, { root: true })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'LOGIN_AS_USER', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async returnToPrevUser ({ dispatch, commit }: ActionContext<AuthState, string>) {
    try {
      commit('RESET_PREV_USER')
      commit('sys/SET_STATE', { hideBeforeCloseDialog: true }, { root: true })
      window.location.reload()
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'RETURN_TO_PREV_USER', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async changeThumbnail ({ dispatch, commit }: ActionContext<AuthState, string>, payload: FormData) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/update_thumbnail',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

        commit('SET_AUTH_DATA', { user_thumbnail_file: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CHANGE_THUMBNAIL_USER', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async removeThumbnail ({ dispatch, commit }: ActionContext<AuthState, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/delete_thumbnail',
          method: 'DELETE'
        },
        { root: true }
      )

        commit('SET_AUTH_DATA', { user_thumbnail_file: null })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'REMOVE_THUMBNAIL_USER', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}

const getters = {
  username (state: AuthState) {
    return state.user_data.us_name
  },
  usertype (state: AuthState) {
    return state.user_data.user_type.type
  },
  cameraWithoutLimit (state: AuthState) {
    return state.user_data.us_config.camera_without_limit
  },
  comunicationsEnabled (state: AuthState) {
    return !state.user_data.us_config.comunications_disabled
  },
  mapsEnabled (state: AuthState) {
    return !state.user_data.us_config.maps_disabled
  },
  userId (state: AuthState) {
    return state.user_data.id_user
  },
  roleId (state: AuthState) {
    return state.user_data.user_type.id
  },
  hvEntryURL (state: AuthState) {
    return state.user_data.us_config.hv_entry_url
  },
  hvUsername (state: AuthState) {
    return state.user_data.us_config.hv_username
  },
  hvPassword (state: AuthState) {
    return state.user_data.us_config.hv_password
  },
  hvAccessURL (state: AuthState) {
    return state.user_data.us_config.hv_access_url
  },
  isRol (_state: AuthState, getters: any) {
    return (...roles: [USER_TYPE]) => {
      return roles.includes(getters.roleId)
    }
  },
  idCompany (state: AuthState) {
    return state.user_data.id_company
  },
  prevUserId (state: AuthState) {
    return state.prevAuth?.user_data?.id_user
  },
  prevUserName (state: AuthState) {
    return state.prevAuth?.user_data?.us_name
  },
  userThumbnail (state: AuthState, getters:any, rootState:any, rootGetters:any) {
    return `${rootGetters['sys/SS_API_URL']}${state.user_thumbnail_file?.fl_url}`
  },
  userSocketNamespace (state: AuthState, getters:any, rootState:any) {
    // @ts-ignore
    return (rootState.sys.socket as Socket)?.nsp
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
