export default function AssingData () {
  return function _DecoratorName<T extends {new (...args: any[]): any}>(constr: T) {
    return class extends constr {
      constructor (...args: any[]) {
        super(...args)
        Object.assign(this, args[0].data)
      }
    }
  }
}
