import { ScheduleTurn } from '@/store/packages/Schedules/ScheduleTurn'
import { State } from '..'
import { IScheduleTurnPeriodType } from '@/store/interfaces/Schedule'
import ScheduleUserPeriod from '@/store/packages/Schedules/ScheduleUserPeriod'

export default {
  SET_SCHEDULE_STATE (state: State, payload: typeof state.schedulestate) {
    Object.assign(state.schedulestate, payload)
  },
  ADD_SCHEDULE_TURN (state: State, payload: ScheduleTurn) {
    state.schedulestate.schedulesTurns.push(payload)
  },
  DELETE_SCHEDULE_TURN (state: State, index: number) {
    state.schedulestate.schedulesTurns.splice(index, 1)
  },
  ADD_SCHEDULE_TURN_PERIOD_TYPE (state: State, payload: IScheduleTurnPeriodType) {
    state.schedulestate.schedulesTurnsPeriodTypes.push(payload)
  },
  DELETE_SCHEDULE_TURN_PERIOD_TYPE (state: State, index: number) {
    state.schedulestate.schedulesTurnsPeriodTypes.splice(index, 1)
  },
  ADD_SCHEDULE_USER_PERIOD (state: State, payload: ScheduleUserPeriod) {
    state.schedulestate.scheduleUsersPeriods.push(payload)
  },
  DELETE_SCHEDULE_USER_PERIOD (state: State, index: number) {
    state.schedulestate.scheduleUsersPeriods.splice(index, 1)
  }
}
