import Timer from 'easytimer.js'

export default (timer: Timer): string => {
  if (timer.getTimeValues().days >= 1) {
    return `Hace ${timer.getTimeValues().days}d ${timer.getTimeValues().hours}h ${timer.getTimeValues().minutes}m ${timer.getTimeValues().seconds}s`
  } else if (timer.getTimeValues().hours >= 1) {
    return `Hace ${timer.getTimeValues().hours}h ${timer.getTimeValues().minutes}m ${timer.getTimeValues().seconds}s`
  } else if (timer.getTimeValues().minutes >= 1) {
    return `Hace ${timer.getTimeValues().minutes}m ${timer.getTimeValues().seconds}s`
  } else {
    return `Hace ${timer.getTimeValues().seconds}s`
  }
}

export function timeFormat (timer: Timer) {
  if (timer.getTimeValues().days >= 1) {
    return `${timer.getTimeValues().days}d ${timer.getTimeValues().hours}h ${timer.getTimeValues().minutes}m ${timer.getTimeValues().seconds}s`
  } else if (timer.getTimeValues().hours >= 1) {
    return `${timer.getTimeValues().hours}h ${timer.getTimeValues().minutes}m ${timer.getTimeValues().seconds}s`
  } else if (timer.getTimeValues().minutes >= 1) {
    return `${timer.getTimeValues().minutes}m ${timer.getTimeValues().seconds}s`
  } else {
    return `${timer.getTimeValues().seconds}s`
  }
}
