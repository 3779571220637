import { ActionContext } from 'vuex';
import { State } from '../state';
import { WResource } from '@/store/interfaces/wialon/Resource.interface';

export default {
  async getApiResources ({ dispatch }: ActionContext<State, string>, params?: { flags?: number; resources_ids?: string; wialon_token?: string; session_id?: string }) {
    try {
      const { data }: { data: WResource[] } = await dispatch(
        'sys/axios',
        {
          url: 'plataform/wialon/resources',
          method: 'GET',
          params
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'WIALON_API_GET_RESOURCES', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getApiResourcesUnits ({ dispatch }: ActionContext<State, string>, { resources_ids, ...params }: { resources_ids: string; units_ids?: string; wialon_token?: string; session_id?: string; flags?: number; }) {
    try {
      const { data }: { data: WResource[] } = await dispatch(
        'sys/axios',
        {
          url: `plataform/wialon/resources/units/${resources_ids}`,
          method: 'GET',
          params
        },
        { root: true }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'WIALON_API_GET_RESOURCES_UNITS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
