/* eslint-disable node/no-callback-literal */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function (resourceIDS: number[], callback: (data: any) => void): Promise<void> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()
    // @ts-ignore
    const flags = wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.base | wialon.item.Resource.dataFlag.drivers | wialon.item.Item.dataFlag.messages | wialon.item.MDriver.flags.driver
    // @ts-ignore
    wialon.item.MDriver.registerDriverProperties()
    sess.updateDataFlags(
      [
        {
          type: 'type',
          data: 'avl_resource',
          flags: flags,
          mode: 0
        }
      ],
      function (code: string) { // updateDataFlags callback
        if (code) {
          reject(code)
        } else {
          for (let i = 0; i < resourceIDS.length; i++) {
            const resource = sess.getItem(resourceIDS[i])
            if (resource) {
              resource.addListener('updateDriver', callback)
            }
          }
          resolve()
        }
      }
    )
  })
}
