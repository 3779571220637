import { ActionContext } from 'vuex';
import { State } from '..';
import { IStep } from '@/store/interfaces/Manager.interface'

export default {
  async getAllSteps ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/steps/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { steps: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_STEPS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getById ({ dispatch }: ActionContext<State, string>, id_step: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/steps/get/${id_step}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_BY_ID_STEPS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createStep ({ dispatch, commit }: ActionContext<State, string>, payload: FormData) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'steps/step/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      );

      commit('UNSHIFT_STEP', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_STEPS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateStep ({ dispatch, commit, state }: ActionContext<State, string>, payload: IStep) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/steps/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.admin.steps.findIndex(obj => obj.id_step === payload.id_step)

      if (index !== -1) {
        commit('UPDATE_STEP', { index, data })
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_STEPS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteStep ({ dispatch, commit, state }: ActionContext<State, string>, id_step: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'steps/step/delete',
          method: 'POST',
          data: { id_step }
        },
        { root: true }
      );

      const index = state.admin.steps.findIndex(obj => obj.id_step === id_step)

      if (index !== -1) {
        commit('DELETE_STEP', index)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_STEP', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async stepUpdate (
    { dispatch }: ActionContext<State, string>, payload: { id_step: number; st_description: number; st_name: string }) {
    try {
      await dispatch(
        'sys/axios',
        {
          url: 'steps/step/update',
          method: 'POST',
          data: payload
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      dispatch('sys/addLogWithError', { title: 'STEP_UPDATE', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
