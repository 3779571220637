import { ActionContext } from 'vuex';
import { State } from '../..';
import { IScheduleUserPeriod } from '@/store/interfaces/Schedule';
import ScheduleUserPeriod from '@/store/packages/Schedules/ScheduleUserPeriod';

export default {
  async createScheduleUserPeriod ({ dispatch, commit }: ActionContext<State, string>, payload: IScheduleUserPeriod): Promise<IScheduleUserPeriod> {
    try {
      const { data }: { data: IScheduleUserPeriod } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/period/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('ADD_SCHEDULE_USER_PERIOD', new ScheduleUserPeriod({ data }))

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createScheduleUserPeriodByTurn ({ dispatch, commit }: ActionContext<State, string>, payload: { id_user: number; id_schedule_turn: number; weeks_intervals: any }): Promise<IScheduleUserPeriod[]> {
    try {
      const { data }: { data: IScheduleUserPeriod[] } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/period/create_by_turn',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      for (const item of data) {
        commit('ADD_SCHEDULE_USER_PERIOD', new ScheduleUserPeriod({ data: item }))
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getAllSchedulesUserPeriods ({ dispatch, commit }: ActionContext<State, string>, payload: { sche_start: string; sche_end: string }): Promise<IScheduleUserPeriod[]> {
    try {
      const { data }: { data: IScheduleUserPeriod[] } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/period/get/all',
          method: 'GET',
          data: payload
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { scheduleUsersPeriods: data.map(scheData => new ScheduleUserPeriod({ data: scheData })) })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_SCHEDULES_USER_PERIODS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getScheduleUserPeriodsByDates ({ dispatch }: ActionContext<State, string>, { start_date, end_date }: { start_date: string; end_date: string }): Promise<IScheduleUserPeriod[]> {
    try {
      const { data }: { data: IScheduleUserPeriod[] } = await dispatch(
        'sys/axios',
        {
          url: `schedule/user/period/get/by_user/by_dates/${start_date}/${end_date}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_SCHEDULE_USER_PERIODS_BY_DATES', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getAllScheduleUserPeriodsByDates ({ dispatch, commit }: ActionContext<State, string>, { start_date, end_date }: { start_date: string; end_date: string }): Promise<IScheduleUserPeriod[]> {
    try {
      const { data }: { data: IScheduleUserPeriod[] } = await dispatch(
        'sys/axios',
        {
          url: `schedule/user/period/get/by_dates/${start_date}/${end_date}`,
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_SCHEDULE_STATE', { scheduleUsersPeriods: data.map(scheData => new ScheduleUserPeriod({ data: scheData })) })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_SCHEDULES_USER_PERIODS_BY_DATES', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteScheduleUserPeriod ({ dispatch, commit, state }: ActionContext<State, string>, id: number): Promise<IScheduleUserPeriod> {
    try {
      const { data }: { data: IScheduleUserPeriod } = await dispatch(
        'sys/axios',
        {
          url: `schedule/user/period/delete/${id}`,
          method: 'DELETE'
        },
        { root: true }
      )

      const index = state.schedulestate.scheduleUsersPeriods.findIndex(obj => obj.id_schedule_user_period === id)

      if (index !== -1) {
        commit('DELETE_SCHEDULE_USER_PERIOD', index)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateScheduleUserPeriod ({ dispatch, state }: ActionContext<State, string>, payload: IScheduleUserPeriod): Promise<IScheduleUserPeriod> {
    try {
      const { data }: { data: IScheduleUserPeriod } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/period/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.schedulestate.scheduleUsersPeriods.findIndex(obj => obj.id_schedule_user_period === data.id_schedule_user_period)

      if (index !== -1) {
        state.schedulestate.scheduleUsersPeriods[index].rehidratateData(data)
        state.schedulestate.scheduleUsersPeriods[index].onInitInstance()
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_SCHEDULE_USER_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteScheduleUserPeriodsByIds ({ dispatch, commit, state }: ActionContext<State, string>, ids: number[]): Promise<IScheduleUserPeriod[]> {
    try {
      const { data }: { data: IScheduleUserPeriod[] } = await dispatch(
        'sys/axios',
        {
          url: 'schedule/user/period/delete/bulk/by_ids',
          method: 'DELETE',
          data: { ids_schedules_users_periods: ids }
        },
        { root: true }
      )

      for (const id of ids) {
        const index = state.schedulestate.scheduleUsersPeriods.findIndex(obj => obj.id_schedule_user_period === id)

        if (index !== -1) {
          commit('DELETE_SCHEDULE_USER_PERIOD', index)
        }
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_SCHEDULE_USER_PERIODS_BY_IDS', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
