import { EventEmitter } from 'events'
import { ItemConstructor } from '../interfaces/Item.interface'

export default abstract class Item<IType> {
    public static ids: string[] = []
    public event: EventEmitter
    public prevData: IType | null
    public lastData: IType | null
    public CHANGE_EVENT!: string

    constructor (config: ItemConstructor) {
      this.prevData = null
      this.lastData = null
      this.event = new EventEmitter()
      this.setData({ data: config.data })
      this.initActionEvents()
    }

    protected initActionEvents () {
      this.CHANGE_EVENT = `ITEM_CHANGE_${this.getId()}`
    }

    public static susbscribeUnitToData (id: string) {
      if (!Item.ids.includes(id)) {
        Item.ids.push(id)
      }
    }

    public static unsusbscribeUnitToData (id: string) {
      const index = Item.ids.findIndex((i) => i === id)
      if (index !== -1) {
        Item.ids.splice(index, -1)
      }
    }

    public reassingData () {
      Object.assign(this, this.lastData)
    }

    abstract isUpdated(data: IType): boolean;

    public setData ({ data }: { data: IType }) {
      this.onDataUpdated(this.lastData, data)
      const isUpdated = this.isUpdated(data)
      Object.assign(this, data)
      this.prevData = this.lastData
      this.lastData = data

      return isUpdated
    }

    protected onDataUpdated (prevData: IType | null, lastData: IType) {
      // --
    }

    public abstract getId(): string
}
