import ConsoleGroup from '@/store/interfaces/ConsoleGroup'
import Driver from '@/store/interfaces/Driver'
import UnitNotificationData from '@/store/packages/UnitNotification/UnitNotificationData'
import UnitNotificationGroup from '@/store/packages/UnitNotification/UnitNotificationGroup'
import { ConsoleState } from './state'

export default {
  SET_STATE (state: ConsoleState, data: ConsoleState) {
    Object.assign(state, data)
  },
  TOGGLE_STEPPER: (state: ConsoleState): void => {
    state.stepperData.isOpen = !state.stepperData.isOpen
  },
  SET_STEPPER_DATA (state: ConsoleState, notification: UnitNotificationData<unknown>): void {
    Object.assign(state.stepperData.notificationData, notification)
  },
  ADD_NOTIFICATION: async (state: ConsoleState, notification: UnitNotificationGroup): Promise<void> => {
    state.consoleNotifications.unshift(notification)
  },
  ADD_NOTIFICATION_EVENT: async (state: ConsoleState, notification: UnitNotificationGroup): Promise<void> => {
    state.consoleNotificationsEvents.unshift(notification)
  },
  REMOVE_NOTIFICATION: (state: ConsoleState, index: number): void => {
    state.consoleNotifications.splice(index, 1)
  },
  REMOVE_NOTIFICATION_EVENT: (state: ConsoleState, index: number): void => {
    state.consoleNotificationsEvents.splice(index, 1)
  },
  SET_DRIVERS (state: ConsoleState, drisversList: Array<Driver>): void {
    state.driversList = drisversList
  },
  SET_KEY_LISTENER (state: ConsoleState, groupData: { groupIndex: number, keyListener: string}): void {
    state.consoleGroups[groupData.groupIndex].keyListener = groupData.keyListener
  },
  CLEAN_KEY_LISTENER (state: ConsoleState, groupIndex: number): void {
    state.consoleGroups[groupIndex].keyListener = ''
  },
  SET_GROUPS (state: ConsoleState, consoleGroups: Array<ConsoleGroup>): void {
    state.consoleGroups = consoleGroups
  },
  SET_CGROUP_COLOR (state: ConsoleState, cgData:{ groupIndex: number, gp_color: string}): void {
    state.consoleGroups[cgData.groupIndex].gp_color = cgData.gp_color
  },
  CLEAN_DRIVERS_LIST (state: ConsoleState): void {
    state.driversList = []
  },
  CLEAN_CONSOLE_GROUPS (state: ConsoleState): void {
    state.consoleGroups = []
  },
  CLEAN_NOTIFICATIONS (state: ConsoleState): void {
    state.consoleNotifications = []
  },
  SET_NOTIFICATIONS (state: ConsoleState, notifications: UnitNotificationGroup[]): void {
    state.consoleNotifications = notifications
  }
}
