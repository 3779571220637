import { State } from '../index'
import { IStep } from '@/store/interfaces/Manager.interface'

export default {
  UNSHIFT_STEP (state: State, payload: IStep) {
    state.admin.steps.unshift(payload)
  },
  PUSH_STEP (state: State, payload: IStep) {
    state.admin.steps.push(payload)
  },
  UPDATE_STEP (state: State, { index, data }: { data: IStep; index: number }) {
    Object.assign(state.admin.steps[index], data)
  },
  DELETE_STEP (state: State, index: number) {
    state.admin.steps.splice(index, 1)
  }
}
