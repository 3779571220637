import { IRoute } from '@/store/interfaces/Route'
import { RouteDestination } from '../geofences'
import { Geofence } from '@/store/interfaces/Geofences'
import { IMarker } from '@/store/interfaces/Marker'
import { ICommonPlace } from '@/store/interfaces/Plataform'
import ReportItemData from '@/store/packages/ReportItem/ReportItemData'

const state = {
  /** Referencia al componente del mapa, para operaciones como centrarlo u otras relacionadas con leaflet */
  $ref: null as any | null,
  /** Muestra las geocercas seleccionadas de las unidades en el mapa */
  selectedGeofences: [] as Geofence[],
  /** Muestra las rutas de geocercas seleccionadas de las rutas en el mapa */
  selectedRouteGeofences: [] as RouteDestination[],
  /** Marcadores seleccionados en el mapa */
  selectedMarkers: [] as IMarker[],
  /** Rutas de google renderizadas  */
  selectedRoutes: [] as IRoute[],
  /** El id del reporte seleccionado y que tiene el foco */
  curr_id_report_item: null as number | null,
  /** La key del mapa para refresar el mapa */
  mapObjsKey: 1,
  /** Lugares comunes de la unidad actual */
  currUnitCommonPlaces: {
    reportItem: null as ReportItemData<unknown, unknown> | null,
    places: [] as ICommonPlace[],
    loading: false,
    loadingMarkerGeofences: false,
    currMarkerGeofences: [] as Geofence[]
  }
}

export type State = typeof state

export default state
