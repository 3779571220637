import { IRoute } from '@/store/interfaces/Route'
import { State } from './state'
import { IMarker } from '@/store/interfaces/Marker'

export default {
  SET_STATE (state: State, payload: typeof state) {
    Object.assign(state, payload)
  },
  SET_COMMON_PLACES_STATE (state: State, payload: typeof state.currUnitCommonPlaces) {
    Object.assign(state.currUnitCommonPlaces, payload)
  },
  SET_MAP_REF (state: State, $ref: any) {
    state.$ref = $ref
  },
  CLEAN_ALL (state: State) {
    state.selectedGeofences = []
    state.selectedRouteGeofences = []
    state.selectedMarkers = []
    state.selectedRoutes = []
    state.currUnitCommonPlaces.places = []
    state.currUnitCommonPlaces.currMarkerGeofences = []
  },
  UPDATE_OBJS_KEY (state: State) {
    state.mapObjsKey++
  },
  DELETE_ROUTE (state: State, index: number) {
    state.selectedRoutes.splice(index, 1)
  },
  SET_ROUTES (state: State, routes: IRoute[]) {
    state.selectedRoutes = routes
  },
  SET_MARKERS (state: State, markers: IMarker[]) {
    state.selectedMarkers = markers
  },
  PUSH_MARKER (state: State, marker: IMarker) {
    state.selectedMarkers.push(marker)
  },
  PUSH_ROUTES (state: State, route: IRoute) {
    state.selectedRoutes.push(route)
  }
}
