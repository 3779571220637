import { UserConected } from '@/interfaces/Comunications.interface'
import { State } from './state'
import Peer from 'peerjs'
import { CALL_STATE } from '@/store/catalogs/CALL_STATE'
import { CALL_TYPE } from '@/store/catalogs/CALL_TYPE'

export default {
  SET_STATE (state: State, objState: State) {
    Object.assign(state, objState)
  },
  SET_AUDIO_CONTROLS (state: State, payload: MediaTrackConstraints) {
    Object.assign(state.audioControls, payload)
  },
  SET_VIDEO_CONTROLS (state: State, payload: MediaTrackConstraints) {
    Object.assign(state.videoControls, payload)
  },
  SET_USERS_CONECTED (state: State, users: UserConected[]) {
    state.users_conected = users
  },
  REMOVE_USER_CONECTED (state: State, index: number) {
    state.users_conected.splice(index, 1)
  },
  ADD_USER_CONECTED (state: State, user: UserConected) {
    state.users_conected.push(user)
  },
  SAVE_SID (state: State, sid: string) {
    state.sid = sid
  },
  SAVE_PEER (state: State, peer: Peer) {
    state.peer = peer
  },
  SAVE_PEER_ID (state: State, id: string) {
    state.peerId = id
  },
  SAVE_USER_STREAM (state: State, stream: MediaStream | null) {
    state.call.stream = stream
  },
  SET_CALL_STATE (state: State, payload: typeof state.call) {
    Object.assign(state.call, payload)
  },
  SET_CONTROLS_STATE (state: State, payload: typeof state.controls) {
    Object.assign(state.controls, payload)
  },
  CLEAN_CALL_STATE (state: State) {
    const call_state: typeof state.call = {
      stream: null,
      connection: null,
      call: null,
      audio: new Audio(),
      incomingCall: CALL_STATE.FREE_TO_CALL,
      type: CALL_TYPE.ONE_TO_ONE,
      users: [],
      enableMicrophone: false,
      enableWebcam: false
    }

    state.call = call_state
  },
  SET_USER_CALL_STREAM  (state: State, { index, stream }: { index: number; stream: MediaStream }) {
    state.call.users[index].stream = stream
  }
}
