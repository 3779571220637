import { ActionContext } from 'vuex';
import { State } from '..';

export default {
  async getAllCompany ({ dispatch, commit }: ActionContext<State, string>) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/company/get/all',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { companies: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_COMPANY', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async paginateCompany ({ dispatch, commit }: ActionContext<State, string>, payload: { page: number; items: number }) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/company/paginate',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('SET_ADMIN_STATE', { companies: data })

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'PAGINATE_COMPANY', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async createCompany ({ dispatch, commit }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/company/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('UNSHIFT_COMPANY', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_COMPANY', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateCompany ({ dispatch, commit, state }: ActionContext<State, string>, payload: any) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/company/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      const index = state.admin.companies.findIndex(obj => obj.id_company === data.id_company)

      if (index !== -1) {
        commit('UPDATE_COMPANY', { data, index })
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_COMPANY', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteCompany ({ dispatch, commit, state }: ActionContext<State, string>, id_company: number) {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/company/delete/${id_company}`,
          method: 'DELETE'
        },
        { root: true }
      )

      const index = state.admin.companies.findIndex(obj => obj.id_company === id_company)

      if (index !== -1) {
        commit('DELETE_COMPANY', index)
      }

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_COMPANY', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async togggleEnableCompany ({ dispatch, state }: ActionContext<State, string>, id_company: number) {
    try {
      const { data: company } = await dispatch(
        'sys/axios',
        {
          url: `admin/company/toggle_enable/${id_company}`,
          method: 'PUT'
        },
        { root: true }
      )

      const index = state.admin.companies.findIndex(obj => obj.id_company === id_company)
      if (index === -1) return

      state.admin.companies[index].com_active = company.com_active
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'TOGGLE_ENABLE_COMPANY', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
