import { ActionContext } from 'vuex'
import { State } from '../..'
import { IScheduleTurnPeriod } from '@/store/interfaces/Schedule'

export default {
  async createTurnPeriod ({ dispatch }: ActionContext<State, string>, payload: Partial<IScheduleTurnPeriod>): Promise<IScheduleTurnPeriod> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: '/schedule/turn/period/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_SCHEDULE_TURN_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async updateTurnPeriod ({ dispatch }: ActionContext<State, string>, payload: Partial<IScheduleTurnPeriod>): Promise<IScheduleTurnPeriod> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: '/schedule/turn/period/update',
          method: 'PUT',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_SCHEDULE_TURN_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async deleteTurnPeriod ({ dispatch }: ActionContext<State, string>, id_schedule_turn_weekday_period: number): Promise<IScheduleTurnPeriod> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `/schedule/turn/period/delete/${id_schedule_turn_weekday_period}`,
          method: 'DELETE'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_SCHEDULE_TURN_PERIOD', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
