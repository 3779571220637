import {
  ITrackSolidParams
} from './interfaces/login.interface';
import defaultParams, { DefaultParams } from './defaultParams';
import { Optional } from './interfaces/utils.interface';
import AccessToken from './api/AccessToken.api'
import Api from './api/Api';
import User from './api/User.api';
import Geofence from './api/Geofence.api';
import Device from './api/Device.api';
import Message from './api/Message.api';
import Scooter from './api/Scooter.api';
import Command from './api/Command.api';
import Utils from './api/Utils.api';
import DeviceItem from './classes/Device.item';
import { EventEmitter } from 'events'

export default class TrackSolidSDK {
  private initialConfig: ITrackSolidParams & DefaultParams;
  public static event: EventEmitter = new EventEmitter()
  public api;
  public item;

  constructor (
    config: ITrackSolidParams &
      Optional<DefaultParams, 'baseURL' | 'accessToken'>
  ) {
    this.initialConfig = { ...defaultParams(), ...config };
    // === Api ===
    Api.prototype.config = this.config
    Api.config = this.config

    this.api = {
      AccessToken,
      User,
      Geofence,
      Device,
      Message,
      Scooter,
      Command,
      Utils
    }

    this.item = {
      DeviceItem
    }

    if (!config.disableInterval) {
      this.loopFetchData()
    }
  }

  private loopFetchData () {
    setInterval(async () => {
      try {
        await this.item.DeviceItem.observeMessages()
      } catch (error) {
        console.error(error)
      }
    }, this.config.intervalTime)
  }

  public set config (config) {
    this.config = config
  }

  public get config () {
    return this.initialConfig;
  }

  public static set config (config) {
    TrackSolidSDK.prototype.config = config
  }

  public static get config () {
    return TrackSolidSDK.prototype.config;
  }

  public setTarget(target: string) {
    this.config.target = target
    Api.config.target = target
  }

  public static setTarget(target: string) {
    TrackSolidSDK.prototype.config = { ...TrackSolidSDK.prototype.config, target }
  }
}
