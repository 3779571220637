import MaponSDK from '@/submodules/maponjssdk/dist'
import AObject from '@/submodules/maponjssdk/dist/classes/actions/AObjects.action'

export interface MaponState {
    sdk?: MaponSDK | undefined,
    objects: AObject[]
}

const state: MaponState = {
  sdk: undefined,
  objects: []
}

export type State = typeof state

export default state
