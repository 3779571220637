
/**
 * DWTFYW License
 *
 * Author: Tony Brix, http://tonybrix.info
 *
 * This function trims all strings in an object or array
 *
 * This is useful when sending ajax requests
 *
 * var vals = {
 *   this: $("#this").val(),
 *   that: $("#that").val(),
 *   the: {
 *     other: $("#other").val(),
 *     thing: $("#thing").val()
 *   }
 * }
 *
 * trimAll(vals);
 *
 * $.post("someurl.php", vals, function(data){
 *   alert("do something");
 * }, "json");
 *
 */

const isLiteralObject = function (a: { constructor: ObjectConstructor; }) {
  return (!!a) && (a.constructor === Object);
};

export default function trimAll (obj: any) {
  for (const prop in obj) {
    if (typeof obj[prop] === 'string') {
      obj[prop] = obj[prop].trim();
    } else if (isLiteralObject(obj[prop])) {
      trimAll(obj[prop]);
    }
  }

  return obj
}
