import AUnit from '@/submodules/maponjssdk/dist/classes/actions/AUnit.action'
import UnitNotificationData from './UnitNotificationData'

export default class UNMapon extends UnitNotificationData<AUnit> {
  getResourceId (): number {
    return 0
  }

  getIcon (): string {
    return require('@/assets/svg/s_a_35.svg')
  }

  getUnitName (): string {
    return this.resourceNotification?.unitData?.getName() || ''
  }
}
