import UnitNotificationData from './UnitNotificationData'

export default class UNWialon extends UnitNotificationData<any> {
  getResourceId (): number {
    return this.resourceNotification?.unitData.getAccountId()
  }

  getIcon (): string {
    return this.resourceNotification?.unitData.getIconUrl()
  }

  getUnitName (): string {
    return this.resourceNotification?.unitData.getName()
  }
}
