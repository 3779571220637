export default function (text:string) {
    return Array.from(text.matchAll(/\{({^\}{}*)}/g), x => x[1])
}

export function extract ([beg, end]: [string, string]) {
    const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
    const normalise = (str: string) => str.slice(beg.length, end.length * -1);
    return function (str: string) {
        const matchs = str.match(matcher)
        if (!matchs) return []
        return matchs.map(normalise)
    }
}
