import DeviceItem from '@/submodules/tracksolidjavacriptsdk/dist/classes/Device.item'
import UnitNotificationData from './UnitNotificationData'

export default class UNTrackSolid extends UnitNotificationData<DeviceItem> {
  getResourceId (): number {
    return 0
  }

  getIcon (): string {
    return require('@/assets/svg/s_a_35.svg')
  }

  getUnitName (): string {
    return this.resourceNotification?.unitData?.getName() || ''
  }
}
