export enum BINNACLE_DEFAULT_COLUMNS_NAMES {
  ACCION = 'act_name',
  EQUIPMENT = 'eq_imei',
  SIM = 'sim_line',
  TECHNICIAN = 'tec_name',
  INS_DATE_CREATED = 'ins_date_created',
  INS_DATE_SCHEDULED = 'ins_date_scheduled',
  MODEL = 'mod_name',
  INS_DATE_REGISTER = 'ins_date_created',
  INS_DATE_EXPIRATION = 'ins_date_expiration',
}

export const BINNACLE_DEFAULT_COLUMNS = [
  {
    id_column: -1,
    label: 'Accion',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.ACCION,
    isOptional: false
  },
  {
    id_column: -2,
    label: 'Equipo',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.EQUIPMENT,
    isOptional: false
  },
  {
    id_column: -3,
    label: 'SIM',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.SIM,
    isOptional: false
  },
  {
    id_column: -4,
    label: 'Tecnico',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.TECHNICIAN,
    isOptional: false
  },
  {
    id_column: -5,
    label: 'Fecha de creacion',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.INS_DATE_CREATED,
    isOptional: false
  },
  {
    id_column: -1001,
    label: 'Modelo',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.MODEL,
    isOptional: true
  },
  {
    id_column: -1002,
    label: 'Fecha de registro',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.INS_DATE_REGISTER,
    isOptional: true
  },
  {
    id_column: -1003,
    label: 'Fecha de vencimiento',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.INS_DATE_EXPIRATION,
    isOptional: true
  },
  {
    id_column: -1005,
    label: 'Fecha de agenda',
    field: BINNACLE_DEFAULT_COLUMNS_NAMES.INS_DATE_SCHEDULED,
    isOptional: true
  }
];

export enum BINNACLE_DEFAULT_COLUMNS_TYPES {
  ACCION = -1,
  EQUIPMENT = -2,
  SIM = -3,
  TECHNICIAN = -4,
  INS_DATE_CREATED = -5,
  MODEL = -1001,
  INS_DATE_REGISTER = -1002,
  INS_DATE_EXPIRATION = -1004,
  INS_DATE_SCHEDULED = -1005,
}
