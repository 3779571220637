import { ActionContext } from 'vuex';
import { State } from '..';
import { Pagination } from '@/store/interfaces/Pagination';
import { UserEventLog } from '@/store/interfaces/Manager.interface';

export default {
  async getUsersHistoryAll ({ dispatch }: ActionContext<State, string>): Promise<UserEventLog[]> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: 'admin/users/history/user_conections/all',
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'USERS_HISTORY_GET_ALL', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  },
  async getUsersHistoryPagination ({ dispatch }: ActionContext<State, string>, { page, items }: { page: number, items: number }): Promise<Pagination<UserEventLog>> {
    try {
      const { data } = await dispatch(
        'sys/axios',
        {
          url: `admin/users/history/user_conections/pagination/${page}/${items}`,
          method: 'GET'
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'USERS_HISTORY_GET_PAGINATION', color: 'error', message: '', error }, { root: true })
      throw error;
    }
  }
}
