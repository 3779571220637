import TrackSolidSDK from '@/submodules/tracksolidjavacriptsdk/dist'
import { Socket } from 'socket.io-client';
export interface TrackSolidState {
    sdk: TrackSolidSDK,
    lastTokens: {
      refresh_token: string
      access_token: string
    }
    data_video:any,
    loading:boolean,
}

const state: TrackSolidState = {
  // @ts-ignore
  sdk: null,
  data_video: null,
  lastTokens: {
    refresh_token: '',
    access_token: ''
  },
  loading: false
}

export type State = typeof state

export default state
