
import Vue from 'vue'
import IconButton from '../utils/IconButton.vue';
import { mapGetters } from 'vuex';
import { CALL_STATE } from '@/store/catalogs/CALL_STATE';

export default Vue.extend({
  data () {
    return {
      CALL_STATE
    }
  },
  methods: {
    dispatchAction (item: any) {
      this.$store.dispatch(item.data.action, item.data.payload)
    }
  },
  components: {
    IconButton
  },
  computed: {
    ...mapGetters({
      viewportWidth: 'sys/viewportWidth',
      isState: 'comunications/isState',
      controlsState: 'comunications/controlsState',
      isType: 'comunications/isType',
      microphoneEnable: 'comunications/microphoneEnable',
      webcamEnable: 'comunications/webcamEnable'
    }),
    small () {
      return !!this.$vuetify.breakpoint.mdAndDown
    }
  }
})
