/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default (aditionalFlags?: number): Promise<Array<any>> => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const flagsUnit = wialon.item.Item.dataFlag.base | wialon.item.Item.dataFlag.image | 4 | (aditionalFlags)
    // @ts-ignore
    const sess = wialon.core.Session.getInstance()

    sess.updateDataFlags( // load items to current session
      [{ type: 'type', data: 'avl_unit', flags: flagsUnit, mode: 0 }],
      function (code: string) {
        if (code) {
          // @ts-ignore
          reject(wialon.core.Errors.getErrorText(code))
        } else {
          // @ts-ignore
          const units = sess.getItems('avl_unit')
          resolve(units)
        }
      })
  })
}
