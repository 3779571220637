import { State } from '../index'
import { IClassification } from '@/store/interfaces/Manager.interface'

export default {
  UNSHIFT_CLASSIFICATION (state: State, payload: IClassification) {
    state.admin.classifications.unshift(payload)
  },
  PUSH_CLASSIFICATION (state: State, payload: IClassification) {
    state.admin.classifications.push(payload)
  },
  UPDATE_CLASSIFICATION (state: State, { index, data }: { data: IClassification; index: number }) {
    Object.assign(state.admin.classifications[index], data)
  },
  DELETE_CLASSIFICATION (state: State, index: number) {
    state.admin.classifications.splice(index, 1)
  }
}
