import { ActionContext } from 'vuex'
import { State } from '../state'
import { IChat, IChatMessage, IChatUser } from '@/store/interfaces/Chat'
import { UserSchemaSimple } from '@/store/interfaces/User'
import { UserConected } from '@/interfaces/Comunications.interface'
import { DateTime } from 'luxon'

export default {
  async getAllUserChats ({ dispatch }: ActionContext<State, any>) {
    try {
      const { data }: { data: IChat[] } = await dispatch(
        'axios',
        {
          url: 'chat/user/get/all',
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_ALL_CHATS_USER', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatUserData ({ dispatch }: ActionContext<State, any>, payload: Partial<IChatUser> & { id_chat_item: number }) {
    try {
      const { data }: { data: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage } } = await dispatch(
        'axios',
        {
          url: 'chat/user/update/data',
          method: 'PUT',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_CHAT_USER', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async addManyUsersToChat ({ dispatch }: ActionContext<State, any>, payload: { ids_users: number[]; id_chat: number; }) {
    try {
      const { data }: { data: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage } } = await dispatch(
        'axios',
        {
          url: 'chat/user/add_many',
          method: 'PUT',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'ADD_MANY_USERS_TO_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async addConsoleUsersToChat ({ dispatch }: ActionContext<State, any>, { id_chat, users }: { id_chat: number; users: any[] }) {
    try {
      const { data }: { data: { id_chat: number; chat_users: IChatUser[]; message: IChatMessage } } = await dispatch(
        'axios',
        {
          url: `chat/user/create_and_add_console_users/${id_chat}`,
          method: 'POST',
          data: users
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'ADD_CONSOLE_USERS_TO_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async removeUserFromChat ({ dispatch }: ActionContext<State, any>, payload: { id_user: number; id_chat: number; }) {
    try {
      const { data }: { data: IChat[] } = await dispatch(
        'axios',
        {
          url: 'chat/user/remove',
          method: 'DELETE',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'REMOVE_USER_FROM_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getAvalibleUsersToChat ({ dispatch }: ActionContext<State, any>, id_chat: number) {
    try {
      const { data }: { data: UserSchemaSimple[] } = await dispatch(
        'axios',
        {
          url: `chat/user/get/avaliable_users_to_chat/${id_chat}`,
          method: 'GET'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_AVALIABLE_USERS_TO_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateUserLastSeen ({ dispatch, state }: ActionContext<State, any>, id_chat: number) {
    try {
      const id_chat_user = state.chats[id_chat].getOwnChatUserId()
      const id_chat_message = state.chats[id_chat].lastChatMessage?.id_chat_message
      if (!id_chat_user || !id_chat_message) return

      const { data }: { data: IChatUser[] } = await dispatch(
        'axios',
        {
          url: `chat/user/update/last_seen/${id_chat_user}/${id_chat_message}`,
          method: 'PUT'
        }
      )

      dispatch('socket_chatUserLastSeenChanged', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_USER_LAST_SEEN', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateLastUserMessageSeenAndGetUsersLastConection ({ dispatch }: ActionContext<State, any>, { id_chat, id_chat_message, id_chat_user, id_user }: { id_chat: number; id_chat_message: number; id_user: number; id_chat_user: number }) {
    try {
      const { data }: { data: { id_chat_user: number; id_user: number; chu_last_seen_message_id: number }[] } = await dispatch(
        'axios',
        {
          url: `chat/user/update/update_last_user_message_seen_and_get_users_last_conection/${id_chat}/${id_user}/${id_chat_user}/${id_chat_message}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_USER_LAST_SEEN_AND_GET_USERS_LAST_CONECTION', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateLastUserMessageSeen ({ dispatch }: ActionContext<State, any>, { id_chat, id_chat_message, id_chat_user, id_user }: { id_chat: number; id_chat_message: number; id_user: number; id_chat_user: number }) {
    try {
      const { data }: { data: { id_chat_user: number; id_user: number; chu_last_seen_message_id: number }[] } = await dispatch(
        'axios',
        {
          url: `chat/user/update/last_seen/${id_chat}/${id_user}/${id_chat_user}/${id_chat_message}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_USER_LAST_SEEN', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async chatUserRemove ({ dispatch }: ActionContext<State, any>, payload: { id_chat: number; id_user: number }) {
    try {
      const { data }: { data: IChatUser[] } = await dispatch(
        'axios',
        {
          url: 'chat/user/remove',
          method: 'DELETE',
          data: payload
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CHAT_USER_REMOVE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async toggleChatUserAdmin ({ dispatch }: ActionContext<State, any>, { id_chat, id_chat_user }: { id_chat: number; id_chat_user: number }) {
    try {
      const { data }: { data: IChatUser[] } = await dispatch(
        'axios',
        {
          url: `chat/user/update/toggle_admin_rights/${id_chat}/${id_chat_user}`,
          method: 'PUT'
        }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'TOGGLE_ADMIN_RIGHTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  /**
   * Reloads the list of chats for the current user.
   */
  async reloadChats ({ dispatch, commit }: ActionContext<State, any>) {
    const data: IChat[] = await dispatch('getAllUserChats')
    commit('chat/SET_CHATS', data, { root: true })
  },
  /**
   * Rehidratates the data of the chats for the current user.
   */
  async rehidratateChats ({ dispatch, state }: ActionContext<State, any>) {
    const data: IChat[] = await dispatch('getAllUserChats')
    for (const chatData of data) {
      const chat = state.chats[chatData.id_chat]
      if (!chat) continue

      chat.rehidratateData(chatData)
    }
  },
  /**
 * Updates the last seen message ID for a chat user.
  *
  * @param state - The Vuex state object.
  * @param payload - The payload containing the chat user information.
  */
  socket_chatUserLastSeenChanged ({ state }: ActionContext<State, any>, payload: { id_chat: number; id_chat_message: number; id_user: number }) {
    const chatItem = state.chats[payload.id_chat]
    if (!chatItem) return

    const chatUser = chatItem.chatUsersIndexed.get(payload.id_user)
    if (!chatUser) return

    chatUser.updateProperty('chu_last_seen_message_id', payload.id_chat_message)
    chatItem.updateUserMessagesStatus()
    chatItem.updateUnreadedChatMessages()
  },
  socket_userConected (context: ActionContext<State, string>, data: UserConected) {
    context.commit('ADD_USER_CONECTED', data)
  },
  socket_userDisconected (context: ActionContext<State, string>, data: UserConected) {
    const index = context.state.chatUsersConected.findIndex(obj => obj.sid === data.sid)
    if (index === -1) return
    context.commit('REMOVE_USER_CONECTED', index)

    // Actualizo la ultima conexion del usuario que se acaba de desconectar
    for (const chat of Object.values(context.state.chats)) {
      if (!chat.chatUsersIndexed.has(data.id_user)) continue
      if (!chat.chatUsersIndexed.get(data.id_user)?.user) continue

      // No se porque le tengo que sumar el tiempo de offset para que de la hora bien
      // Pero si no la hora que te da es igual a la del timezone del usuario plus el offset
      // @ts-ignore
      chat.chatUsersIndexed.get(data.id_user).user.last_time_conected = DateTime.utc().plus({ hours: -(context.rootState.sys.timeoffset) }).toISO()
    }
  },
  socket_ChatUserUpdated (context: ActionContext<State, string>, data: { id_chat: number; chat_user: IChatUser }) {
    const chat = context.state.chats[data.id_chat]
    if (!chat) return
    chat.updateChatUserByChatUserId(data.chat_user.id_chat_user, data.chat_user)
  }
}
