import { ActionContext } from 'vuex';
import { State } from '../state';
import { IChatBotCase, IChatBotCaseToolBinded } from '@/store/interfaces/Chat';

export default {
  async getChatBotCasesByChatId ({ dispatch }: ActionContext<State, any>, id_chat_item: number) {
    try {
      const { data }: { data: IChatBotCase } = await dispatch(
        'axios',
        {
          url: `chat/item/bot/cases/get/by_item/${id_chat_item}`,
          method: 'GET'
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'GET_CHAT_BOT_CASES_BY_CHAT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async createChatBotCase ({ dispatch }: ActionContext<State, string>, payload: IChatBotCase) {
    try {
      const { data }: { data: IChatBotCase } = await dispatch(
        'axios',
        {
          url: 'chat/item/bot/cases/create',
          method: 'POST',
          data: payload
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_CHAT_BOT_CASE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatBotCase ({ dispatch }: ActionContext<State, string>, payload: Partial<IChatBotCase>) {
    try {
      const { data }: { data: IChatBotCase } = await dispatch(
        'axios',
        {
          url: 'chat/item/bot/cases/update',
          method: 'POST',
          data: payload
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_CHAT_BOT_CASE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async createChatBotCaseTool ({ dispatch }: ActionContext<State, string>, payload: IChatBotCaseToolBinded) {
    try {
      const { data }: { data: IChatBotCaseToolBinded } = await dispatch(
        'axios',
        {
          url: 'chat/item/bot/cases/tool/create',
          method: 'POST',
          data: payload
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'CREATE_CHAT_BOT_CASE_TOOL', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteChatBotCase ({ dispatch }: ActionContext<State, string>, id_chat_bot_case: number) {
    try {
      const { data }: { data: IChatBotCase } = await dispatch(
        'axios',
        {
          url: `chat/item/bot/cases/delete/${id_chat_bot_case}`,
          method: 'DELETE'
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_CHAT_BOT_CASE', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async updateChatBotCaseTool ({ dispatch }: ActionContext<State, string>, payload: Partial<IChatBotCaseToolBinded>) {
    try {
      const { data }: { data: IChatBotCase } = await dispatch(
        'axios',
        {
          url: 'chat/item/bot/cases/tool/update',
          method: 'POST',
          data: payload
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'UPDATE_CHAT_BOT_CASE_TOOL', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteChatBotCaseTool ({ dispatch }: ActionContext<State, string>, id_chat_bot_cases_tool_binded: number) {
    try {
      const { data }: { data: IChatBotCaseToolBinded } = await dispatch(
        'axios',
        {
          url: `chat/item/bot/cases/tool/delete/${id_chat_bot_cases_tool_binded}`,
          method: 'DELETE'
        }
      )
      return data
    } catch (error) {
      console.error(error)
      dispatch('sys/addLogWithError', { title: 'DELETE_CHAT_BOT_CASE_TOOL', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
