import { GEOFENCES_TYPE } from '@/store/catalogs/GEOFENCES_TYPES';
import { Geofence, ICoordenadas } from '@/store/interfaces/Geofences';
import pip from 'point-in-polygon'
import circleToPolygon from 'circle-to-polygon'

export default function (
    lat: number, lng: number,
  geofence: Geofence & {
    geo_payload: {
      type: number;
      radius?: number;
      coordinates: ICoordenadas[] | ICoordenadas;
    };
  }
) {
    if (geofence.geo_payload.type === GEOFENCES_TYPE.POLYGON) {
        // @ts-ignore
        const points: number[][] = geofence.geo_payload.coordinates.map(coor => [coor.lat, coor.lng])
        const isInside = pip([lat, lng], points)
        return isInside
    } else if (geofence.geo_payload.type === GEOFENCES_TYPE.CIRCLE) {
        // @ts-ignore
        const poligon = circleToPolygon([geofence.geo_payload.coordinates.lng, geofence.geo_payload.coordinates.lat], geofence.geo_payload.radius);
        const points: number[][] = poligon.coordinates[0].map(coor => [coor[1], coor[0]])
        const isInside = pip([lat, lng], points)
        return isInside
    }

    return false
}
