import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'

const state = {
  plataformUnits: {
    [PLATAFORM_TYPE.CONSOLA]: []
  } as { [key: number]: any[] }
}

export type State = typeof state

export default state
