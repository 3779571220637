import { State } from '..'

export default {
  currScheduleUserPeriodDone: (state: State) => {
    return state.schedulestate.currScheduleUserPeriodDone
  },
  currFormatedLabelTime: (state: State) => {
    return state.schedulestate.currFormatedLabelTime
  },
  areUserPeriodTypes (state: State) {
    return state.schedulestate.scheduleAvaliableUserPeriodsTypes.length
  }
}
