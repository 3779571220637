import { IResponce } from './interfaces/login.interface';

export interface DefaultParams {
  disableInterval: boolean
  baseURL: string;
  intervalTime: number;
  debug: boolean
  accessToken: IResponce<{
    appKey: string;
    account: string;
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
    time: string;
  }>;
}

const defaultParams = (): DefaultParams => {
  const params = {
    disableInterval: false,
    baseURL: 'https://us-open.tracksolidpro.com/route/rest',
    intervalTime: 10000,
    debug: false
  };
  // @ts-ignore
  return params;
};

export default defaultParams;
